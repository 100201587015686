import React from "react";
import EventBus from "./EventBus";
import InvoiceService from "../services/invoice.service";

export const viewPDF = async (id, fileName, isReseller, regenerate) => {
        if (fileName && !regenerate) {
			if (isReseller  && fileName.indexOf("reseller") == -1) { fileName = '../pdfs/reseller/' + fileName;}
			if (!isReseller && fileName.indexOf("invoices") == -1) { fileName = '../pdfs/invoices/' + fileName;}
			
			window.open(process.env.REACT_APP_SERVER_API + "/" + fileName, '_blank');
        }
        else{
			if (isReseller){
				await InvoiceService.viewResellerPDF(id).then(
                  (res) => {
                    if (res.data) {
                        window.open(process.env.REACT_APP_SERVER_API + "/" + res.data.data.path, '_blank');
                    }
    
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
    
                    alert(_content);
    
                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
    
                    }
                }
            );
			}else{				
				await InvoiceService.viewPDF(id, regenerate).then(
					  (res) => {
						if (res.data) {
							window.open(process.env.REACT_APP_SERVER_API + "/" + res.data.data.path, '_blank');
						}
		
					},
					(error) => {
						const _content =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();
		
						alert(_content);
		
						if (error.response && error.response.status === 401) {
							EventBus.dispatch("logout");
		
						}
					}
				);
			}
        }
     
}







