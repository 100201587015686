import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import SubscriptionService from '../services/subscription.service';
import EventBus from "../common/EventBus";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import DateFormatter from "../common/DateFromatter";

export default function ReturnPackageModal(props) {
    const currentDate = DateFormatter.getCurrentDate();

    const returnPackageModal = props.returnPackageModal;
    const setReturnPackageModal = props.setReturnPackageModal;
    const selectedSubscriptionForReturnPackage = props.selectedSubscriptionForReturnPackage;
    const list = props.list;
    const setList = props.setList;
    const isClients = props.isClients;

    const [isFrozen, setIsFrozen] = useState("");

    const markPackageAsReturned = () => {
        if (isFrozen === "") {
            alert("Please choose an action before proceeding!");
            return;
        }
        SubscriptionService.markPackageAsReturned(selectedSubscriptionForReturnPackage, isFrozen).then(
            (res) => {


                if (res && res.data && res.data.data && res.data.data.receiptId && res.data.data.receiptId != 0) {
                    //redirect to the new invoice created.
                    if (!isFrozen) alert("Package has been marked as returned.\n A draft return deposit has been created for the client.");
                    window.open("/invoice/edit/" + res.data.data.receiptId + "?subscription", '_blank');
                }
                else {
                    if (isFrozen) alert("Package has been marked as returned.\n\n Subscription is frozen. No return deposit invoice is created.");
                    else alert("Package has been marked as returned.\n\n No receipt deposit was found for the client!!!");
                    if (isClients) {
                        setList(list.map((s, i) => {
                            if (s.usersubscribe && s.usersubscribe.id === selectedSubscriptionForReturnPackage) {
                                s.usersubscribe.packageReturned = true;
                                s.usersubscribe.packageReturnedDate = currentDate;
                                s.usersubscribe.isFrozen = isFrozen;
                            }
                            return s;
                        }))
                    }
                    else {
                        setList(list.map((s, i) => {
                            if (s && s.id === selectedSubscriptionForReturnPackage) {
                                s.packageReturned = true;
                                s.packageReturnedDate = currentDate;
                                s.isFrozen = isFrozen;
                            }
                            return s;
                        }))
                    }
                }
                if (res && res.data && res.data.data && res.data.data.surchagerId && res.data.data.surchagerId != 0) {
                    //redirect to the new invoice created.
                    alert("A short period surcharge invoice has been created for the client.");
                    window.open("/invoice/edit/" + res.data.data.surchagerId + "?subscription", "_blank");
                }

            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                alert(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");

                }
            }
        );

        handleReturnPackageModalClose();
    }

    const handleReturnPackageModalClose = () => {
        setIsFrozen("");
        setReturnPackageModal(false);
    }

    return (
        <Modal
            show={returnPackageModal}
            onHide={handleReturnPackageModalClose}
        >
            <Modal.Header>
                <Modal.Title>Return Package</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form style={{ fontSize: 14 }}>
                    <span>Please confirm what action you want to do:</span>
                    <div className="d-flex flex-row align-items-baseline">
                        <Input type="radio" id="notFrozen" name="returnPackage" onChange={() => setIsFrozen(false)} />&nbsp;&nbsp;
                        <label for="notFrozen">Permanently return the package.</label>
                    </div>
                    <div className="d-flex flex-row align-items-baseline">
                        <Input type="radio" id="frozen" name="returnPackage" onChange={() => setIsFrozen(true)} />&nbsp;&nbsp;
                        <label for="frozen">Freeze the subscription. This will only return the package without creating any invoices.</label>
                    </div>
                </Form>
            </Modal.Body>
            <div className="d-flex flex-row justify-content-around">
                <button style={{ margin: '10px', fontSize: 16, width: 150 }} onClick={handleReturnPackageModalClose}>Cancel</button>
                <button style={{ margin: '10px', fontSize: 16, width: 150 }} onClick={markPackageAsReturned}>Return Package</button>
            </div>

        </Modal>
    )
}