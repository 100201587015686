import React, { useState } from "react";
import { Card, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Modal } from "react-bootstrap";

import PullDown from "./PullDown";
import RequiredLabel from "../common/RequiredLabel";
import OptionalLabel from "../common/OptionalLabel";
const TerminationModal = (props) => {
  const terminateSubscriptionModal = props.terminateSubscriptionModal;
  const handleTerminateSubscriptionModalClose =
    props.handleTerminateSubscriptionModalClose;
  const terminationCategory = props.terminationCategory;
  const setTerminationCategory = props.setTerminationCategory;
  const categories = props.categories;
  const reason = props.reason;
  const setReason = props.setReason;
  const sendReasonHandler = props.sendReasonHandler;

  return (
    <Modal
      show={terminateSubscriptionModal}
      onHide={handleTerminateSubscriptionModalClose}
    >
      <Modal.Header>
        <Modal.Title>Reason</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col>
          <RequiredLabel htmlFor="terminationCategory" text="Category" />
          <PullDown
            filterkey="key"
            defaultValue={terminationCategory}
            labelInside={false}
            pulldownFor={"terminationCategory"}
            forceBlank={true}
            setValue={setTerminationCategory}
            options={categories}
            blankLabel="Others"
          />
        </Col>
        <Col>
          <OptionalLabel htmlFor="reason" text="Details" />
          <textarea
            style={{ width: "100%", borderColor: "#dee2e6" }}
            onChange={(e) => setReason(e.target.value)}
            rows="10"
            defaultValue={reason}
          />
        </Col>
      </Modal.Body>
      <button
        style={{ width: "auto", margin: "10px" }}
        variant="primary"
        onClick={sendReasonHandler}
      >
        Save Changes
      </button>
    </Modal>
  );
};

export default TerminationModal;
