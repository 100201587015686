import React, { useState } from "react";
import { CSVLink } from "react-csv";
import Modal from "react-bootstrap/Modal";
import userService from "../services/user.service";
import Loader from "../commonComponents/Loader";
import commonFunctions from "../common/Functions";

const UserCycleExport = () => {
  const [userHistory, setUserHistory] = useState([]);
  const [data, setData] = useState({});
  const [isExporting, setIsExporting] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleExportClick = async () => {
    try {
      setIsDialogOpen(true);
      setIsExporting(true);
      setIsDataFetched(false);

      // Simulate API call to fetch user history
      await userService.exportUserHistory().then(
        (response) => {
          if (response && response.data && response.data.data) {
            let data = response.data.data;
            setUserHistory(data.csvData);
            setData(data);
            setIsExporting(false);
            setIsDataFetched(true);
          }
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          alert(errorMessage);
          setIsExporting(false);
          if (error.response && error.response.status === 401) {
            // Handle logout logic or redirect to login
            // EventBus.dispatch("logout");
          }
        }
      );
    } catch (error) {
      console.error("Error fetching user history:", error);
      setIsExporting(false);
    }
  };
  const handleExportResults = () => {
    setIsDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <div className="floatRight">
      {isExporting ? (
        <label className="red" style={{ marginTop: 0 }}>
          Exporting <i className="fa fa-spinner"></i>
        </label>
      ) : (
        <>
          {!isDataFetched && (
            <a
              href="javascript:void(0)"
              className="greenLink mx-0"
              onClick={handleExportClick}
            >
              Request User Cycle
            </a>
          )}
          {isDataFetched && (
            <a
              href="javascript:void(0)"
              className="greenLink mx-0"
              onClick={handleExportResults}
            >
              Export Results
            </a>
          )}
        </>
      )}

      {/* Dialog component for showing totals/averages and additional options */}
      {isDialogOpen && (
        <Modal show={isDialogOpen} onHide={handleCloseDialog}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h5>User Cycle Report</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isExporting ? (
              <Loader />
            ) : (
              <div className="user-cycle-dialog">
                <p>Total Subscribers: {data.totalUsers}</p>
                <p>Total Active Subscribers: {data.totalActiveUsers}</p>
                <p>Total Terminated Subscribers: {data.totalTerminatedUsers}</p>
                <p>
                  Total Life Cycle of Terminated Subscribers:{" "}
                  {data.totalLifeCycleofTerminatedUsers}
                </p>
                <p>
                  Average Life Cycle of Terminated Subscribers:{" "}
                  {commonFunctions.CommaFormatted(
                    data.AvgLifeCycleofTerminatedUsers
                  )}
                </p>
                <p>
                  Total Life Cycle of all Subscribers:{" "}
                  {data.totalLifeCycleofTotalUsers}
                </p>
                <p>
                  Average Life Cycle of all Subscribers:{" "}
                  {commonFunctions.CommaFormatted(data.AvgLifeCycleofAllUsers)}
                </p>
                <br />
                <br />
                <p>
                  <b>Average Life Cycle</b>:{" "}
                  {commonFunctions.CommaFormatted(data.AvgLifeCycle)}
                  &nbsp;&nbsp;&nbsp;
                  <a href="#" className="export-button">
                    <CSVLink data={userHistory} filename="user_history.csv">
                      Export Results
                    </CSVLink>
                  </a>
                </p>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            {!isExporting ? (
              <>
                <button onClick={handleExportClick}>Request New Report</button>
              </>
            ) : (
              ""
            )}
            <button onClick={handleCloseDialog}>Close</button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default UserCycleExport;
