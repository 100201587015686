import React, { useState, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import { Modal, Col, Row } from "react-bootstrap";

import RequiredLabel from "../common/RequiredLabel";
import * as options from "../labels/options_en";

import AuthService from "../services/user.service";

export default function QuickUser(props) {
  const openUserModal = props.openUserModal || false;
  const setOpenUserModal = props.setOpenUserModal || false;
  const callbackFn = props.callbackFn || null;
  const branch = props.branch;

  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [source, setSource] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneOnly, setPhoneOnly] = useState("");
  const [message, setMessage] = useState("");

  const handleSaveUser = (e) => {
    e.preventDefault();

    setMessage("");

    if (!email || !first_name || !last_name || !phone || !source) {
      alert("Please fill all required fields");
      return false;
    } else {
      const data = {
        email: email,
        first_name: first_name,
        last_name: last_name,
        phone: phone,
        type: "registered",
        branch: branch,
        phoneCode: phoneCode,
        phoneOnly: phoneOnly,
        source: source,
        membership: false,
      };
      AuthService.postUser(data).then(
        (response) => {
          setMessage("User Saved.");
          setOpenUserModal(false);
          if (callbackFn) {
            callbackFn();
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          alert(resMessage);
        }
      );
    }
  };

  const handlePhoneChange = (phone, countryData) => {
    setPhone(phone);
    setPhoneOnly(phone.split(countryData.dialCode)[1]);
    setPhoneCode(countryData.dialCode);
  };
  return (
    <>
      <Modal show={openUserModal} onHide={() => setOpenUserModal(false)}>
        <Modal.Header>
          <Modal.Title>Add a new user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row
            className="white-background "
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginLeft: 0,
              marginRight: 0,
            }}
          >
            {/* firstname, lastname, email, phone */}
            <Col md={4} lg={4} className="align-baseline">
              <RequiredLabel htmlFor="first_name" text="First Name" />
              <input
                type="text"
                className="form-control"
                name="first_name"
                value={first_name}
                onChange={(e) => {
                  setfirst_name(e.target.value);
                }}
              />
            </Col>
            <Col md={4} lg={4}>
              <RequiredLabel htmlFor="last_name" text="Last Name" />
              <input
                type="text"
                className="form-control"
                name="last_name"
                value={last_name}
                onChange={(e) => {
                  setlast_name(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <RequiredLabel htmlFor="email" text="Email" />
              <input
                type="text"
                className="form-control"
                name="email"
                value={email}
                autoComplete="new-password"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <RequiredLabel htmlFor="phone" text="Phone" />
              <PhoneInput
                country={"lb"}
                inputClass="phoneUser"
                buttonStyle={{ borderRadius: 0, width: 50 }}
                name="email"
                placeholder=""
                value={phone}
                onChange={(phone, countryData) => {
                  handlePhoneChange(phone, countryData);
                }}
              />
            </Col>
            <Col md={6}>
              <RequiredLabel htmlFor="source" text="Source" />
              <select
                className="form-control"
                value={source}
                onChange={(e) => {
                  setSource(e.target.value);
                }}
              >
                <option value=""></option>
                {options.source_register.map((item, index) => (
                  <option key={"soruce" + index} value={item.key}>
                    {item.value}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <Row
            className="white-background "
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginLeft: 0,
              marginRight: 0,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Col md={12}>
              <button
                className="btn btn-primary btn-block"
                onClick={handleSaveUser}
              >
                Save
              </button>
              <br />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
