import React, { useState, useEffect, useRef, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import CashLogService from "../services/cashLog.service";
import EventBus from "../common/EventBus";
import commonFunctions from "../common/Functions";
import { required } from "../common/Validation";
import {
  getBranches,
  getSuppliers,
  getCashlogCategories,
} from "../common/getFunctions";
import Switch from "react-switch";
import RequiredLabel from "../common/RequiredLabel";
import OptionalLabel from "../common/OptionalLabel";
import BranchePullDown from "../commonComponents/BranchePullDown";
import SupplierPullDown from "../commonComponents/SupplierPullDown";
import PullDown from "../commonComponents/PullDown";
import { Col, Row } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Prompt } from "react-router-dom";
import DateFormatter from "../common/DateFromatter";

const CashLogForm = (props) => {
  const defaultLabel = "Currency Rate";
  const form = useRef();
  const checkBtn = useRef();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [cashLogLabel, setCashLogLabel] = useState("");
  const [receipt, setReceipt] = useState(false);
  const [amount, setAmount] = useState("");
  const [cashDate, setCashDate] = useState("");
  const [notes, setNotes] = useState("");
  const [branches, setBranches] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [branch, setBranch] = useState(currentUser.branch);
  const [suppliers, setSuppliers] = useState([]);
  const [sender, setSender] = useState("");
  const [reciever, setReciever] = useState("");
  const [accountsSender, setAccountsSender] = useState([]);
  const [accountsReciever, setAccountsReciever] = useState([]);
  const [senderAccount, setSenderAccount] = useState("");
  const [recieverAccount, setRecieverAccount] = useState("");
  const [VAT, setVAT] = useState(11);
  const [netAmount, setNetAmount] = useState(0);
  const [cashLogId, setCashLogId] = useState(props.match.params.id);
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [receiverCurrency, setReceiverCurrency] = useState("");
  const [senderCurrency, setSenderCurrency] = useState("");
  const [currencyRate, setCurrencyRate] = useState("1");
  const [currencyRateLabel, setCurrencyRateLabel] = useState(defaultLabel);
  const [iAmTyping, setIAmTyping] = useState(false);
  const [number, setNumber] = useState("");
  const clone = props.location.pathname.includes("/clone/");
  const re = /^[0-9\b]+$/;

  const getCashLogInfo = async (branches) => {
    if (cashLogId) {
      CashLogService.getCashLogById(cashLogId).then(
        async (response) => {
          let c = response.data;
          setCashLogLabel(c.label);
          setAmount(c.amount);
          setBranch(c.branch);

          setCashDate(new Date(c.cashDate.split("T")[0]));
          setReceipt(c.receipt);
          await getSuppliers(c.branch).then(async (tempSuppliers) => {
            setSuppliers(tempSuppliers);
            const senderSelected = tempSuppliers.filter(function (obj) {
              return obj._id == c.sender;
            });
            setAccountsSender(
              senderSelected ? senderSelected[0]?.accounts : []
            );
            const recieverSelected = tempSuppliers.filter(function (obj) {
              return obj._id == c.reciever;
            });
            await setAccountsReciever(
              recieverSelected ? recieverSelected[0]?.accounts : []
            );
            setSender(c.sender);
            setSenderAccount(c.senderAccount);

            setReciever(c.reciever);
            setRecieverAccount(c.recieverAccount);
            setReceiverCurrency(c.currency);
            setSenderCurrency(c.currency);
          });

          setNotes(c.notes);
          setVAT(c.VAT || 0);
          setNetAmount(c.netAmount || c.amount);
          setCategory(c.categoryId);
          setNumber(c.number);
          if (clone === true) {
            setCashLogId("");
            triggerNumberChange(branches, c.branch);
          } // reset the id to clone
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          alert(_content);
          setMessage(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    } else {
      setSuppliers(await getSuppliers(currentUser.branch));
      triggerNumberChange(branches, currentUser.branch);
    }
  };

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      const branches = await getBranches();
      setBranches(branches);
      setCategories(await getCashlogCategories(currentUser.branch));
      getCashLogInfo(branches);
      window.addEventListener("beforeunload", alertUser);

      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }

    onReady();
  }, []);

  const saveLogAndClose = (e) => {
    e.preventDefault();
    setIAmTyping(false);
    handleSaveCashLog(true);
  };
  const saveLog = (e) => {
    e.preventDefault();
    setIAmTyping(false);
    handleSaveCashLog(false);
  };

  function handleSaveCashLog(closeOnSave) {
    setMessage("");
    setSuccessful(false);
    setAmountWithVAT(amount, VAT);
    form.current.validateAll();
    const selectedBranch = branches.find((b) => b._id === branch);
    const companyId = selectedBranch.companyId;
    if (!senderAccount && !reciever) {
      alert("Please fill at least either the sender or the receiver!");
      return;
    }
    if (sender == companyId && !senderAccount) {
      alert("Please specify the account for the sender!");
      return;
    }
    if (reciever == companyId && !recieverAccount) {
      alert("Please specify the account for the reciever!");
      return;
    }
    if (!cashDate) {
      alert("Please specify a date.");
      return;
    }

    if (checkBtn.current.context._errors.length === 0) {
      CashLogService.postCashLog({
        _id: cashLogId,
        label: cashLogLabel,
        amount: commonFunctions.removeComma(amount),
        netAmount: commonFunctions.removeComma(netAmount),
        VAT: VAT,
        notes: notes,
        branch: branch,
        receipt: receipt,
        cashDate: DateFormatter.getSimpleDate(cashDate),
        sender: sender,
        reciever: reciever,
        category: category,
        senderAccount: senderAccount,
        recieverAccount: recieverAccount,
        companyId: companyId,
        currencyRate: commonFunctions.removeComma(currencyRate),
        senderCurrency: senderCurrency,
        receiverCurrency: receiverCurrency,
        number: number,
      }).then(
        (response) => {
          setMessage("CashLog Saved.");
          setSuccessful(true);
          if (closeOnSave) {
            props.history.push("/cashLogs");
          } else {
            alert("Transaction is saved!");
            props.history.push("/cashlog/new");
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  }

  const setReceiptSwitch = () => {
    setReceipt(!receipt);
  };
  const handleAmountChanged = (newAmount) => {
    setIAmTyping(true);
    setAmountWithVAT(newAmount, VAT);
  };

  const setAmountWithVAT = (amount2, vat) => {
    setAmount(commonFunctions.removeComma(amount2));
    setNetAmount(commonFunctions.removeComma(amount2) / (1 + vat / 100));
  };

  const triggerBranchChange = async (b) => {
    setBranch(b);
    triggerNumberChange(branches, b);
    setCategories(await getCashlogCategories(b));
    setSuppliers(await getSuppliers(b));
  };

  const triggerNumberChange = async (branches, branchId) => {
    setNumber("Not Set");

    let selectedBranch = branches.filter(function (obj) {
      return obj._id == branchId;
    });
    if (selectedBranch && selectedBranch[0] && selectedBranch[0].parentBranch) {
      branchId =
        selectedBranch && selectedBranch[0] && selectedBranch[0].parentBranch;
      selectedBranch = branches.filter(function (obj) {
        return obj._id == branchId;
      });
    }

    if (
      selectedBranch &&
      selectedBranch[0] &&
      selectedBranch[0].transactionPrefix
    )
      setNumber(
        selectedBranch[0].transactionPrefix +
          selectedBranch[0].transaction_counter
      );
  };

  const vatHandler = (value) => {
    if (re.test(value)) {
      setVAT(value);
      setAmountWithVAT(amount, value);
    }
  };

  const handleCategory = (value) => {
    setCategory(value);
    setIAmTyping(true);
    const selectedCategory = categories.filter(function (obj) {
      return obj._id === value;
    });
    if (selectedCategory && selectedCategory[0]) {
      setCashLogLabel(selectedCategory[0].label);
    }
  };

  const handleCurrencyRate = (value) => {
    setCurrencyRate(value);
    setIAmTyping(true);
  };

  const handleSenderAccount = (value) => {
    setSenderAccount(value);
    const senderAccountSelected = accountsSender.filter(function (obj) {
      return obj.key == value;
    });
    if (senderAccountSelected) {
      const currency = senderAccountSelected[0]
        ? senderAccountSelected[0].currency
        : "";
      setSenderCurrency(currency);

      if (receiverCurrency != "") {
        setCurrencyRateLabel(
          "Conversion Rate: " + currency + " = " + receiverCurrency
        );
      } else {
        setCurrencyRateLabel(defaultLabel);
      }
    }
  };
  const handleRecieverAccount = (value) => {
    setRecieverAccount(value);
    const recieverAccountSelected = accountsReciever.filter(function (obj) {
      return obj.key == value;
    });
    if (recieverAccountSelected) {
      const currency = recieverAccountSelected[0]
        ? recieverAccountSelected[0].currency
        : "";
      setReceiverCurrency(currency);
      if (senderCurrency != "") {
        setCurrencyRateLabel(
          "Conversion Rate: " + senderCurrency + " = " + currency
        );
      } else {
        setCurrencyRateLabel(defaultLabel);
      }
    }
  };
  return (
    <Fragment>
      <Prompt
        when={iAmTyping}
        message={(location) =>
          "Are you sure, you want to leave? All your data will be lost!"
        }
      />
      <div className="col-md-12">
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          ref={form}
        >
          {!successful && (
            <div>
              <table className="styled-table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "100%" }}>Transaction Form</th>
                  </tr>
                </thead>
                <tbody>
                  <Row
                    className="white-background "
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 0,
                    }}
                  >
                    {" "}
                    <Col md={6}>
                      <label htmlFor="number" className="waveLabel">
                        Transaction Number: <b>{number}</b>
                      </label>
                    </Col>
                  </Row>
                  <Row
                    className="white-background "
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 0,
                    }}
                  >
                    <Col md={3}>
                      {currentUser.type === "admin" ? (
                        <>
                          <RequiredLabel htmlFor="branch" text="Branch" />
                          <BranchePullDown
                            disabled={cashLogId}
                            branch={branch}
                            addFormGroup="no"
                            labelInside={true}
                            forceBlank={true}
                            setBranch={triggerBranchChange}
                            branches={branches}
                          />
                        </>
                      ) : null}
                    </Col>
                    <Col md={3}>
                      <RequiredLabel htmlFor="category" text="Category" />
                      <Select
                        name="category"
                        className="form-control"
                        value={category}
                        onChange={(e) => handleCategory(e.target.value)}
                        validations={[required]}
                      >
                        <option value=""></option>
                        {categories &&
                          categories.map((c) => (
                            <option value={c._id}>{c.label}</option>
                          ))}
                      </Select>
                    </Col>
                    <Col md={3}>
                      <RequiredLabel htmlFor="cashLogLabel" text="Label" />
                      <Input
                        type="text"
                        className="form-control"
                        name="cashLogLabel"
                        value={cashLogLabel}
                        onChange={(e) => {
                          setCashLogLabel(e.target.value);
                          setIAmTyping(true);
                        }}
                        validations={[required]}
                      />
                    </Col>
                    <Col md={3}>
                      <RequiredLabel htmlFor="cashDate" text="Date" />
                      <DatePicker
                        dateFormat="dd-MM-yyyy"
                        className="form-control"
                        name="cashDate"
                        selected={cashDate}
                        onChange={(date) => {
                          setCashDate(date);
                          setIAmTyping(true);
                        }}
                        validations={[required]}
                      />
                    </Col>
                    <Col md={3}>
                      <OptionalLabel htmlFor="sender" text="Sender" />
                      <SupplierPullDown
                        supplier={sender}
                        addFormGroup="no"
                        labelInside={true}
                        forceBlank={true}
                        setSupplier={setSender}
                        setAccounts={setAccountsSender}
                        suppliers={suppliers}
                      />
                    </Col>
                    <Col md={3}>
                      <OptionalLabel
                        htmlFor="senderAccount"
                        text="Sender Account"
                      />
                      <PullDown
                        defaultValue={senderAccount}
                        labelInside={false}
                        pulldownFor={"senderAccount"}
                        forceBlank={true}
                        setValue={(value) => {
                          handleSenderAccount(value);
                          setIAmTyping(true);
                        }}
                        options={accountsSender}
                      />
                    </Col>
                    <Col md={3}>
                      <OptionalLabel htmlFor="reciever" text="Receiver" />
                      <SupplierPullDown
                        supplier={reciever}
                        addFormGroup="no"
                        labelInside={true}
                        forceBlank={true}
                        setSupplier={setReciever}
                        setAccounts={setAccountsReciever}
                        suppliers={suppliers}
                      />
                    </Col>
                    <Col md={3}>
                      <OptionalLabel
                        htmlFor="recieverAccount"
                        text="Receiver Account"
                      />
                      <PullDown
                        defaultValue={recieverAccount}
                        labelInside={false}
                        pulldownFor={"recieverAccount"}
                        forceBlank={true}
                        setValue={(value) => {
                          handleRecieverAccount(value);
                          setIAmTyping(true);
                        }}
                        options={accountsReciever}
                      />
                    </Col>
                    <Col md={3}>
                      <RequiredLabel
                        htmlFor="currencyRate"
                        text={currencyRateLabel}
                      />
                      <NumericFormat
                        type="text"
                        className="form-control"
                        name="currencyRate"
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        decimalSeparator="."
                        value={currencyRate}
                        onChange={(e) => handleCurrencyRate(e.target.value)}
                        validations={[required]}
                      />
                    </Col>

                    <Col md={3}>
                      <RequiredLabel htmlFor="receipt" text="Receipt" />
                      <Switch
                        onChange={() => {
                          setReceiptSwitch();
                          setIAmTyping(true);
                        }}
                        checked={receipt}
                      />
                    </Col>

                    <Col md={3}>
                      <RequiredLabel htmlFor="amount" text="Amount with VAT" />
                      <NumericFormat
                        type="text"
                        className="form-control"
                        name="amount"
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        decimalSeparator="."
                        value={amount}
                        onChange={(e) => handleAmountChanged(e.target.value)}
                        validations={[required]}
                      />
                    </Col>
                    <Col md={3}>
                      <RequiredLabel htmlFor="VAT" text="VAT" />
                      <input
                        type="number"
                        className="form-control"
                        name="VAT"
                        value={VAT}
                        onChange={(e) => {
                          vatHandler(e.target.value);
                          setIAmTyping(true);
                        }}
                      />
                    </Col>
                    <Col md={3}>
                      <RequiredLabel htmlFor="netAmount" text="Net Amount" />
                      <NumericFormat
                        type="text"
                        className="form-control"
                        name="netAmount"
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        decimalSeparator="."
                        disabled={true}
                        value={netAmount}
                        validations={[required]}
                      />
                    </Col>

                    <Col md={3}>
                      <OptionalLabel htmlFor="notes" text="Description" />
                      <textarea
                        className="form-control"
                        name="notes"
                        autoComplete="off"
                        onChange={(e) => {
                          setNotes(e.target.value);
                          setIAmTyping(true);
                        }}
                        cols="30"
                        rows="4"
                        value={notes}
                      />
                    </Col>
                  </Row>
                  <Row></Row>
                </tbody>
              </table>

              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  onClick={saveLogAndClose}
                >
                  Save
                </button>
                <button className="btn btn-primary btn-block" onClick={saveLog}>
                  Save & Add New
                </button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </Fragment>
  );
};

export default CashLogForm;
