import React, { useState, useRef, useEffect } from "react";
import Input from "react-validation/build/input";
import { required, validEmail } from "../../common/Validation";
import UserService from "../../services/user.service";
import Header from "../components/Header";
import { NavLink } from "react-router-dom";
import authService from "../../services/auth.service";
import { wait } from "@testing-library/react";
import { isAndroid, isIOS } from "react-device-detect";
import { Redirect } from "react-router-dom";
import { trackPage } from "../components/GoogleAnalytics";
export default function ActivationCode(props) {
  const form = useRef();
  const checkBtn = useRef();

  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    async function onReady() {
      //force a logout whenever user reaches this page.
      localStorage.setItem("user", null);
      await verifyCode();
    }

    trackPage("Wave");
    onReady();
  }, []);

  const redirectToHome = (data) => {
    /*if (isAndroid) {
            const url =
                "intent://my_host/#Intent;scheme=my_scheme;package=com.example.wave;end";

            window.location.replace(url);
            //return <Redirect to='mobile-site'/>
        }
        else if (isIOS) {
            window.location.replace("wavebike://");
            //return <Redirect to='mobile-site'/>
        }
        else 
			*/
    if (!data.waitingList) {
      window.location.href =
        window.location.protocol + "//" + window.location.host + "/dashboard";
    } else {
      window.location.href =
        window.location.protocol +
        "//" +
        window.location.host +
        "/editprofile?complete";
    }
  };

  const verifyCode = async () => {
    setLoading(true);
    await UserService.verifyActivationCode(
      props.match.params.id,
      props.match.params.ActivationCode
    ).then(
      (res) => {
        if (res && res.status === 200) {
          console.log("data is ", res.data);
          setVerified(res.data.data.verified);
          setTimeout(() => {
            if (res.data && res.data.data && res.data.success) {
              let data = res.data.data.user;
              let branch = res.data.data.user.branch;

              if (data) {
                localStorage.setItem("user", JSON.stringify(data));
                localStorage.setItem(
                  "bikerInfo",
                  JSON.stringify({
                    rides: data.rides,
                    distance: data.distance,
                    avgspeed: data.avgspeed,
                    co2: data.co2,
                    calories: data.calories,
                    costsaved: data.costsaved,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    buildingName: data.buildingName,
                    floor: data.floor,
                    town: data.town,
                    street: data.street,
                    phone: data.phone,
                  })
                );
                localStorage.setItem(
                  "wave_token",
                  JSON.stringify(res.data.data.token)
                );
                localStorage.setItem("branch", JSON.stringify(branch));
                redirectToHome(data);
              } else {
                //alert(res.data.data.msg)
                //return alert remove redirect
                //redirectToHome(data);
                return res.data.data.msg;
              }
            }
          }, 3000);
        }
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  const resendCode = async () => {
    setLoading(true);
    await UserService.resendCode(email).then(
      (res) => {
        if (res && res.status === 200) {
          console.log("data is ", res.data.data);
        }
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  return (
    <div>
      <Header
        title={verified ? "Account Verified" : "Account Not Verified"}
        extraClass="headerMedium"
      />
      <div className="col-md-12 login-page-container">
        <div className="landing-login-card">
          {!loading ? (
            verified ? (
              <>
                <div style={{ textAlign: "center" }}>
                  <span style={{ fontSize: 16 }}>
                    Your email address was successfully verified!
                  </span>
                </div>
              </>
            ) : (
              <>
                <span style={{ fontSize: 16 }}>
                  Failed to verify this account!
                </span>
                <div className="landing-login-input-container">
                  <svg
                    className="landing-login-logo"
                    width={30}
                    viewBox="0 0 512 512"
                  >
                    <path d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z" />
                  </svg>
                  <input
                    autoComplete="off"
                    placeholder="E-mail"
                    type="text"
                    className="landing-login-input"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.trim())}
                    validations={[required, validEmail]}
                  />
                </div>
                <br />
                <br />
                <div id="landing-login-button-container">
                  <button
                    id="landing-login-button"
                    disabled={loading}
                    onClick={resendCode}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Resend Activation Code</span>
                  </button>
                </div>
                {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
              </>
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
