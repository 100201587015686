import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import DateFormatter from "../common/DateFromatter";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../commonComponents/Loader";
import * as labels from '../labels/en';
import { getJourneysList, getBranchJourneyQuestions } from "../common/getFunctions";
import JourneyService from "../services/journey.service";
import EventBus from "../common/EventBus";
import { Modal } from "react-bootstrap";
import QuestionsView from "./QuestionsView";
import SurveyService from "../services/survey.service";
import AnswersView from "./AnswersView";

const JourneysList = (props) => {

    //const journeys = props.journeys;
    //const loading = props.loading;
    const userId = props.userId || "";
    const list = props.list || "";
    const [journeys, setJourneys] = useState("");
    const [loading, setLoading] = useState(false);
    const [itemsPerPage, setitemsPerPage] = useState(list ? 3 : 9);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const pageSizes = [9, 18, 24, 30, 36, 60];
    const [error, setError] = useState("");
    const [resetMsg, setResetMsg] = useState("");
    const [total, setTotal] = useState(0);
    const [questionsModal, setQuestionsModal] = useState(false);
    const [formId, setFormId] = useState("");
    const [questions, setQuestions] = useState([]);
    const [journeyId, setJourneyId] = useState("");
    const [answersModal, setAnswersModal] = useState(false);
    const [surveyId, setSurveyId] = useState("");
    const currentUser = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        async function onReady() {
            let temp = await (getBranchJourneyQuestions());
            setFormId(temp.form);
            setQuestions(temp.questions);

            if (list) {
                setJourneys(list.list);
                setCount(Math.ceil(list.total / itemsPerPage));
            }
            else {
                listJourneys(page, itemsPerPage);
            }

        }

        onReady()

    }, []);

    const listJourneys = async (page, itemsPerPage) => {
        setLoading(true);
        let data = await getJourneysList(parseInt(page - 1) * itemsPerPage, itemsPerPage, userId);
        setJourneys(data.list);
        setTotal(data ? data.total : 0);
        setCount(Math.ceil(data.total / itemsPerPage));
        setResetMsg("");
        setLoading(false);
    }

    const deleteJourney = async (id) => {

        if (window.confirm("Are you sure you want to delete this journey?")) {
            JourneyService.deleteJourney(id).then(
                () => {

                    alert("Journey is deleted!");
                    setJourneys(journeys.filter(j => j._id !== id));
                    setTotal(total - 1)
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setError(_content);

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");

                    }
                }
            );
        }
    }





    const handlePageChange = async (event, value) => {
        setPage(value);
        await listJourneys(value, itemsPerPage);

    };

    const handlePageSizeChange = async (newItemsPerPage) => {

        setitemsPerPage(newItemsPerPage);
        setPage(1);
        await listJourneys(1, newItemsPerPage);

    };

    const handleQuestionsModalShow = (jid) => {
        setJourneyId(jid);
        setQuestionsModal(true);
    }

    const handleAnswersModalShow = (sid) => {
        setSurveyId(sid);
        setAnswersModal(true);
    }

    const hideRateUs = () => {
        setQuestionsModal(false);
    }

    const hideAnswers = () => {
        setAnswersModal(false);
    }

    const deleteRating = () => {
        if (window.confirm("Are you sure you want to delete this rating?")) {
            SurveyService.deleteSurvey(surveyId).then(
                (response) => {
                    alert("This rating has been deleted.");

                    setJourneys(journeys.map((j, i) => {
                        if (j.survey && j.survey._id === surveyId) {
                            j.survey._id = null;
                        }
                        return j;
                    }));
                    hideAnswers();
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    alert(_content);

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                }
            )
        }
    }
    return (
        <div>
            <Modal show={questionsModal} onHide={hideRateUs}>
                <Modal.Header><Modal.Title>Rate Us</Modal.Title></Modal.Header>
                <Modal.Body>
                    <QuestionsView formId={formId} questions={questions} journeyId={journeyId} questionsLoaded={true} isCss={true} branch={currentUser.branch} userId={currentUser._id} closeFn={hideRateUs} oldList={journeys} setOldList={setJourneys} />
                </Modal.Body>
            </Modal>
            <Modal show={answersModal} onHide={hideAnswers}>
                <Modal.Header><Modal.Title>Your Rating Experience <button style={{ fontSize: "1rem" }} onClick={deleteRating} className="linkBtn greenLink"><i alt="Delete" title="Delete" className="fa fa-trash" ></i></button></Modal.Title></Modal.Header>
                <Modal.Body>
                    <AnswersView surveyId={surveyId} hideDetails={true} />
                </Modal.Body>
            </Modal>
            {error ? <header className="jumbotron"> <h3>{error}</h3>  </header> : null}
            {!error && !loading && journeys && journeys.length !== 0 ?
                <div>


                    {journeys.map((journey, index) => (
                        <div style={{ paddingTop: 10, paddingBottom: 20, width: "95%" }} >

                            <h6><NavLink to={"/journey/" + journey._id} target="_blank">{DateFormatter.DateFormatterOne(journey.createdAt)} {"("}{DateFormatter.convertMsToTime(journey.startdate)} {"->"} {DateFormatter.convertMsToTime(journey.enddate)}{")"}</NavLink> &nbsp;&nbsp;<a href="javascript:void(0)" className="text-dark" onClick={(e) => deleteJourney(journey._id)} ><i alt="Delete" title="Delete" className="fa fa-trash" ></i></a>&nbsp;&nbsp;{journey.survey && journey.survey._id ? <><button onClick={() => handleAnswersModalShow(journey.survey._id)} className="linkBtn greenLink">Your Rating</button></> : formId ? <button onClick={() => handleQuestionsModalShow(journey._id)} className="linkBtn greenLink">Rate Us</button> : ""}</h6>

                            {journey.fromlocation && journey.tolocation ? <iframe onClick={() => null} width="100%" style={{ minHeight: 300, marginBottom: 20, marginTop: 20 }} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={"https://www.google.com/maps/embed/v1/directions?origin=" + journey.fromlocation.latitude + "," + journey.fromlocation.longitude + "&destination=" + journey.tolocation.latitude + "," + journey.tolocation.longitude + "&avoid=highways&key=AIzaSyDEBPLItA1bzKTw3jl9AYE-n6y5xo4jD5Q&mode=driving"} title="%3$s" aria-label="%3$s" ></iframe> : ""}
                            <hr />

                        </div>
                    ))}

                    <div className="mt-3">
                        {!list ?
                            <>
                                {"Items per Page: "}
                                <select onChange={(e) => handlePageSizeChange(e.target.value)} value={itemsPerPage}>
                                    {pageSizes.map((size) => (
                                        <option key={size} value={size}>
                                            {size}
                                        </option>
                                    ))}
                                </select>
                            </>
                            : ""}

                        <Pagination
                            className="my-3"
                            count={count}
                            page={page}
                            siblingtotal={1}
                            boundarytotal={1}
                            variant="outlined"
                            color="primary"
                            shape="rounded"
                            onChange={handlePageChange}
                            size="medium"

                        />
                    </div>
                </div>
                : loading ? <Loader /> :
                    resetMsg !== "" ? <div className='defaultMsg'>{resetMsg}</div> :
                        <div className="noResultsFound">{labels.NO_RESULTS}</div>}
        </div>





    )
}

export default JourneysList;