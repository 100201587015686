import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import DateFormatter from "../common/DateFromatter";
import SubscriptionService from "../services/subscription.service";
import InvoiceService from "../services/invoice.service";
import { viewPDF } from "../common/pdf";
import EventBus from "../common/EventBus";
import AccessoriesList from "../commonComponents/AccessoriesList";
import { Card, Table, Col, Row, Modal } from "react-bootstrap";
import PullDown from "../commonComponents/PullDown";
import { getCategories } from "../common/getFunctions";
import RequiredLabel from "../common/RequiredLabel";
import OptionalLabel from "../common/OptionalLabel";

const currentUser = JSON.parse(localStorage.getItem("user"));
const Subscription = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [plan, setPlan] = useState("");
  const [draft, setDraft] = useState("");
  const [biker, setBiker] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [bike, setBike] = useState("");
  const [charger, setCharger] = useState("");
  const [battery, setBattery] = useState("");
  const [helmet, setHelmet] = useState("");
  const [bottle, setBottle] = useState("");
  const [bag, setBag] = useState("");
  const [invoice, setInvoice] = useState("");
  const [branch, setBranch] = useState("");
  const [packageReturned, setPackageReturned] = useState(false);
  const [message, setMessage] = useState("");
  const [terminated, setTerminated] = useState(false);
  const [lastDate, setLastDate] = useState("");
  const [terminateReason, setTerminateReason] = useState("");
  const subscriptionId = props.match.params.id;
  const [terminateSubscriptionModal, setTerminateSubscriptionModal] =
    useState(false);
  const [reason, setReason] = useState("");
  const [selectedUserByReason, setSelectedUserByReason] = useState("");
  const [note, setNote] = useState("");
  const [source, setSource] = useState("");
  const [terminationCategory, setTerminationCategory] = useState("");
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      await getSubscriptionInfo();
      setCategories(await getCategories(currentUser.branch, "termination"));
    }

    onReady();
  }, []);

  const getInviceInfo = async (invoiceId) => {
    await InvoiceService.getInvoiceById(invoiceId).then(
      ({ data }) => {
        setInvoice(data);
        console.log("data", data);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content);
        setMessage(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const getSubscriptionInfo = async () => {
    await SubscriptionService.getSubscriptionDetailsById(subscriptionId).then(
      async (response) => {
        let b = response.data;
        setPlan(b.plan ? b.plan.label : "");
        setDraft(b.draft);
        setBiker(b.user ? b.user.full_name : "");
        setBike(b.bike ? b.bike.number : "");
        setPackageReturned(b.packageReturned ? b.packageReturned : false);
        setCharger(b.charger ? b.charger.number : "");
        setBattery(b.battery ? b.battery.number : "");
        setBranch(b.branch ? b.branch.label : "");
        setStartDate(DateFormatter.DateFormatterOne(b.start));
        if (b.plan.planType == "daily" || b.plan.planType === "hourly") {
          setStartTime(b.startTime);
        }
        setEndDate(DateFormatter.DateFormatterOne(b.end));
        setBag(b.bag ? b.bag.number + " - " + b.bag.label : "");
        setHelmet(b.helmet ? b.helmet.number + " - " + b.helmet.label : "");
        setBottle(b.bottle ? b.bottle.number + " - " + b.bottle.label : "");
        setTerminated(b.terminated);
        setLastDate(
          b.lastDate ? DateFormatter.DateFormatterOne(b.lastDate) : ""
        );
        let reason = b.terminationCategory || "";
        if (b.terminateReason) {
          if (reason != "") reason += " | ";
          reason += b.terminateReason;
        }
        setTerminateReason(b.terminated ? reason : "");
        setSource(b.user ? b.user.source : "");
        if (b.invoice) await getInviceInfo(b.invoice);
        if (b.note) setNote(b.note);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content);
        setMessage(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };
  const handleReasonModalShow = (id) => {
    setSelectedUserByReason(id);

    setTerminateSubscriptionModal(true);
  };

  const handleTerminateSubscriptionModalClose = () => {
    setSelectedUserByReason("");
    setReason("");
    setTerminationCategory("");
    setTerminateSubscriptionModal(false);
  };

  const sendReasonHandler = () => {
    if (!terminationCategory && !reason) {
      alert("Terminate reason is required!");
      return;
    }

    var data = {
      id: selectedUserByReason,
      terminateReason: reason,
      terminationCategory: terminationCategory,
    };

    SubscriptionService.terminateSubscription(data).then(async ({ data }) => {
      //console.log(data);
      setTerminated(true);
      let combinedReason = terminationCategory;
      if (reason) {
        if (combinedReason != "") {
          combinedReason += " | ";
        }
        combinedReason += reason;
      }
      setTerminateReason(combinedReason);
    });

    handleTerminateSubscriptionModalClose();
  };

  return (
    <Row
      style={{
        flexDirection: "row",
        textAlign: "left",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <Modal
        show={terminateSubscriptionModal}
        onHide={handleTerminateSubscriptionModalClose}
      >
        <Modal.Header>
          <Modal.Title>Dear {currentUser.first_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            We hope you had a great experience with us! We're sorry to lose you,
            and hope to see you on a Wave bike soon again!
            <br />
            <br /> Kindly share your reason of termination and click the button
            below to terminate your subscription. Note that this cannot be
            undone. Otherwise click Cancel.
          </p>
          <Col>
            <RequiredLabel htmlFor="terminationCategory" text="Category" />
            <PullDown
              filterkey="key"
              defaultValue={terminationCategory}
              labelInside={false}
              pulldownFor={"terminationCategory"}
              forceBlank={true}
              setValue={setTerminationCategory}
              options={categories}
              blankLabel="Others"
            />
          </Col>
          <Col>
            <OptionalLabel htmlFor="reason" text="Details" />
            <textarea
              style={{ width: "100%", borderColor: "#dee2e6" }}
              onChange={(e) => setReason(e.target.value)}
              rows="7"
              defaultValue={reason}
            />
          </Col>
        </Modal.Body>
        <Row style={{ display: "flex", flexDirection: "row" }}>
          <Col md={2}></Col>
          <Col md={4}>
            <button
              style={{ width: "100%", margin: "10px" }}
              variant="primary"
              onClick={sendReasonHandler}
            >
              Terminate
            </button>
          </Col>

          <Col md={4}>
            <button
              style={{ width: "100%", margin: "10px" }}
              variant="primary"
              onClick={() => setTerminateSubscriptionModal(false)}
            >
              Cancel
            </button>
          </Col>
          <Col md={2}></Col>
        </Row>
      </Modal>

      <Col md={12}>
        {(currentUser.type == "admin" ||
          currentUser.type == "manager" ||
          currentUser.type == "helpdesk") &&
        packageReturned !== true ? (
          <div className="floatRight ">
            <NavLink
              to={"/subscription/edit/" + subscriptionId}
              className="text-dark "
            >
              Edit
            </NavLink>
          </div>
        ) : (
          ""
        )}
        <h2>
          {" "}
          {currentUser.type == "admin" ||
          currentUser.type == "manager" ||
          currentUser.type == "helpdesk" ? (
            <>{biker} 's Subscription</>
          ) : (
            <>Hi {currentUser.first_name}!</>
          )}
        </h2>

        <div
          className="card"
          style={{ width: "100%", maxWidth: "100%", padding: 10 }}
        >
          <Row
            style={{
              flexDirection: "row",
              textAlign: "left",
              alignItems: "flex-start",
              width: "95%",
            }}
          >
            <Col lg={6}>
              {DateFormatter.DateFormatterOne(invoice.start)} -{" "}
              {DateFormatter.DateFormatterOne(invoice.end)}
            </Col>
            <Col lg={6}>
              <b>{plan}</b>
              {" - "}
              {currentUser.type == "admin" ||
              currentUser.type == "manager" ||
              currentUser.type == "helpdesk" ? (
                <>
                  {packageReturned
                    ? "Package Returned"
                    : terminated
                    ? "Terminated"
                    : draft
                    ? "Draft"
                    : !invoice
                    ? "Active"
                    : "Invoice Sent"}
                </>
              ) : invoice &&
                (currentUser.type == "admin" ||
                  currentUser.type == "manager" ||
                  currentUser.type == "helpdesk" ||
                  invoice.paid) ? (
                <>
                  <span className="midText">Invoice: </span>{" "}
                  <a
                    href="javascript:void(0)"
                    onClick={() => viewPDF(invoice._id)}
                    className="text-light "
                  >
                    {invoice.number}
                  </a>
                </>
              ) : (
                ""
              )}
            </Col>
            {currentUser.type == "admin" ||
            currentUser.type == "manager" ||
            currentUser.type == "helpdesk" ? (
              <Col lg={6}>
                <span className="midText">Subscribed since:</span> {startDate}{" "}
                {startTime}
              </Col>
            ) : (
              ""
            )}

            {currentUser.type === "registered" ||
            currentUser.type === "subscriber" ? (
              <Col lg={6}>
                {terminated ? (
                  lastDate ? (
                    <>
                      <span className="midText strong">
                        Please return your bike before: {lastDate}
                      </span>
                    </>
                  ) : (
                    <span className="strong">Terminated </span>
                  )
                ) : (
                  <>
                    <span className="midText">Subscribed since:</span>{" "}
                    {startDate} {startTime}
                  </>
                )}
              </Col>
            ) : (
              ""
            )}
            {terminated ? (
              <Col lg={6}>
                <span className="midText">Terminate Reason: </span>
                {terminateReason}
              </Col>
            ) : (
              ""
            )}

            {currentUser.type == "admin" ? (
              <Col lg={6}>
                <span className="midText">Branch:</span> {branch}
              </Col>
            ) : (
              ""
            )}

            {currentUser.type == "admin" ||
            currentUser.type == "manager" ||
            currentUser.type == "helpdesk" ? (
              <>
                <Col lg={6}>
                  <span className="midText">Bike:</span> {bike}
                  <br />
                  <span className="midText">Charger:</span> {charger}
                  <br />
                  <span className="midText">Battery:</span> {battery}
                  <br />
                  {helmet ? (
                    <>
                      <span className="midText">Helmet:</span> {helmet}
                    </>
                  ) : (
                    ""
                  )}
                </Col>
              </>
            ) : (
              ""
            )}
            <Col lg={6}>
              {note ? (
                <>
                  <span className="midText">Note:</span> {note}
                  <br />
                </>
              ) : (
                ""
              )}
              {source ? (
                <>
                  <span className="midText">Source:</span> {source}
                </>
              ) : (
                ""
              )}
            </Col>
            {!terminated ? (
              <Col lg={12}>
                <button
                  onClick={() => handleReasonModalShow(subscriptionId)}
                  className="btn-primary btn-block small-btn"
                >
                  Terminate subscription
                </button>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </div>
      </Col>
      <Col md={12}>
        <AccessoriesList subscriptionId={subscriptionId} />
      </Col>
    </Row>
  );
};

export default Subscription;
