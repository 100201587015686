import React, { useState, useEffect, useRef } from "react";
import Currency from "./Currency";
import * as options from "../labels/options_en";
import PullDown from "./PullDown";

export default function CompanyAccounts(props) {
  let accounts = props.accounts;
  const disableField = props.disableField || false;
  const setAccounts = props.setAccounts;

  const addAccount = () => {
    setAccounts([
      ...accounts,
      {
        key: "",
        label: "",
        disabled: false,
        number: "",
        currency: "USD",
        type: "cash",
      },
    ]);
  };

  const removeAccount = (i) => {
    const list = [...accounts];
    list.splice(i, 1);
    setAccounts(list);
  };

  function updateAccount(e, i) {
    const { name, value } = e.target;
    const list = [...accounts];
    list[i][name] = value;
    if (name === "label" && !list[i].disabled) {
      const key = value.replace(/\s+/g, "").toLowerCase();
      list[i].key = key;
    }
    setAccounts(list);
  }

  return (
    <>
      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th className="accessories_subscription_th" colSpan={2}>
              <div>Accounts</div>
              <div>
                {!disableField ? (
                  <a
                    href="javascript:void(0)"
                    className="text-light"
                    onClick={addAccount}
                  >
                    Add
                  </a>
                ) : (
                  ""
                )}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {accounts &&
            accounts.length !== 0 &&
            accounts.map((acc, index) => (
              <tr key={"acc" + index}>
                <td className="accessories_big_grid">
                  <td className="accessories_subscription_td">
                    <div className="pr-2 form-group" style={{ width: "80%" }}>
                      <label htmlFor="number">Number</label>
                      <input
                        type="text"
                        className="font-size14 form-control"
                        name="number"
                        value={acc?.number}
                        onChange={(e) => updateAccount(e, index)}
                      />
                    </div>

                    <div className="pr-2 form-group" style={{ width: "80%" }}>
                      <label htmlFor="label">Label</label>
                      <input
                        type="text"
                        className="font-size14 form-control"
                        name="label"
                        value={acc?.label}
                        onChange={(e) => updateAccount(e, index)}
                      />
                    </div>
                    <div className="pr-2 form-group" style={{ width: "80%" }}>
                      <label htmlFor="label">Type</label>
                      <PullDown
                        defaultValue={acc?.type}
                        labelInside={false}
                        pulldownFor={"type"}
                        forceBlank={true}
                        setValue={(e) => updateAccount(e, index)}
                        noOverWrite={true}
                        options={options.accountTypes}
                        pulldownName="type"
                      />
                    </div>
                    <div className="pr-2 form-group" style={{ width: "80%" }}>
                      <Currency
                        fn={(e) => updateAccount(e, index)}
                        name="currency"
                        value={acc?.currency}
                        isRequired={true}
                      />
                    </div>
                  </td>
                  <td>
                    {disableField || acc.disabled ? (
                      ""
                    ) : (
                      <div className="pr-2 form-group" style={{ width: "80%" }}>
                        <br />
                        <br />
                        <a
                          href="javascript:void(0)"
                          className="text-dark"
                          onClick={() => removeAccount(index)}
                        >
                          <i
                            alt="Delete"
                            title="Delete"
                            className="fa fa-trash"
                          ></i>
                        </a>
                      </div>
                    )}
                  </td>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}
