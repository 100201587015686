import React, { useEffect, useState } from 'react';
import SurveryService from "../services/survey.service";
import EventBus from "../common/EventBus";
import { Pie, PieChart, Tooltip, Cell, Legend } from 'recharts';
import { Row } from 'react-bootstrap';
import Loader from "../commonComponents/Loader";
import * as labels from '../labels/en';


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const SurveyFormAnalytics = (props) => {
    const surveyFormId = props.match.params.id;
    const [label, setLabel] = useState("")
    const [data, setData] = useState([]);
    const [questionsLabels, setQuestionsLabels] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [resetMsg, setResetMsg] = useState(true);

    useEffect(() => {
        async function onReady() {
            window.scrollTo(0, 0);
            await loadSurveyFormAnalytics();
        }
        onReady()
    }, [])

    const loadSurveyFormAnalytics = async () => {
        setLoading(true);
        await SurveryService.getSurveyFormAnalyticsById(surveyFormId).then(
            (res) => {
                let q = Object.values(res.data.data.data);
                let bigData = [];
                let labels = [];
                for (let i = 0; i < q.length; i++) {
                    let innerData = [];
                    if (q[i].answerType === "slider1to5") {
                        for (let j = 1; j <= 5; j++) {
                            innerData.push({ name: j.toString(), value: q[i][j.toString()] || 0 });
                        }
                    }
                    else if (q[i].answerType === "slider1to10") {
                        for (let j = 1; j <= 10; j++) {
                            innerData.push({ name: j.toString(), value: q[i][j.toString()] || 0 });
                        }
                    }
                    else {
                        for (let j = 0; j < q[i].answerOptions.length; j++) {
                            innerData.push({ name: q[i].answerOptions[j], value: q[i][q[i].answerOptions[j]] || 0 });
                        }
                    }
                    labels.push(q[i].question);
                    if (innerData.length > 0) bigData.push(innerData);
                }
                setQuestionsLabels(labels);
                setData(bigData);
                setLabel(res.data.data.form)
                setResetMsg("");
                setLoading(false);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setError(_content);
                setLoading(false);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        )
    }

    return (
        <div>
            {error ? <header className="jumbotron"> <h3>{error}</h3>  </header> : null}
            <h3>Form Analytics </h3>
            {!error && !loading && data.length !== 0 ?
                <>
                    <h5>Label: {label}</h5>
                    <Row className='d-flex flex-row m-1'>
                        {data && data.length > 0 ?
                            data.map((d, i) =>
                                <div style={{ float: 'left', margin:"5px 10px", width:'45%' }}>
                                    <div style={{ maxWidth: 500, padding: 10, align: "top", height: 400, elevation: 20, borderRadius: 10, marginBottom: 20, boxShadow: "7px 7px 5px 5px rgba(191, 194, 199,0.5)" }}>
                                        <h5 className='mb-3'>{i + 1}. {questionsLabels[i]}</h5>
                                        <PieChart width={400} height={400}>
                                            <Pie
                                                data={d}
                                                cx="40%"
                                                cy="40%"
                                                labelLine={false}
                                                label={renderCustomizedLabel}
                                                outerRadius={120}
                                                fill="#8884d8"
                                                dataKey="value"
                                            >
                                                {d.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Tooltip />
                                            <Legend layout="vertical" verticalAlign="top" align="left" />
                                        </PieChart>
                                    </div>
                                </div>)
                            : "There are no answers!"}
                    </Row>
                </>
                :
                loading ? <Loader /> :
                    resetMsg != "" ? <div className='defaultMsg'>{resetMsg}</div> :
                        <div className="noResultsFound">{labels.NO_RESULTS}</div>
            }
        </div>
    )

}

export default SurveyFormAnalytics;