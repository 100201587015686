import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import bikeService from '../services/bike.service';
import ChargerService from '../services/charger.service';
import BatService from "../services/bat.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function NoteModal(props) {
    const noteModal = props.noteModal;
    const setNoteModal = props.setNoteModal;
    const selectedByNote = props.selectedByNote;
    const setSelectedByNote = props.setSelectedByNote;
    const type = props.type;
    const stolen = props.stolen;
    const list = props.list;
    const setList = props.setList;


    const [note, setNote] = useState("");
    const [stolenDate, setStolenDate] = useState("");


    const handleNoteModalClose = () => {
        setSelectedByNote("")
        setNote("")
        setStolenDate(new Date());
        setNoteModal(false)
    };
    const sendNoteHandler = () => {

        var data = {
            _id: selectedByNote,
            notes: note || props.oldNote,
        }
        if (stolen) {
            data.stolenDate = stolenDate;
            let d = new Date().toISOString();
            if (stolenDate) d = stolenDate.toISOString();
            else if (props.oldStolenDate) d = props.oldStolenDate.toISOString();
            if (type === "bike") {
                bikeService.markBikeAsStolen(data).then(async (res) => {
                    setList(list.map((b, i) => {
                        if (b._id === selectedByNote) {
                            b.status = "Stolen";
                            b.notes = note || props.oldNote;
                            b.stolenDate = d;
                        }
                        return b;
                    }))
                })
            }
            if (type === "charger") {
                ChargerService.markChargerAsStolen(data).then(async (res) => {
                    setList(list.map((b, i) => {
                        if (b._id === selectedByNote) {
                            b.status = "Stolen";
                            b.notes = note || props.oldNote;
                            b.stolenDate = d;
                        }
                        return b;
                    }))
                })
            }
            if (type === "bat") {
                BatService.markBatAsStolen(data).then(async (res) => {
                    setList(list.map((b, i) => {
                        if (b._id === selectedByNote) {
                            b.status = "Stolen";
                            b.notes = note || props.oldNote;
                            b.stolenDate = d;
                        }
                        return b;
                    }))
                })
            }
        }
        else {
            if (type === "bike") {
                bikeService.markBikeAsFound(data).then(async (res) => {
                    setList(list.map((b, i) => {
                        if (b._id === selectedByNote) {
                            b.status = "Maintenance";
                            b.notes = note || props.oldNote;
                        }
                        return b;
                    }))
                })
            }
            if (type === "charger") {
                ChargerService.markChargerAsFound(data).then(async (res) => {
                    setList(list.map((b, i) => {
                        if (b._id === selectedByNote) {
                            b.status = "Maintenance";
                            b.notes = note || props.oldNote;
                        }
                        return b;
                    }))
                })
            }
            if (type === "bat") {
                BatService.markBatAsFound(data).then(async (res) => {
                    setList(list.map((b, i) => {
                        if (b._id === selectedByNote) {
                            b.status = "Maintenance";
                            b.notes = note || props.oldNote;
                        }
                        return b;
                    }))
                })
            }
        }



        handleNoteModalClose();
    }




    return (
        <Modal
            show={noteModal}
            onHide={handleNoteModalClose}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h5>Notes</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <textarea
                    style={{ width: '100%', borderColor: '#dee2e6' }}
                    onChange={(e) => setNote(e.target.value)}
                    rows="10"
                    value={note || props.oldNote}
                />
                {stolen ? <DatePicker
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    name="stolenDate"
                    selected={stolenDate ? stolenDate : props.oldStolenDate ? props.oldStolenDate : new Date()}
                    onChange={date => setStolenDate(date)}
                /> : ""}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button onClick={sendNoteHandler}>Save Changes</Button>
                <Button onClick={handleNoteModalClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
