import React, { useState, useEffect } from "react";
import SurveyService from "../services/survey.service";
import EventBus from "../common/EventBus";
import OptionalLabel from "../common/OptionalLabel";
import RequiredLabel from "../common/RequiredLabel";
import DateFormatter from "../common/DateFromatter";

const AnswersView = (props) => {
    const surveyId = props.surveyId || null;
	const hideDetails = props.hideDetails || false;
  
    const [answers, setAnswers] = useState([]);
    const [survey, setSurvey] = useState({});
    useEffect(() => {

        async function onReady() {
            fetchAnswers();
        }

        onReady()

    }, [])

    const fetchAnswers = () => {
        SurveyService.getAnswersBySurveyId(surveyId).then(
            (response) => {
                let answersTemp = response.data.data.answers;
                answersTemp.sort((a, b) => {
                    return a.question.order - b.question.order;
                });
                setAnswers(answersTemp);
                setSurvey(response.data.data.survey);

            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                alert(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }

    const handleDeleteSurvey = () => {
        if (window.confirm("Are you sure you want to delete this survey?")) {
            SurveyService.deleteSurvey(surveyId).then(
                (response) => {
                    alert("This survey has been deleted.");
                    window.location.href = window.location.protocol + "//" + window.location.host + "/forms";
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    alert(_content);

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                }
            )
        }
    }

    return (
        <div>
            {survey && !hideDetails ?
                <>
                    <h4>{survey.form ? survey.form.label : "Survey's"} answer &nbsp;&nbsp;<button style={{fontSize: "1rem"}} onClick={handleDeleteSurvey} className="text-dark midText linkBtn"  ><i alt="Delete" title="Delete" className="fa fa-trash" ></i></button></h4><br />
                    <h5>
                        <span className="tinyCardLabel">Form:&nbsp;</span><span className="midText">{survey.form ? survey.form.label : "unknown"}</span>
                        <span className="tinyCardLabel">&nbsp;| Biker:&nbsp;</span><span className="midText">{survey.user ? survey.user.full_name : "unknown"}</span>
                        <span className="tinyCardLabel">&nbsp;| Date:&nbsp;</span><span className="midText">{DateFormatter.DateFormatterOne(survey.createdDate)} </span>

                    </h5>
                </> : ""}
            {answers && answers.length > 0 ? answers.map((a, i) =>
                <div className="mb-5 survey-question">
                    {a.question ?
                        <>
                            {a.question.isrequired ?
                                <b><RequiredLabel htmlFor="question" text={(i + 1) + ". " + a.question.label} /></b>
                                :
                                <b><OptionalLabel htmlFor="question" text={(i + 1) + ". " + a.question.label} /></b>
                            }

                            <div className="survey-answer">{a.answer}</div>
                        </>
                        : ""}

                </div>
            ) : ""}

        </div>
    )
}

export default AnswersView;