import React, { useEffect } from "react";
import "../css/home.css";
import Accent from "../components/Accent";
import Battery from "../assets/images/bike_parts/Battery.jpg";
import Engine from "../assets/images/bike_parts/Engine.jpg";
import Gears from "../assets/images/bike_parts/Gears.jpg";
import Handlebar from "../assets/images/bike_parts/Handlebar.jpg";
import Light from "../assets/images/bike_parts/Light.jpg";
import Phoneholder from "../assets/images/bike_parts/Phoneholder.png";
import Rack from "../assets/images/bike_parts/Rack.jpg";
import Wheel from "../assets/images/bike_parts/Wheel.jpg";
import WaveApp from "../assets/images/WaveApp_Ourbike_page.png";
import Apps from "../assets/images/BikeStoreIcons.png";
import OurBikeImg from "../assets/images/OurBike.jpg";
import GooglePlay from "../assets/images/google-play-badge desktop.png";
import AppStore from "../assets/images/App Store desktop.png";
import { trackPage } from "../components/GoogleAnalytics";

export default function WaveBike() {
  useEffect(() => {
    window.scrollTo(0, 0);
    trackPage("Our bike");
  }, []);

  const bikeParts = [
    {
      image: Wheel,
      content: "Thick and wide tires with reflectors for a safe ride",
    },
    {
      image: Battery,
      content: "Detachable battery for easy charging",
    },
    {
      image: Engine,
      content: "Strong engine to get you uphill comfortably",
    },
    {
      image: Handlebar,
      content: "Narrow handlebar to pass traffic conveniently",
    },
    {
      image: Light,
      content:
        "Powerful head and tail lights to be visible during night time, even when the street lights are off",
    },
    {
      image: Rack,
      content: "Front carrier to carry your bag",
    },
    {
      image: Gears,
      content: "8 gears for an easy ride on different inclines",
    },
    {
      image: Phoneholder,
      content:
        "A phone holder with a usb charger to allow for handsfree routing during your ride",
    },
  ];

  const bikePartsMobile = [
    {
      image: Wheel,
      content: "Thick and wide tires with reflectors for a safe ride",
    },
    {
      image: Battery,
      content: "Detachable battery for easy charging",
    },
    {
      image: Light,
      content:
        "Powerful head and tail lights to be visible during night time, even when the street lights are off",
    },
    {
      image: Rack,
      content: "Front carrier to carry your bag",
    },
    {
      image: Engine,
      content: "Strong engine to get you uphill comfortably",
    },
    {
      image: Phoneholder,
      content:
        "A phone holder with a usb charger to allow for handsfree routing during your ride",
    },
  ];
  return (
    <div className="outer_container">
      <div className="inner_container">
        <div className="our-bike-section">
          <div className="landing_titles">
            <h1>
              <b className="pageTitle">
                <Accent className="accent_container">Specs</Accent>
              </b>
            </h1>
            <p style={{ fontSize: "14px" }}>
              <span className="text_bold">Bike weight:</span> 21.5 kg,{" "}
              <span className="text_bold">Battery weight:</span> 3 kg
              <br />
              <span className="text_bold">Battery energy:</span> 500 Wh
              <br />
              <span className="text_bold">Engine power:</span> 350 Watt,{" "}
              <span className="text_bold">Engine torque:</span> 75 Nm
              <br />
              <span className="text_bold">Max supported speed:</span> 30 km/h
              <br />
              <span className="text_bold">Range:</span> up to 80 km,{" "}
              <span className="text_bold">Gears:</span> 8<br />
              <span className="text_bold">Wheels:</span> 26",{" "}
              <span className="text_bold">Tires:</span> 1.95" wide
              <br />
              <span className="text_bold">Brakes:</span> Hydraulic disc brakes
              <br />
              <span className="text_bold">Kickstand:</span> Double kickstand
              <br />
              <span className="text_bold">Lock:</span> Frame lock + Extension
              chain
            </p>
            <div className="aligning_buttons_bike_page_mobile">
              <button
                id="landing_home_reserve_now_button"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/signup";
                }}
              >
                Subscribe&nbsp;Now
              </button>{" "}
              <button
                id="landing_home_reserve_now_button"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href =
                    "https://wave.bike/survey/64e59c5dec972c31ea69cc72";
                }}
              >
                Get a Test Ride
              </button>{" "}
            </div>
          </div>
          <div>
            <div>
              <img src={OurBikeImg} alt="OurWaveBike" width={"100%"} />
            </div>
          </div>
        </div>
      </div>
      {/* the wave bike on desktop */}
      <div className="inner_container desktop_version">
        <div className="wave-bike-header">
          <div>
            <div>
              <h1>
                <b className="pageTitle">
                  <Accent>
                    <span>The Wave bike</span>
                  </Accent>
                </b>
              </h1>
              <p style={{ fontSize: "14px" }}>
                The Wave bike has been specifically designed for the streets of
                dense Lebanese cities to offer you a safe and comfortable ride.
              </p>
            </div>
          </div>
          <div>
            <div>
              <div className="get_bike_parts">
                {bikeParts.map((step, index) => {
                  return (
                    <div key={`step-${index}`}>
                      {step.image && (
                        <div>
                          <img src={step.image} alt="Wave Logo" />
                        </div>
                      )}

                      <p
                        className="styling_bike_parts_text"
                        dangerouslySetInnerHTML={{ __html: step.content }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* The Wave Bike Mobile Version */}
      <div id="why_wave_mobile_version" className="inner_container">
        <div className="wave-bike-header">
          <div>
            <div>
              <h1>
                <b className="pageTitle">
                  <Accent>
                    <span>The Wave bike</span>
                  </Accent>
                </b>
              </h1>
              <p style={{ fontSize: "14px" }}>
                The Wave bike has been specifically designed for the streets of
                dense Lebanese cities to offer you a safe and comfortable ride.
              </p>
            </div>
          </div>
          <div>
            <div>
              <div className="get_bike_parts">
                {bikePartsMobile.map((step, index) => {
                  return (
                    <div key={`step-${index}`}>
                      {step.image && (
                        <div>
                          <img src={step.image} alt="Wave Logo" />
                        </div>
                      )}

                      <p
                        className="styling_bike_parts_text"
                        dangerouslySetInnerHTML={{ __html: step.content }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="inner_container">
        <div className="ourbike-app">
          <div>
            <div>
              <h1>
                <b className="pageTitle">
                  <Accent>
                    <span>The Wave App</span>
                  </Accent>
                </b>
              </h1>
              <p style={{ fontSize: "14px", paddingBottom: "36px" }}>
                The Wave app is now here to support all you Wave subscribers in
                your daily commute by bike. It offers you the chance to discover
                the friendliest cycling routes while enjoying its special
                features including:
                <div>
                  &bull;&nbsp;Routing
                  <br />
                  &bull;&nbsp;Journey Tracking
                  <br />
                  &bull;&nbsp;Maintenance Support
                  <br />
                  &bull;&nbsp;Access to Bike Functions
                </div>
              </p>
              <img id="why_wave_mobile_version" src={WaveApp} alt="Waveapp" />
              <div className="aligning_stores_logos_mobile">
                <a
                  href="https://play.google.com/store/apps/details?id=com.waveB&pcampaignid=web_share"
                  target="_blank"
                >
                  <img
                    id="why_wave_mobile_version"
                    src={GooglePlay}
                    alt="google play logo"
                  />
                </a>
                <a
                  href="https://apps.apple.com/lb/app/wave-bike/id1473328880"
                  target="_blank"
                >
                  <img
                    id="why_wave_mobile_version"
                    src={AppStore}
                    alt="App Store logo"
                    style={{ marginTop: "5px" }}
                  />
                </a>
              </div>
              <div className="aligning_stores_logos_mobile desktop_version">
                <a
                  href="https://play.google.com/store/apps/details?id=com.waveB&pcampaignid=web_share"
                  target="_blank"
                >
                  <img src={GooglePlay} alt="google play logo" />
                </a>
                <a
                  href="https://apps.apple.com/lb/app/wave-bike/id1473328880"
                  target="_blank"
                >
                  <img
                    src={AppStore}
                    alt="App Store logo"
                    style={{ marginTop: "5px" }}
                  />
                </a>
              </div>
            </div>
          </div>
          <div>
            <div>
              <img
                className="desktop_version"
                style={{ border: "5px solid white" }}
                src={WaveApp}
                alt="Waveapp"
              />
            </div>
          </div>
        </div>
      </div>
      <section className="inner_container padding_container">
        <p id="centering_the_text">
          Our super team of mechanics ensures all bikes are well maintained,
          gone through a thorough check and ready for your smooth ride.
        </p>
      </section>
    </div>
  );
}
