import React from "react";
import { Card } from "react-bootstrap";
import "../css/home.css";

export default function Testimonial() {
  return (
    <>
    <div className="desktop_version">
      <div className="testimonial_cards_1">
        <div style={{ width: "285px", height: "285px" }}>
          <div className="testimonial_card_container">
            <Card.Body
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <p
                style={{
                  color: "#867D77",
                  textAlign: "center",
                  paddingTop: "20px",
                  fontSize: "13px",
                }}
              >
                “Loving the wave experience <br />
                thanks!The bike is brilliant!
                <br /> I am recommending to
                <br /> everybody 🙂”
              </p>
              <br />
              <h6
                style={{
                  color: "#01B6B8",
                  paddingTop: "15px",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                Joy Saade
              </h6>
            </Card.Body>
          </div>
        </div>
        <div>
          <div className="testimonial_card_container">
            <Card.Body
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <p
                style={{
                  color: "#867D77",
                  textAlign: "center",
                  paddingTop: "20px",
                  fontSize: "13px",
                }}
              >
                “Highly recommended, the
                <br /> staff are really friendly and <br />
                the bikes are of excellent <br />
                quality.”
              </p>
              <br />
              <h6
                style={{
                  color: "#01B6B8",
                  paddingTop: "15px",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                Ricardo Khoury
              </h6>
            </Card.Body>
          </div>{" "}
        </div>
        <div>
          <div className="testimonial_card_container">
            <Card.Body 
            style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:"center",
                height:"100%"
            }}>
              <p
                style={{
                  color: "#867D77",
                  textAlign: "center",
                  paddingTop: "20px",
                  fontSize: "13px",
                }}
              >
                “hello hello wave team!commuting <br /> has become so much fun{" "}
                <br />
                thanks to this bike, very grateful
                <br /> that you exist here in lebanon❤️”
              </p>
              <br />
              <h6
                style={{
                  color: "#01B6B8",
                  fontStyle: "italic",
                  textAlign: "center",
                  paddingTop: "15px",
                }}
              >
                Christabel Morris
              </h6>
            </Card.Body>
          </div>{" "}
        </div>
        <div>
          <div className="testimonial_card_container">
            <Card.Body
            style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:"center",
                height:"100%"
            }}>
              <p
                style={{
                  color: "#867D77",
                  textAlign: "center",
                  paddingTop: "20px",
                  fontSize: "13px",
                }}
              >
                “Long lasting batteries, <br />
                excellent service, <br /> lovely bikes
                <br /> highly recommended.”
              </p>
              <br />
              <h6
                style={{
                  color: "#01B6B8",
                  paddingTop: "15px",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                Nadine Bou Nassar
              </h6>
            </Card.Body>
          </div>{" "}
        </div>
        </div>
        <div className="testimonial_cards_2">
        <div>
          <div className="testimonial_card_container">
            <Card.Body
            style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:"center",
                height:"100%"
            }}>
              <p
                style={{
                  color: "#867D77",
                  textAlign: "center",
                  paddingTop: "20px",
                  fontSize: "13px",
                }}
              >
                “Hey. I'm very happy <br />
                with my bike and
                <br /> it's really convenient,
                <br />
                plus your service is great”
              </p>
              <br />
              <h6
                style={{
                  color: "#01B6B8",
                  paddingTop: "15px",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                Alexandre Rausis
              </h6>
            </Card.Body>
          </div>{" "}
        </div>
        <div>
          <div className="testimonial_card_container">
            <Card.Body
            style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:"center",
                height:"100%"
            }}>
              <p
                style={{
                  color: "#867D77",
                  textAlign: "center",
                  paddingTop: "20px",
                  fontSize: "13px",
                }}
              >
                “Brilliant way to see Beirut <br />& get places with
                <br /> adrenaline pumping through
                <br /> your veins!”
              </p>
              <br />
              <h6
                style={{
                  color: "#01B6B8",
                  paddingTop: "15px",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                Juri Senior
              </h6>
            </Card.Body>
          </div>{" "}
        </div>
        <div>
          <div className="testimonial_card_container">
            <Card.Body
            style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:"center",
                height:"100%"
            }}>
              <p
                style={{
                  color: "#867D77",
                  textAlign: "center",
                  paddingTop: "20px",
                  fontSize: "13px",
                }}
              >
                “Wonderful service. <br />
                keep it up guys.. <br /> highly”
                <br /> recommended 😊
              </p>
              <br />
              <h6
                style={{
                  color: "#01B6B8",
                  paddingTop: "15px",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                Ramzi El Far
              </h6>
            </Card.Body>
          </div>{" "}
        </div>
        <div>
          <div className="testimonial_card_container">
            <Card.Body
            style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:"center",
                height:"100%"
            }}>
              <p
                style={{
                  color: "#867D77",
                  textAlign: "center",
                  paddingTop: "20px",
                  fontSize: "13px",
                }}
              >
                “Great bikes, <br />
                super solid and <br />
                fantastic way to <br /> get around in Beirut!”
              </p>
              <br />
              <h6
                style={{
                  color: "#01B6B8",
                  paddingTop: "15px",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                Nienke Edelenbosch
              </h6>
            </Card.Body>
          </div>{" "}
          </div>
        </div>
        </div>
        {/* feedback from wavers on mobile */}
        <div  id="why_wave_mobile_version">
      <div className="testimonial_cards_1">
        <div style={{ width: "285px", height: "285px" }}>
          <div className="testimonial_card_container">
            <Card.Body
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <p
                style={{
                  color: "#867D77",
                  textAlign: "center",
                  paddingTop: "20px",
                  fontSize: "13px",
                }}
              >
                “Loving the wave experience <br />
                thanks!The bike is brilliant!
                <br /> I am recommending to
                <br /> everybody 🙂”
              </p>
              <br />
              <h6
                style={{
                  color: "#01B6B8",
                  paddingTop: "15px",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                Joy Saade
              </h6>
            </Card.Body>
          </div>
        </div>
        <div>
          <div className="testimonial_card_container">
            <Card.Body
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <p
                style={{
                  color: "#867D77",
                  textAlign: "center",
                  paddingTop: "20px",
                  fontSize: "13px",
                }}
              >
                “Highly recommended, the
                <br /> staff are really friendly and <br />
                the bikes are of excellent <br />
                quality.”
              </p>
              <br />
              <h6
                style={{
                  color: "#01B6B8",
                  paddingTop: "15px",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                Ricardo Khoury
              </h6>
            </Card.Body>
          </div>{" "}
        </div>
        <div>
          <div className="testimonial_card_container">
            <Card.Body 
            style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:"center",
                height:"100%"
            }}>
              <p
                style={{
                  color: "#867D77",
                  textAlign: "center",
                  paddingTop: "20px",
                  fontSize: "13px",
                }}
              >
                “hello hello wave team!commuting <br /> has become so much fun{" "}
                <br />
                thanks to this bike, very grateful
                <br /> that you exist here in lebanon❤️”
              </p>
              <br />
              <h6
                style={{
                  color: "#01B6B8",
                  fontStyle: "italic",
                  textAlign: "center",
                  paddingTop: "15px",
                }}
              >
                Christabel Morris
              </h6>
            </Card.Body>
          </div>{" "}
        </div>
        <div>
          <div className="testimonial_card_container">
            <Card.Body
            style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:"center",
                height:"100%"
            }}>
              <p
                style={{
                  color: "#867D77",
                  textAlign: "center",
                  paddingTop: "20px",
                  fontSize: "13px",
                }}
              >
                “Long lasting batteries, <br />
                excellent service, <br /> lovely bikes
                <br /> highly recommended.”
              </p>
              <br />
              <h6
                style={{
                  color: "#01B6B8",
                  paddingTop: "15px",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                Nadine Bou Nassar
              </h6>
            </Card.Body>
          </div>{" "}
        </div>
        </div>
        <div className="testimonial_cards_2">
        <div>
          <div className="testimonial_card_container">
            <Card.Body
            style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:"center",
                height:"100%"
            }}>
              <p
                style={{
                  color: "#867D77",
                  textAlign: "center",
                  paddingTop: "20px",
                  fontSize: "13px",
                }}
              >
                “Loving the wave experience <br />
                thanks!The bike is brilliant!
                <br /> I am recommending to
                <br /> everybody 🙂”
              </p>
              <br />
              <h6
                style={{
                  color: "#01B6B8",
                  paddingTop: "15px",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                Joy Saade
              </h6>
            </Card.Body>
          </div>{" "}
        </div>
        <div>
          <div className="testimonial_card_container">
            <Card.Body
            style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:"center",
                height:"100%"
            }}>
              <p
                style={{
                  color: "#867D77",
                  textAlign: "center",
                  paddingTop: "20px",
                  fontSize: "13px",
                }}
              >
                  “Highly recommended, the
                <br /> staff are really friendly and <br />
                the bikes are of excellent <br />
                quality.”
              </p>
              <br />
              <h6
                style={{
                  color: "#01B6B8",
                  paddingTop: "15px",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                Ricardo Khoury
              </h6>
            </Card.Body>
          </div>{" "}
        </div>
        <div>
          <div className="testimonial_card_container">
            <Card.Body
            style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:"center",
                height:"100%"
            }}>
              <p
                style={{
                  color: "#867D77",
                  textAlign: "center",
                  paddingTop: "20px",
                  fontSize: "13px",
                }}
              >
                 “hello hello wave team!commuting <br /> has become so much fun{" "}
                <br />
                thanks to this bike, very grateful
                <br /> that you exist here in lebanon❤️”
              </p>
              <br />
              <h6
                style={{
                  color: "#01B6B8",
                  paddingTop: "15px",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                Christabel Morris
              </h6>
            </Card.Body>
          </div>{" "}
        </div>
        <div>
          <div className="testimonial_card_container">
            <Card.Body
            style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:"center",
                height:"100%"
            }}>
              <p
                style={{
                  color: "#867D77",
                  textAlign: "center",
                  paddingTop: "20px",
                  fontSize: "13px",
                }}
              >
                 “Long lasting batteries, <br />
                excellent service, <br /> lovely bikes
                <br /> highly recommended.”
              </p>
              <br />
              <h6
                style={{
                  color: "#01B6B8",
                  paddingTop: "15px",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                               Nadine Bou Nassar
              </h6>
            </Card.Body>
          </div>{" "}
          </div>
        </div>
        </div>
    </>
  );
}
