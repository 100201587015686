import React, { useEffect, useState, useLayoutEffect } from 'react'
import SubscriptionService from "../services/subscription.service";
import EventBus from "../common/EventBus";
import { Card, Table, Col, Row } from "react-bootstrap";

export default function AccessoriesList(props) {


	const subscriptionId = props.subscriptionId;
	const [accessories, setAccessories] = useState([]);


	useLayoutEffect(() => {

		async function onReady() {
			await SubscriptionService.getAccessories(subscriptionId)
				.then((res) => setAccessories(res.data));
		}

		onReady()

	}, [])


	return (
		<>
			<h2>Accessories</h2>
			
			
					{accessories && accessories.length !== 0  ? accessories.map((acc, index) => (
					
						<div style={{ float: "left", marginLeft: 20 }} key={"acc" + index}>
							<Card style={{ marginLeft: 0, maxWidth: 250, minHeight: 260, paddingLeft: 10, align: "top" }}>
								<Card.Body>
									<Card.Title>{accessories[index].inventory} | <>{accessories[index].usage}{accessories[index].usage == "Buy" && accessories[index].sendAgain ==false ? <span className="tinyLabel red">&nbsp;(sent)</span>:"" }</>
										
									</Card.Title>
									<Card.Text>
										<Table>
												
												<tr><th>Quantity:</th><td>{accessories[index].quantity}</td></tr>
												<tr><th>Unit Price:</th><td>{accessories[index].currency} {accessories[index].unitPrice}</td></tr>
												<tr><th>VAT:</th><td>{accessories[index].VAT} %</td></tr>
												<tr><th>Total Price:</th><td>{accessories[index].currency} {accessories[index].totalPrice}</td></tr>
												

										</Table>
									</Card.Text>

								</Card.Body>
							</Card>
						</div>
								
						
					)): <div className="noResultsFound">None added!</div>}

		</>
	)
}
