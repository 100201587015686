import React from 'react'
import EventBus from "./EventBus";
import WaitingServices from '../services/waiting.service'

export async function PutInWaitingList(branch,selectedBranchObj) {

  const user = JSON.parse(localStorage.getItem("user")) || null
  if (user === null)
    return "Please Login"

  var today = new Date();
  var currentDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  // return false;
  const data = {
    _id: user._id,
    waitingList: true,
    waitingStatus: "Not Contacted",
    waitingSince: currentDate,
    branch: branch
  }

  return await WaitingServices.postWaiting(data).then(
    ({ data }) => {
      if (data.status == 200 && data.success) {
        user.waitingList = true;
        user.waitingSince = currentDate;
        user.branch = branch;
		localStorage.setItem("branch", JSON.stringify(selectedBranchObj));
        localStorage.setItem("user", JSON.stringify(user))
        window.location.href = window.location.protocol + "//" + window.location.host + "/dashboard";
      }
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      alert(_content);

      if (error.response && error.response.status === 401)
        EventBus.dispatch("logout");
    });
}

export async function PutOutWaitingList() {

  const user = JSON.parse(localStorage.getItem("user")) || null

  if (user === null) {
    window.location.href = window.location.protocol + "//" + window.location.host + "/login";
    return null;
  }

  const data = {
    _id: user._id,
    waitingList: false,
    waitingStatus: "",
    waitingSince: ""
  }

  return await WaitingServices.postWaiting(data).then(
    ({ data }) => {
      console.log(data)
      if (data.status == 200 && data.success) {
        user.waitingList = false;
        localStorage.setItem("user", JSON.stringify(user))
        window.location.reload(false);
      }
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      alert(_content);

      if (error.response && error.response.status === 401)
        EventBus.dispatch("logout");
    });
}
