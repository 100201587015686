import React, { useState, useEffect } from "react";
import SurveyService from "../services/survey.service";
import EventBus from "../common/EventBus";
import OptionalLabel from "../common/OptionalLabel";
import RequiredLabel from "../common/RequiredLabel";
import DateFormatter from "../common/DateFromatter";
import AnswersView from "../commonComponents/AnswersView";

const SurveyAnswersView = (props) => {
    useEffect(() => {

        async function onReady() {

        }

        onReady()

    }, [])



    return (
        <div>
            <AnswersView surveyId={props.match.params.id} />

        </div>
    )
}

export default SurveyAnswersView;