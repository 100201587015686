import React, { useState, useRef, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { required, validEmail } from "../common/Validation";
import UserService from "../services/user.service";
import Functions from "../common/Functions";
import * as labels from '../labels/en';

export default function ChangePassword(props) {
  const form = useRef();
  const checkBtn = useRef();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
    }
    onReady()
  }, []);

  const handleForgetPassword = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);

    if (newPassword !== confirmNewPassword) {
      setMessage("password does not match");
      setLoading(false)
      return false;
    }

    if (!Functions.validatePassword(newPassword)) {
      setMessage(labels.INVALID_PASSWORD);
      setLoading(false);
      return false;
    }

    console.log(currentUser);

    const data = {
      userid: currentUser._id,
      oldpass: currentPassword,
      newpass: newPassword
    }

    UserService.changePassword(data)
      .then(
        (response) => {
          setLoading(false);
          alert(labels.PASSWORD_CHANGED)
          window.location.href = window.location.protocol + "//" + window.location.host + "/profile";
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          setMessage(resMessage);

        });


  };

  return (
    <div className="col-md-12">
      <div className="card card-container">

        <Form ref={form}>

          <div className="form-group">
            <label htmlFor="currentPassword">Current Password</label>
            <Input
              type={showCurrentPassword ? "text" : "password"}
              className="form-control"
              name="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              validations={[required]}
            />
            <i className={"eyeIcon2 fa fa-eye" + (showCurrentPassword ? "-slash" : "")} onClick={() => setShowCurrentPassword(!showCurrentPassword)}></i>
          </div>

          <div className="form-group">
            <label htmlFor="newPassword">New Password</label>
            <Input
              type={showNewPassword ? "text" : "password"}
              className="form-control"
              name="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              validations={[required]}
            />
            <i className={"eyeIcon2 fa fa-eye" + (showNewPassword ? "-slash" : "")} onClick={() => setShowNewPassword(!showNewPassword)}></i>
          </div>

          <div className="form-group">
            <label htmlFor="confirmNewPassword">Confirm New Password</label>
            <Input
              type={showConfirmNewPassword ? "text" : "password"}
              className="form-control"
              name="confirmNewPassword"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              validations={[required]}
            />
            <i className={"eyeIcon2 fa fa-eye" + (showConfirmNewPassword ? "-slash" : "")} onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}></i>
          </div>

          <div className="form-group">
            <button className="btn btn-primary btn-block" onClick={(e) => handleForgetPassword(e)} disabled={loading}>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Change Password</span>
            </button>
          </div>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}

        </Form>
      </div>
    </div>
  );
}
