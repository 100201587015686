import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import AuthService from "../services/auth.service";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import bikerImg from "../images/biker.jpg";
import DeleteUserModal from "../commonComponents/DeleteUserModal";

const Profile = (props) => {
	const currentUser = AuthService.getCurrentUser();
	if (!currentUser) { window.location.href = window.location.protocol + "//" + window.location.host + "/login"; }

	const [user, setUser] = useState(currentUser);
	const [deleteUserModal, setDeleteUserModal] = useState(false);
	useEffect(() => {

		async function onReady() {
			window.scrollTo(0, 0);
			await getUser();
		}

		onReady()

	}, []);


	const getUser = async () => {

		if (currentUser.type === 'registered' || currentUser.type === 'subscriber') {
			let res = await UserService.getUser().then(
				(response) => {
					let data = response.data.data;
					let biker = JSON.parse(localStorage.getItem("bikerInfo"));
					if (biker) {
						localStorage.setItem("bikerInfo", JSON.stringify({ "rides": data.rides, "distance": data.distance, "avgspeed": data.avgspeed, "co2": data.co2, "calories": data.calories, "costsaved": data.costsaved, "first_name": biker.first_name, "last_name": biker.last_name, "buildingName": biker.buildingName, "floor": biker.floor, "town": biker.town, "street": biker.street, "phone": biker.phone }));
						if (data.rides !== biker.rides) {
							window.location.reload();
						}
					}

				},
				(error) => {
					const _content =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					alert(_content);

					if (error.response && error.response.status === 401) {
						EventBus.dispatch("logout");

					}
				}
			);
			return res;
		}
	}




	return (
		<Form>
			<DeleteUserModal deleteUserModal={deleteUserModal} setDeleteUserModal={setDeleteUserModal} selectedUserForDelete={currentUser._id} />
			<div width="100%" className="profile-container">
				<Row style={{ flexDirection: 'row', textAlign: 'center', alignItems: "flex-start" }}>


					<Col sm={(currentUser.type === 'registered' || currentUser.type === 'subscriber') ? 6 : 12}>
						<Card style={{ width: 'fit-content', textAlign: 'center', margin: 'auto' }}>
							{user.picture ? <img src={process.env.REACT_APP_SERVER_API + "/upload/profiles/" + user.picture} onError={(e) => (e.target.onerror = null, e.target.src = bikerImg)} style={{ width: "100px", height: "100px", marginTop: "20px", alignSelf: 'center' }} /> : <img src={bikerImg} style={{ width: "100px", height: "100px", marginTop: "20px", alignSelf: 'center' }} />}
							<Card.Body>
								<Card.Title>
									{user.first_name} {user.last_name}
									&nbsp;
									<NavLink
										to={"/editprofile"}
									>
										<i alt="Edit Profile" className="fa fa-pen" aria-hidden="true"></i>
									</NavLink>

								</Card.Title>
								<Card.Title>{user.type.toUpperCase()}</Card.Title>
								<Card.Text>
									{user.email}
								</Card.Text>
								<Card.Text>
									{user.phone}
								</Card.Text>
								<Card.Text>
									{user.buildingName === "" || user.floor === "" || user.street === "" || user.town === "" ? null : user.buildingName + ", " + user.floor + ", " + user.street + ", " + user.town}
								</Card.Text>
								{user.type === "registered" ? <button style={{ margin: '10px', background: "#dc3545", width: 150, height: 30, fontSize: 14 }} onClick={(e) => { e.preventDefault(); setDeleteUserModal(true); }} >Delete Account</button> : ""}

							</Card.Body>
						</Card>
					</Col>



				</Row>




			</div>
		</Form>
	);

};

export default Profile;
