import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const getMaintenancesList = (
  branch,
  bikeStatus,
  offset,
  limit,
  type,
  number,
  action,
  bike,
  charger,
  bat,
  keywordFilter
) => {
  return axios.get(
    API_URL +
      "/maintenance/list?bikeStatus=" +
      bikeStatus +
      "&branch=" +
      branch +
      "&offset=" +
      offset +
      "&limit=" +
      limit +
      "&type=" +
      type +
      "&number=" +
      number +
      "&action=" +
      action +
      "&bikeId=" +
      bike +
      "&chargerId=" +
      charger +
      "&batId=" +
      bat +
      "&keywordFilter=" +
      keywordFilter,
    { headers: authHeader() }
  );
};

const deleteMaintenance = (id) => {
  return axios(API_URL + "/maintenance/" + id, {
    method: "delete",
    headers: authHeader(),
  });
};

const getMaintenanceById = (id) => {
  return axios.get(API_URL + "/maintenance/" + id, { headers: authHeader() });
};
const getMaintenanceDetailsById = (id) => {
  return axios.get(API_URL + "/maintenance/details/" + id, {
    headers: authHeader(),
  });
};

const postMaintenance = (data) => {
  return axios(API_URL + "/maintenance/save", {
    method: "post",
    headers: authHeader(),
    data,
  });
};

const getBikerList = () => {
  return axios.get(API_URL + "/admin/subscribers/list", {
    headers: authHeader(),
  });
};

const getBikeInventoryById = (id) => {
  return axios.get(API_URL + "/admin/bike/inventories/" + id, {
    headers: authHeader(),
  });
};
// Function to get summary data in JSON format
const getSummaryJson = () => {
  return axios.get(`${API_URL}/maintenance/summary/json`, {
    headers: authHeader(),
  });
};

// Function to get summary data in CSV format
const getSummaryCsv = () => {
  return axios.get(`${API_URL}/maintenance/summary/csv`, {
    responseType: "blob",
    headers: authHeader(),
  });
};

const getActionsJson = () => {
  return axios.get(`${API_URL}/maintenance/summary/actions`, {
    headers: authHeader(),
  });
};

export default {
  postMaintenance,
  deleteMaintenance,
  getMaintenancesList,
  getMaintenanceById,
  getBikeInventoryById,
  getBikerList,
  getMaintenanceDetailsById,
  getSummaryJson,
  getSummaryCsv,
  getActionsJson,
};
