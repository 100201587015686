import React, { useState, useEffect, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import PackageService from "../services/package.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";

const currentUser = JSON.parse(localStorage.getItem("user"));
const Package = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [packageName, setPackageName] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [company, setCompany] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [bikes, setBikes] = useState("");
  const [chargers, setChargers] = useState([]);
  const [bats, setBats] = useState([]);
  const [bottles, setBottles] = useState(0);
  const [helmetLabel1, setHelmetLabel1] = useState("Helmet Size 1");
  const [helmetLabel2, setHelmetLabel2] = useState("Helmet Size 2");
  const [bags, setBags] = useState(0);
  const [helmetSize1, setHelmetSize1] = useState(0);
  const [helmetSize2, setHelmetSize2] = useState(0);
  const [branch, setBranch] = useState("");
  const [terminated, setTerminated] = useState(false);
  const [reason, setReason] = useState("");
  const packageId = props.match.params.id;
  const [message, setMessage] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    PackageService.getPackageDetailsById(packageId).then(
      (response) => {
        let returnedData =
          response.data && response.data.data ? response.data.data : null;
        if (returnedData) {
          //console.log(returnedData)
          let pack = returnedData.pack;
          if (pack) {
            setPackageName(pack.title);
            setCompany(pack.company);
            setPrice(pack.price);
            setCurrency(pack.currency);
            setDisabled(pack.disabled);
            setBranch(pack.branch);
            setHelmetSize1(pack.helmetSize1);
            setHelmetSize2(pack.helmetSize2);
            setBottles(pack.bottles);
            setBags(pack.bags);
            setHelmetLabel1(
              pack.helmetId1
                ? pack.helmetId1.number + "-" + pack.helmetId1.label
                : ""
            );
            setHelmetLabel2(
              pack.helmetId2
                ? pack.helmetId2.number + "-" + pack.helmetId2.label
                : ""
            );
          }
          setBikes(returnedData.bikeList);
          setChargers(returnedData.chargerList);
          setBats(returnedData.batteryList);
          setTerminated(pack.terminated);
          let combinedReason = pack.terminationCategory;
          if (pack.reason) {
            if (combinedReason != "") {
              combinedReason += " | ";
            }
            combinedReason += pack.reason;
          }
          setReason(combinedReason);
        }
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content);
        setMessage(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  return (
    <div className="col-md-12">
      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "50%" }}>Package Specification</th>
            <th style={{ float: "right" }}>
              <NavLink to={"/package/edit/" + packageId} className="text-dark ">
                Edit
              </NavLink>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Name</td>
            <td>{packageName}</td>
          </tr>
          <tr>
            <td>Disabled</td>
            <td>{disabled ? "Disabled" : "Active"}</td>
          </tr>
          <tr>
            <td>Terminated</td>
            <td>
              {terminated ? (
                <label className="red"> Yes: {reason}</label>
              ) : (
                "No"
              )}
            </td>
          </tr>
          <tr>
            <td>Company</td>
            <td>{company ? company.name : ""}</td>
          </tr>
          <tr>
            <td>Price</td>
            <td>
              {price} {currency}
            </td>
          </tr>
          <tr>
            <td>Branch</td>
            <td>{branch ? branch.label : ""}</td>
          </tr>
          <tr>
            <td>Bikes</td>
            <td> {bikes && bikes.map((b) => <>{b.number}, </>)}</td>
          </tr>
          <tr>
            <td>Baterries</td>
            <td> {bats && bats.map((b) => <>{b.number}, </>)}</td>
          </tr>
          <tr>
            <td>Chargers</td>
            <td> {chargers && chargers.map((c) => <>{c.number}, </>)}</td>
          </tr>
          <tr>
            <td>{helmetLabel1 ? helmetLabel1 : "Helmet1"}</td>
            <td> {helmetSize1}</td>
          </tr>
          <tr>
            <td>{helmetLabel2 ? helmetLabel2 : "Helmet2"}</td>
            <td> {helmetSize2}</td>
          </tr>
          <tr>
            <td>Bags Count</td>
            <td> {bags}</td>
          </tr>
          <tr>
            <td>Bottles Count</td>
            <td> {bottles}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Package;
