import React, { useEffect } from "react";
import "../css/about.css";
import CorporateImg from "../assets/Corporate.jpg";
import DeliveryImg from "../assets/Delivery.png";
import brandStandout from "../assets/brandStandout.png";
import Accent from "../components/Accent";
import { trackPage } from "../components/GoogleAnalytics";

export default function Corporate() {
  useEffect(() => {
    window.scrollTo(0, 0);
    trackPage("Corporate & NGO");
  }, []);
  return (
    <div className="outer_container">
      <div className="inner_container desktop_version">
        <div className="corporate-header">
          <div>
            <div>
              <h1>
                <b className="pageTitle">
                  <Accent>
                    <span>Corporate & NGO</span>
                  </Accent>
                </b>
              </h1>
              <p style={{ fontSize: "17px" }}>
                <b>Companies today are choosing e-bikes.</b>
                <br />
                <div style={{ fontSize: "14px", fontWeight: "400" }}>
                  Be part of this change and cultivate a healthier and happier
                  <br />
                  team, achieve cost reductions, and significantly reduce your
                  <br />
                  company's carbon footprint.
                </div>{" "}
                <br />
                <b>For more information and inquiries, contact us via</b>
                <br />
                <span style={{ fontSize: "14px", fontWeight: "400" }}>
                  Mail: corporate@wave.bike
                  <br />
                  Tel: 70 632 955
                </span>
              </p>
            </div>
          </div>
          <div>
            <div>
              <img
                src={CorporateImg}
                alt="Corporate"
                width={"100%"}
                style={{ borderRadius: "20px" }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "-60px",
            marginBottom: 100,
          }}
        >
          <img
            width={"60%"}
            src={brandStandout}
            alt="Make your brand stand out by"
          />
        </div>
      </div>
      {/* corporate header on mobile */}
      <div id="why_wave_mobile_version" className="inner_container">
        <div className="corporate-header-mobile">
          <div>
            <div style={{ textAlign: "center" }}>
              <h1>
                <b className="pageTitle">
                  <Accent>
                    <span>Corporate & NGO</span>
                  </Accent>
                </b>
              </h1>
              <p style={{ fontSize: "17px", color: "#595959" }}>
                <b>
                  Companies today are
                  <br /> choosing e-bikes.
                </b>
                <br />
                <br />
                <p style={{ fontSize: "14px", fontWeight: "400" }}>
                  Be part of this change and cultivate a healthier and happier
                  team, achieve cost reductions, and significantly reduce your
                  company's carbon footprint.
                </p>{" "}
              </p>
            </div>
          </div>
          <div>
            <div>
              <img
                width={"100%"}
                src={brandStandout}
                style={{ borderRadius: "20px", padding: "0px !important" }}
                alt="Make your brand stand out by"
              />
            </div>
            <p
              style={{
                fontSize: "17px",
                color: "#595959",
                textAlign: "center",
              }}
            >
              <br />
              <span style={{ fontSize: "14px", fontWeight: "400" }}>
                For more information and inquiries, contact us via
                <br />
                Mail: corporate@wave.bike
                <br />
                Tel: 70 632 955
              </span>
            </p>
          </div>
          <div>
            <div>
              <img
                src={CorporateImg}
                alt="Corporate"
                width={"100%"}
                style={{ borderRadius: "20px", padding: "0px !important" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* smart delivery on desktop */}
      <div className="inner_container desktop_version">
        <div className="rides-header  padding_container">
          <div>
            <div>
              <img src={DeliveryImg} alt="Smart Delivery" />
            </div>
          </div>
          <div className="smartDeliveryContainer">
            <div>
              <h1>
                <b className="pageTitle">
                  <Accent>
                    <span>Smart Delivery</span>
                  </Accent>
                </b>
              </h1>
              <p style={{ fontSize: "17px" }}>
                <b>
                  Let’s revolutionize your operation by offering your delivery
                  business
                </b>
                <br />
                <div style={{ fontSize: "14px", fontWeight: "400px" }}>
                  &bull;&nbsp;A trendy ride for a trendy brand
                  <br />
                  &bull;&nbsp;Lower carbon emission consumption
                  <br />
                  &bull;&nbsp;Uninterrupted Service
                  <br />
                  &bull;&nbsp;More flexibility with future recruitment
                  <br />
                  &bull;&nbsp;Healthier and happier drivers
                  <br />
                  &bull;&nbsp;More cost saving
                </div>
              </p>
              <b>For more information and inquiries, contact us via</b>
              <br />
              <span style={{ fontSize: "14px", fontWeight: "400" }}>
                Mail: corporate@wave.bike
                <br />
                Tel: 70 632 955
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* smart delivery on mobile */}
      <div id="why_wave_mobile_version" className="inner_container">
        <div className="corporate-section-mobile">
          <div>
            <div style={{ textAlign: "center" }}>
              <h1>
                <b className="pageTitle" style={{}}>
                  <Accent>
                    <span>Smart Delivery</span>
                  </Accent>
                </b>
              </h1>
              <p style={{ fontSize: "17px" }}>
                <b>
                  Let’s revolutionize your operation by offering your delivery
                  business
                </b>
                <br />
                <br />
                <p style={{ fontSize: "14px", fontWeight: "400" }}>
                  A trendy ride for a trendy brand
                  <br />
                  Lower carbon emission consumption
                  <br />
                  Uninterrupted Service
                  <br />
                  More flexibility with future recruitment
                  <br />
                  Healthier and happier drivers
                  <br />
                  More cost saving
                </p>{" "}
                <br />
                <span style={{ fontSize: "14px", fontWeight: "400" }}>
                  For more information and inquiries, contact us via
                  <br />
                  Mail: corporate@wave.bike
                  <br />
                  Tel: 70 632 955
                </span>
              </p>
            </div>
          </div>
          <div>
            <div>
              <img
                src={DeliveryImg}
                alt="Corporate"
                width={"100%"}
                style={{ borderRadius: "20px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <section className="inner_container padding_container">
        <p id="centering_the_text">
          By providing your employees green and efficient transportation to
          replace their cars, you are contributing to the lowering of carbon
          footprint by 18 times.
        </p>
      </section>
    </div>
  );
}
