import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api" ;


const getList = (type, id) => {
  return axios.get(API_URL + "/admin/marker/list/" + id + "?type=" + type, { headers: authHeader() });
};


export default {
 
  getList
};

