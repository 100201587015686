import React, { useState, useEffect } from "react";
import WaitingService from "../services/waiting.service";
import { NavLink } from "react-router-dom";
import EventBus from "../common/EventBus";
import DateFormatter from "../common/DateFromatter";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import GetDateModal from "../commonComponents/GetDateModal";
import { getBranches } from "../common/getFunctions";
import * as labels from "../labels/en";
import Loader from "../commonComponents/Loader";
import Pagination from "@material-ui/lab/Pagination";
import * as options from "../labels/options_en";
import { Card, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Functions from "../common/Functions.js";
import bikerImg from "../images/biker.jpg";
import InternalNoteComponent from "../commonComponents/InternalNoteComponent";
import BranchePullDown from "../commonComponents/BranchePullDown";
import InternalNoteModal from "../commonComponents/InternalNoteModal";
import UserService from "../services/user.service";

const WaitingsUser = () => {
  const currentUser = JSON.parse(localStorage.getItem("user")) || null;
  if (!currentUser) {
    window.location.href =
      window.location.protocol + "//" + window.location.host + "/login";
  }

  const [error, setError] = useState("");
  const [waitings, setWaitings] = useState([]);
  const [commentModal, setCommentModal] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedUserByComment, setSelectedUserByComment] = useState("");
  const [modalFollowUpShow, setModalFollowUpShow] = useState(false);
  const [userFollowUp, setUserFollowUp] = useState("");

  const [modalPickUpShow, setModalPickUpShow] = useState(false);
  const [userPickUp, setUserPickUp] = useState("");
  const [loading, setLoading] = useState(true);
  const [branches, setBranches] = useState([]);
  const [resetMsg, setResetMsg] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const pageSizes = [9, 18, 24, 30, 36, 60];
  const [branchFilter, setBranchFilter] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const [bikerFilter, setBikerFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [sortByFilter, setSortByFilter] = useState(-1);
  const [subTypeFilter, setSubTypeFilter] = useState("membership");
  const [internalNoteModal, setInternalNoteModal] = useState(false);
  const [selectedUserByNote, setSelectedUserByNote] = useState("");
  const [oldInternalNote, setOldInternalNote] = useState("");
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedUserById, setSelectedUserById] = useState("");
  const [rejectCardIdCheck, setRejectCardIdCheck] = useState(false);
  const [rejectedName, setRejectedName] = useState("");
  const [rejectNote, setRejectNote] = useState("");

  const handleCommentModalShow = (id) => {
    var commentByUser = waitings.find((user) => user._id === id);
    setComment(commentByUser.waitingNote);
    setSelectedUserByComment(id);

    setCommentModal(true);
  };

  const handleCommentModalClose = () => {
    setSelectedUserByComment("");
    setComment("");
    setCommentModal(false);
  };

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
      await listWaitings("", "", "", page, itemsPerPage, "", "", "membership");
    }

    onReady();
  }, []);

  const getWaitings = async (
    branch,
    biker,
    status,
    offset,
    limit,
    sortBy,
    pickupDate,
    subTypeFilter
  ) => {
    let res = await WaitingService.getWaitingList(
      branch,
      biker,
      status,
      offset,
      limit,
      sortBy,
      pickupDate,
      subTypeFilter
    ).then(
      (response) => {
        return response.data.data;
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        alert(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
    return res;
  };
  const listWaitings = async (
    branchFilter,
    bikerFilter,
    statusFilter,
    page,
    itemsPerPage,
    sortBy,
    pickupDate,
    subTypeFilter
  ) => {
    setLoading(true);
    let data = await getWaitings(
      branchFilter,
      bikerFilter,
      statusFilter,
      parseInt(page - 1) * itemsPerPage,
      itemsPerPage,
      sortBy,
      pickupDate,
      subTypeFilter
    );
    setWaitings(data.list);
    setTotal(data ? data.total : 0);
    setCount(Math.ceil(data.total / itemsPerPage));
    setResetMsg("");
    setLoading(false);
  };

  const triggerStatusForFollowUp = (date) => {
    setModalFollowUpShow(false);
    triggerStatus("Follow up", userFollowUp, date);
  };

  const triggerStatusForPickUp = (date) => {
    setModalPickUpShow(false);
    triggerStatus("Confirmed", userPickUp, date);
  };

  // here show status pulldown.
  const triggerStatus = async (status, user, date) => {
    var id = user._id;
    var data = {
      _id: id,
      waitingStatus: status,
    };

    if (date) {
      if (status === "Follow up") {
        data = { ...data, followupDate: date };
      } else if (status === "Confirmed") {
        data = { ...data, pickupDate: date };
      }
    } else {
      if (status === "Follow up") {
        setModalFollowUpShow(true);
        setUserFollowUp(user);
        return false;
      } else if (status === "Confirmed") {
        setModalPickUpShow(true);
        setUserPickUp(user);
        return false;
      }
    }

    if (
      window.confirm(
        "Are you sure you want to change the status to " + status + "?"
      )
    ) {
      await WaitingService.postWaiting(data).then(
        async ({ data }) => {
          setWaitings(
            waitings.map((w, i) => {
              if (w._id === id) {
                w.waitingStatus = status;
                if (status === "Follow up") {
                  w.followupDate = date;
                  w.pickupDate = "";
                } else if (status === "Confirmed") {
                  w.pickupDate = date;
                  w.followupDate = "";
                }
              }
              return w;
            })
          );
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          alert(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  const deleteWaiting = async (event, id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this biker from waiting list?"
      )
    ) {
      WaitingService.deleteWaiting(id).then(
        (response) => {
          alert("Bike remove from waiting list!");
          let oldList = waitings;
          var data = oldList.filter(function (obj) {
            return obj._id !== id;
          });
          setWaitings(data);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  const sendCommentHandler = () => {
    var data = {
      _id: selectedUserByComment,
      waitingNote: comment,
    };

    WaitingService.postWaiting(data).then(async ({ data }) => {
      console.log(data);
      setWaitings(
        waitings.map((s, i) => {
          if (s.id === selectedUserByComment) {
            s.waitingNote = comment;
          }
          return s;
        })
      );
    });

    handleCommentModalClose();
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
    await listWaitings(
      branchFilter,
      bikerFilter,
      statusFilter,
      value,
      itemsPerPage,
      sortByFilter,
      pickupDate,
      subTypeFilter
    );
  };

  const handlePageSizeChange = async (newItemsPerPage) => {
    setitemsPerPage(newItemsPerPage);
    setPage(1);
    await listWaitings(
      branchFilter,
      bikerFilter,
      statusFilter,
      1,
      newItemsPerPage,
      sortByFilter,
      pickupDate,
      subTypeFilter
    );
  };

  const handleBranchChange = async (bid) => {
    setBranchFilter(bid);
    resetResults();
  };

  const handleBikerFilterChange = async (n) => {
    setBikerFilter(n);
    resetResults();
  };
  const handleFilterPickupDate = async (n) => {
    setPickupDate(n);
    resetResults();
  };
  const handleStatusFilterChange = async (bid) => {
    setStatusFilter(bid);
    resetResults();
  };
  const handleSortByFilterChange = async (sfilter) => {
    setSortByFilter(sfilter);
    await setPage(1); // return to first page
    await listWaitings(
      branchFilter,
      bikerFilter,
      statusFilter,
      1,
      itemsPerPage,
      sfilter,
      pickupDate,
      subTypeFilter
    );
  };
  const handleSubTypeChange = async (val) => {
    setSubTypeFilter(val);
    resetResults();
  };

  const resetResults = () => {
    setTotal(0);
    setCount(0);
    setWaitings([]);
    setResetMsg(labels.RESET_MSG);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      triggerSearch(e);
    }
  };
  const triggerSearch = async () => {
    await setPage(1); // return to first page
    await listWaitings(
      branchFilter,
      bikerFilter,
      statusFilter,
      1,
      itemsPerPage,
      sortByFilter,
      pickupDate,
      subTypeFilter
    );
  };
  const resetSearch = async () => {
    setBranchFilter("");
    setBikerFilter("");
    setPickupDate("");
    setSubTypeFilter("membership");
    setStatusFilter(false);
    await listWaitings("", "", "", page, itemsPerPage, "", "", "");
  };

  const handleAddInternalNoteClick = (id, internalNote) => {
    setSelectedUserByNote(id);
    setOldInternalNote(internalNote);
    setInternalNoteModal(true);
  };

  const handleRejectUserClick = (id, name) => {
    setSelectedUserById(id);
    setRejectedName(name);
    setShowRejectModal(true);
  };

  const handleRejectModalClose = () => {
    setSelectedUserById("");
    setRejectedName("");
    setRejectNote("");
    setRejectCardIdCheck(false);
    setShowRejectModal(false);
  };

  const checkBoxHandler = () => {
    if (document.getElementById("checkBox-cardId").checked)
      document.getElementById("checkBox-cardId-label").style.color =
        "rgb(52, 58, 64)";
    setRejectCardIdCheck(!rejectCardIdCheck);
  };

  const handleRejectUserSubmit = () => {
    if (
      window.confirm("You are going to reject " + rejectedName + ". Continue?")
    ) {
      var data = {
        userId: selectedUserById,
        rejectReason: rejectNote,
        approved: 2,
        removeCardId: rejectCardIdCheck,
      };

      UserService.userApproveReject(data).then(
        (response) => {
          alert("User has been rejected.");
          setWaitings(
            waitings.map((u, index) => {
              if (u._id === selectedUserById) {
                u.approved = 2;
                u.rejectReason = rejectNote;
              }
              return u;
            })
          );
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          alert(resMessage);
        }
      );
      handleRejectModalClose();
    }
  };

  return (
    <>
      <InternalNoteModal
        internalNoteModal={internalNoteModal}
        setInternalNoteModal={setInternalNoteModal}
        selectedUserByNote={selectedUserByNote}
        oldInternalNote={oldInternalNote}
        list={waitings}
        setList={setWaitings}
      />
      <Modal show={commentModal} onHide={handleCommentModalClose}>
        <Modal.Header>
          <Modal.Title>Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            style={{ width: "100%", borderColor: "#dee2e6" }}
            onChange={(e) => setComment(e.target.value)}
            cols="30"
            rows="10"
            defaultValue={comment}
          />
        </Modal.Body>
        <button
          style={{ width: "auto", margin: "10px" }}
          variant="primary"
          onClick={sendCommentHandler}
        >
          Save Changes
        </button>
      </Modal>

      <Modal show={showRejectModal} onHide={handleRejectModalClose}>
        <Modal.Header>
          <Modal.Title>Reject User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>
            <span>
              Reason -{" "}
              <span className="smallText">
                The biker will be notified via email.
              </span>
            </span>
          </label>
          <textarea
            style={{ width: "100%", borderColor: "#dee2e6" }}
            onChange={(e) => setRejectNote(e.target.value)}
            cols="30"
            rows="10"
            defaultValue={rejectNote}
          />

          <div style={{ display: "flex", width: "93%" }}>
            <input
              id="checkBox-cardId"
              onChange={checkBoxHandler}
              style={{ width: "auto", margin: "5px" }}
              type="checkbox"
            />
            <label
              id="checkBox-cardId-label"
              style={{
                width: "auto",
                margin: "0px",
                padding: "0px",
                color: "#343a40",
              }}
            >
              Delete Identity Card
            </label>
          </div>
        </Modal.Body>
        <button
          style={{ width: "auto", margin: "10px" }}
          variant="primary"
          onClick={handleRejectUserSubmit}
        >
          Reject
        </button>
        <button
          style={{ width: "auto", margin: "10px" }}
          variant="primary"
          onClick={handleRejectModalClose}
        >
          Cancel
        </button>
      </Modal>

      <div className="container">
        <h3> Waitings List </h3>
        <>
          <Row style={{ display: "flex", flexDirection: "row" }}>
            {currentUser && currentUser.type === "admin" ? (
              <Col md={3}>
                <label
                  className="filterLabelInput maintenanceLabels"
                  htmlFor="branch"
                >
                  Branch
                </label>
                <BranchePullDown
                  branch={branchFilter}
                  labelInside={true}
                  forceBlank={true}
                  setBranch={handleBranchChange}
                  branches={branches}
                />
              </Col>
            ) : null}
            <Col md={3}>
              <label
                className="filterLabelInput maintenanceLabels"
                htmlFor="bikerFilter"
              >
                Client
              </label>

              <input
                className="form-control filterInput2"
                type="text"
                style={{
                  border: "1px solid #ced4da",
                  width: "95%",
                  height: 38,
                }}
                value={bikerFilter}
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(e) => handleBikerFilterChange(e.target.value)}
              />
            </Col>
            <Col md={3}>
              <label
                className="filterLabelInput maintenanceLabels"
                htmlFor="statusFilter"
              >
                Status
              </label>

              <select
                className="form-control filterInput2"
                value={statusFilter}
                onChange={(e) => handleStatusFilterChange(e.target.value)}
              >
                <option value="" key="0">
                  All
                </option>
                {options.waitings_status.map((status, index) => (
                  <option key={index} value={status.key}>
                    {status.value}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <Row style={{ display: "flex", flexDirection: "row" }}>
            <Col md={3} style={{ height: 85 }}>
              <label
                className="filterLabelInput maintenanceLabels"
                htmlFor="pickupDate"
              >
                Pickup Date
              </label>
              <DatePicker
                autoComplete="off"
                dateFormat="dd-MM-yyyy"
                className="form-control filterInput2"
                name="pickupDate"
                selected={pickupDate}
                onChange={(date) => handleFilterPickupDate(date)}
              />
            </Col>
            <Col md={3}>
              <label
                className="filterLabelInput maintenanceLabels"
                htmlFor="subTypeFilter"
              >
                Subscription Type
              </label>
              <select
                className="form-control filterInput2"
                name="subTypeFilter"
                placeholder="Subscription Type"
                value={subTypeFilter}
                onChange={(e) => handleSubTypeChange(e.target.value)}
              >
                <option value="">All</option>
                {options &&
                  options.userSubscribeType.map((item, index) => (
                    <option key={"subType" + index} value={item.key}>
                      {item.value}
                    </option>
                  ))}
              </select>
            </Col>
            <Col md={3}>
              <label
                className="filterLabelInput maintenanceLabels"
                htmlFor="sortByFilter"
              >
                Sort By
              </label>

              <select
                className="form-control filterInput2"
                value={sortByFilter}
                onChange={(e) => handleSortByFilterChange(e.target.value)}
              >
                <option value={-1}>Newest</option>
                <option value={1}>Oldest</option>
              </select>
            </Col>
            <Col md={3}>
              <table style={{ marginTop: 50 }} border="0">
                <tr>
                  <td>
                    <button
                      onClick={(e) => triggerSearch(e)}
                      className="btn btn-primary btn-block search-button "
                    >
                      Go
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={(e) => resetSearch(e)}
                      className="btn btn-primary btn-block reset-button "
                    >
                      Reset
                    </button>
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </>
        {error ? (
          <header className="jumbotron">
            {" "}
            <h3>{error}</h3>{" "}
          </header>
        ) : null}
        {!error && !loading && waitings && waitings.length !== 0 ? (
          <div>
            <Row>
              <Col>
                <span style={{ float: "right", marginLeft: 10 }}>
                  {total} results
                </span>
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "first baseline",
              }}
            >
              {waitings && waitings.length !== 0
                ? waitings.map((user, index) => (
                    <div style={{ float: "left", marginLeft: 20 }}>
                      <Card
                        style={{
                          marginLeft: 0,
                          maxWidth: 250,
                          minHeight: 450,
                          paddingLeft: 10,
                          align: "top",
                        }}
                      >
                        <Card.Body>
                          <Card.Title>
                            <table border="0">
                              <tr>
                                <td>
                                  {user.picture ? (
                                    <img
                                      style={{
                                        width: 50,
                                        height: 50,
                                        marginRight: 10,
                                      }}
                                      src={
                                        process.env.REACT_APP_SERVER_API +
                                        "/upload/profiles/" +
                                        user.picture
                                      }
                                      alt="Profile"
                                      onError={(e) => (
                                        (e.target.onerror = null),
                                        (e.target.src = bikerImg)
                                      )}
                                    />
                                  ) : (
                                    <img
                                      style={{
                                        float: "right",
                                        width: 50,
                                        height: 50,
                                        marginRight: 10,
                                      }}
                                      src={bikerImg}
                                      alt="logo"
                                    />
                                  )}
                                </td>
                                <td>
                                  <NavLink
                                    target="_blank"
                                    to={"/user/" + user.id}
                                    className="text-dark"
                                  >
                                    {user.first_name} {user.last_name}
                                  </NavLink>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="2">
                                  {user.cardId ? (
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={
                                        process.env.REACT_APP_SERVER_API +
                                        "/upload/cardIds/" +
                                        user.cardId
                                      }
                                      className="greenLink mx-0 waveMedLink"
                                    >
                                      Identity Card
                                    </a>
                                  ) : (
                                    <span className="red midText">
                                      No Identity card
                                    </span>
                                  )}
                                  {user.cardId2 ? (
                                    <>
                                      <br />
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={
                                          process.env.REACT_APP_SERVER_API +
                                          "/upload/cardIds/" +
                                          user.cardId2
                                        }
                                        className="greenLink mx-0 waveMedLink"
                                      >
                                        Identity Card 2
                                      </a>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">
                                  <button
                                    className="linkBtn greenLink mx-0"
                                    onClick={() =>
                                      handleAddInternalNoteClick(
                                        user._id,
                                        user.internalNote
                                      )
                                    }
                                  >
                                    Add an internal note
                                  </button>
                                  <br />
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">
                                  <button
                                    className="linkBtn greenLink mx-0"
                                    onClick={() =>
                                      handleRejectUserClick(
                                        user.id,
                                        user.first_name + " " + user.last_name
                                      )
                                    }
                                  >
                                    Reject
                                  </button>
                                  <br />
                                </td>
                              </tr>
                            </table>
                          </Card.Title>
                          {user.waitingNote.length > 0 ? null : null}
                          <Row
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-around",
                            }}
                          >
                            <div
                              onClick={() => handleCommentModalShow(user._id)}
                            >
                              <InternalNoteComponent
                                list={waitings}
                                setList={setWaitings}
                                oldInternalNote={user.internalNote || ""}
                                selectedUserByNote={user._id}
                                note={user.waitingNote ? user.waitingNote : ""}
                              />
                            </div>

                            <span className="midText">
                              <select
                                className="form-control filterInput2"
                                value={user.waitingStatus}
                                onChange={(e) =>
                                  triggerStatus(e.target.value, user)
                                }
                              >
                                <option value=""></option>
                                {options.waitings_status.map(
                                  (status, index) => (
                                    <option key={index} value={status.key}>
                                      {status.value}
                                    </option>
                                  )
                                )}
                              </select>
                            </span>
                          </Row>
                          <br />

                          <span className="midText">
                            <a href={"mailto:" + user.email}>{user.email}</a>
                          </span>
                          <br />
                          <span className="midText">{user.phone}</span>
                          <br />
                          <span className="midText">
                            {user.buildingName === "" ||
                            user.floor === "" ||
                            user.street === "" ||
                            user.town === "" ? (
                              <span className="red">Address is missing</span>
                            ) : (
                              user.buildingName +
                              " building, " +
                              user.floor +
                              " floor, " +
                              user.street +
                              ", " +
                              user.town
                            )}
                          </span>
                          <br />
                          <span className="midText">
                            {user.iagree ? (
                              <span style={{ color: "green" }}>
                                iAgree checked
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                iAgree not checked
                              </span>
                            )}
                          </span>
                          <br />
                          {currentUser.type === "admin" ? (
                            <>
                              <span className="tinyCardLabel">
                                Branch:&nbsp;
                              </span>
                              <span className="midText">
                                {user.branch ? user.branch.label : ""}
                              </span>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          <span className="tinyCardLabel">Source:&nbsp;</span>
                          <span className="midText">
                            {user.source ? user.source : "Unknown"}
                          </span>
                          <br />
                          <span className="tinyCardLabel">
                            Waiting Since:&nbsp;
                          </span>
                          <span className="midText">
                            {user.waitingSince
                              ? DateFormatter.DateFormatterOne(
                                  user.waitingSince
                                )
                              : ""}
                          </span>
                          <br />
                          {user.membrship == true ||
                          user.membership == undefined ? (
                            <>
                              <span className="tinyCardLabel">
                                {" "}
                                Plan:&nbsp;
                              </span>
                              <span className="midText">
                                {user.plan && user.plan != ""
                                  ? user.plan
                                  : "Basic"}
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="tinyCardLabel">
                                {" "}
                                Plan Type:&nbsp;
                              </span>
                              <span className="midText">
                                {user.planType && user.planType != ""
                                  ? user.planType
                                  : "monthly"}
                                (
                                {user.period && user.period != ""
                                  ? user.period
                                  : "1"}
                                )
                                {user.planType &&
                                (user.planType == "" ||
                                  user.planType == "monthly") ? (
                                  <> - {user.plan}</>
                                ) : (
                                  ""
                                )}
                              </span>
                              <br />
                              <span className="tinyCardLabel">
                                {" "}
                                Number of Bikes:&nbsp;
                              </span>
                              <span className="midText">
                                {user.totalBikes && user.totalBikes != ""
                                  ? user.totalBikes
                                  : "1"}
                              </span>
                            </>
                          )}
                          <br />
                          {user.waitingStatus === "Confirmed" ? (
                            <>
                              <span className="tinyCardLabel">
                                {" "}
                                Pickup at:&nbsp;
                              </span>
                              <span className="midText">
                                {DateFormatter.DateFormatterOne(
                                  user.pickupDate
                                )}
                              </span>
                              <br />
                            </>
                          ) : user.waitingStatus === "Follow up" ? (
                            <div>
                              <span className="tinyCardLabel">
                                Follow up at&nbsp;
                              </span>
                              <span className="midText">
                                {DateFormatter.DateFormatterOne(
                                  user.followupDate
                                )}
                              </span>
                              <br />
                            </div>
                          ) : (
                            ""
                          )}
                          <span className="midText">
                            {user.waitingStatus === "Confirmed" ? (
                              user.branch ? (
                                <>
                                  <NavLink
                                    to={`/subscription/new?branch=${user.branch._id}&user=${user._id}&planId=${user.planId}`}
                                  >
                                    Create Subscription
                                  </NavLink>
                                  <br />
                                </>
                              ) : (
                                <>
                                  <span className="red">No branch</span>
                                  <br />
                                </>
                              )
                            ) : null}
                          </span>

                          <span className="tinyCardLabel">Approved:&nbsp;</span>
                          <span className="midText">
                            {user.approved === 1
                              ? "Yes"
                              : user.approved === 2
                              ? "No"
                              : "Not Set"}
                          </span>
                          <br />
                          {user.approved === 2 && user.rejectReason ? (
                            <>
                              <span className="tinyCardLabel">
                                Reject Reason:&nbsp;
                              </span>
                              <span className="midText">
                                {user.rejectReason}
                              </span>
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                        </Card.Body>
                      </Card>
                    </div>
                  ))
                : "No recod found"}
            </Row>

            <div className="mt-3">
              {"Items per Page: "}
              <select
                onChange={(e) => handlePageSizeChange(e.target.value)}
                value={itemsPerPage}
              >
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>

              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingtotal={1}
                boundarytotal={1}
                variant="outlined"
                color="primary"
                shape="rounded"
                onChange={handlePageChange}
                size="medium"
              />
            </div>
          </div>
        ) : loading ? (
          <Loader />
        ) : resetMsg != "" ? (
          <div className="defaultMsg">{resetMsg}</div>
        ) : (
          <div className="noResultsFound">{labels.NO_RESULTS}</div>
        )}
      </div>

      <GetDateModal
        title="Select Date For Follow Up"
        modalShow={modalFollowUpShow}
        setModalShow={setModalFollowUpShow}
        onSubmitDate={triggerStatusForFollowUp}
      />

      <GetDateModal
        title="Select Date For Pick Up"
        modalShow={modalPickUpShow}
        setModalShow={setModalPickUpShow}
        onSubmitDate={triggerStatusForPickUp}
      />
    </>
  );
};

export default WaitingsUser;
