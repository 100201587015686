import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const getBikesList = (
  branch,
  status,
  number,
  offset,
  limit,
  onlyList,
  model
) => {
  return axios.get(
    API_URL +
      "/v2/admin/bike/list?branch=" +
      branch +
      "&status=" +
      status +
      "&number=" +
      number +
      "&offset=" +
      offset +
      "&limit=" +
      limit +
      "&onlyList=" +
      onlyList +
      "&model=" +
      model,
    { headers: authHeader() }
  );
};

const deleteBike = (id) => {
  return axios(API_URL + "/admin/bike/delete/" + id, {
    method: "get",
    headers: authHeader(),
  });
};

const postBike = (
  id,
  number,
  model,
  kilometers,
  status,
  description,
  branch,
  pullFromModel,
  removedparts
) => {
  return axios(API_URL + "/admin/bike/save", {
    method: "post",
    headers: authHeader(),
    data: {
      _id: id,
      number,
      model,
      kilometers,
      status,
      description,
      branch,
      pullFromModel,
      removedparts,
    },
  });
};

const getBikeById = (id) => {
  return axios.get(API_URL + "/admin/bike/" + id, { headers: authHeader() });
};

const getBikeDetailsById = (id) => {
  return axios.get(API_URL + "/admin/bike/details/" + id, {
    headers: authHeader(),
  });
};

const markBikeAsStolen = (data) => {
  return axios(API_URL + "/bike/stolen", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const markBikeAsFound = (data) => {
  return axios(API_URL + "/bike/found", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

export default {
  postBike,
  deleteBike,
  getBikesList,
  getBikeById,
  getBikeDetailsById,
  markBikeAsStolen,
  markBikeAsFound,
};
