import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";




const deleteModel = (id) => {
  return axios(API_URL + "/model/" + id, { method: 'delete', headers: authHeader() });
};

const postModel = (id, label, type, frameSize, wheelDiameter, maxRiderWeight, maxSpeed, bikeWeight, motorType, motorRatedPower, motorRatedRPM, motorRatedVoltage, brakes, gears, picture, picChanged, ampere,voltage, weight, branch, inventories, addedInventories, removedInventories) => {
  return axios(API_URL + "/model/save", { method: 'post', headers: authHeader(), data: { _id: id, label: label, type: type, frameSize: frameSize, wheelDiameter: wheelDiameter, maxRiderWeight: maxRiderWeight, maxSpeed: maxSpeed, bikeWeight: bikeWeight, motorType: motorType, motorRatedPower: motorRatedPower, motorRatedRPM: motorRatedRPM, motorRatedVoltage: motorRatedVoltage, brakes: brakes, gears: gears, picture:picture , picChanged: picChanged, ampere: ampere, voltage: voltage, weight:weight, branch: branch, inventories, addedInventories, removedInventories } });
};


const getModelObjById = (id) => {
  var data = axios.get(API_URL + "/model/obj/" + id, { headers: authHeader() });
  console.log(data);
  return data;
};

const getModelById = (id) => {
  var data = axios.get(API_URL + "/model/" + id, { headers: authHeader() });
  console.log(data);
  return data;
};

export default {
  // putModel,
  postModel,
  deleteModel,
  getModelById,
  getModelObjById
};
