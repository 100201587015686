import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const getWaitingList = (
  branch,
  keyword,
  status,
  offset,
  limit,
  orderby,
  pickupDate,
  subTypeFilter
) => {
  return axios.get(
    API_URL +
      "/waiting/list?keyword=" +
      keyword +
      "&branch=" +
      branch +
      "&status=" +
      status +
      "&offset=" +
      offset +
      "&limit=" +
      limit +
      "&orderby=" +
      orderby +
      "&pickupDate=" +
      pickupDate +
      "&subTypeFilter=" +
      subTypeFilter,
    { headers: authHeader() }
  );
};

const deleteWaiting = (id) => {
  return axios(API_URL + "/waiting/" + id, {
    method: "delete",
    headers: authHeader(),
  });
};

const postWaiting = (data) => {
  return axios(API_URL + "/waiting/update", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const getWaitingById = (id) => {
  return axios.get(API_URL + "/waiting/details/" + id, {
    headers: authHeader(),
  });
};

const addToWaitingListHelpdesk = (data) => {
  return axios(API_URL + "/waiting/addHelpDesk", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

export default {
  postWaiting,
  deleteWaiting,
  getWaitingList,
  getWaitingById,
  addToWaitingListHelpdesk,
};
