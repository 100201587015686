import React from "react";
import Resizer from "react-image-file-resizer";
import ImageUploader from "react-images-upload";
import userService from "../../services/user.service";
import EventBus from "../../common/EventBus";

const CardUploadSection = ({ userId, userData, setUserData, activeTab }) => {
  const { defaultCardId, defaultCardId2 } = userData;
  const saveCardId = async (p, isSecondCard) => {
    try {
      Resizer.imageFileResizer(
        p[0],
        300,
        300,
        "JPEG",
        100,
        0,
        async (uri) => {
          const newUri = uri.replace(/^data:image\/[^;]+/, "data:image/jpeg");
          setUserData((prevState) => ({
            ...prevState,
            [isSecondCard ? "defaultCardId2" : "defaultCardId"]: newUri,
          }));
          await userService.uploadCardId({
            userid: userId,
            cardId: newUri,
            isSecondCard,
          });
        },
        "base64",
        200,
        200
      );
    } catch (err) {
      console.error(err);
    }
  };

  const rejectCardId = (cardToDelete) => {
    if (
      window.confirm(
        "Are you sure you want to delete this card" + cardToDelete + "?"
      )
    ) {
      userService
        .rejectCardId({
          userId: userId,
          cardToDelete: cardToDelete,
          rejectReason: "Deleted by Admin.",
        })
        .then(
          () => {
            if (cardToDelete == 1) {
              setUserData((prevState) => ({
                ...prevState,
                defaultCardId: null,
              }));
            } else {
              setUserData((prevState) => ({
                ...prevState,
                defaultCardId2: null,
              }));
            }
          },
          (error) => {
            const _content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            alert(_content);

            if (error.response && error.response.status === 401) {
              EventBus.dispatch("logout");
            }
          }
        );
    }
  };

  return (
    <div
      id="identiy"
      className={` card-upload-section tab-content ${
        activeTab === "identiy" ? "expanded" : "collapsed"
      }`}
    >
      <div className="card-upload">
        <h4>
          Card ID 1{" "}
          {defaultCardId && (
            <a
              onClick={() => rejectCardId(1)}
              className="reject_card"
              aria-label={`Delete Card ID 1}`}
            >
              ❌
            </a>
          )}
        </h4>
        {defaultCardId ? (
          <>
            <img src={defaultCardId} alt="Card ID 1" className="card-image" />
          </>
        ) : (
          <ImageUploader
            withIcon={true}
            buttonText="Choose image"
            onChange={(picture) => saveCardId(picture, false)}
            imgExtension={[".jpg", ".jpeg", ".png", ".gif"]}
            maxFileSize={5242880}
            singleImage={true}
          />
        )}
      </div>
      <div className="card-upload">
        <h4>
          Card ID 2{" "}
          {defaultCardId2 && (
            <a
              onClick={() => rejectCardId(2)}
              className="reject_card"
              aria-label={`Delete Card ID 2}`}
            >
              ❌
            </a>
          )}
        </h4>
        {defaultCardId2 ? (
          <>
            <img src={defaultCardId2} alt="Card ID 2" className="card-image" />
          </>
        ) : (
          <ImageUploader
            withIcon={true}
            buttonText="Choose image"
            onChange={(picture) => saveCardId(picture, true)}
            imgExtension={[".jpg", ".jpeg", ".png", ".gif"]}
            maxFileSize={5242880}
            singleImage={true}
          />
        )}
      </div>
    </div>
  );
};

export default CardUploadSection;
