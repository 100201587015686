import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";


const getBatsList = (branch, number, offset, limit, onlyList, status, model) => {
  return axios.get(API_URL + "/bat/list?branch=" + branch + "&number=" + number + "&offset=" + offset + "&limit=" + limit + "&onlyList=" + onlyList + "&status=" + status + "&model=" + model, { headers: authHeader() });
};

const deleteBat = (id) => {
  return axios(API_URL + "/bat/" + id, { method: 'delete', headers: authHeader() });
};

const putBat = (id, number, status, model, branch) => {
  return axios(API_URL + "/bat/save", { method: 'post', headers: authHeader(), data: { _id: id, number: number, status: status, model: model, branch: branch } });
};

const postBat = (number, status, model, branch) => {
  return axios(API_URL + "/bat/save", { method: 'post', headers: authHeader(), data: { _id: '', number: number, status: status, model: model, branch: branch } });
};


const getBatById = (id) => {
  return axios.get(API_URL + "/bat/details/" + id, { headers: authHeader() });
};

const markBatAsStolen = (data) => {
  return axios(API_URL + "/bat/stolen", { method: 'post', headers: authHeader(), data: data })
}

const markBatAsFound = (data) => {
  return axios(API_URL + "/bat/found", { method: 'post', headers: authHeader(), data: data })
}

export default {
  putBat,
  postBat,
  deleteBat,
  getBatsList,
  getBatById,
  markBatAsStolen,
  markBatAsFound
};
