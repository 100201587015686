import React, { useEffect, useState } from "react";

import AnalyticsService from "../services/analytics.service";
import EventBus from "../common/EventBus";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Col } from "react-bootstrap";
import Loader from "../commonComponents/Loader";
import { CSVLink } from "react-csv";
import ErrorBoundary from "../common/ErrorBoundary";
import * as labels from "../labels/en";
import WaveBarChart from "./charts/WaveBarChart";

const AnalyticsRevenue = () => {
  const monthsNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const lastYearDate = new Date(
    new Date().getFullYear() - 1,
    new Date().getMonth(),
    new Date().getDate()
  );
  const [fromDate, setFromDate] = useState(lastYearDate);
  const [toDate, setToDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [revenueData, setRevenueData] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  // Prepare CSV headers
  const csvHeader = [
    { label: "Year", key: "year" },
    { label: "Month", key: "month" },
    { label: "Total Amount", key: "totalAmount" },
    { label: "Total Net Amount", key: "totalNetAmount" },
    { label: "Currency", key: "currency" },
  ];

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      await triggerSearch(lastYearDate, new Date(), "");
    }

    onReady();
  }, []);

  const triggerSearch = async (fromDateT, toDateT) => {
    if (fromDateT) {
      await getRevenues(fromDateT, toDateT);
    }
  };

  const getRevenues = async (fromDate, toDate) => {
    setLoading(true);

    AnalyticsService.getRevenues(fromDate, toDate).then(
      (response) => {
        let data = response.data?.data;
        if (data) {
          setRevenueData(data);
        } else {
          data = null;
        }
        setLoading(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        alert(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
        setLoading(false);
        return _content;
      }
    );
  };

  const resetSearch = async () => {
    setFromDate(lastYearDate);
    setToDate(new Date());
    await triggerSearch(lastYearDate, new Date());
  };
  const period =
    fromDate.getMonth() +
    1 +
    "-" +
    fromDate.getFullYear() +
    "" +
    " to " +
    (toDate.getMonth() + 1) +
    "-" +
    toDate.getFullYear();
  return (
    <div>
      <h3>Revenues (For Monthly)</h3>
      <div className="filterContainer">
        <div className="d-flex flex-row">
          <Col lg={3} className="p-0">
            <label
              htmlFor="start"
              className="filterLabelInput maintenanceLabels"
            >
              From
            </label>
            <DatePicker
              autoComplete="off"
              dateFormat="MM-yyyy"
              className="form-control filterInput2"
              name="start"
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              showMonthYearPicker
            />
          </Col>
          <Col lg={3} className="p-0">
            <label htmlFor="to" className="filterLabelInput maintenanceLabels">
              To
            </label>
            <DatePicker
              autoComplete="off"
              dateFormat="MM-yyyy"
              className="form-control filterInput2"
              name="end"
              selected={toDate}
              onChange={(date) => setToDate(date)}
              showMonthYearPicker
            />
          </Col>
          <Col lg={3} className="p-0">
            <div style={{ marginTop: 35 }} border="0">
              <div className="d-flex flex-row align-items-start">
                <button
                  onClick={(e) => triggerSearch(fromDate, toDate)}
                  className="btn btn-primary search-button"
                >
                  Go
                </button>
                <button
                  onClick={(e) => resetSearch(e)}
                  className="btn btn-primary reset-button"
                >
                  Reset
                </button>
              </div>
            </div>
          </Col>
        </div>
      </div>
      <br />
      {loading && <Loader />}
      {revenueData ? (
        <>
          <h5 style={{ paddingLeft: 50 }}>Revenues for {period}</h5>
          <div className="floatRight ">
            <a
              href="javascript:void(0)"
              className="text-dark midText"
              onClick={() => setShowDetails(!showDetails)}
            >
              {showDetails ? "Hide" : "Show"} Details
            </a>
          </div>
          <div className="floatRight ">
            <CSVLink
              data={revenueData}
              headers={csvHeader}
              filename={period + "-" + "revenues.csv"}
            >
              <i alt="View" title="View" className="fa fa-download"></i> Export
            </CSVLink>
          </div>

          <div className="bubble-chart-container-analtycis">
            <ErrorBoundary message="Problem in index.render: Charts">
              <WaveBarChart data={revenueData} />
            </ErrorBoundary>
          </div>
        </>
      ) : (
        <div className="noResultsFound">{labels.NO_RESULTS}</div>
      )}
      {!loading && revenueData && revenueData.length != 0 ? (
        showDetails ? (
          <>
            <h5 style={{ paddingLeft: 50 }}>Details</h5>

            <div style={{ paddingLeft: 50, marginTop: 50 }}>
              <table className="styled-table">
                <thead>
                  <tr>
                    <th>Year</th>
                    <th>Month</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {revenueData && revenueData.length != 0
                    ? revenueData.map((r) => {
                        return (
                          <tr>
                            <td>{r.year}</td>
                            <td>{r.month}</td>
                            <td>{r.total}</td>
                          </tr>
                        );
                      })
                    : "No record found"}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          ""
        )
      ) : (
        <div> No records found.</div>
      )}
    </div>
  );
};

export default AnalyticsRevenue;
