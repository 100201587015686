import React, { useEffect, useState } from 'react';
import { Accordion, Modal } from 'react-bootstrap';
import SurveyQuestionAccordionItem from '../commonComponents/SurveyQuestionAccordionItem';
import SurveyService from '../services/survey.service';
import EventBus from "../common/EventBus";
import Input from "react-validation/build/input";
import RequiredLabel from '../common/RequiredLabel';
import { required } from '../common/Validation';
import Form from "react-validation/build/form";
import Select from "react-validation/build/select";
import { NavLink } from 'react-router-dom';
import { answerTypeOptions } from '../labels/options_en';

const currentUser = JSON.parse(localStorage.getItem('user'));
export default function SurveyQuestions(props) {

    const [questions, setQuestions] = useState([]);
    const [questionModal, setQuestionModal] = useState(false);
    const [question, setQuestion] = useState("");
    const [answerType, setAnswerType] = useState("yes_no");
    const [form, setForm] = useState("");
    //const [order, setOrder] = useState(1);
    const [answerOptions, setAnswerOptions] = useState(["", ""]);
    const [answerWeight, setAnswerWeight] = useState(["", ""]);
    const [isRequired, setIsRequired] = useState(true);
    const [isActive, setIsActive] = useState([]);
    const formId = props.match.params.id;


    useEffect(() => {

        async function onReady() {
            await fetchQuestions();
            getSurveyFormInfo();
        }

        onReady()

    }, [])

    const getSurveyFormInfo = async () => {
        if (formId) {
            SurveyService.getSurveyFormById(formId).then(
                (response) => {

                    let u = response.data;
                    setForm(u);
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    alert(_content);

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        }

    }

    const fetchQuestions = async () => {
        return await SurveyService.getQuestionsListByFormId(formId)
            .then(({ data }) => {
                console.log('data', data)
                setQuestions(data.data.list);
                let a = [];
                data.data.list.map((q, i) => {
                    a.push(false);
                });
                setIsActive(a);
            },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    alert(_content);

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                }
            );
    }

    const closeQuestionModalHandler = () => {
        setQuestion("");
        setAnswerType("yes_no");
        setAnswerOptions(["", ""]);
        setAnswerWeight(["", ""]);
        setQuestionModal(false);
    }

    const addQuestionHandler = () => {
        /*let sum = 0;
        answerWeight.map(w => sum += parseInt(w));
        if (sum > 100) {
            alert("Weight sum should be less than 100.");
            return;
        }*/
        //add weight to conditions
        if (!question || !answerType || ((answerType === "Checkbox" || answerType === "Radio") && (!answerOptions || !answerOptions[0] || !answerOptions[1]))) {
            alert("Please fill all required fields");
            return;
        }
        let options = answerOptions
        if (answerType === "yes_no") options = ["Yes", "No"];
        else if (answerType === "yes_maybe_no") options = ["Yes", "Maybe", "No"];
        let q = questions;
        q.push({ label: question, type: form.type, isrequired: isRequired, order: q.length + 1, answerType: answerType, answerOptions: options, answerWeight: answerWeight });
        setQuestions(q);
        //setOrder("");
        setQuestion("");
        setAnswerType("yes_no");
        setIsRequired(true);
        setAnswerOptions(["", ""]);
        setAnswerWeight(["", ""]);
        setQuestionModal(false);
    }

    const handleSaveQuestions = (preview) => {
        let data = {
            branch: form.branch,
            form: formId,
            questions: questions
        }
        SurveyService.saveQuestions(data).then(
            (response) => {
                console.log(response);
                alert("Questions have been saved.");
                if (!preview) window.location.href = window.location.protocol + "//" + window.location.host + "/questions/" + formId;
                else {
                    window.open(window.location.protocol + "//" + window.location.host + "/survey/" + formId, '_blank');
                    window.location.href = window.location.protocol + "//" + window.location.host + "/forms";

                }
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                alert(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        )
    }

    const AnswerComponent = (props) => {
        return (
            <Form>
                <div className="form-group">
                    <RequiredLabel htmlFor="question" text={"Question " + (props.index + 1)} />
                    <Input
                        type="text"
                        className="form-control"
                        name="question"
                        value={questions[props.index].label}
                        onChange={e => handleEditQuestion(e.target.value, props.index, "question")}
                        validations={[required]}
                    />
                </div>
                <div className="form-group">
                    <RequiredLabel htmlFor="required" text="Required" />
                    <Select
                        className="form-control"
                        value={questions[props.index].isrequired}
                        onChange={(e) => handleEditIsRequired(e.target.value, props.index)}
                        validations={[required]}
                    >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>

                    </Select>
                </div>
                <div className="form-group">
                    <RequiredLabel htmlFor="answerType" text="Answer Type" />
                    <Select
                        className="form-control"
                        value={questions[props.index].answerType}
                        onChange={e => handleEditAnswerType(e.target.value, props.index)}
                        validations={[required]}
                    >
                        {answerTypeOptions.map((t, i) =>
                            <option value={t.key}>{t.value}</option>)}

                    </Select>
                </div>
                {questions[props.index].answerType === "Radio" || questions[props.index].answerType === "Checkbox" ?
                    <div style={{ paddingLeft: 100, width: "50%" }}>

                        {questions[props.index].answerOptions.map((r, i) =>
                            <div className="d-flex flex-row align-items-center" >
                                <div className="form-group">
                                    <RequiredLabel htmlFor="radioCheckLabel" text={questions[props.index].answerType + " " + (i + 1)} />
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="radioCheckLabel"
                                        value={r}
                                        onChange={e => handleEditQuestion(e.target.value, props.index, "answerOptions", i)}
                                        validations={[required]}
                                    />
                                </div>
                                &nbsp;&nbsp;&nbsp;
                                {i > 1 && i == (questions[props.index].answerOptions.length - 1) ?
                                    <button onClick={() => handleDeleteAnswerOptionEdit(props.index, i)} className="linkBtn"><i alt="Delete" title="Delete" className="fa fa-trash" ></i></button> : ""}
                                {/*<div className="form-group">
                                    <RequiredLabel htmlFor="question" text={"Weight " + i} />
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="question"
                                        value={questions[props.index].answerWeight[i]}
                                        onChange={e => handleEditQuestion(e.target.value, props.index, "answerWeight", i)}
                                        validations={[required]}
                                    />
                                </div>*/}
                            </div>

                        )

                        }
                        <span className="linkBtn greenLink float-right" style={{ marginRight: -120, marginTop: -5 }} onClick={(e) => handleAddRadioCheckEdit(e, props.index)}>Add {questions[props.index].answerType}</span>
                    </div>
                    : ""}
            </Form>

        )
    }

    const handleAddRadioCheck = (e) => {
        e.preventDefault();
        setAnswerOptions([...answerOptions, ""]);
        //setAnswerWeight([...answerWeight, ""]);
    }

    const handleRadioCheckLabelChange = (label, i) => {
        let options = answerOptions;
        options[i] = label;
        setAnswerOptions(options);
    }

    const handleRadioCheckWeightChange = (w, i) => {
        setAnswerWeight(answerWeight.map((weight, index) => {
            if (index === i) {
                weight = w;
            }
            return weight;
        })
        )
    }

    const handleEditQuestion = (value, questionIndex, type, answerOptionsIndex) => {
        let list = questions;
        if (type === "question") list[questionIndex].label = value;
        //else if (type === "answerType") list[questionIndex].answerType = value;
        else if (type === "answerOptions") list[questionIndex].answerOptions[answerOptionsIndex] = value;
        else if (type === "answerWeight") list[questionIndex].answerWeight[answerOptionsIndex] = value;

        /* setQuestions(questions.map((q, i) => {
             if (questionIndex === i) {
                 if (type === "question") q.label = value;
                 else if (type === "answerType") q.answerType = value;
                 else if (type === "answerOptions") q.answerOptions[answerOptionsIndex] = value;
                 esle if(type === "")
             }
             return q;
         }));*/
    }

    const handleEditAnswerType = (value, questionIndex) => {
        setQuestions(questions.map((q, i) => {
            if (questionIndex === i) {
                q.answerType = value;
                if (value === "yes_no") {
                    q.answerOptions = ["yes", "no"];
                }
                else if (value === "yes_maybe_no") {
                    q.answerOptions = ["yes", "maybe", "no"];
                }
            }
            return q;
        }))
    }

    const handleAddRadioCheckEdit = (e, index) => {
        e.preventDefault();
        setQuestions(questions.map((q, i) => {
            if (index === i) {
                q.answerOptions = [...q.answerOptions, ''];
            }
            return q;
        }))
    }

    const handleDeleteQuestionClick = (questionId, index) => {
        if (window.confirm("Are you sure you want to delete this question?")) {
            setQuestions(questions.filter((q, i) => i !== index));
            console.log(questions);
            if (questionId) {
                SurveyService.deleteQuestion(questionId).then(
                    (response) => {
                        alert("Question has been deleted.");
                    },
                    (error) => {
                        const _content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        alert(_content);

                        if (error.response && error.response.status === 401) {
                            EventBus.dispatch("logout");
                        }
                    }
                )
            }
            else alert("Question has been deleted.")
        }
    }

    const handleDeleteAnswerOption = async (e, index) => {
        e.preventDefault();
        const list = [...answerOptions];
        const length = list.length - 1;

        list.splice(index, 1);
        setAnswerOptions([...list]);



    }

    const handleDeleteAnswerOptionEdit = (questionIndex, answerOptionIndex) => {
        setQuestions(questions.map((q, index) => {
            if (index === questionIndex) {
                q.answerOptions = q.answerOptions.filter((a, i) => i !== answerOptionIndex);
            }
            return q;
        }))
    }

    const handleEditIsRequired = (required, index) => {
        setQuestions(questions.map((q, i) => {
            if (i === index) {
                q.isrequired = required;
            }
            return q;
        }))
    }

    const closeOtherAccordionItems = (index) => {
        let active = isActive;
        active.map((a, i) => {
            if (i !== index) a = false;
            return a;
        })
        setIsActive(active);
    }






    return (
        <div>
            <Modal show={questionModal} onHide={closeQuestionModalHandler}>
                <Modal.Header><Modal.Title>Question</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="form-group">
                            <RequiredLabel htmlFor="question" text="Question" />
                            <Input
                                type="text"
                                className="form-control"
                                name="question"
                                value={question}
                                onChange={e => setQuestion(e.target.value)}
                                validations={[required]}
                            />
                        </div>
                        <div className="form-group">
                            <RequiredLabel htmlFor="required" text="Required" />
                            <Select
                                className="form-control"
                                value={isRequired}
                                onChange={(e) => setIsRequired(e.target.value)}
                                validations={[required]}
                            >
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>

                            </Select>
                        </div>
                        <div className="form-group">
                            <RequiredLabel htmlFor="answerType" text="Answer Type" />
                            <Select
                                className="form-control"
                                value={answerType}
                                onChange={(e) => setAnswerType(e.target.value)}
                                validations={[required]}
                            >
                                {answerTypeOptions.map((t, i) =>
                                    <option value={t.key}>{t.value}</option>)}
                            </Select>
                        </div>
                        {answerType === "Radio" || answerType === "Checkbox" ?
                            <div style={{ paddingLeft: 50, width: "50%" }}>

                                {answerOptions.map((r, i) =>
                                    r !== null ?
                                        <div className="d-flex flex-row align-items-center" >
                                            <div className="form-group" >
                                                <RequiredLabel htmlFor="radioCheckLabel" text={answerType + " " + (i + 1)} />
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="radioCheckLabel"
                                                    value={r}
                                                    onChange={e => handleRadioCheckLabelChange(e.target.value, i)}
                                                    validations={[required]}
                                                />
                                            </div>&nbsp;&nbsp;&nbsp;
                                            {i > 1 && i == (answerOptions.length - 1) ?
                                                <button onClick={(e) => handleDeleteAnswerOption(e, i)} className="linkBtn"><i alt="Delete" title="Delete" className="fa fa-trash" ></i></button> : ""}
                                            {/*<div className="form-group" style={{ width: "45%" }}>
                                            <RequiredLabel htmlFor="question" text={"Weight " + (i + 1)} />
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="question"
                                                value={answerWeight[i]}
                                                onChange={e => handleRadioCheckWeightChange(e.target.value, i)}
                                                validations={[required]}
                                            />
                                        </div>*/}
                                        </div>
                                        : ""


                                )

                                }
                                <button className="linkBtn float-right greenLink" style={{ marginRight: -100 }} onClick={(e) => handleAddRadioCheck(e)}>Add {answerType}</button>
                            </div>
                            : ""}
                    </Form>
                </Modal.Body>
                <div className="d-flex flex-row">
                    <button style={{ margin: '10px' }} onClick={closeQuestionModalHandler}>Cancel</button>
                    <button style={{ margin: '10px' }} variant="primary" onClick={addQuestionHandler}>Add Question</button>
                </div>

            </Modal>

            <h3>Manage Questions for {form.label}</h3>

            <div className="floatRight "><button onClick={() => setQuestionModal(true)} className="greenLink linkBtn mx-0">Add Question</button></div>

            <div id='landing_FAQ_container' style={{ width: "100%" }}>

                <Accordion style={{ width: "100%" }} alwaysOpen>

                    {questions.length > 0 && questions.map((q, index) =>
                        <div style={{ marginTop: 10 }}>
                            <SurveyQuestionAccordionItem key={q._id} index={index} question={q.label} answer={<AnswerComponent index={index} />} deleteQuestion={handleDeleteQuestionClick} questionId={q._id} isActive={isActive[index]} setIsActive={setIsActive} closeOthers={closeOtherAccordionItems} />
                        </div>
                    )}

                </Accordion>

            </div>
            {questions.length > 0 ?
                <div className='d-flex flex-row'>
                    <button style={{ margin: 10 }} onClick={() => handleSaveQuestions(false)}>Save</button>
                    <button style={{ margin: 10 }} onClick={() => handleSaveQuestions(true)}>Save and Preview</button>
                    <NavLink to="/survey/forms" style={{ width: "80%" }}><button style={{ margin: 10, width: "100%" }}>Cancel</button></NavLink>
                </div>
                : ""}
        </div>
    )
}
