import React from "react";

const LogsSection = ({ logs, activeTab }) => {
  return (
    <div
      id="logs"
      className={`tab-content ${
        activeTab === "logs" ? "expanded" : "collapsed"
      }`}
    >
      <div dangerouslySetInnerHTML={{ __html: logs }}></div>
    </div>
  );
};

export default LogsSection;
