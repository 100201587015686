import React from "react";

export default function RequiredLabel(props) {
  const text = props.text || "";
  const to = props.htmlFor || "";
  const showRefresh = props.showRefresh || false;
  const onclickAction = props.onclickAction || "";
  const loaded = props.loaded || false;
  const showNew = props.showNew || false;
  const onNewClick = props.onNewClick || "";

  const className = props.className || "waveLabel";
  return (
    <label htmlFor={to} className={className}>
      {showRefresh ? (
        <>
          <button className="linkBtn text-dark" onClick={onclickAction}>
            <i className="fa fa-refresh"></i>
          </button>
        </>
      ) : (
        ""
      )}
      {showNew ? (
        <>
          &nbsp;
          <button className="linkBtn text-dark" onClick={onNewClick}>
            <i className="fa fa-add"></i>
          </button>
        </>
      ) : (
        ""
      )}
      {text} <span className="red">*</span>
      {!loaded && showRefresh ? <i className="fa fa-spinner"></i> : ""}{" "}
    </label>
  );
}
