import React from 'react'
import { Col, Row } from "react-bootstrap";


export default function SubscriptionInfo(props) {



	return (
		<div className="infoContainer">
			<Row className="dashboardRow" style={{ flexDirection: 'row', textAlign: 'center', alignItems: "flex-start" }}>
				<Col lg={6}>
					<div className="infoTitle">The maintenance</div>
					<div className="infoDetail">
						The Wave bike is a hassle-free ride which means that all maintenance is included. By simply contacting us on our hotline +96170632955 when something is not 100% on the bike, we will come either to fix the bike or switch it. Besides that, you are always welcome to pass by the Wave hub. Within no time you can enjoy your Wave bike again. It's that easy!
					</div>

				</Col>
				<Col lg={6}>
					<div className="infoTitle">The pickup</div>
					<div className="infoDetail">
						When a bike is available for you, we will inform you and provide you with the necessary information to get you riding as soon as possible. We will invite you to come to our Wave hub in Geitawi to pick up your bike. During this pick up, we will take our time to explain the bike and go through some tips and tricks. For legal reasons, and to adjust the bike to your needs, you have to be personally present to receive it.
					</div>

				</Col>
			</Row>
			<Row className="dashboardRow" style={{ flexDirection: 'row', textAlign: 'center', alignItems: "flex-start" }}>
				<Col lg={6}>
					<div className="infoTitle">The renewal</div>
					<div className="infoDetail">
						The Wave bike subscription is on a monthly basis. Your subscription will be automatically renewed until you decide to terminate it (e.g., if you started the subscription on the 12th of a certain month, the renewal will take place every 12th of the month). You can do this at any time, and the subscription will end at the end of the current subscription period. Upon termination, you are required to bring back the bike to the Wave Hub in Geitawi so you can collect your deposit.

					</div>

				</Col>
				<Col lg={6}>
					<div className="infoTitle">The payment</div>
					<div className="infoDetail">
						At the moment, we only accept cash payments. They are to be paid upon bike pickup at the Wave hub. Additionally, we ask for a 1-time deposit of $150, which you will get back once you terminate the subscription.<br/>
						To ensure your safety, we will provide you with a helmet. After 3 months of subscription, the helmet is free. If you subscribe for a period shorter than three months, we will deduct $20 from your deposit. The regular sales price of a helmet is $65, so you benefit greatly!
						<br /><br />
						Upon subscription renewal, the fee should be paid at the Wave hub or by OMT-transfer.<br/>
						We will always notify you about a price change on beforehand, so that there never will be any surprises.

					</div>

				</Col>
			</Row>


		</div>)
}
