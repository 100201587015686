import React from "react";


import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    InfoWindow,
    Marker
} from "react-google-maps";

//import socketIOClient from "socket.io-client";

import markergreen from "../assets/img/markergreen.png";
import markerred from "../assets/img/markerred.png";
import markeryellow from "../assets/img/markeryellow.png";
import markerpanicimg from "../assets/img/markerpanic.png"
import LocationService from "../services/location.service";
import EventBus from "../common/EventBus";
import { HollowDotsSpinner } from 'react-epic-spinners'
import Constants from "../core/Constants";


const RegularMap = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultZoom={props.defaultZoom}
            defaultCenter={props.defaultCenter}
            defaultOptions={props.defaultOptions}
        >

            {props.markeroffline.map((item,index) => {

                const onClick = props.onClick.bind(this, item)
                let iconMarker = new window.google.maps.MarkerImage(
                    markerred,
                    null, /* size is determined at runtime */
                    null, /* origin is 0,0 */
                    null, /* anchor is bottom center of the scaled image */
                    new window.google.maps.Size(28, 28)
                );
                return (
                    <Marker  onClick={onClick} icon={iconMarker} key={index} position={item} >
                        {props.selectedMarker === item &&
                        <InfoWindow>
                            <div>
                                finished journey
                            </div>
                        </InfoWindow>}
                    </Marker>
                )
            })}

            {props.markermaintenace.map((item,index) => {
                const onClick = props.onClick.bind(this, item)
                let iconMarker = new window.google.maps.MarkerImage(
                    markeryellow,
                    null, /* size is determined at runtime */
                    null, /* origin is 0,0 */
                    null, /* anchor is bottom center of the scaled image */
                    new window.google.maps.Size(28, 28)
                );
                return (
                    <Marker  onClick={onClick} icon={iconMarker} key={index} position={item} >
                        {props.selectedMarker === item &&
                        <InfoWindow>
                            <div>
                                bike needs maintenance
                            </div>
                        </InfoWindow>}
                    </Marker>
                )
            })}

            {props.markerlive.map((item,index) => {
                const onClick = props.onClick.bind(this, item)
                let iconMarker = new window.google.maps.MarkerImage(
                    markergreen,
                    null, /* size is determined at runtime */
                    null, /* origin is 0,0 */
                    null, /* anchor is bottom center of the scaled image */
                    new window.google.maps.Size(28, 28)
                );
                return (
                    <Marker  onClick={onClick} icon={iconMarker} key={index} position={item} >
                        {props.selectedMarker === item &&
                        <InfoWindow>
                            <div>
                                not finished journey
                            </div>
                        </InfoWindow>}
                    </Marker>
                )
            })}

            {props.markerpanic.map((item,index) => {
                const onClick = props.onClick.bind(this, item)
                let iconMarker = new window.google.maps.MarkerImage(
                    markerpanicimg,
                    null, /* size is determined at runtime */
                    null, /* origin is 0,0 */
                    null, /* anchor is bottom center of the scaled image */
                    new window.google.maps.Size(28, 28)
                );
                return (
                    <Marker  onClick={onClick} icon={iconMarker} key={index} position={item} >
                        {props.selectedMarker === item &&
                        <InfoWindow>
                            <div>
                                Panic !! , Bike needs help
                            </div>
                        </InfoWindow>}
                    </Marker>
                )
            })}




            {props.liveLocation.map((item,index) => {
                const onClick = props.onClick.bind(this, item)
                let iconMarker = new window.google.maps.MarkerImage(
                    markergreen,
                    null, /* size is determined at runtime */
                    null, /* origin is 0,0 */
                    null, /* anchor is bottom center of the scaled image */
                    new window.google.maps.Size(28, 28)
                );
                return (
                    <Marker  onClick={onClick} icon={iconMarker} key={index}  position={item} >
                        {props.selectedMarker === item &&
                        <InfoWindow>
                            <div>
                                Live Journey
                            </div>
                        </InfoWindow>}
                    </Marker>
                )
            })}

        </GoogleMap>
    ))
);

export default class BikeMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            routes:[],
            userlist:[],
            defaultCenter :{ lat: 34.450592041015625, lng: 35.81779098510742 }, // lat=34.450592041015625,lon=35.81779098510742
            defaultZoom :  9 ,
            liveLocation:[],
            markeroffline:[],
            markerlive:[],
            markerpanic:[],
            markermaintenace:[],
            defaultOptions:{scrollwheel: false},
            selectedMarker: false,
			error:''
        };
       
    }

    handleClick = (marker, event) => {
        const {selectedMarker} = this.state;
        if(selectedMarker !==false){
            this.setState({ selectedMarker: false })
        }
        this.setState({ selectedMarker: marker })
    }

    componentDidMount() {

        const {type,selectedid} = this.props
		if (type == undefined || type == "") { type = 1 }
		
		
		
		if(type == 1){ // show all data
			this.prepareData()
		}else
		if(type == 2){ // show mainteneace
			this.showMaintenace()
		}else
		if(type ==3){
		 this.showjourneylocation(selectedid)
		}else
		if(type == 4){ // show ony subscribed id
			this.prepareData(selectedid)
		}else
		if(type == 5){ // show panic bike location
			this.showpaniclocation(selectedid)
		}
		
        

    }

    async getBikeLocations(type,journeyid){
		let res = LocationService.getList(type, journeyid).then(
			(response) => {
				return response;
			},
			(error) => {
				const _content =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();
				alert(_content)
				this.setState({
                        loading: false,
						error:_content
                    }
                );
				
				if (error.response && error.response.status === 401) {
					EventBus.dispatch("logout");

				}
			}
		);
		return res;
    }

	async showMaintenace(){
        const {selectedrow} = this.props
        if(selectedrow){
            this.processMarker([selectedrow],"markermaintenace","location");
        }
    }
    async showjourneylocation(journeyid) {
        this.getBikeLocations("journeylocation",journeyid,(response) => {
            console.log(response.status);
            if(response.status === 200){
                const data = response.data.data;
                this.processMarker(data,"markerlive");
                this.setState({
                        loading: false
                    }
                );
            }
        });
    }

    async showpaniclocation(panic) {
        this.getBikeLocations("paniclocation",panic,(response) => {
            if(response.status === 200){
                const data = response.data.data;
                this.processMarker(data,"markerpanic","location");
                this.setState({
                        loading: false
                    }
                );
            }
        });
    }



    processMarker(data,statename,locationnamepath="",center = true,live=false){

        let positionlist = []

        if(!live){
            if(locationnamepath.length>1){
                data.map(p =>
                    positionlist.push( { lat: parseFloat(p[locationnamepath].latitude), lng: parseFloat(p[locationnamepath].longitude) })
                )
            }else{
                data.map(p =>
                    positionlist.push( { lat: parseFloat(p.latitude), lng: parseFloat(p.longitude) })
                )
            }
        }else{
            positionlist = this.state[statename]

            this.setState({
                [statename]: []
            });
            data.map(p =>{

                    const positioninfo = positionlist.find(v => v.journey === p.journey)
                    const positionindex = positionlist.findIndex((v) => v.journey === p.journey)
                    if(positioninfo){
                        // position fount update location
                       // positioninfo
                        positioninfo.lat = parseFloat(p.latitude);
                        positioninfo.lng = parseFloat(p.longitude);
                        // get index
                        positionlist[positionindex] = positioninfo;
                    }else{
                        positionlist.push( { lat: parseFloat(p.latitude), lng: parseFloat(p.longitude),journey:p.journey })
                    }
            }

            )
        }



        if(center && positionlist.length>0){
            this.setState({
                defaultCenter : {lat: parseFloat(positionlist[0].lat), lng: parseFloat(positionlist[0].lng)}
            })
        }
        this.setState({
            [statename]: positionlist
        });



    }
    async prepareData(selectedid=0){
        this.setState({loading : true})
        let responseuni =[];

        if(selectedid !==0){
            responseuni = await this.getBikeLocations("marker",selectedid);
        }else{
            responseuni = await this.getBikeLocations("marker");
        }

        if(responseuni){

			console.log(responseuni.data.data)
            // offline
            let data = responseuni.data.data.markeroffline;

            this.processMarker(data,"markeroffline","tolocation");

            // online
            data = responseuni.data.data.markerlive;

            this.processMarker(data,"markerlive","fromlocation");

            // maintenance
            data = responseuni.data.data.markermaintenace;

            this.processMarker(data,"markermaintenace","location");


            // markerpanic
            data = responseuni.data.data.markerpanic;
            this.processMarker(data,"markerpanic","location");

            this.setState({
                loading : false
            });

            this.forceUpdate()
        }

    }


    viewuser(){
        
        this.processMarker([{latitude:"34.420",longitude:"35.84151",journey:"5cc44d50c8aed51ad07ec9f4"}],"liveLocation","",true,true);
       // this.setState({liveLocation : []})
    }

    render() {
        // taking all the states
        const {defaultCenter,defaultZoom,defaultOptions,markerpanic,markerlive,markermaintenace,markeroffline,liveLocation,loading} = this.state;


        const {height} = this.props;
        if (loading) {
            return (<div className="logo-img">
                <HollowDotsSpinner color="#51BCDA"  animationDelay={50} />
            </div>)
        }
        return (
				
                <p className="centrino">

                    <RegularMap
                        defaultZoom ={defaultZoom}
                        defaultCenter ={defaultCenter}
                        defaultOptions ={defaultOptions}
                        markerlive ={markerlive}
                        markeroffline ={markeroffline}
                        markermaintenace ={markermaintenace}
                        markerpanic={markerpanic}
                        liveLocation={liveLocation}
                        googleMapURL={"https://maps.googleapis.com/maps/api/js?key="+Constants.GOOGLEAPIKEY}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: height }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        selectedMarker={this.state.selectedMarker}
                        onClick={this.handleClick}
                    />
                    <div className="legend">
                    <ul>
                        <li>
                            <img src={markergreen}  className="pin" /> not finished journey
                        </li>
                        <li>
                            <img src={markerred}  className="pin" /> finished journey
                        </li>
                        <li>
                            <img src={markeryellow}  className="pin" /> bike needs maintenance
                        </li>
                        <li>
                            <img src={markerpanicimg}  className="pin" /> panic
                        </li>
                    </ul>
                    </div>
                </p>

        );
    }
}

BikeMap.defaultProps = {
    type : 1,
    height : "300px"
};


