import React from "react";
import Select from "react-select";
import styles from "./RemovedInventories.module.css";

const RemovedInventories = ({ removedInventories }) => {
  return (
    <div id="removeList" className={styles.container}>
      <div className={styles.removedHeader}>Removed Inventories</div>

      <div className={styles.removedCardContainer}>
        {removedInventories && removedInventories.length != 0 ? (
          removedInventories.map((inv, index) => (
            <div key={index} className={styles.card}>
              <div className={styles.cardContent}>
                <h3>
                  {inv.number} - {inv.label}
                </h3>
                <p>Type: {inv.type}</p>
              </div>
            </div>
          ))
        ) : (
          <div className="styles.perfectBike">Bike is still perfect!</div>
        )}
      </div>
    </div>
  );
};

export default RemovedInventories;
