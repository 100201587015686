import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import InvoiceService from "../services/invoice.service";
import EventBus from "../common/EventBus";
import DateFormatter from "../common/DateFromatter";
import AccessoriesList from "../commonComponents/AccessoriesList";
import commonFunctions from "../common/Functions";
import CashLogs from "./CashLogs";

const Invoice = (props) => {
  const [type, setType] = useState("");
  const [bikerId, setBikerId] = useState("");
  const [number, setNumber] = useState("");
  const [user, setUser] = useState("");
  const [date, setDate] = useState();
  const [draft, setDraft] = useState();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [merchantNumber, setMerchantNumber] = useState("");
  const [vat, setVat] = useState(0);
  const [currency, setCurrency] = useState("");
  const [branchCurrency, setBranchCurrency] = useState("");
  const [paymentNumber, setPaymentNumber] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [sent, setSent] = useState(true);
  const [plan, setPlan] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [floor, setFloor] = useState("");
  const [street, setStreet] = useState("");
  const [currencyRate, setCurrencyRate] = useState("");
  const [town, setTown] = useState("");
  const [branch, setBranch] = useState("");
  const [description, setDescription] = useState("");
  const [branches, setBranches] = useState([]);
  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState(0);
  const [final_amount, setFinal_amount] = useState(0);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [total_amount, setTotalAmount] = useState();
  const [successful, setSuccessful] = useState(false);
  const [invoiceId, setInvoicetId] = useState(props.match.params.id || "");
  const [paid, setPaid] = useState(false);
  const [period, setPeriod] = useState(1);
  const [paymentDate, setPaymentDate] = useState("");
  const [paymentNote, setPaymentNote] = useState("");
  const [packageId, setPackage] = useState("");
  const [company, setCompany] = useState("");
  const [displayedCurrency, setDisplayedCurrency] = useState("$");
  const [realPaymentMethod, setRealPaymentMethod] = useState("");

  const currentUser = JSON.parse(localStorage.getItem("user"));

  const loadInvoice = () => {
    InvoiceService.getInvoiceById(invoiceId).then(
      (response) => {
        let b = response.data;
        let displayedCurrency = b.currency;
        let currencyRate = 1;
        if (b.paymentMethod == "Cash-USD" && b.currency !== "USD") {
          displayedCurrency = "$";
          currencyRate = b.currencyRate;
        } else if (b.paymentMethod == "Cash-Euro" && b.currency != "Euro") {
          displayedCurrency = "€";
          currencyRate = b.currencyRate;
        } else if (b.paymentMethod == "Cash-LBP" && b.currency != "LBP") {
          displayedCurrency = "LBP";
          currencyRate = b.currencyRate;
        }
        setDisplayedCurrency(displayedCurrency);
        setType(b.type);
        setNumber(b.number);
        setUser(b.user);
        setBikerId(b.bikerId);
        setPaymentMethod(b.paymentMethod);
        setMerchantNumber(b.merchantNumber);
        setVat(b.vat);
        setCurrencyRate(currencyRate);
        setCurrency(b.currency);
        setBranchCurrency(b.branch.currency);
        setDraft(b.draft);
        setPaymentNumber(b.paymentNumber);
        setTotalAmount(b.total_amount);
        setStart(b.start);
        setEnd(b.end);
        setBranch(b.branch);
        setPlan(b.plan);
        setDate(b.date);
        setBuildingName(b.buildingName);
        setFloor(b.floor);
        setStreet(b.street);
        setTown(b.town);
        setAmount(b.amount);
        setSubscriptionId(b.usersubscribe._id);
        setSent(b.sent);
        setPaid(b.paid);
        setPeriod(b.period);
        setDescription(b.description);
        setFinal_amount(b.final_amount);
        setPaymentDate(b.paymentDate);
        setPaymentNote(b.paymentNote);
        setRealPaymentMethod(b.realpaymentMethod);
        setPackage(b.package);
        setCompany(b.company);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content);
        setMessage(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const sendInvoice = async (id, name, bikerId, packageId) => {
    if (
      window.confirm(
        "Are you sure you want to send this invoice to " + name + "?"
      )
    ) {
      InvoiceService.send({
        id: id,
        bikerId: bikerId,
        packageId: packageId,
      }).then(
        (res) => {
          setSent(true);
          alert("Invoice is sent!");
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          alert(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  return (
    <div className="col-md-12">
      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "50%" }}>Invoice Specification</th>
            <th style={{ float: "right", display: "flex" }}>
              {(currentUser.type !== "registered") &
              (currentUser.type !== "subscriber") ? (
                <>
                  <div className="text-dark">
                    {sent ? (
                      <a
                        href="javascript:void(0)"
                        onClick={() =>
                          sendInvoice(
                            invoiceId,
                            user ? user.full_name : company.name,
                            user ? user._id : "",
                            packageId
                          )
                        }
                        className="text-dark "
                      >
                        Resend
                      </a>
                    ) : type === "Standard" ||
                      (type === "Surchage" && !draft) ? (
                      <a
                        href="javascript:void(0)"
                        onClick={() =>
                          sendInvoice(
                            invoiceId,
                            user ? user.full_name : company.name,
                            user ? user._id : "",
                            packageId
                          )
                        }
                        className="text-dark "
                      >
                        Send
                      </a>
                    ) : !draft ? (
                      <a
                        href="javascript:void(0)"
                        onClick={() =>
                          sendInvoice(
                            invoiceId,
                            user ? user.full_name : company.name,
                            user ? user._id : "",
                            packageId
                          )
                        }
                        className="text-dark "
                      >
                        Send Receipt
                      </a>
                    ) : null}
                  </div>
                  &nbsp;&nbsp;
                  <div>
                    {sent ? (
                      ""
                    ) : (
                      <NavLink
                        to={"/invoice/edit/" + invoiceId}
                        className="text-dark "
                      >
                        Edit
                      </NavLink>
                    )}
                  </div>
                </>
              ) : null}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Number</td>
            <td>{number}</td>
          </tr>

          <tr>
            <td>Type</td>
            <td>
              {type === "Standard" ? (
                <span>Subscription Fee</span>
              ) : type === "Surchage" ? (
                "Short Period Surchage"
              ) : (
                type
              )}
            </td>
          </tr>

          <tr>
            <td>Draft</td>
            <td>
              {draft ? (
                <span style={{ color: "red" }}>Yes</span>
              ) : (
                <span style={{ color: "green" }}>
                  No
                  {paid ? (
                    <span>
                      {" "}
                      - Paid{" "}
                      {paymentDate ? (
                        <span>
                          {" "}
                          at {DateFormatter.DateFormatterOne(paymentDate)}
                        </span>
                      ) : (
                        ""
                      )}{" "}
                    </span>
                  ) : (
                    <span> - Not Paid</span>
                  )}
                </span>
              )}
            </td>
          </tr>

          <tr>
            <td>Date of Invoice</td>
            <td>{DateFormatter.DateFormatterTwo(date)}</td>
          </tr>

          <tr>
            <td>Branch</td>
            <td>{branch ? branch.label : ""}</td>
          </tr>

          <tr>
            <td>Start Date</td>
            <td>{DateFormatter.DateFormatterTwo(start)}</td>
          </tr>

          <tr>
            <td>End Date</td>
            <td>{DateFormatter.DateFormatterTwo(end)}</td>
          </tr>
          {type === "Standard" ? (
            <tr>
              <td>Period</td>
              <td>
                {period} month{period > 1 ? "s" : ""}
              </td>
            </tr>
          ) : (
            ""
          )}
        </tbody>
      </table>
      {company ? (
        <table className="styled-table" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th style={{ width: "50%" }}>Company Specification</th>
              <th style={{ float: "right" }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Company Name</td>
              <td>{company ? company.name : ""}</td>
            </tr>

            <tr>
              <td>Company Phone Number</td>
              <td>{company ? company.phone : ""}</td>
            </tr>

            <tr>
              <td>Company Address</td>
              <td>{company ? company.address : ""}</td>
            </tr>

            <tr>
              <td>Company Email</td>
              <td>{company ? company.email : ""}</td>
            </tr>
          </tbody>
        </table>
      ) : user &&
        (currentUser.type !== "registered") &
          (currentUser.type !== "subscriber") ? (
        <table className="styled-table" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th style={{ width: "50%" }}>User Specification</th>
              <th style={{ float: "right" }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>User Name</td>
              <td>{user ? user.full_name : ""}</td>
            </tr>

            <tr>
              <td>User Phone Number</td>
              <td>{user ? user.phone : ""}</td>
            </tr>

            <tr>
              <td>User Town</td>
              <td>{user ? user.town : ""}</td>
            </tr>

            <tr>
              <td>User Street</td>
              <td>{user ? user.street : ""}</td>
            </tr>

            <tr>
              <td>User Building Name</td>
              <td>{user ? user.buildingName : ""}</td>
            </tr>

            <tr>
              <td>User Floor</td>
              <td>{user ? user.floor : ""}</td>
            </tr>
          </tbody>
        </table>
      ) : (
        ""
      )}

      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "50%" }}>Payment Specification</th>
            <th style={{ float: "right" }}></th>
          </tr>
        </thead>
        <tbody>
          {(currentUser.type !== "registered") &
          (currentUser.type !== "subscriber") ? (
            <>
              <tr>
                <td>Displayed Payment Method</td>
                <td>{paymentMethod}</td>
              </tr>
              {paid && realPaymentMethod ? (
                <tr>
                  <td>Internal Payment Method</td>
                  <td>{realPaymentMethod}</td>
                </tr>
              ) : (
                ""
              )}

              <tr>
                <td>Displayed Currency</td>
                <td>{displayedCurrency}</td>
              </tr>
            </>
          ) : null}
          {paymentNumber !== "" ? (
            <tr>
              <td>Payment Number</td>
              <td>{paymentNumber}</td>
            </tr>
          ) : (
            ""
          )}

          <tr>
            <td>Subscribtion price (Excluding VAT)</td>
            <td>
              {commonFunctions.CommaFormatted(amount * currencyRate) +
                "" +
                displayedCurrency}
            </td>
          </tr>

          <tr>
            <td>VAT</td>
            <td>{vat}%</td>
          </tr>

          <tr>
            <td>Subscribtion price (Including VAT)</td>
            <td>
              {commonFunctions.CommaFormatted(total_amount * currencyRate) +
                " " +
                displayedCurrency}
            </td>
          </tr>
          <tr>
            <td>Total Fee (including accessories + VAT)</td>
            <td>
              {commonFunctions.CommaFormatted(final_amount * currencyRate) +
                " " +
                displayedCurrency}
            </td>
          </tr>

          <tr>
            <td>Description</td>
            <td>{description}</td>
          </tr>

          {paid && paymentNote ? (
            <tr>
              <td>Payment Note</td>
              <td>{paymentNote}</td>
            </tr>
          ) : (
            ""
          )}
        </tbody>
      </table>

      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "50%" }}>Plan Specification</th>
            <th style={{ float: "right" }}></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Plan</td>
            <td>{plan ? plan.label : ""}</td>
          </tr>

          <tr>
            <td>Plan Period </td>
            <td>{plan ? plan.period : ""} month(s)</td>
          </tr>

          <tr>
            <td>Price</td>
            <td>
              {plan
                ? commonFunctions.CommaFormatted(plan.price) +
                  " " +
                  plan.currency
                : ""}
            </td>
          </tr>
          <tr>
            <td>VAT</td>
            <td>{plan ? plan.VAT + "%" : ""}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td>
              {plan
                ? commonFunctions.CommaFormatted(
                    Math.round(plan.price * (1 + plan.VAT / 100))
                  ) +
                  " " +
                  plan.currency
                : ""}
            </td>
          </tr>
        </tbody>
      </table>
      {subscriptionId ? (
        <AccessoriesList subscriptionId={subscriptionId} />
      ) : (
        ""
      )}

      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "100%" }}>Receipts</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <CashLogs
              invoiceId={invoiceId}
              isView={true}
              readyFunction={loadInvoice}
            />
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Invoice;
