import React from "react";
import JourneysList from "../../commonComponents/JourneysList";

const JourneysSection = ({ journeysList, userId, activeTab }) => {
  return (
    <div
      id="journeys"
      className={`tab-content ${
        activeTab === "journeys" ? "expanded" : "collapsed"
      }`}
    >
      {journeysList && journeysList.list?.length > 0 ? (
        <JourneysList list={journeysList} userId={userId} />
      ) : (
        <p>There are no journeys.</p>
      )}
    </div>
  );
};

export default JourneysSection;
