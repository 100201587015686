import React, { useEffect } from "react";
import Header from "../components/Header";
import "../css/about.css";
import WhyWaveImg from "../../assets/img/Why Wave.jpg";
import { trackPage } from "../components/GoogleAnalytics";

export default function WhyWave() {
  useEffect(() => {
    trackPage("Why Wave");
  }, []);
  return (
    <div>
      <Header title="Why Wave" extraClass="headerMedium" />
      <section>
        <div className="landing_About_section">
          <p>
            As commuters we are polluting the air, wasting a lot of time in
            traffic, and lacking the time to properly exercise to reduce stress
            and live a healthy lifestyle. This is our daily struggle. That’s why
            we brought knowledge from Lebanese and Dutch engineers together and
            developed Wave: a game-changing smart electric bicycle subscription
            that offers you a safe and efficient commute. Wave has been
            tailor-made to the culture, the weather, and the streets of the
            regional cities. It will bring you safely to your destination
            without a sweat. Wave enables you to finally upgrade your commute to
            the trendiest standard.
            <br />
            <br />
            Wave is an e-bicycle service providing commuters with a modern
            stress-free experience to move around in the city while saving time
            and money!
            <br />
            <br />
            Through an all-inclusive monthly subscription, you will avoid
            traffic, high fuel costs, the hassle of finding parking spots, all
            while preserving the planet and maintaining a healthy lifestyle. How
            cool is that?
          </p>
          <div align="center">
            <button
              id="landing_home_reserve_now_button"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/signup";
              }}
            >
              Let's get you signed up
            </button>
          </div>
          <br />
          <br />
          <img src={WhyWaveImg} alt="Why Wave" />
          <br />
          <br />
        </div>
      </section>
    </div>
  );
}
