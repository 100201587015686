import React from "react";
import "../css/footer.css";
import WhiteLogo from "../assets/logo_white.png";
import FooterNavLink from "./FooterNavLink";
import StayUpdatedModal from "./StayUpdatedModal";
import TermsAndConditions from "../assets/pdf/wave-terms-and-conditions.pdf";
import PrivacyStatement from "../assets/pdf/wave-privacy-statement.pdf";
import ExternalGrievancePolicy from "../assets/pdf/External Grievance Policy.pdf";
import GooglePlay from "../assets/images/google-play-badge desktop.png";
import AppStore from "../assets/images/App Store desktop.png";
import GooglePlay1 from "../assets/images/google-play-badge.png";
import AppStore1 from "../assets/images/App Store.png";

export default function Footer() {
  const goTo = (path) => {
    window.open(path, "_blank");
  };

  const [modalStayUpdated, setModalStayUpdated] = React.useState(false);

  return (
    <div className="outer_container">
      <section className="landing_Footer_container inner_container">
        <div id="landing_Footer_logo" className="isBrowser">
          <img src={WhiteLogo} width="150px" alt="Wave logo in white color" />
        </div>

        <div className="landing_Footer_section_menu isBrowser">
          {/*<FooterNavLink path="/" text="HOW IT WORKS" exact />*/}
          <h5 style={{ textIndent: "1.2rem" }} className="footer_title">
            <b>Menu</b>
          </h5>
          <div style={{ paddingTop: "1.2rem" }}>
            <FooterNavLink path="/about" text="ABOUT US" exact />
            <FooterNavLink path="/wavebike" text="OUR BIKE" exact />
            <FooterNavLink path="/rides" text="RIDES" exact />
            <FooterNavLink path="/shortTermRent" text="SHORT TERM RENT" exact />
            <FooterNavLink path="/csr" text="CSR" exact />
            <FooterNavLink path="/corporate" text="CORPORATE" exact />

            <FooterNavLink path="/faq" text="FAQ" exact />

            <FooterNavLink path="/#landing_home_fees" text="FEES" disabled />
            {/*
        <div onClick={() => setModalStayUpdated(true)}>
          <FooterNavLink path="/" text="Stay updated" disabled />
        </div>
		*/}

            {/* <FooterNavLink path="/careers" text="CAREERS" disabled /> */}
          </div>

          <FooterNavLink path="/signup" text="REGISTER" disabled />

          <FooterNavLink path="/login" text="LOGIN" disabled />
        </div>

        <div className="landing_Footer_section isBrowser">
          <h5 className="footer_title ">
            <b>Contact us</b>
          </h5>
          <br />

          <div>
            <a
              href="//www.google.com/maps/place/Wave/@33.8919643,35.5307855,18.94z/data=!4m5!3m4!1s0x151f17711b93c4ff:0x6a6c9a22d10b2c7e!8m2!3d33.8922325!4d35.5310995?hl=en"
              target="_blank"
            >
              <div id="landing_Footer_section_address">
                <table border="0" className="isBrowser">
                  <tr>
                    <td className="footer_icon">
                      <svg
                        fill="white"
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 384 512"
                      >
                        <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z" />
                      </svg>
                    </td>
                    <td className="footer_text">14 St. Louis Street,</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="footer_text">
                      Geitawi Kishishian bldg basement,
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="footer_text">Beirut, Lebanon</td>
                  </tr>
                </table>
              </div>
            </a>
          </div>
          <br />
          <div className="isBrowser">
            <table border="0" className="isBrowser">
              <tr>
                <td className="footer_icon">
                  <svg
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    width={21}
                    height={21}
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                  </svg>
                </td>
                <td className="footer_text">
                  Monday to Friday
                  <br />
                  9:00 AM - 5:00 PM
                  <br />
                  Saturday
                  <br />
                  9:00 AM - 12:00 PM
                </td>
              </tr>
            </table>

            <br />

            <div>
              <table border="0">
                <tr>
                  <td className="footer_icon">
                    <svg
                      fill="white"
                      width={21}
                      height={21}
                      viewBox="0 0 512 512"
                    >
                      <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" />
                    </svg>
                  </td>
                  <td className="footer_text">+961 70 632 955</td>
                </tr>
              </table>

              <br />
            </div>
            <div className="isBrowser">
              <table border="0">
                <tr>
                  <td className="footer_icon">
                    <svg
                      fill="white"
                      focusable="false"
                      width={21}
                      height={21}
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path>
                    </svg>
                  </td>
                  <td className="footer_text">hello@wave.bike</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        {/*<iframe frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?q=Wave%20Bicycle%20store%20Beirut&amp;t=m&amp;z=11&amp;output=embed&amp;iwloc=near" title="%3$s" aria-label="%3$s"></iframe>*/}

        <div className="landing_Footer_section">
          <h5 className="footer_title isBrowser">
            <b>Follow us</b>
          </h5>
          <br />
          <div className="isBrowser">
            <div className="landing_Footer_section_follow_us">
              <div
                onClick={() =>
                  goTo("https://www.facebook.com/Wave-113634967226828")
                }
              >
                <svg
                  className="iconstyle"
                  fill="white"
                  width={22}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
                </svg>
              </div>
            </div>
            <div className="landing_Footer_section_follow_us ">
              <div onClick={() => goTo("https://www.instagram.com/wavebike/")}>
                <svg
                  className="iconstyle"
                  fill="white"
                  width={20}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z" />
                </svg>
              </div>
            </div>

            <div className="landing_Footer_section_follow_us ">
              <div
                onClick={() =>
                  goTo(
                    "https://www.linkedin.com/company/wave-e-bike/?viewAsMember=true"
                  )
                }
              >
                <svg
                  className="iconstyle"
                  fill="white"
                  width={20}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                </svg>
              </div>
            </div>
            <div className="styling_disclaimers">
              <FooterNavLink
                path={PrivacyStatement}
                target="_blank"
                text="Privacy Statement"
                disabled
              />
              <FooterNavLink
                path={TermsAndConditions}
                target="_blank"
                text="Terms & Conditions"
                disabled
              />
              <FooterNavLink
                path={ExternalGrievancePolicy}
                target="_blank"
                text="External Grievance Policy"
                disabled
              />
              <div className="aligning_the_icons">
                <div className="footerIcons">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.waveB&pcampaignid=web_share"
                    target="_blank"
                  >
                    <img
                      src={GooglePlay}
                      width={"120px"}
                      style={{ marginTop: "10px" }}
                      alt="google play logo"
                    />
                  </a>
                </div>
                <div className="footerIcons ">
                  <a
                    href="https://apps.apple.com/lb/app/wave-bike/id1473328880"
                    target="_blank"
                  >
                    <img src={AppStore} width={"120px"} alt="App Store logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="isMobile">
            <table border="0">
              <tr>
                <td colSpan={3}>
                  <div
                    id="landing_Footer_logo"
                    className="landing_Footer_section isBrowswer"
                  >
                    <img
                      src={WhiteLogo}
                      width="115px"
                      alt="Wave logo in white color"
                    />
                  </div>
                  <br />
                  <br />
                </td>
              </tr>
              <tr>
                <td></td>
                <td className="footer_icon">
                  <a
                    href="//www.google.com/maps/place/Wave/@33.8919643,35.5307855,18.94z/data=!4m5!3m4!1s0x151f17711b93c4ff:0x6a6c9a22d10b2c7e!8m2!3d33.8922325!4d35.5310995?hl=en"
                    target="_blank"
                  >
                    <svg
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 384 512"
                    >
                      <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z" />
                    </svg>
                  </a>
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={3} className="footer_text" width="80%">
                  <a
                    href="//www.google.com/maps/place/Wave/@33.8919643,35.5307855,18.94z/data=!4m5!3m4!1s0x151f17711b93c4ff:0x6a6c9a22d10b2c7e!8m2!3d33.8922325!4d35.5310995?hl=en"
                    target="_blank"
                  >
                    14 St. Louis Street,
                    <br />
                    Geitawi Kishishian
                    <br /> bldg basement,
                    <br />
                    Beirut, Lebanon
                  </a>
                  <br />
                  <br />
                </td>
              </tr>
              <tr>
                <td></td>
                <td className="footer_icon">
                  <svg
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    width={21}
                    height={21}
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                  </svg>
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={3} className="footer_text">
                  Monday to Friday
                  <br />
                  9:00 AM - 5:00 PM
                  <br />
                  Saturday
                  <br />
                  9:00 AM - 12:00 PM
                  <br />
                  <br />
                </td>
              </tr>

              <tr>
                <td></td>
                <td className="footer_icon">
                  <svg
                    fill="white"
                    width={21}
                    height={21}
                    viewBox="0 0 512 512"
                  >
                    <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" />
                  </svg>
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={3} className="footer_text">
                  +961 70 632 955
                  <br />
                  <br />
                </td>
              </tr>

              <tr>
                <td></td>
                <td className="footer_icon">
                  <svg
                    fill="white"
                    focusable="false"
                    width={21}
                    height={21}
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path>
                  </svg>
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={3} className="footer_text">
                  hello@wave.bike
                  <br />
                  <br />{" "}
                </td>
              </tr>

              <tr>
                <td>
                  <div
                    onClick={() =>
                      goTo("https://www.facebook.com/Wave-113634967226828")
                    }
                  >
                    <svg
                      className="iconstyle"
                      fill="white"
                      width={22}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
                    </svg>
                  </div>
                </td>

                <td>
                  {" "}
                  <div
                    onClick={() => goTo("https://www.instagram.com/wavebike/")}
                  >
                    <svg
                      className="iconstyle"
                      fill="white"
                      width={20}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z" />
                    </svg>
                  </div>
                </td>
                <td>
                  <div
                    onClick={() =>
                      goTo(
                        "https://www.linkedin.com/company/wave-e-bike/?viewAsMember=true"
                      )
                    }
                  >
                    <svg
                      className="iconstyle"
                      fill="white"
                      width={20}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                    </svg>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <br />
                  <br />
                  <FooterNavLink
                    path={PrivacyStatement}
                    target="_blank"
                    text="Privacy Statement"
                    disabled
                  />
                  <FooterNavLink
                    path={TermsAndConditions}
                    target="_blank"
                    text="Terms & Conditions"
                    disabled
                  />
                  <FooterNavLink
                    path={ExternalGrievancePolicy}
                    target="_blank"
                    text="External Grievance Policy"
                    disabled
                  />
                  <div className="footerIcons">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.waveB&pcampaignid=web_share"
                      target="_blank"
                    >
                      <img src={GooglePlay1} alt="google play logo" />
                    </a>
                  </div>
                  <div className="footerIcons">
                    <a
                      href="https://apps.apple.com/lb/app/wave-bike/id1473328880"
                      target="_blank"
                    >
                      <img src={AppStore1} alt="App Store logo" />
                    </a>{" "}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <StayUpdatedModal
          show={modalStayUpdated}
          onHide={() => setModalStayUpdated(false)}
        />
      </section>
    </div>
  );
}
