import React, { useState, useEffect, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import PlanService from "../services/plan.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";

const currentUser = JSON.parse(localStorage.getItem("user"));
const Plan = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [label, setLabel] = useState("");
  const [disabled, setDisabled] = useState("");
  const [internal, setInternal] = useState("");
  const [period, setPeriod] = useState("");
  const [VAT, setVAT] = useState(0);
  const [price, setPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [branch, setBranch] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [penalty, setpenalty] = useState("");
  const [currency, setCurrency] = useState("");
  const planId = props.match.params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    PlanService.getPlanDetailsById(planId).then(
      (response) => {
        let b = response.data;
        setLabel(b.label);
        setDisabled(b.disabled);
        setInternal(b.internal);
        let periodString = b.period + " ";
        if (b.planType === "daily") {
          periodString += "day";
        } else if (b.planType === "hourly") {
          periodString += "hour";
        } else if (b.planType === "weekly") {
          periodString += "week";
        } else periodString += "month";

        if (b.period > 1) periodString += "s";

        setPeriod(periodString);
        setPrice(b.price);
        setBranch(b.branch);
        setVAT(b.VAT);
        setTotal(Math.round(b.price * (1 + b.VAT / 100)));
        setpenalty(b.penalty);
        setCurrency(b.currency);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content);
        setMessage(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  return (
    <div className="col-md-12">
      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "50%" }}>Plan Specification</th>
            <th style={{ float: "right" }}>
              <NavLink to={"/plan/edit/" + planId} className="text-dark ">
                Edit
              </NavLink>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Label</td>
            <td>
              {label} {internal ? "- Internal" : ""}
            </td>
          </tr>
          <tr>
            <td>Disabled</td>
            <td>{disabled ? "Disabled" : "Active"}</td>
          </tr>
          <tr>
            <td>Period</td>
            <td>{period}</td>
          </tr>
          <tr>
            <td>Unit Price</td>
            <td>
              {price} {currency}
            </td>
          </tr>
          <tr>
            <td>VAT</td>
            <td>{VAT} </td>
          </tr>
          <tr>
            <td>Total</td>
            <td>
              {total} {currency}
            </td>
          </tr>
          <tr>
            <td>Branch</td>
            <td>{branch ? branch.label : ""}</td>
          </tr>
          <tr>
            <td>Penalty Per Day</td>
            <td>{penalty}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Plan;
