import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const createEvent = (data) => {
    return axios(API_URL + "/event/save", { method: 'post', headers: authHeader(), data: data });
}

const getEventsList = (branch, offset, limit, onlyList) => {
    return axios.get(API_URL + "/event/list?branch=" + branch + "&offset=" + offset + "&limit=" + limit + "&onlyList=" + onlyList, { headers: authHeader() });
};

const getEventById = (id) => {
    return axios.get(API_URL + "/event/" + id, { headers: authHeader() });
}

const deleteEvent = (id) => {
    return axios(API_URL + "/event/" + id, { method: 'delete', headers: authHeader() });
};

const getEventDetailsById = (id) => {
    return axios.get(API_URL + "/event/details/" + id, { headers: authHeader() });
};

export default {
    createEvent,
    getEventsList,
    getEventById,
    deleteEvent,
    getEventDetailsById
};