import React, { useState, useEffect, useRef } from "react";
import DateFormatter from "../common/DateFromatter";
import CashLogService from "../services/cashLog.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";

const currentUser = JSON.parse(localStorage.getItem('user'));
const CashLog = (props) => {
	const form = useRef();
	const checkBtn = useRef();
	const [label, setLabel] = useState("");
	const [receipt, setReceipt] = useState(false);
	const [amount, setAmount] = useState("");
	const [currency, setCurrency] = useState("");
	const [logType, setLogType] = useState("");
	const [cashDate, setCashDate] = useState("");
	const [notes, setNotes] = useState("");
	const [branch, setBranch] = useState("");
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	const cashLogId = props.match.params.id;


	useEffect(() => {
		window.scrollTo(0, 0);
		CashLogService.getCashLogDetailsById(cashLogId).then(
			(response) => {
				let c = response.data;
				setLabel(c.label);
				setAmount(c.amount);
				setCurrency(c.currency);
				setLogType(c.user ? "Manual" : "System");
				setCashDate(c.cashDate);
				setReceipt(c.receipt);
				setNotes(c.notes);
				setBranch(c.branch)

			},
			(error) => {
				const _content =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();
				alert(_content);
				setMessage(_content);

				if (error.response && error.response.status === 401) {
					EventBus.dispatch("logout");
				}
			}
		);
	}, []);


	return (
		<div className="col-md-12">

			<table className="styled-table" style={{ width: "100%" }}>
				<thead>
					<tr>
						<th style={{ width: "50%" }}>Log Specification</th>
						<th style={{ float: "right" }}><NavLink to={"/cashlog/edit/" + cashLogId} className="text-dark ">Edit</NavLink></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Label</td>
						<td>{label}</td>
					</tr>
					<tr>
						<td>Receipt</td>
						<td>{receipt ? 'Yes' : 'No'}</td>
					</tr>
					<tr>
						<td>Amount</td>
						<td>{amount}</td>
					</tr>
					<tr>
						<td>Currency</td>
						<td>{currency}</td>
					</tr>
					<tr>
						<td>Date</td>
						<td>{cashDate ? DateFormatter.DateFormatterOne(cashDate) : "None"} </td>
					</tr>
					<tr>
						<td>Type</td>
						<td>{logType} </td>
					</tr>
					<tr>
						<td>Branch</td>
						<td>{branch ? branch.label : ""}</td>
					</tr>


				</tbody>
			</table>






		</div>
	);
};

export default CashLog;
