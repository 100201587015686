import React from "react";
import MaintenanceList from "../commonComponents/MaintenanceList";

const currentUser = JSON.parse(localStorage.getItem('user'));
const Maintenances = () => {
	
	return(

	<MaintenanceList showFilters="true" currentUser={currentUser}/> 

	);
};

export default Maintenances;
