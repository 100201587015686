import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import UserService from "../services/user.service";
import EventBus from "../common/EventBus";


export default function DeleteUserModal(props) {
    const deleteUserModal = props.deleteUserModal;
    const setDeleteUserModal = props.setDeleteUserModal;
    const selectedUserForDelete = props.selectedUserForDelete;
    const setSelectedUserForDelete = props.setSelectedUserForDelete || null;
    const isUsersList = props.isUsersList || false;
    const list = props.list || null;
    const setList = props.setList || null;
    const firstName = props.firstName || null;
    const lastName = props.lastName || null;
    const email = props.email || null;
    const phone = props.phone || null;


    const handleDeleteUserModalClose = () => {
        if (isUsersList) setSelectedUserForDelete("");
        setDeleteUserModal(false);
    }

    const deleteUser = async () => {

        let confirmMessage = isUsersList ? "Are you sure you want to delete this user?" : "Are you sure you want to delete your account?";
        if (window.confirm(confirmMessage)) {
            UserService.deleteUser(selectedUserForDelete).then(data => {
                let alertMessage = isUsersList ? "User deleted!" : "Sorry to have you go!";
                alert(alertMessage);
                if (isUsersList) {
                    let oldList = list;
                    var data = oldList.filter(function (obj) {
                        return obj.id !== selectedUserForDelete;
                    });
                    setList(data);
                    handleDeleteUserModalClose();
                }
                else {
                    EventBus.dispatch("logout");
                }


            }).catch(function (error) {

                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                alert(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }


            })
        }


    }







    return (
        <Modal
            show={deleteUserModal}
            onHide={handleDeleteUserModalClose}
        >
            <Modal.Header className="bg-danger text-white d-flex flex-row justify-content-between">
                <Modal.Title>Delete User</Modal.Title>
                <button style={{ background: "transparent", width: 50 }} onClick={handleDeleteUserModalClose}><i className="fa fa-times text-white"></i></button>
            </Modal.Header>

            <Modal.Body>
                <p>
                    You are about to delete {isUsersList ? "this" : "your"} account.
                    This will delete all data and documents associated with this account
                    (such as contact information, address, id card etc...)
                </p>
                {isUsersList ?
                    <>
                        <span className="tinyCardLabel">First Name:&nbsp;</span><span className="midText">{firstName}</span><br />
                        <span className="tinyCardLabel">Last Name:&nbsp;</span><span className="midText">{lastName}</span><br />
                        <span className="tinyCardLabel">Email:&nbsp;</span><span className="midText">{email}</span><br />
                        <span className="tinyCardLabel">Phone:&nbsp;</span><span className="midText">{phone}</span><br />

                    </>
                    : ""}
            </Modal.Body>

            <div className="d-flex flex-row" >
                <button style={{ margin: '10px' }} onClick={handleDeleteUserModalClose}>Cancel</button>
                <button style={{ margin: '10px', background: "#dc3545" }} onClick={deleteUser}>Delete User</button>
            </div>
        </Modal >
    );
}
