import React, { useEffect, useState } from "react";
import { PieChart, Pie, Legend, Cell, Tooltip } from "recharts";
import DashboardService from "../services/dashboard.service";
import EventBus from "../common/EventBus";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Col } from "react-bootstrap";
import Loader from "../commonComponents/Loader";
import * as labels from "../labels/en";
import * as options from "../labels/options_en";

const AnalyticsRegistered = () => {
  const monthsNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const barColors = [
    "#368b85",
    "#0db66e",
    "#3db3e4",
    "#8e569f",
    "#eecd63",
    "#ffae94",
    "#dac1b9",
    "#e5867b",
  ];
  const lastYearDate = new Date(
    new Date().getFullYear() - 1,
    new Date().getMonth(),
    new Date().getDate()
  );
  const [fromDate, setFromDate] = useState(lastYearDate);
  const [toDate, setToDate] = useState(new Date());
  const [sourcesData, setSourcesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subTypeFilter, setSubTypeFilter] = useState("membership");
  const [resetMsg, setResetMsg] = useState("");
  const [total, setTotal] = useState(0);

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      await triggerSearch(lastYearDate, new Date(), "membership");
    }

    onReady();
  }, []);

  const triggerSearch = async (fromDateT, toDateT, subTypeFilter) => {
    if (fromDateT) {
      await getSources(fromDateT, toDateT, subTypeFilter);
    }
  };
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const getSources = async (fromDate, toDate, subTypeFilter) => {
    setLoading(true);

    DashboardService.getRegisterSources(fromDate, toDate, subTypeFilter).then(
      (response) => {
        let subs = response.data.data.anlaytics;
        let data = [];
        let total = 0;
        subs &&
          subs.map((s, i) => {
            data.push({
              name: s._id.source || "Not set",
              value: s.total,
            });
            total = total + s.total;
          });
        setTotal(total);
        setSourcesData(data);
        setResetMsg("");
        setLoading(false);
        return data;
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        alert(_content);
        setLoading(false);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
        return _content;
      }
    );
  };

  const resetSearch = async () => {
    setFromDate(lastYearDate);
    setToDate(new Date());
    setSubTypeFilter("membership");
    await triggerSearch(lastYearDate, new Date(), "membership");
  };

  const registerToolTip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            padding: "5px",
            border: "1px solid #cccc",
          }}
        >
          <label>
            {payload[0].name} : {payload[0].value}
          </label>
        </div>
      );
    }
    return null;
  };
  const handleSubTypeChange = async (val) => {
    setSubTypeFilter(val);
    resetResults();
  };
  const resetResults = () => {
    setSourcesData([]);
    setResetMsg(labels.RESET_MSG);
  };

  return (
    <div>
      <h3>Analytics Registered</h3>
      <div className="filterContainer">
        <div className="d-flex flex-row">
          <Col lg={3} className="p-0">
            <label
              htmlFor="start"
              className="filterLabelInput maintenanceLabels"
            >
              From
            </label>
            <DatePicker
              autoComplete="off"
              dateFormat="MM-yyyy"
              className="form-control filterInput2"
              name="start"
              selected={fromDate}
              onChange={(date) => {
                setFromDate(date);
                resetResults();
              }}
              showMonthYearPicker
            />
          </Col>
          <Col lg={3} className="p-0">
            <label htmlFor="to" className="filterLabelInput maintenanceLabels">
              To
            </label>
            <DatePicker
              autoComplete="off"
              dateFormat="MM-yyyy"
              className="form-control filterInput2"
              name="end"
              selected={toDate}
              onChange={(date) => {
                resetResults();
                setToDate(date);
              }}
              showMonthYearPicker
            />
          </Col>
          <Col md={3} className="p-0">
            <label
              className="filterLabelInput maintenanceLabels"
              htmlFor="subTypeFilter"
            >
              Subscription Type
            </label>
            <select
              className="form-control filterInput2"
              name="subTypeFilter"
              placeholder="Subscription Type"
              value={subTypeFilter}
              onChange={(e) => handleSubTypeChange(e.target.value)}
            >
              <option value="">All</option>
              {options &&
                options.userSubscribeType.map((item, index) => (
                  <option key={"subType" + index} value={item.key}>
                    {item.value}
                  </option>
                ))}
            </select>
          </Col>
          <Col lg={3} className="p-0">
            <div style={{ marginTop: 35 }} border="0">
              <div className="d-flex flex-row align-items-start">
                <button
                  onClick={(e) =>
                    triggerSearch(fromDate, toDate, subTypeFilter)
                  }
                  className="btn btn-primary search-button"
                >
                  Go
                </button>
                <button
                  onClick={(e) => resetSearch(e)}
                  className="btn btn-primary reset-button"
                >
                  Reset
                </button>
              </div>
            </div>
          </Col>
        </div>
      </div>
      <br />

      {!loading && sourcesData && sourcesData.length !== 0 ? (
        <div>
          <h5 className="center" style={{ width: 730 }}>
            {total} registered found
          </h5>
          <PieChart width={730} height={300}>
            <Pie
              data={sourcesData}
              color="#000000"
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={120}
              fill="#8884d8"
            >
              {sourcesData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={barColors[index % barColors.length]}
                />
              ))}
            </Pie>
            <Tooltip content={registerToolTip} />
            <Legend layout="vertical" verticalAlign="top" align="left" />
          </PieChart>
        </div>
      ) : loading ? (
        <Loader />
      ) : resetMsg != "" ? (
        <div className="defaultMsg">{resetMsg}</div>
      ) : (
        <div className="noResultsFound">{labels.NO_RESULTS}</div>
      )}
    </div>
  );
};

export default AnalyticsRegistered;
