import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const deleteJourney = (id) => {
  return axios.delete(API_URL + "/journey/delete/" + id, { headers: authHeader() });

};


const getJourneyById = (id) => {
  return axios.get(API_URL + "/journey/info/" + id, { headers: authHeader() });
}


export default {
  getJourneyById,
  deleteJourney
};
