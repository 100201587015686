import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { required, validEmail } from "../../common/Validation";
import UserService from "../../services/user.service";
import Header from "../components/Header";
import * as labels from "../../labels/en";
import Functions from "../../common/Functions";
import { trackPage } from "../components/GoogleAnalytics";

const ResetPassword = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  trackPage("Reset Password");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState(props.match.params.id);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleForgetPassword = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);

    if (password !== confirmPassword) {
      setMessage("password does not match");
      setLoading(false);
      return false;
    }

    if (!Functions.validatePassword(password)) {
      setMessage(labels.INVALID_PASSWORD);
      setLoading(false);
      return false;
    }

    const data = {
      token: token,
      newpass: password,
    };

    UserService.sendNewPasswordRequest(data).then(
      (response) => {
        setLoading(false);
        alert("Password has been changed");
        window.location.href =
          window.location.protocol + "//" + window.location.host + "/login";
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  return (
    <div>
      <Header title="Reset Password" extraClass="headerMedium" />
      <div className="col-md-12 login-page-container">
        <div className="landing-login-card">
          <Form onSubmit={ResetPassword} ref={form}>
            <div className="landing-login-input-container">
              <svg
                className="landing-login-logo"
                width={27}
                viewBox="0 0 448 512"
              >
                <path d="M80 192V144C80 64.47 144.5 0 224 0C303.5 0 368 64.47 368 144V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80zM144 192H304V144C304 99.82 268.2 64 224 64C179.8 64 144 99.82 144 144V192z" />
              </svg>
              <Input
                autoComplete="off"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                className="landing-login-input"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                validations={[required]}
              />
              <i
                className={"eyeIcon fa fa-eye" + (showPassword ? "-slash" : "")}
                onClick={() => setShowPassword(!showPassword)}
              ></i>
            </div>

            <div className="landing-login-input-container">
              <svg
                className="landing-login-logo"
                width={27}
                viewBox="0 0 448 512"
              >
                <path d="M80 192V144C80 64.47 144.5 0 224 0C303.5 0 368 64.47 368 144V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80zM144 192H304V144C304 99.82 268.2 64 224 64C179.8 64 144 99.82 144 144V192z" />
              </svg>
              <Input
                autoComplete="off"
                placeholder="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                className="landing-login-input"
                name="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                validations={[required]}
              />
              <i
                className={
                  "eyeIcon fa fa-eye" + (showConfirmPassword ? "-slash" : "")
                }
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              ></i>
            </div>
            <br />
            <br />

            <div id="landing-login-button-container">
              <button
                id="landing-login-button"
                disabled={loading}
                onClick={handleForgetPassword}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Reset Password</span>
              </button>
            </div>
            <br />

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
