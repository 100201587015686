import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const deleteSubscription = (id) => {
  return axios(API_URL + "/admin/usersubscribe/delete/" + id, {
    method: "get",
    headers: authHeader(),
  });
};

const postSubscription = (data) => {
  return axios(API_URL + "/admin/usersubscribe/save", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const getSubscriptionById = (id) => {
  return axios.get(API_URL + "/admin/usersubscribe/" + id, {
    headers: authHeader(),
  });
};

const getSubscriptionDetailsById = (id) => {
  return axios.get(API_URL + "/admin/usersubscribe/details/" + id, {
    headers: authHeader(),
  });
};

//send id of subscribption.
const getAccessories = (id) => {
  return axios.get(API_URL + "/admin/usersubscribe/accessories/" + id, {
    headers: authHeader(),
  });
};

const createInvoice = (id, isRenewal) => {
  return axios(API_URL + "/admin/usersubscribe/invoice", {
    method: "post",
    headers: authHeader(),
    data: { id, isRenewal },
  });
};

const terminateSubscription = (data) => {
  return axios(API_URL + "/admin/usersubscribe/terminate", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const markPackageAsReturned = (id, isFrozen) => {
  return axios(API_URL + "/admin/usersubscribe/close", {
    method: "post",
    headers: authHeader(),
    data: { id, isFrozen },
  });
};

const activateSubscription = (id) => {
  return axios(API_URL + "/admin/usersubscribe/activate", {
    method: "post",
    headers: authHeader(),
    data: { id },
  });
};

const unfreezeSubscription = (id) => {
  return axios(API_URL + "/admin/usersubscribe/unfreeze", {
    method: "post",
    headers: authHeader(),
    data: { id },
  });
};

const forceTerminate = (id) => {
  return axios(API_URL + "/admin/usersubscribe/forceTerminate", {
    method: "post",
    headers: authHeader(),
    data: { id },
  });
};

export default {
  postSubscription,
  deleteSubscription,
  getSubscriptionById,
  getSubscriptionDetailsById,
  getAccessories,
  createInvoice,
  terminateSubscription,
  markPackageAsReturned,
  activateSubscription,
  unfreezeSubscription,
  forceTerminate,
};
