import React from 'react'
import '../css/header.css'

export default function Header({ title ,extraClass}) {
	
    return (
        
        <div className={"landing_Header_container " + extraClass}>
            <div className="landing_Header_title" >
                <h1>{title}</h1>
            </div>
        </div>
    )
}
