import React, { useRef } from 'react';
import { createContext } from "react";

export const LandingPageContext = createContext();

export const LandingPageProvider = ({ children }) => {

    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   
    const Interested =  useRef(null);

    return(
        <LandingPageContext.Provider value={{Interested, scrollToRef}}>
            {children}
        </LandingPageContext.Provider>
    )

}