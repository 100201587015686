import React, { useState, useEffect } from "react";
import MaintenanceService from "../services/maintenance.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import DateFromatter from "../common/DateFromatter";

export default function Maintenance(props) {
  const [maintenance, setMaintenance] = useState({
    startdate: Date.now,
    enddate: "",
    bikeId: "",
    user: "",
    branch: { label: "" },
    category: "",
    subcategory: "",
    location: { longitude: "", latitude: "", name: "" },
    comments: "",
    diagnose: "",
    status: 0,
    obsInventories: [],
    repairedInventories: [],
    recycledInventories: [],
    lostInventories: [],
  });

  const maintenanceId = props.match.params.id;

  const getMaintenanceInfo = async () => {
    if (maintenanceId) {
      try {
        const response = await MaintenanceService.getMaintenanceDetailsById(
          maintenanceId
        );
        setMaintenance(response.data);
      } catch (error) {
        const _content =
          error.response?.data?.message || error.message || error.toString();
        alert(_content);
        if (error.response?.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getMaintenanceInfo();
  }, [maintenanceId]);

  const renderTableRows = (items) => {
    return items?.map(
      (item, index) =>
        item && (
          <tr key={index}>
            <td>{item.number}</td>
            <td>{item.label}</td>
            <td>{item.type}</td>
          </tr>
        )
    );
  };

  const renderInventoryTable = (title, items) => (
    <table className="styled-table" style={{ width: "100%" }}>
      <thead>
        <tr>
          <th style={{ width: "50%" }}>{title}</th>
          <th style={{ width: "25%" }}></th>
          <th style={{ width: "25%" }}></th>
        </tr>
      </thead>
      <tbody>{renderTableRows(items)}</tbody>
    </table>
  );

  return (
    <div className="col-md-12">
      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "50%" }}>Maintenance Specification</th>
            <th style={{ float: "right" }}>
              <NavLink
                to={`/maintenance/edit/${maintenanceId}`}
                className="text-dark"
              >
                Edit
              </NavLink>
            </th>
          </tr>
        </thead>
        <tbody>
          {[
            {
              label: "Start Date",
              value: DateFromatter.DateFormatterThree(maintenance.startdate),
            },
            {
              label: "End Date",
              value:
                maintenance.enddate === ""
                  ? "Not Complete"
                  : DateFromatter.DateFormatterThree(maintenance.enddate),
            },
            {
              label: "Type",
              value: maintenance.type || "Bike",
              className: "upperCase",
            },
            { label: "Number", value: maintenance.number },
            {
              label: "Biker",
              value: maintenance.usersubscribe?.full_name || "",
            },
            { label: "Category", value: maintenance.category },
            {
              label: "Sub Category",
              value:
                maintenance.type === "bike" ? maintenance.subcategory : "N/A",
            },
            { label: "Comments", value: maintenance.comments },
            { label: "Diagnose", value: maintenance.diagnose },
            {
              label: "Odometer",
              value:
                maintenance.type === "bike"
                  ? maintenance.bikeId?.kilometers || ""
                  : "",
            },
            { label: "Branch", value: maintenance.branch.label },
            { label: "Status", value: maintenance.status },
          ].map(({ label, value, className }, index) => (
            <tr key={index}>
              <td>{label}</td>
              <td className={className}>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "100%" }}>Maintenance Location</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {maintenance.location?.latitude &&
              maintenance.location?.longitude ? (
                <iframe
                  scrolling="no"
                  width="100%"
                  marginHeight="0"
                  marginWidth="0"
                  src={`https://maps.google.com/maps?q=${maintenance.location.latitude},${maintenance.location.longitude}&t=m&z=12&output=embed&iwloc=near`}
                  title={maintenance.location.name}
                  aria-label={maintenance.location.name}
                  frameBorder="0"
                ></iframe>
              ) : (
                "Location is not set."
              )}
            </td>
          </tr>
        </tbody>
      </table>

      {maintenance.type === "bike" && (
        <>
          {renderInventoryTable(
            "Repaired Inventories",
            maintenance.repairedInventories
          )}
          {renderInventoryTable(
            "Recycled Inventories",
            maintenance.recycledInventories
          )}
          {renderInventoryTable(
            "Lost Inventories",
            maintenance.lostInventories
          )}
          {renderInventoryTable("Obs Inventories", maintenance.obsInventories)}
        </>
      )}
    </div>
  );
}
