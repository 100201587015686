import React from "react";
import InvoicesByUser from "../../commonComponents/InvoicesByUser";

const InvoicesSection = ({ userId, invoiceList, activeTab }) => {
  return (
    <div
      id="invoices"
      className={`tab-content ${
        activeTab === "invoices" ? "expanded" : "collapsed"
      }`}
    >
      <InvoicesByUser
        userId={userId}
        border={false}
        title={false}
        list={invoiceList}
        InvoicesCardContainerClass={"flexContainer"}
      />
    </div>
  );
};

export default InvoicesSection;
