import React, { useState, useEffect, PureComponent } from "react";
import { Card, Modal, Col, Row, Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import EventBus from "../common/EventBus";
import {
  getBranches,
  getPlansList,
  getFollowups,
} from "../common/getFunctions";
import DashboardService from "../services/dashboard.service";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import BranchePullDown from "../commonComponents/BranchePullDown";
import { months_opt } from "../labels/options_en";
import DateFromatter from "../common/DateFromatter";
import InternalNoteComponent from "../commonComponents/InternalNoteComponent";
import FollowupService from "../services/followup.service";
import Functions from "../common/Functions";
export default function AdminDashboard(props) {
  const currentUser = JSON.parse(localStorage.getItem("user")) || null;
  if (!currentUser) {
    window.location.href =
      window.location.protocol + "//" + window.location.host + "/login";
  }
  const [loaded, setLoaded] = useState(false);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [waitingData, setWaitingData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [technicalData, setTechnicalData] = useState([]);
  const [branches, setBranches] = useState([]);
  const [invoiceMonths, setInvoiceMonths] = useState([0, 0, 0]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const [followups, setFollowups] = useState([]);
  const [newlyRegistered, setNewlyRegistered] = useState(0);
  useEffect(() => {
    async function onReady() {
      if (
        currentUser.type === "registered" ||
        currentUser.type === "subscriber"
      ) {
        return;
      }
      if (currentUser.type === "admin") {
        setBranches(await getBranches());
      }
      window.scrollTo(0, 0);
      await getDashboard("");
      setLoaded(true);
    }

    onReady();
  }, []);

  if (currentUser.type === "registered" || currentUser.type === "subscriber") {
    return null;
  }
  const barColors = ["#368b85", "purple", "#ff8c00", "green"];

  const CustomInvoicesTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      payload[0].chartType = "AreaChart";
      console.log(payload[0]);
      return (
        <div className="custom-tooltip">
          <p className="label">{payload[0].name}</p>
          <p className="user-number">{payload[0].payload.number}</p>
          <p className="time">{label}</p>
        </div>
      );
    }

    return null;
  };

  const getDashboard = async (branch) => {
    await setTechnicalData([
      {
        name: "Ready",
        Bikes: await getTotalBikes(branch, "bikes", "Ready"),
        Batteries: await getTotalBikes(branch, "bats", "Ready"),
        Chargers: await getTotalBikes(branch, "chargers", "Ready"),
      },
      {
        name: "In Use",
        Bikes: await getTotalBikes(branch, "bikes", "In Use"),
        Batteries: await getTotalBikes(branch, "bats", "In Use"),
        Chargers: await getTotalBikes(branch, "chargers", "In Use"),
      },
      {
        name: "Maintenance",
        Bikes: await getTotalBikes(branch, "bikes", "NotReady"),
        Batteries: await getTotalBikes(branch, "bats", "NotReady"),
        Chargers: await getTotalBikes(branch, "chargers", "NotReady"),
      },
    ]);
    if (
      currentUser.type === "admin" ||
      currentUser.type === "manager" ||
      currentUser.type === "helpdesk"
    ) {
      await setWaitingData([
        {
          name: "Not Contacted",
          users: await getUserDashboard(branch, "yes", "no", "Not Contacted"),
        },
        {
          name: "On Top",
          users: await getUserDashboard(branch, "yes", "no", "On Top"),
        },
        {
          name: "Confirmed",
          users: await getUserDashboard(branch, "yes", "no", "Confirmed"),
        },
        {
          name: "Clients",
          users: await getUserDashboard(branch, "no", "yes", ""),
        },
      ]);

      await setTotalQuestions(await getQuestionDashboard(branch));
      await getInvoicesStats(branch);
      await getFollowupsToday(followups, new Date());
      getNewlyRegistered(branch);
    }
  };

  const getInvoicesStats = async (branch) => {
    var invoices = [];

    let res = await DashboardService.getInvoicesStats(branch).then(
      async ({ data }) => {
        if (data.data && data.data.invoices) {
          let plansOptions = await getPlansList(branch);
          await setPlansList(plansOptions);
          setInvoiceMonths(data.data.monthArr);
          for (const i of data.data.invoices) {
            let temp = {};
            let plansOptions = await getPlansList(branch);
            let name =
              plansOptions && plansOptions.filter((p) => p._id === i._id)[0];
            temp.name = name && name.label ? name.label : "";
            for (var k = 0; k < data.data.monthArr.length; k++) {
              temp[months_opt[data.data.monthArr[k] - 1]] = 0;
            }

            for (var j = 0; j < i.date.length; j++) {
              for (var k = 0; k < data.data.monthArr.length; k++) {
                if (i.date[j].date.month == data.data.monthArr[k]) {
                  temp[months_opt[data.data.monthArr[k] - 1]] = Number(
                    i.date[j].count / 1000
                  ).toLocaleString(undefined, { maximumFractionDigits: 2 });
                }
              }
            }
            invoices.push(temp);
          }
        }
        await setInvoiceData(invoices);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content);
        if (error.response && error.response.status === 401)
          EventBus.dispatch("logout");
      }
    );

    return res;
  };

  const getQuestionDashboard = async (branch) => {
    let res = await DashboardService.getTotalUnanswered(branch).then(
      ({ data }) => {
        return data.data.total;
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content);
        if (error.response && error.response.status === 401)
          EventBus.dispatch("logout");
      }
    );

    return res;
  };

  const getUserDashboard = async (
    branch,
    waitingList,
    clientsOnly,
    waitingStatus
  ) => {
    let res = await DashboardService.getTotalUsers(
      branch,
      waitingList,
      clientsOnly,
      waitingStatus
    ).then(
      ({ data }) => {
        return data.data.total;
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content);
        if (error.response && error.response.status === 401)
          EventBus.dispatch("logout");
      }
    );

    return res;
  };

  const getTotalBikes = async (branch, type, s) => {
    let res = await DashboardService.getTotalBikes(branch, type, s).then(
      ({ data }) => {
        return data.data.total;
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content);
        if (error.response && error.response.status === 401)
          EventBus.dispatch("logout");
      }
    );

    return res;
  };

  const handleBranchChange = async (bid) => {
    setBranchFilter(bid);
    getDashboard(bid);
  };

  const getFollowupsToday = async (columns, startDate) => {
    await getFollowups(
      "birthdays?1=1&startDate=" + startDate + "&onlyCurrent=yes"
    ).then(async (res) =>
      setFollowups(
        await Functions.fillList(
          "birthdays",
          "Birthday",
          "fas fa-birthday-cake icon-color-calendar",
          res.list,
          "",
          columns,
          true
        )
      )
    );
    await getFollowups(
      "pickups?waitingStatus=Confirmed&startDate=" +
        startDate +
        "&onlyCurrent=yes"
    ).then(async (res) =>
      setFollowups(
        await Functions.fillList(
          "confirmedPickups",
          "Pickup",
          "pickupDate fas fa-biking",
          res.list,
          "",
          columns,
          true
        )
      )
    );
    await getFollowups(
      "pickups?waitingStatus=Follow up&startDate=" +
        startDate +
        "&onlyCurrent=yes"
    ).then(async (res) =>
      setFollowups(
        await Functions.fillList(
          "followups",
          "Reservation Follow Up",
          "followup fas fa-calendar-alt icon-color-calendar",
          res.list,
          "",
          columns,
          true
        )
      )
    );
    await getFollowups(
      "followup?action=reminder&startDate=" + startDate + "&onlyCurrent=yes"
    ).then(async (res) =>
      setFollowups(
        await Functions.fillList(
          "renewal",
          "Renewal Reminder",
          "fas fa-bell icon-color-calendar",
          res.list,
          "",
          columns,
          true
        )
      )
    );
    await getFollowups(
      "followup?1=1&startDate=" + startDate + "&onlyCurrent=yes"
    ).then(async (res) =>
      setFollowups(
        await Functions.fillList(
          "seventhDay",
          "7 days Follow Up",
          "phoneFollow fas fa-phone",
          res.list,
          "",
          columns,
          true
        )
      )
    );
    await getFollowups(
      "overDue?1=1&startDate=" + startDate + "&onlyCurrent=yes"
    ).then(async (res) => {
      setFollowups(
        await Functions.fillList(
          "overDue",
          "overDue",
          "fas fa-clock delayedPayment",
          res.list.filter((p) => p.delay !== 0),
          "",
          columns,
          true
        )
      );
      setFollowups(
        await Functions.fillList(
          "paymentToday",
          "Payment Today",
          "fas fa-file-invoice-dollar icon-color-calendar",
          res.list.filter((p) => p.delay === 0),
          "",
          columns,
          true
        )
      );
    });
    await getFollowups(
      "renewals?1=1&startDate=" + startDate + "&onlyCurrent=yes"
    ).then(async (res) =>
      setFollowups(
        await Functions.fillList(
          "renewals",
          "Renewal",
          "fas fa-exclamation icon-color-calendar",
          res.list,
          "",
          columns,
          true
        )
      )
    );
    await getFollowups(
      "followup?action=packageReminder&startDate=" + startDate
    ).then(async (res) =>
      setFollowups(
        await Functions.fillList(
          "packageReminder",
          "Package Reminder",
          "fas fa-people-carry text-warning",
          res.list,
          "",
          columns,
          true
        )
      )
    );
    await getFollowups(
      "followup?action=packageDelay&startDate=" + startDate
    ).then(async (res) =>
      setFollowups(
        await Functions.fillList(
          "packageDelay",
          "Package Delay",
          "fas fa-people-carry red",
          res.list,
          "",
          columns,
          true
        )
      )
    );
  };

  const getNewlyRegistered = (branch) => {
    const now = new Date();
    let dateAddedAfter = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 7
    );
    DashboardService.getNewlyRegistered(
      branch,
      dateAddedAfter.toISOString().split("T")[0]
    ).then(
      (res) => {
        setNewlyRegistered(res.data.data.total);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        alert(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const markAsFollowed = async (details) => {
    setFollowups(await Functions.markAsFollowed(details, followups, true));
  };

  return (
    <>
      {currentUser && currentUser.type === "admin" ? (
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "first baseline",
          }}
        >
          <Col md={3}>
            <label
              className="filterLabelInput maintenanceLabels"
              htmlFor="branch"
            >
              Branch
            </label>
            <BranchePullDown
              branch={branchFilter}
              labelInside={true}
              forceBlank={true}
              setBranch={handleBranchChange}
              branches={branches}
            />
          </Col>
        </Row>
      ) : null}

      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "first baseline",
        }}
      >
        {currentUser.type === "admin" ||
        currentUser.type === "manager" ||
        currentUser.type === "helpdesk" ? (
          <Col lg={6}>
            <h5>Wavers</h5>
            <BarChart
              width={500}
              height={300}
              data={waitingData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" stroke="#595959" />
              <YAxis stroke="#595959" />
              <Tooltip />

              <Bar
                dataKey="users"
                fill="#00a0fc"
                stroke="#595959"
                strokeWidth={1}
              >
                {waitingData &&
                  waitingData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
                  ))}
              </Bar>
            </BarChart>
          </Col>
        ) : (
          ""
        )}
        {currentUser.type === "admin" ||
        currentUser.type === "manager" ||
        currentUser.type === "helpdesk" ? (
          <Col lg={6}>
            <h5>Standard Invoices</h5>
            <BarChart
              width={500}
              height={300}
              titles={"Standard Invoices for the last 3 months"}
              data={invoiceData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip
                formatter={(value) => {
                  return `${value}k $`;
                }}
              />
              <Legend />
              <Bar dataKey={months_opt[invoiceMonths[0] - 1]} fill="#368b85" />
              <Bar dataKey={months_opt[invoiceMonths[1] - 1]} fill="green" />
              <Bar dataKey={months_opt[invoiceMonths[2] - 1]} fill="navy" />
            </BarChart>
          </Col>
        ) : (
          ""
        )}
        <Col lg={6}>
          <h5>Packages</h5>
          <BarChart
            width={500}
            height={300}
            data={technicalData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Bikes" fill="#368b85" />
            <Bar dataKey="Batteries" fill="#8884d8" />
            <Bar dataKey="Chargers" fill="#ff8c00" />
          </BarChart>
        </Col>

        {currentUser.type === "admin" ||
        currentUser.type === "manager" ||
        currentUser.type === "helpdesk" ? (
          <Col lg={6}>
            <h5>&nbsp;</h5>
            <Card
              style={{
                marginLeft: 80,
                maxWidth: 150,
                minHeight: 100,
                paddingLeft: 10,
                align: "top",
              }}
            >
              <Card.Body>
                <Card.Title>
                  <NavLink
                    className="text-dark bigText"
                    to={"/helpdesk?unanswered=true"}
                  >
                    Enquiries not answered
                  </NavLink>
                </Card.Title>
                <div className="largeText center">{totalQuestions}</div>
              </Card.Body>
            </Card>
          </Col>
        ) : (
          ""
        )}
        {currentUser.type === "admin" ||
        currentUser.type === "manager" ||
        currentUser.type === "helpdesk" ? (
          <Col lg={6}>
            <h5>&nbsp;</h5>
            <Card
              style={{
                marginLeft: 80,
                maxWidth: 150,
                minHeight: 100,
                paddingLeft: 10,
                align: "top",
              }}
            >
              <Card.Body>
                <Card.Title>
                  Newly Registered{" "}
                  <span className="smallText">(Last Week)</span>
                </Card.Title>
                <div className="largeText center">{newlyRegistered}</div>
              </Card.Body>
            </Card>
          </Col>
        ) : (
          ""
        )}
        {currentUser.type === "admin" ||
        currentUser.type === "manager" ||
        currentUser.type === "helpdesk" ? (
          <Col lg={12}>
            <h5>&nbsp;</h5>
            <Card
              style={{
                marginLeft: 80,
                maxWidth: "unset",
                width: "95%",
                minHeight: 100,
                paddingLeft: 10,
                align: "top",
                maxHeight: "unset",
              }}
            >
              <Card.Body>
                <Card.Title>Current Day Followup List</Card.Title>
                <div className="largeText center">
                  <Table>
                    <tbody>
                      <tr>
                        <td></td>
                        <td className="leftAlign font-size14">Title</td>
                        <td className="leftAlign font-size14">User</td>
                        <td className="leftAlign font-size14">Tel</td>
                        <td className="leftAlign font-size14">Plan</td>
                        <td className="leftAlign font-size14">Other Details</td>
                      </tr>

                      {followups.map((f, i) => (
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              onClick={(e) => markAsFollowed(f.details)}
                              checked={f.followupDone}
                            />
                          </td>
                          <td className="font-size14 text-light-gray leftAlign">
                            <i className={"font-size13 " + f.styles}></i>
                            <span className="font-size13">
                              &nbsp;&nbsp;{f.title}
                            </span>
                          </td>
                          <td className="margin-10px-top font-size14 leftAlign">
                            <NavLink
                              className="text-dark"
                              target="_blank"
                              to={
                                f.userId
                                  ? "/user/" + f.userId
                                  : "/company/" + f.companyId
                              }
                            >
                              {f ? f.name : "Unknown"}
                            </NavLink>
                          </td>
                          <td>
                            {f.title !== "Birthday" ? (
                              <div className="margin-10px-top font-size14 leftAlign">
                                {f.details.user
                                  ? f.details.user.phone
                                  : f.details.phone}
                              </div>
                            ) : null}
                          </td>
                          <td>
                            {f.details.planTitle &&
                            f.details.planTitle !== "" ? (
                              <div className="margin-10px-top font-size14 leftAlign">
                                {f.details.planTitle}
                              </div>
                            ) : null}
                          </td>
                          <td>
                            <div className="margin-10px-top font-size14 leftAlign">
                              {f.title === "Birthday" ? (
                                DateFromatter.getAge(f.details.birthday) +
                                " years old"
                              ) : f.title === "overDue" ||
                                f.title === "Package Delay" ? (
                                f.details.delay + " days delay"
                              ) : f.title === "Renewal Reminder" ? (
                                <>
                                  <span className="smallText">Renewal:</span>{" "}
                                  {DateFromatter.DateFormatterOne(
                                    f.details.nextRenewal
                                  )}
                                </>
                              ) : null}
                            </div>
                            {f.details.user ? (
                              <InternalNoteComponent
                                list={followups}
                                setList={setFollowups}
                                oldInternalNote={
                                  f.details.user.internalNote || ""
                                }
                                selectedUserByNote={f.details.user._id}
                                clickable={false}
                                note={f.details.note || ""}
                                rentedAcc={f.details.rentedAcc || ""}
                              />
                            ) : f.details.company ? (
                              <InternalNoteComponent
                                list={followups}
                                setList={setFollowups}
                                oldInternalNote={
                                  f.details.company.internalNote || ""
                                }
                                selectedCompanyByNote={f.details.company._id}
                                clickable={false}
                                note={f.details.note || ""}
                                rentedAcc={f.details.rentedAcc || ""}
                              />
                            ) : (
                              ""
                            )}
                            {(f.title === "Package Reminder" ||
                              f.title === "Package Delay") &&
                            f.details &&
                            f.details.lastDate ? (
                              <div className="margin-10px-top font-size14 leftAlign">
                                <span className="smallText">
                                  Last Date:&nbsp;
                                </span>{" "}
                                {DateFromatter.DateFormatterOne(
                                  f.details.lastDate
                                )}
                              </div>
                            ) : null}
                            {f.lastFollowup && f.lastFollowup !== "" ? (
                              <div className="margin-10px-top font-size14 leftAlign">
                                <span className="smallText">
                                  Last Followup:&nbsp;
                                </span>{" "}
                                {DateFromatter.DateFormatterOne(f.lastFollowup)}
                              </div>
                            ) : null}
                            {f.details.subscribeId ? (
                              <div className="margin-10px-top font-size14 leftAlign">
                                <NavLink
                                  className="text-dark"
                                  target="_blank"
                                  to={"/subscription/" + f.details.subscribeId}
                                >
                                  Subscription Details
                                </NavLink>
                              </div>
                            ) : (
                              ""
                            )}
                            {f.title === "Pickup" ? (
                              <div className="margin-10px-top font-size14 leftAlign">
                                <NavLink
                                  className="text-dark"
                                  target="_blank"
                                  to={
                                    "/subscription/new?branch=" +
                                    f.branch +
                                    "&user=" +
                                    f.userId
                                  }
                                >
                                  Create Subscription
                                </NavLink>
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </>
  );
}
