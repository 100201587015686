import React, { useEffect } from "react";
import DisplayMap from "../Map/DisplayMap";

const BikeLocation = (props) => {

	useEffect(() => {
		async function onReady() {
			window.scrollTo(0, 0);
		}
		onReady()
	}, []);


	return (
		<>
			<DisplayMap height={600} type="1" />
		</>
	);

};


export default BikeLocation;
