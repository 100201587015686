import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api" ;


const getCompaniesList = (branch, offset, limit, onlyList) => {
  return axios.get(API_URL + "/company/list?branch=" + branch + "&offset=" + offset + "&limit=" + limit + "&onlyList=" + onlyList, { headers: authHeader() });
};

const deleteCompany = (id) => {
  return axios(API_URL + "/company/" + id, {method: 'delete', headers: authHeader()});
};

const postCompany = (data) => {
  return axios(API_URL + "/company/save", {method: 'post', headers: authHeader(),data: data});
};


const getCompanyById = (id) => {
  return axios.get(API_URL + "/company/" + id, { headers: authHeader() });
};
const getCompanyDetailsById = (id) => {
  return axios.get(API_URL + "/company/details/" + id, { headers: authHeader() });
};

const getCompanyResellerDetails = () => {
  return axios.get(API_URL + "/reseller/company", { headers: authHeader() });
};

const updateInternalNote = (data) => {
  return axios(API_URL + "/company/note", { method: 'post', headers: authHeader(), data: data });
}

export default {
 
  postCompany,
  deleteCompany,
  getCompaniesList,
  getCompanyById,
  getCompanyDetailsById,
  updateInternalNote,
  getCompanyResellerDetails
};
