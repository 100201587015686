import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const createSurveyForm = (data) => {
    return axios(API_URL + "/survey/form/save", { method: 'post', headers: authHeader(), data: data });
};

const getSurveyFormsList = (typeFilter, offset, limit) => {
    return axios.get(API_URL + "/survey/form/list?typeFilter=" + typeFilter + "&offset=" + offset + "&limit=" + limit, { headers: authHeader() });
}

const getSurveyFormById = (id) => {
    return axios.get(API_URL + "/survey/form/" + id, { headers: authHeader() });
};

const getUserSurveyFormDetailsById = (id) => {
    return axios.get(API_URL + "/survey/form/details/" + id, { headers: authHeader() });
};

const getQuestionsListByFormId = (id) => {
    return axios.get(API_URL + "/survey/question/list?form=" + id);
}

const saveQuestions = (data) => {
    return axios(API_URL + "/survey/question/save", { method: 'post', headers: authHeader(), data: data });
};

const saveAnswers = (data) => {
    return axios(API_URL + "/survey/save", { method: 'post', headers: authHeader(), data: data });
}

const getSurveysList = (offset, limit, form_id) => {
    return axios.get(API_URL + "/survey/list?offset=" + offset + "&limit=" + limit + "&form_id=" + form_id, { headers: authHeader() });
}

const getAnswersBySurveyId = (id) => {
    return axios.get(API_URL + "/survey/view/" + id, { headers: authHeader() });
}

const exportAnswers = (id) => {
    return axios.get(API_URL + "/survey/form/export/" + id, { headers: authHeader() });
}

const deleteQuestion = (id) => {
    return axios(API_URL + "/survey/question/" + id, { method: 'delete', headers: authHeader() });
}

const deleteForm = (id) => {
    return axios(API_URL + "/survey/form/" + id, { method: 'delete', headers: authHeader() });
}

const deleteSurvey = (id) => {
    return axios(API_URL + "/survey/delete/" + id, { method: 'delete', headers: authHeader() });
}

const deleteSurveys = (id) => {
    return axios(API_URL + "/surveys/delete/" + id, { method: 'delete', headers: authHeader() });
}

const getSurveyFormAnalyticsById = (id) => {
    return axios.get(API_URL + "/survey/form/anayltics/" + id, { headers: authHeader() });
}



export default {
    createSurveyForm,
    getSurveyFormsList,
    getSurveyFormById,
    getUserSurveyFormDetailsById,
    getQuestionsListByFormId,
    saveQuestions,
    saveAnswers,
    getAnswersBySurveyId,
    getSurveysList,
    deleteQuestion,
    deleteForm,
    deleteSurvey,
    deleteSurveys,
    exportAnswers,
    getSurveyFormAnalyticsById
}