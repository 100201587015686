import React, { useEffect } from "react";
import "../css/home.css";
import Accent from "../components/Accent";
import CityRide from "../assets/shortTerm/CityRide.jpeg";
import HeightsRide from "../assets/shortTerm/HeightsRide.JPG";
import MountainRide from "../assets/shortTerm/MountainRide.JPG";
import SeasideRide from "../assets/shortTerm/SeasideRide.jpg";
import SunsetRide from "../assets/shortTerm/SunsetRide.jpg";
import VillageRide from "../assets/shortTerm/VillageRide.jpg";
import WetlandRide from "../assets/shortTerm/WetlandRide.jpg";
import WineryRide from "../assets/shortTerm/WineryRide.jpg";
import shortTermTogether from "../assets/shortTerm/shortTermTogether.png";

import SinglePlan from "../components/SingelPlan";
import { trackPage } from "../components/GoogleAnalytics";

export default function ShortTermRent() {
  useEffect(() => {
    window.scrollTo(0, 0);
    trackPage("Short term rent");
  }, []);
  const images = [
    {
      url: SeasideRide,
      title: "Wave electric bike facing the sea in Lebanon",
    },
    {
      url: MountainRide,
      title: "Group of Wave electric bike riders gathered in Mount Lebanon",
    },
    {
      url: WetlandRide,
      title: "Wave electric bikes parked at a beautiful spot in Lebanon",
    },
    {
      url: WineryRide,
      title: "Cyclist riding the Wave e-bike at a Lebanese winery",
    },
    {
      url: CityRide,
      title:
        "Cyclist riding the Wave e-bike under Beirut’s beautiful architecture",
    },
    {
      url: SunsetRide,
      title: "Wave electric bikes at sunset in Batroun, Lebanon",
    },
    {
      url: HeightsRide,
      title: "Group of riders on e-bikes enjoying Lebanon’s natural scenery",
    },
    {
      url: VillageRide,
      title: "Wave electric bikes in downtown, Beirut",
    },
  ];

  const plans = [
    {
      title: "Daily",
      price: "$15",
      includes: "Bike<br/> Battery & Charger<br/>Helmet<br/>Theft Insurance",
      planType: "daily",
      period: "1",
    },
    {
      title: "Weekend",
      price: "$40/3 days",
      includes: "Bike<br/>Battery & Charger<br/>Helmet<br/>Theft Insurance",
      planType: "daily",
      period: "3",
    },
    {
      title: "Weekly",
      price: "$50/7 days",
      includes: "Bike<br/>Battery & Charger<br/>Helmet<br/>Theft Insurance",
      planType: "weekly",
      period: "1",
    },
    {
      title: "Monthly",
      price: "$80/month",
      includes: "Bike<br/>Battery & Charger<br/>Helmet<br/>Theft Insurance",
      planType: "monthly",
      period: "1",
    },
  ];
  return (
    <div className="outer_container">
      {/* rides header on desktop */}
      <div className="desktop_version">
        <div className="inner_container ">
          <div className="about-header">
            <div>
              <div className="about-header-mobile">
                <h1>
                  <b className="pageTitle">
                    <Accent>
                      <span>Short term rent</span>
                    </Accent>
                  </b>
                </h1>
                <p
                  className="text-center-mobile"
                  style={{
                    fontSize: "14px",
                    fontWeight: "400px",
                    paddingRight: "30px",
                  }}
                >
                  Want to explore the vibrant streets of Beirut? Perhaps plan an
                  exciting group ride with friends to other gorgeous parts of
                  Lebanon on e-bikes? Whether it's a day, a weekend , or even an
                  entire week, we have short term rental packages available for
                  you.
                  <br />
                  <br />
                  With the right set of wheels, you can now truly discover the
                  beauty of Lebanon, creating memories that will last a
                  lifetime. Our e-bikes effortlessly take you wherever you want
                  to go, you don't need to be an athlete to enjoy it. Scroll
                  down to checkout the short term rental plans.
                  <br />
                  <br />
                  <div style={{ fontSize: "17px", fontWeight: "400" }}>
                    <b>
                      If you’re looking for a guided ride, we can arrange that
                      for you, just contact us via
                    </b>
                    <br />
                    <span style={{ fontSize: "14px", fontWeight: "400" }}>
                      Mail: hello@wave.bike
                      <br />
                      Tel: 70 632 955
                    </span>
                  </div>
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div>
              <img
                src={shortTermTogether}
                style={{ borderRadius: "20px", width: 630, height: 415 }}
                alt="Wavers at a ride"
              />
            </div>
          </div>
        </div>
      </div>
      {/* rides header on mobile */}
      <div id="why_wave_mobile_version">
        <div className="inner_container">
          <div className="about-header">
            <div className="about-header-mobile">
              <h1>
                <b className="pageTitle">
                  <Accent>
                    <span>Short term rent</span>
                  </Accent>
                </b>
              </h1>
              <p
                className="text-center-mobile"
                style={{ fontSize: "14px", fontWeight: "500px" }}
              >
                Want to explore the vibrant streets of Beirut? Perhaps plan an
                exciting group ride with friends to other gorgeous parts of
                Lebanon on e-bikes? Whether it's a day, a weekend , or even an
                entire week, we have short term rental packages available for
                you.
                <br />
                <br />
                With the right set of wheels, you can now truly discover the
                beauty of Lebanon, creating memories that will last a lifetime.
                Our e-bikes effortlessly take you wherever you want to go, you
                don't need to be an athlete to enjoy it. Scroll down to checkout
                the short term rental plans.
                <br /> With the right set of wheels, you can now truly discover
                the beauty of Lebanon, creating memories that will last a
                lifetime.
                <br />
                <br />
                <span style={{ fontSize: "17px", fontWeight: "400px" }}>
                  <b>
                    If you’re looking for a guided ride, we can arrange that for
                    you, just contact us via
                  </b>
                  <br />
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    Mail: hello@wave.bike
                    <br />
                    Tel: 70 632 955
                    <br />
                  </span>
                </span>
                <br />
                <br />
              </p>
              <div className="shifting_text_with_images">
                <img
                  src={shortTermTogether}
                  style={{ borderRadius: "20px" }}
                  alt="Wavers at a ride"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="inner_container sectionPadding">
        <h1>
          <b className="pageTitle">
            <Accent>
              <span>Short term rental plans</span>
            </Accent>
          </b>
        </h1>
        {/* payment plans on desktop */}
        <div id="why_wave_desktop_version" className="payment_plan_cards">
          {plans.map((plan, index) => {
            return (
              <SinglePlan
                index={index}
                planTitle={plan.title}
                titleColor={plan.color}
                description={plan.price}
                includesDescription={plan.includes}
                minHeight={30}
                minCardHeight={220}
                remark={plan.remark}
                planType={plan.planType}
                period={plan.period}
              />
            );
          })}
        </div>

        {/* payment plans on mobile */}
        <div id="why_wave_mobile_version" className="payment_plan_cards">
          {plans.map((plan, index) => {
            return (
              <SinglePlan
                index={index}
                planTitle={plan.title}
                titleColor={plan.color}
                description={plan.price}
                includesDescription={plan.includes}
                minHeight={30}
                minCardHeight={220}
                remark={plan.remark}
                planType={plan.planType}
                period={plan.period}
              />
            );
          })}
        </div>

        <br />
      </section>

      <section className="inner_container sectionPadding">
        <h1>
          <b className="pageTitle">
            <Accent>
              <span>Lebanon through the eyes of our Wavers</span>
            </Accent>
          </b>
        </h1>
        <div className="payment_plan_cards">
          {images.map((obj, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <img
                  src={obj.url}
                  className="landing_home_recent_features_img"
                  title={obj.title}
                  alt={obj.title}
                />
              </div>
            );
          })}
        </div>
        <br />
      </section>

      {/* the end section */}
      <section className="inner_container padding_container">
        <p id="centering_the_text">
          When riding a bicycle you get to enjoy much more of the beauty around
          and what often goes unnoticed when you are driving a car.
        </p>
      </section>
    </div>
  );
}
