import React, { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import TechnicalDashboard from "./TechnicalDashboard";
import AuthService from "../services/auth.service";
import { Redirect } from "react-router-dom";
import { trackPage } from "../LandingPage/components/GoogleAnalytics";

export default function DashboardController() {
  const [user, setUser] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    trackPage("Wave");
    const user = AuthService.getCurrentUser() || "";
    setUser(user);
    setType(user.type);
  }, []);

  if (type === "technical") return <Redirect to="/Maintenances" />;
  else return <Dashboard />;
}
