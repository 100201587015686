import React, { useState, useEffect, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import CompanyService from "../services/company.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";

const currentUser = JSON.parse(localStorage.getItem("user"));
const Company = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [companyName, setCompanyName] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [industry, setIndustry] = useState("");
  const [notes, setNotes] = useState("");
  const [branch, setBranch] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const companyId = props.match.params.id;
  const [company, setCompany] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    CompanyService.getCompanyDetailsById(companyId).then(
      (response) => {
        let c = response.data;
        setCompanyName(c.name);
        setPhone(c.phone);
        setEmail(c.email);
        setAddress(c.address);
        setIndustry(c.industry);
        setDisabled(c.disabled);
        setNotes(c.notes);
        setBranch(c.branch);
        setCompany(c);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content);
        setMessage(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  return (
    <div className="col-md-12">
      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "50%" }}>Company Specification</th>
            <th style={{ float: "right" }}>
              {currentUser.type !== "reseller" ? (
                <NavLink
                  to={"/company/edit/" + companyId}
                  className="text-dark "
                >
                  Edit
                </NavLink>
              ) : (
                ""
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Name</td>
            <td>{companyName}</td>
          </tr>
          <tr>
            <td>Disabled</td>
            <td>{disabled ? "Disabled" : "Active"}</td>
          </tr>
          <tr>
            <td>Phone</td>
            <td>{phone}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{email}</td>
          </tr>
          <tr>
            <td>Industry</td>
            <td>{industry ? industry : "None"} </td>
          </tr>
          <tr>
            <td>Address</td>
            <td>{address ? address : "None"} </td>
          </tr>
          <tr>
            <td>Notes</td>
            <td>
              {notes ? (
                <span dangerouslySetInnerHTML={{ __html: notes }}></span>
              ) : (
                "None"
              )}
            </td>
          </tr>
          <tr>
            <td>Branch</td>
            <td>{branch ? branch.label : ""}</td>
          </tr>
          <tr>
            <td>Half Hour Rent Price</td>
            <td>{company?.halfHourPrice || 0} USD</td>
          </tr>

          <tr>
            <td>Hourly Rent Price</td>
            <td>{company?.hourlyPrice || 0} USD</td>
          </tr>
          <tr>
            <td>Daily Rent Price</td>
            <td>{company?.dailyPrice || 0} USD</td>
          </tr>
          <tr>
            <td>Weekly Rent Price</td>
            <td>{company?.weeklyPrice || 0} USD</td>
          </tr>
          <tr>
            <td>Wave Percentage</td>
            <td>{company?.wavePercent || 0} %</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Company;
