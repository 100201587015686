import React, { useState, useEffect, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { NavLink } from "react-router-dom";
import BranchService from "../services/branch.service";
import EventBus from "../common/EventBus";

const currentUser = JSON.parse(localStorage.getItem("user"));
const Branch = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [label, setLabel] = useState("");
  const [disabled, setDisabled] = useState("");
  const [language, setLanguage] = useState("");
  const [currency, setCurrency] = useState("");
  const [unitCurrency, setUnitCurrency] = useState("");
  const [country, setCountry] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const branchId = props.match.params.id;
  const [timezone, setTimeZone] = useState("");
  const [phone, setPhone] = useState("");
  const [town, setTown] = useState("");
  const [city, setCity] = useState("");
  const [MOF, setMOF] = useState("");
  const [buildingNumber, setBuildingNumber] = useState("");
  const [floor, setFloor] = useState("");
  const [street, setStreet] = useState("");
  const [currencyRate, setCurrencyRate] = useState("");
  const [defaultPlan, setDefaultPlan] = useState("");
  const [journeyForm, setJourneyForm] = useState("");
  const [company, setCompany] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    BranchService.getBranchById(branchId).then(
      (response) => {
        let b = response.data;
        setLabel(b.label);
        setDisabled(b.disabled);
        setLanguage(b.language);
        setCurrency(b.currency);
        setTimeZone(b.timezone);
        setCurrencyRate(b.currencyRate);
        setCountry(b.country);
        setPhone(b.phone);
        setTown(b.town);
        setCity(b.city);
        setMOF(b.MOF);
        setBuildingNumber(b.buildingNumber);
        setFloor(b.floor);
        setStreet(b.street);
        setDefaultPlan(b.defaultPlan ? b.defaultPlan.label : "");
        setJourneyForm(b.journeyform ? b.journeyform.label : "");
        setCompany(b.company ? b.company.name : "None");
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content);
        setMessage(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  return (
    <div className="col-md-12">
      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "50%" }}>Branch Specification</th>
            <th style={{ float: "right" }}>
              <NavLink to={"/branch/edit/" + branchId} className="text-dark ">
                Edit
              </NavLink>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Label</td>
            <td>{label}</td>
          </tr>
          <tr>
            <td>Company</td>
            <td>{company}</td>
          </tr>
          <tr>
            <td>Disabled</td>
            <td>{disabled ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <td>Language</td>
            <td>{language == "tr" ? "Turkish" : "English"}</td>
          </tr>
          <tr>
            <td>Currency</td>
            <td>{currency}</td>
          </tr>
          <tr>
            <td>1 USD</td>
            <td>
              {currencyRate} {currency}
            </td>
          </tr>
          <tr>
            <td>Phone</td>
            <td>{phone}</td>
          </tr>
          <tr>
            <td valign="top">Address</td>
            <td>
              {" "}
              {buildingNumber ? <>{buildingNumber} building </> : ""}{" "}
              {floor ? (
                buildingNumber ? (
                  <>, {floor}</>
                ) : (
                  <>{floor} floor</>
                )
              ) : (
                ""
              )}{" "}
              {buildingNumber || floor ? <br /> : ""}{" "}
              {street ? (
                <>
                  {street}, {town}
                </>
              ) : (
                town
              )}
              <br />
              {city}, {country}
            </td>
          </tr>
          <tr>
            <td>Default Plan</td>
            <td>{defaultPlan}</td>
          </tr>
          <tr>
            <td>Default Joureny Form</td>
            <td>{journeyForm}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Branch;
