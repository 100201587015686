import React from 'react'
import { NavLink } from "react-router-dom";
import '../css/navBar.css'

export default function NavBarNavLink(props) {

    const path = props.path || false;
    const text = props.text || "";
    const exact = props.exact;
    const disabled = props.disabled;
	const targetPath= props.targetPath || "";
    const extraClass= props.extraClass || "";
    var activeClass = props.activeClass || "landing_NavBar_selected";
    var inactiveClass = props.inactiveClass || "landing_NavBar_unselected";

    if(disabled){
        activeClass = "";
        inactiveClass = "";
    }

    return (
        <NavLink to={path} ismenu="true" exact={exact} 
        className={isActive =>
          `nav-link landing_NavBar_black ${extraClass} ` + (isActive ? activeClass : inactiveClass)}
      >
        {text}
      </NavLink>
      )
}
