import React, { useState, useEffect, useRef, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import Textarea from "react-validation/build/textarea";
import CheckButton from "react-validation/build/button";
import InventoryService from "../services/inventory.service";
import AuthService from "../services/inventory.service";
import EventBus from "../common/EventBus";
import { getBranches } from "../common/getFunctions";
import { required } from "../common/Validation";
import Switch from "react-switch";
import { inventoryType } from "../labels/options_en";
import Currency from '../commonComponents/Currency';
import { Prompt } from "react-router-dom";

const InventoryForm = (props) => {
	const form = useRef();
	const checkBtn = useRef();
	const currentUser = JSON.parse(localStorage.getItem('user'));
	const [number, setNumber] = useState("");
	const [label, setLabel] = useState("");
	const [type, setType] = useState("");
	const [description, setDescription] = useState("");
	const [total_in_use, setTotal_in_use] = useState(0);
	const [total, setTotal] = useState(0);
	const [available, setAvailable] = useState(0);
	const [total_in_repair, setTotal_in_repair] = useState(0);
	const [total_in_stock, setTotal_in_stock] = useState(0);
	const [total_in_recycle, setTotal_in_recycle] = useState(0);
	const [total_in_obs, setTotal_in_obs] = useState(0);
	const [branches, setBranches] = useState([]);
	const [branch, setBranch] = useState(currentUser.branch);
	const [isAccessory, setIsAccessory] = useState(false);
	const [rentPrice, setRentPrice] = useState(0);
	const [originalPrice, setOriginalPrice] = useState(0);
	const [sellPrice, setSellPrice] = useState(0);
	const [currency, setCurrency] = useState("");
	const [VAT, setVAT] = useState("");
	const [unit, setUnit] = useState("");
	const [size, setSize] = useState("");
	const [color, setColor] = useState("");
	const [MOQ, setMOQ] = useState("");
	const [supplier, setSupplier] = useState("");
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	const [requiredValidation, setRequiredValidation] = useState([]);
	const [total_lost, setTotal_lost] = useState(0);



	const [inventoryId, setInventoryId] = useState(props.match.params.id);
	const [iAmTyping, setIAmTyping] = useState(false);
	const clone = props.location.pathname.includes('/clone/');

	const getInventoryInfo = async () => {
		if (inventoryId) {
			InventoryService.getInventoryById(inventoryId).then(
				(response) => {
					let b = response.data;
					setLabel(b.label);
					setType(b.type);
					setNumber(b.number);
					setDescription(b.description);
					setBranch(b.branch.id);
					setAvailable(parseInt(b.total_available));
					setTotal(parseInt(b.total_available) + parseInt(b.total_in_recycle) + parseInt(b.total_lost) + parseInt(b.total_in_obs));
					setTotal_in_use(b.total_in_use);
					setTotal_in_repair(b.total_in_repair);
					setTotal_in_stock(b.total_in_stock);
					setTotal_in_recycle(b.total_in_recycle);
					setTotal_lost(b.total_lost);
					setTotal_in_obs(b.total_in_obs);
					setIsAccessory(b.isAccessory);
					setRentPrice(b.rentPrice);
					setOriginalPrice(b.originalPrice);
					setSellPrice(b.sellPrice);
					setColor(b.color);
					setSize(b.size);
					setMOQ(b.MOQ);
					setSupplier(b.supplier);
					setUnit(b.unit);
					setCurrency(b.currency);
					setVAT(b.VAT);
					if (clone === true) { setInventoryId(""); } // reset the id to clone

				},
				(error) => {
					const _content =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
					alert(_content);
					setMessage(_content);

					if (error.response && error.response.status === 401) {
						EventBus.dispatch("logout");
					}
				}
			);
		}
		else {
			setBranch(currentUser.branch)
		}
	}


	useEffect(() => {
		async function onReady() {
			window.scrollTo(0, 0);
			const tempBranches = await getBranches();
			setBranches(tempBranches);
			getInventoryInfo();

			const userBranch = tempBranches.find((branch) => branch._id === currentUser.branch);
			const userBranchCurrency = userBranch.currency || "";
			setCurrency(userBranchCurrency);


		}

		onReady()

	}, []);

	const handleSaveInventory = (e) => {
		e.preventDefault();
		setIAmTyping(false);
		setMessage("");
		setSuccessful(false);
		if (currency == "") { alert("Currency is a required field!"); return; }
		form.current.validateAll();

		if (checkBtn.current.context._errors.length === 0) {
			if (inventoryId) {
				AuthService.putInventory(inventoryId, number, label, type, description, branch, total_in_use, total_in_repair, total_in_stock, total_in_recycle, total_in_obs, isAccessory, rentPrice, sellPrice, currency, VAT, originalPrice, size, color, MOQ, supplier, unit, total_lost).then(
					(response) => {
						setMessage("Inventory Updated.");
						setSuccessful(true);
						props.history.push('/inventories');

					},
					(error) => {
						const resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();

						setMessage(resMessage);
						setSuccessful(false);
					}
				);
			}
			else {

				AuthService.postInventory(number, label, type, description, branch, total_in_use, total_in_repair, total_in_stock, total_in_recycle, total_in_obs, isAccessory, rentPrice, sellPrice, currency, VAT, originalPrice, size, color, MOQ, supplier, unit,total_lost).then(
					(response) => {
						setMessage("Inventory Saved.");
						setSuccessful(true);
						props.history.push('/inventories');
					},
					(error) => {
						const resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();

						setMessage(resMessage);
						setSuccessful(false);
					}
				);
			}

		}
	};


	const branchHandler = (id) => {
		setBranch(id)
		const selectedBranch = branches.find((branch) => branch._id === id)

		const tempCurrency = selectedBranch.currency || ""

		setCurrency(tempCurrency)
	}
	const setInUse = (t) => {
		setTotal_in_use(t);
		var t_available = parseInt(t) + parseInt(total_in_stock) + parseInt(total_in_repair);
		setAvailable(t_available);
		setTotal(parseInt(t_available) + parseInt(total_in_recycle)  + parseInt(total_lost) + parseInt(total_in_obs));
	}
	const setInStock = (t) => {
		setTotal_in_stock(t);
		var t_available = parseInt(t) + parseInt(total_in_use) + parseInt(total_in_repair);
		setAvailable(t_available);
		setTotal(parseInt(t_available) + parseInt(total_in_recycle) + parseInt(total_lost) + parseInt(total_in_obs));
	}
	const setInRepair = (t) => {
		setTotal_in_repair(t);
		var t_available = parseInt(t) + parseInt(total_in_use) + parseInt(total_in_stock);
		setAvailable(t_available);
		setTotal(parseInt(t_available) + parseInt(total_in_recycle) + parseInt(total_lost)  + parseInt(total_in_obs));
	}
	const setInRecycle = (t) => {
		setTotal_in_recycle(t);
		var t_available = parseInt(total_in_use) + parseInt(total_in_stock) + parseInt(total_in_repair);
		setAvailable(t_available);
		setTotal(parseInt(t_available) + parseInt(t) + parseInt(total_in_obs) + parseInt(total_lost) );
	}
	const setInObs = (t) => {
		setTotal_in_obs(t);
		var t_available = parseInt(total_in_use) + parseInt(total_in_stock) + parseInt(total_in_repair);
		setAvailable(t_available);
		setTotal(parseInt(t_available) + parseInt(t) + parseInt(total_in_recycle) + parseInt(total_lost) );
	}
	const setLost = (t) => {
		setTotal_lost(t);
		var t_available = parseInt(total_in_use) + parseInt(total_in_stock) + parseInt(total_in_repair);
		setAvailable(t_available);
		setTotal(parseInt(t_available) + parseInt(t) + parseInt(total_in_recycle) + parseInt(total_in_obs) );
	}


	return (
		<Fragment>
			<Prompt
				when={iAmTyping}
				message={(location) =>
					'Are you sure, you want to leave? All your data will be lost!'
				}
			/>
		<div className="col-md-12">


			<Form onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} ref={form}>
				{!successful && (
					<div>


						<table className="styled-table" style={{ width: "100%" }}>
							<thead>
								<tr>
									<th colSpan="8">Inventory Form</th>

								</tr>
							</thead>
							<tbody>
								<tr className="white-background">

									<td>
										<div className="form-group">
											<label htmlFor="number">Number</label>
											<Input
												type="text"
												className="form-control"
												name="number"
												value={number}
													onChange={e => {
														setNumber(e.target.value);
														setIAmTyping(true);
													}}
												validations={[required]}
											/>
										</div>
									</td>
									<td>
										<div className="form-group">
											<label htmlFor="label">Label</label>
											<Input
												type="text"
												className="form-control"
												name="label"
												value={label}
													onChange={e => {
														setLabel(e.target.value);
														setIAmTyping(true);
													}}
												validations={[required]}
											/>
										</div>
									</td>
									<td>
										<div className="form-group">
											<label htmlFor="type">Sub Category</label>
											<Select

												className="form-control"

												value={type}
													onChange={e => {
														setType(e.target.value);
														setIAmTyping(true);
													}}
												validations={[required]}
											>
												<option value="">None</option>

												{inventoryType.map((item, index) =>
													<option key={index} value={item}>{item}</option>
												)}
											</Select>
										</div>
									</td>
									<td>
										<div className="form-group">
											<label htmlFor="type">Size</label>
											<Input
												type="text"
												className="form-control"
												name="size"
												value={size}
													onChange={e => {
														setSize(e.target.value);
														setIAmTyping(true);
													}}

											/>
										</div>
									</td>
									<td>
										<div className="form-group">
											<label htmlFor="color">Color</label>
											<Input
												type="text"
												className="form-control"
												name="color"
												value={color}
													onChange={e => {
														setColor(e.target.value);
														setIAmTyping(true);
													}}

											/>
										</div>
									</td>
									<td>
										<div className="form-group">
											<label htmlFor="MOQ">MOQ</label>
											<Input
												type="text"
												className="form-control"
												name="MOQ"
												value={MOQ}
													onChange={e => {
														setMOQ(e.target.value);
														setIAmTyping(true);
													}}

											/>
										</div>
									</td>
									<td>
										<div className="form-group">
											<label htmlFor="supplier">Supplier</label>
											<Input
												type="text"
												className="form-control"
												name="supplier"
												value={supplier}
													onChange={e => {
														setSupplier(e.target.value);
														setIAmTyping(true);
													}}

											/>
										</div>
									</td>

								</tr>



									<tr className="white-background">
									<td>
										<div className="form-group">
											<label htmlFor="isAccessory">Is Accessory?</label>
												<Switch onChange={() => {setIsAccessory(!isAccessory);setIAmTyping(true);}} checked={isAccessory} />
										</div>
									</td>
									<td>
										<div className="form-group">
												<Currency fn={e => {setCurrency(e.target.value);setIAmTyping(true);}} name="currency" value={currency} isRequired={true} />

										</div>
									</td>
									<td>
										<div className="form-group">
											<label htmlFor="orginalPrice">Cost</label>
											<Input
												type="number"
												className="form-control"
												name="originalPrice"
												value={originalPrice}
													onChange={e => {
														setOriginalPrice(e.target.value);
														setIAmTyping(true);
													}}

											/>
										</div>
									</td>
									<td>
										<div className="form-group">
											<label htmlFor="rentPrice">Rent Price <span className="tinyLabel">(VAT Included)</span></label>
											<Input
												type="number"
												className="form-control"
												name="rentPrice"
												value={rentPrice}
													onChange={e => {
														setRentPrice(e.target.value);
														setIAmTyping(true);
													}}

											/>
										</div>
									</td>
									<td>
										<div className="form-group">
											<label htmlFor="sellPrice">Sell Price <span className="tinyLabel">(VAT Included)</span></label>
											<Input
												type="number"
												className="form-control"
												name="sellPrice"
												value={sellPrice}
													onChange={e => {
														setSellPrice(e.target.value);
														setIAmTyping(true);
													}}

											/>
										</div>
									</td>
									<td>
										<div className="form-group">
											<label htmlFor="VAT">VAT</label>
											<Input
												type="number"
												className="form-control"
												value={VAT}
													onChange={e => {
														setVAT(e.target.value);
														setIAmTyping(true);
													}}
												validations={[required]}
											/>
										</div>
									</td>
									<td>
										<div className="form-group">
											<label htmlFor="unit">Unit</label>
											<Input
												type="text"
												className="form-control"
												value={unit}
													onChange={e => {
														setUnit(e.target.value);
														setIAmTyping(true);
													}}

											/>
										</div>
									</td>
								</tr>
									<tr className="white-background">
									<td>
										<div className="form-group">
											<label htmlFor="total">Total:<br />{total}</label>

										</div>
									</td>
									<td>
										<div className="form-group">
											<label htmlFor="total">Available:<br />{available}</label>

										</div>
									</td>

									<td>
										<div className="form-group">
											<label htmlFor="total_in_use">In-use</label>
											<Input
												type="number"
												className="form-control"
												name="total_in_use"
												value={total_in_use}
													onChange={e => {
														setInUse(e.target.value);
														setIAmTyping(true);
													}}

											/>
										</div>
									</td>

									<td>
										<div className="form-group">
											<label htmlFor="total_in_stock">In-stock</label>
											<Input
												type="number"
												className="form-control"
												name="total_in_stock"
												value={total_in_stock}
													onChange={e => {
														setInStock(e.target.value);
														setIAmTyping(true);
													}}

											/>
										</div>
									</td>

									<td>
										<div className="form-group">
											<label htmlFor="total_in_repair">In-service</label>
											<Input
												type="number"
												className="form-control"
												name="total_in_repair"
												value={total_in_repair}
													onChange={e => {
														setInRepair(e.target.value);
														setIAmTyping(true);
													}}

											/>
										</div>
									</td>



									<td>
										<div className="form-group">
											<label htmlFor="total_in_recycle">In-recyle</label>
											<Input
												type="number"
												className="form-control"
												name="total_in_recycle"
												value={total_in_recycle}
													onChange={e => {
														setInRecycle(e.target.value);
														setIAmTyping(true);
													}}

											/>
										</div>
									</td>

									<td>
										<div className="form-group">
											<label htmlFor="total_in_obs">In-Obs</label>
											<Input
												type="number"
												className="form-control"
												name="total_in_obs"
												value={total_in_obs}
													onChange={e => {
														setInObs(e.target.value);
														setIAmTyping(true);
													}}

											/>
										</div>
									</td>
									<td>
										<div className="form-group">
											<label htmlFor="total_lost">Lost</label>
											<Input
												type="number"
												className="form-control"
												name="total_lost"
												value={total_lost}
													onChange={e => {
														setLost(e.target.value);
														setIAmTyping(true);
													}}

											/>
										</div>
									</td>


								</tr>

									<tr className="white-background">
									{
										currentUser.type === "admin" ?
											<td valign="top">
												<div className="form-group">
													<label htmlFor="branch">Branch</label>
													<Select
														className="form-control"
														value={branch}
															onChange={e => {
																branchHandler(e.target.value);
																setIAmTyping(true);
															}}
														validations={[required]}
													>
														<option value=""></option>
														{branches && branches.map((m) => (

															<option value={m._id}>{m.label}</option>
														))}

													</Select>
												</div>

											</td>
											:
											<td></td>
									}
									<td colSpan="6">
										<div className="form-group">
											<label htmlFor="description">Description</label>
											<Textarea
												type="text"
												className="form-control"
												name="description"
												value={description}
													onChange={e => {
														setDescription(e.target.value);
														setIAmTyping(true);
													}}

											/>
										</div>
									</td>

								</tr>

							</tbody>
						</table>

						<div className="form-group">
							<button className="btn btn-primary btn-block" onClick={handleSaveInventory}  >Save</button>
						</div>
					</div>
				)}

				{message && (
					<div className="form-group">
						<div
							className={
								successful ? "alert alert-success" : "alert alert-danger"
							}
							role="alert"
						>
							{message}
						</div>
					</div>
				)}
				<CheckButton style={{ display: "none" }} ref={checkBtn} />
			</Form>

		</div>
		</Fragment>
	);
};

export default InventoryForm;