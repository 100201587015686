import React, { useState, useEffect } from "react";
import EventService from "../services/event.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import DateFromatter from "../common/DateFromatter";


const Event = (props) => {

    const [title, setTitle] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [internal, setInternal] = useState(false);
    const [company, setCompany] = useState("");
    const [pack, setPack] = useState("");
    const [budget, setBudget] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [link, setLink] = useState("");
    const [notes, setNotes] = useState("");
    const [picture, setPicture] = useState("");
    const [type, setType] = useState("");
    const [summary, setSummary] = useState("");
    const [fees, setFees] = useState("");
	const [address, setAddress] = useState("");
    const [branch, setBranch] = useState("");
    const eventId = props.match.params.id;


    useEffect(() => {
        window.scrollTo(0, 0);
        EventService.getEventDetailsById(eventId).then(
            (response) => {
                let e = response.data;
                if (e) {
                    setBranch(e.branch);
                    setBudget(e.budget);
                    setCompany(e.company);
                    setDisabled(e.disabled);
                    setEnd(e.end);
                    setFees(e.fees);
                    setInternal(e.internal);
                    setLink(e.link);
                    setNotes(e.notes);
                    setPack(e.package);
                    setPicture(e.picture);
                    setStart(e.start);
					setAddress(e.address);
                    setSummary(e.summary);
                    setTitle(e.title);
                    setType(e.type);
                    if (e.picture) {
                        setPicture(process.env.REACT_APP_SERVER_API + "/upload/events/" + e.picture);

                    } else {
                        setPicture("");
                    }
                }



            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                alert(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }, []);


    return (
        <div className="col-md-12">

            <table className="styled-table" style={{ width: "100%" }}>
                <thead>
                    <tr>
                        <th style={{ width: "50%" }}>Event Specification</th>
                        <th style={{ float: "right" }}><NavLink to={"/event/edit/" + eventId} className="text-dark ">Edit</NavLink></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Title</td>
                        <td>{title}</td>
                    </tr> 
					<tr>
                        <td>Address</td>
                        <td>{address}</td>
                    </tr>
                    <tr>
                        <td>Budget</td>
                        <td>{budget}</td>
                    </tr>
                    <tr>
                        <td>Start date</td>
                        <td>{DateFromatter.beautifyDate(start)} {DateFromatter.tConvert(start)}</td>
                    </tr>
                    <tr>
                        <td>End date</td>
                        <td>{DateFromatter.beautifyDate(end)} {DateFromatter.tConvert(end)}</td>
                    </tr>
                    <tr>
                        <td>Disabled</td>
                        <td>{disabled ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr>
                        <td>Internal</td>
                        <td>{internal ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr>
                        <td>Company</td>
                        <td>{company ? company.name : ""}</td>
                    </tr>
                    <tr>
                        <td>Package</td>
                        <td>{pack ? pack.title : ""}</td>
                    </tr>
                    <tr>
                        <td>Fees</td>
                        <td>{fees}</td>
                    </tr>
                    <tr>
                        <td>Link</td>
                        <td>{link}</td>
                    </tr>
                    <tr>
                        <td>Notes</td>
                        <td>{notes}</td>
                    </tr>
                    <tr>
                        <td>Summary</td>
                        <td>{summary}</td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td>{type}</td>
                    </tr>
                    <tr>
                        <td>Branch</td>
                        <td>{branch ? branch.label : ""}</td>
                    </tr>
                    {picture ?
                        <tr>
                            <td>Picture</td>
                            <td><a target="_blank" rel="noopener noreferrer" href={picture}><img alt="event" src={picture} /></a></td>
                        </tr>
                        : ""}

                </tbody>
            </table>






        </div>
    );
};

export default Event;
