import React, { useEffect, useState } from "react";
import { getBranches } from "../common/getFunctions";
import Select from "react-validation/build/select";
import { required } from "../common/Validation";

export default function BranchePullDown(props) {
  const branch = props.branch;
  const branches = props.branches;
  const setBranch = props.setBranch;
  const extraClass = props.extraClass;
  const extraClassLabel = props.extraClassLabel;
  const labelInside = props.labelInside || false;
  const label = props.title || "Branch";
  const isRequired = props.isRequired || "";
  const branchRef = props.branchRef;
  const validationArr = props.validationArr || [];
  const forceBlank = props.forceBlank || false;
  const labelOption = props.labelOption || false;
  const addFormGroup = props.addFormGroup;
  const showAll = props.showAll || false;
  const disabled = props.disabled;
  // useEffect(() => {
  //     async function onReady() {
  //         setBranches(await getBranches());
  //     }
  //     onReady();
  // }, []);
  let option1 = "";
  if (forceBlank === true) {
    option1 = <option value=""></option>;
  } else if (labelOption) {
    option1 = (
      <option disabled="disabled" value="">
        {label} *
      </option>
    );
  }

  /*if (branches.length === 1)
        return null */

  let selectPulldown = (
    <select
      autoComplete="new-password"
      className={"form-control " + extraClass}
      value={branch}
      Placeholder={label}
      disabled={disabled}
      onChange={(e) => setBranch(e.target.value)}
      required={isRequired}
      ref={branchRef}
      validations={validationArr}
    >
      {option1}
      {showAll === true && <option value="null">Applied to All</option>}
      {branches &&
        branches.map((m, index) => (
          <option value={m._id} key={"branch_" + index} company={m.companyId}>
            {m.label}
          </option>
        ))}
    </select>
  );

  if (labelInside) {
    return selectPulldown;
  } else {
    if (addFormGroup != "no") {
      return (
        <div className="form-group">
          <label htmlFor="branch" className={extraClassLabel}>
            {label}
          </label>
          {selectPulldown}
        </div>
      );
    } else {
      return (
        <>
          <label htmlFor="branch" className={extraClassLabel}>
            {label}
          </label>
          {selectPulldown}
        </>
      );
    }
  }
}
