import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// Transform data to prepare for grouped bars
const transformData = (data) => {
  const result = {};

  data.forEach((item) => {
    const key = `${item.month}-${item.year}`;
    const currency = item.currency.split("-")[1]; // Extract 'USD', 'Euro', or 'LBP'

    if (!result[key]) {
      result[key] = {
        month: item.month,
        year: item.year,
        totalAmount_USD: 0,
        totalNetAmount_USD: 0,
        totalAmount_Euro: 0,
        totalNetAmount_Euro: 0,
        totalAmount_LBP: 0,
        totalNetAmount_LBP: 0,
      };
    }

    if (currency === "USD") {
      result[key].totalAmount_USD += item.totalAmount || 0;
      result[key].totalNetAmount_USD += item.totalNetAmount || 0;
    } else if (currency === "Euro") {
      result[key].totalAmount_Euro += item.totalAmount || 0;
      result[key].totalNetAmount_Euro += item.totalNetAmount || 0;
    } else if (currency === "LBP") {
      result[key].totalAmount_LBP += item.totalAmount || 0;
      result[key].totalNetAmount_LBP += item.totalNetAmount || 0;
    }
  });

  return Object.values(result);
};

const WaveBarChart = ({ data }) => {
  const transformedData = transformData(data);

  // Get all unique currencies
  const currencies = ["USD", "Euro", "LBP"];

  // Create custom tooltip formatter for different currencies
  const tooltipFormatter = (value, name, props) => {
    console.log(name);
    const currency = name.split(" ")[2] || "";
    const formatValue = (currency) => {
      switch (currency) {
        case "USD":
          return `$${value.toFixed(2)}`;
        case "Euro":
          return `€${value.toFixed(2)}`;
        case "LBP":
          return `${value.toFixed(2)} LBP`;
        default:
          return value.toFixed(2);
      }
    };
    return [
      formatValue(currency),
      `${props.payload.month}/${props.payload.year} - ${name}`,
    ];
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={transformedData}>
        <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
        <XAxis
          dataKey="month"
          tickFormatter={(month) => `${month}/${transformedData[0].year}`} // Display month/year on the x-axis
          label={{
            value: "Month/Year",
            position: "insideBottomRight",
            offset: -5,
          }}
        />
        <YAxis tickFormatter={(value) => value.toFixed(2)} />{" "}
        {/* Format y-axis values to 2 decimal places */}
        <Tooltip
          formatter={tooltipFormatter}
          labelFormatter={(label) =>
            `${transformedData.find((item) => item.month === label).month}/${
              transformedData.find((item) => item.month === label).year
            }`
          }
          labelStyle={{ color: "rgba(0, 0, 0, 0.87)" }}
        />
        <Legend
          verticalAlign="top"
          height={36}
          wrapperStyle={{ paddingLeft: 20, paddingRight: 20 }}
          formatter={(value, entry, index) => {
            const { color } = entry;
            return (
              <span style={{ color, fontSize: "14px", fontWeight: "bold" }}>
                {value}
              </span>
            );
          }}
        />
        {/* Grouped bars for each currency */}
        {currencies.map((currency) => {
          const currencyCode = currency;
          return (
            <>
              <Bar
                key={`totalAmount-${currencyCode}`}
                dataKey={`totalAmount_${currencyCode}`}
                fill={
                  currencyCode === "USD"
                    ? "#4CAF50"
                    : currencyCode === "Euro"
                    ? "#2196F3"
                    : "#9C27B0"
                }
                name={`Total   
                    ${
                      currencyCode === "USD"
                        ? "$"
                        : currencyCode === "Euro"
                        ? "€"
                        : "LBP"
                    }
                `} // Set bar label with FontAwesome icon
                barSize={15}
              />
              <Bar
                key={`totalNetAmount-${currencyCode}`}
                dataKey={`totalNetAmount_${currencyCode}`}
                fill={
                  currencyCode === "USD"
                    ? "#388E3C"
                    : currencyCode === "Euro"
                    ? "#1976D2"
                    : "#7B1FA2"
                }
                name={`Net  
                    ${
                      currencyCode === "USD"
                        ? "$"
                        : currencyCode === "Euro"
                        ? "€"
                        : "LBP"
                    }
                `} // Set bar label with FontAwesome icon
                barSize={15}
              />
            </>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default WaveBarChart;
