import React from 'react'
import { NavLink } from "react-router-dom";
import '../css/footer.css'

export default function FooterNavLink(props) {

  const path = props.path || "/";
  const text = props.text || "";
  const exact = props.exact;
  const target= props.target || "";
  const disabled = props.disabled;

  var activeClass = props.activeClass || "landing_Footer_selected";
  var inactiveClass = props.inactiveClass || "landing_Footer_unselected";

  if (disabled) {
    activeClass = "";
    inactiveClass = "";
  }

  return (
    <NavLink to={path}
      exact={exact}
      target={target}
      className={isActive =>
        "nav-link nav-link-footer " + (isActive ? activeClass : inactiveClass)}
    >
      {text}
    </NavLink>
  )
}
