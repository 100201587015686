import React from "react";
import Accent from "../components/Accent";
import WIRED from "../assets/recentFeatures/WIRED.jpg";
import Lorient from "../assets/recentFeatures/Lorient.jpg";
import Lebanon24 from "../assets/recentFeatures/Lebanon24.jpg";
import AlJazeera from "../assets/recentFeatures/AlJazeera.jpg";
import { Col, Row } from "react-bootstrap";
export default function RecentFeatures(props) {
  const links = [
    {
      title: "This e-bike startup wants to clear up Beirut's traffic",
      dir: "ltr",
      subTitle: "WIRED Middle East",
      url: "https://wired.me/gear/this-e-bike-startup-wants-to-clear-up-beiruts-traffic/",
      imgURL: WIRED,
    },
    {
      title:
        "Meet the e-bike company peddling a revolution in urban transportation",
      dir: "ltr",
      subTitle: "L'Orient Today",
      url: "https://today.lorientlejour.com/article/1348411/meet-the-e-bike-company-peddling-a-revolution-in-urban-transportation.html",
      imgURL: Lorient,
    },
    {
      title: 'وسيلة "وفّيرة وصحية" للتنقل في بيروت... وداعاً للزحمة!',
      dir: "rtl",
      subTitle: "Lebanon24",
      url: "https://www.lebanon24.com/news/lebanon/1050916/%D9%88%D8%B3%D9%8A%D9%84%D8%A9-%D9%88%D9%81%D9%91%D9%8A%D8%B1%D8%A9-%D9%88%D8%B5%D8%AD%D9%8A%D8%A9-%D9%84%D9%84%D8%AA%D9%86%D9%82%D9%84-%D9%81%D9%8A-%D8%A8%D9%8A%D8%B1%D9%88%D8%AA-%D9%88%D8%AF%D8%A7%D8%B9%D8%A7%D9%8B-%D9%84%D9%84%D8%B2%D8%AD%D9%85%D8%A9",
      imgURL: Lebanon24,
    },
    {
      title: "Cycling culture gains ground in Lebanon as fuel runs dry",
      dir: "ltr",
      subTitle: "Al Jazeera",
      url: "https://www.aljazeera.com/amp/news/2021/6/24/cycling-culture-gains-ground-in-lebanon-amid-fuel-shortages",
      imgURL: AlJazeera,
    },
  ];
  return (
    <section className="inner_container">
      <div id="landing_home_recent_features">
        <div className="landing_titles">
          <h1>
            <b className="pageTitle">
              <Accent className="accent_container">Recent Features</Accent>
            </b>
          </h1>
        </div>
        <div style={{ fontSize: 14 }} className="text-center-mobile">
          The Wave story has been picked up by many news sources. Find out more
          at:
        </div>
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            maxWidth: "none",
          }}
        >
          {links.map((link, index) => {
            return (
              <Col sm={12} md={6} lg={3} key={`step-${index}`}>
                <img
                  src={link.imgURL}
                  className="landing_home_recent_features_img"
                />

                <br />
                <p style={{ maxWidth: 300, fontSize: 15, height: 120 }}>
                  <div dir={link.dir}>
                    <a href={link.url} target="_blank">
                      {link.title}
                    </a>
                  </div>
                  <div style={{ fontSize: 12, paddingTop: 10 }}>
                    {link.subTitle}
                  </div>
                </p>
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
}
