import React from "react";
import { Card, Col } from "react-bootstrap";
import "../css/home.css";

export default function SinglePlan(props) {
  const index = props.index || 0;
  const planTitle = props.planTitle;
  const description = props.description;
  const includesDescription = props.includesDescription;
  const remark = props.remark;
  const titleColor = props.titleColor || "#3CB4E5";
  const minHeight = props.minHeight || 60;
  const minCardHeight = props.minCardHeight || "350px";
  const planType = props.planType || "monthly";
  const period = props.period || "1";
  const planId = props.id || null;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          style={{
            width: "285px",
            height: minCardHeight,
            align: "top",
            borderRadius: "20px",
            background: "#EEE",
            borderColor: "white",
          }}
        >
          <Card.Body>
            <Card.Title
              style={{
                color: titleColor,
                textAlign: "center",
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "normal",
              }}
            >
              {planTitle}
            </Card.Title>
            <p
              style={{
                textAlign: "center",
                color: "#595959",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "normal",
                minHeight: { minHeight },
              }}
            >
              <b dangerouslySetInnerHTML={{ __html: description }}></b>
            </p>

            <div
              style={{
                textAlign: "center",
                color: "#595959",
                fontSize: "12px",
                fontWeight: 700,
                lineHeight: "normal",
              }}
            >
              Includes:
              <p
                style={{
                  textAlign: "center",
                  color: "#595959",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
                dangerouslySetInnerHTML={{ __html: includesDescription }}
              ></p>
              {remark && (
                <p
                  style={{
                    textAlign: "center",
                    color: "#595959",
                    fontSize: "12px",
                    fontWeight: 400,
                    fontStyle: "italic",
                    lineHeight: "normal",
                  }}
                >
                  {remark}
                </p>
              )}
            </div>
          </Card.Body>
        </Card>
        <button
          id="landing_home_reserve_now_button"
          onClick={(e) => {
            e.preventDefault();
            let link =
              "/signup?planType=" +
              planType +
              "&period=" +
              period +
              "&index=" +
              index;
            if (planId) {
              link += "&planId=" + planId;
            }
            window.location.href = link;
          }}
        >
          Choose Plan
        </button>{" "}
      </div>
    </>
  );
}
