import React from 'react'
import Header from '../components/Header';
import '../css/about.css';
import bikeImg from "../assets/images/bike.png"
import landingImgMobile from "../assets/images/landing-mobile-work.png"
import { Card, Col, Row } from 'react-bootstrap';

export default function LandingStudent() {

	return (
		<div className="public-page-container">
			<section>
				<div className='landing_About_section landingWidth' style={{ maxHeight: 1400,   width: "87%" }} >
					<div className="isBrowser">
						
						<div className="landingWorkDiv" >
							<Row style={{ width: "100%", display: "flex ", alignItems: "flex-start",  flexDirection: "row", marginLeft: 0, marginRight: 0 }} >
									<Col md={7} style={{ paddingLeft: 60}}>

										<h1 className="landingStudentTitle">
										Save the pocket money!<br/>
										Ace that attendance sheet!
										<div className="landingStudentTitle2" style={{ marginTop:30 }}>The trendiest way to<br/> get to university in <br /><span className="landingWorkBigTitle">Beirut</span></div>
										</h1>
										
										<div className="landingWorkSummary">
											How much time are you spending in traffic every day?<br/>
											How much money are you spending on taxis, fuel or<br/>
											parking fees? How late are you to class most of the time?<br/>
											You now have a great commuting option to get to<br/>
											university easily everyday!<br/><br/>
											And you can try it now 1 week for free!<br/><br/>
											<ul type="1">
											<li>Send the Whatsapp message "Coupon" to 70 632 955, and we will schedule a date with you to pick up your Wave bike at our hub in Geitawi.</li><br/>
											<li>Bring your university card and national ID card with you for registration, and you get a free one-week trial without any obligations to take afterwards.</li>
											</ul>
										</div>
										{/*<div  ><button className='green_button' style={{ width: 150 }} onClick={(e) => { e.preventDefault(); window.location.href = '/'; }}>Learn More</button></div><br/>*/}
									</Col>
									<Col md={5}  >
										<div style={{width:"75%", marginTop: 40, marginLeft:"auto", marginRight:"auto"}}>
											<h1 className="landingWorkTitle"  >E-bike monthly<div className="landingWorkTitle2">subscription Plans</div></h1>
										</div>
										<img src={bikeImg} style={{ width: "100%" , marginTop:130}} />
										<div className="LandingWorkBtn"><button className='green_button' onClick={(e) => { e.preventDefault(); window.location.href = '/signup'; }}>Book your Bike</button></div>
										
						
									</Col>
								</Row>
								
						</div>
					</div>
					<div className="isMobile">
						
						<div className="landingWorkDivMobile whiteFont center" >

							<h1 style={{ fontSize: 24, marginTop:50 }}>
								Save the pocket money!  Ace that attendance sheet!
							</h1>
							
							<h1 style={{ fontSize: 21, marginTop:40 }}>
								The trendiest way to <br/>get to university in <div className="landingStudentTitle">Beirut</div>
							</h1>
							
							<div style={{ fontSize: 12, marginTop:15 }}>
								How much time are you spending in traffic every day? How much money are you spending on taxis, fuel or parking fees? How late are you to class most of the time? You now have a great commuting option to get to university easily everyday!
								
								<br/><br/>
								And you can try it now 1 week for free!<br/><br/>
								<ul type="1">
									<li>Send the Whatsapp message "Coupon" to 70 632 955, and we will schedule a date with you to pick up your Wave bike at our hub in Geitawi.</li><br/>
									<li>Bring your university card and national ID card with you for registration, and you get a free one-week trial without any obligations to take afterwards.</li>
								</ul>
							</div>
							
							{/*<div style={{marginTop:30}} ><button className='green_button_mobile' style={{ width: 150 }} onClick={(e) => { e.preventDefault(); window.location.href = '/'; }}>Learn More</button></div>*/}

							<div>
								<h1 className="whiteFont" style={{ fontSize: 27, marginTop:25 }}>E-bike monthly<div style={{ fontSize: 22}}>subscription Plans</div></h1>
								
							</div>
							<div className="bikeContainer">
								<img className='bikeMobile' src={bikeImg} alt="bike" />
								<div style={{marginTop:-30}} >
									<button className='green_button_mobile' style={{ width: 150 }} onClick={(e) => { e.preventDefault(); window.location.href = '/signup'; }}>Book your Bike</button>
								</div>
							</div>
							
							
						</div>
						
						
<br/>
					</div>
					<br/>

				</div >
			</section>
		</div>
	)
}
