import React from "react";
import EventBus from "../common/EventBus";
import SubscriptionService from "../services/subscription.service";

export default function (props) {
  const id = props.id;
  const name = props.name;

  const handleUnfreezeSubscriptionClick = () => {
    if (
      window.confirm(
        "Are you sure you want to unfreeze the subscription for " + name + "?"
      )
    ) {
      SubscriptionService.unfreezeSubscription(id).then(
        (res) => {
          const newId = res.data?.data?.newId;
          if (newId) {
            window.location.href =
              window.location.protocol +
              "//" +
              window.location.host +
              "/subscription/edit/" +
              newId;
          }
        },
        (error) => {
          console.log(error);
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          alert(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };
  return (
    <button
      className="linkBtn text-dark small-link midText"
      onClick={handleUnfreezeSubscriptionClick}
    >
      <i className="fa fa-fire red">
        {" "}
        <span className="faText red">Unfreeze Subscription</span>
      </i>
    </button>
  );
}
