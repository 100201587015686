import React, { useState, useEffect } from 'react';
import { Link, NavLink } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import bikerImg from "../images/biker.jpg";
import Accordion from 'react-bootstrap/Accordion'

export default function ClientMenu(props) {

  const [user, setUser] = useState(props.user);
  const [expanded, setExpanded] = useState(false);
  let biker = JSON.parse(localStorage.getItem("bikerInfo"));
  let branch = JSON.parse(localStorage.getItem("branch"));
  const type = props.type;
  const logOut = props.logOut;
  //console.log(biker)
  if (!biker) {
    biker = user;
  }

  const menuBlur = async (e) => {

    if (e && e.relatedTarget && e.relatedTarget.attributes && ((e.relatedTarget.attributes.getNamedItem("ismenu") && e.relatedTarget.attributes.getNamedItem("ismenu").value == "true") || !e.relatedTarget.attributes.getNamedItem("ismenu"))) {
      return;
    }


    setExpanded(false)
  }
  return (
    <Navbar id="sidebar" expand="xl" expanded={expanded} onToggle={() => setExpanded(!expanded)} onBlur={(e) => menuBlur(e)}>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="pt-5">

        <Nav className="list-unstyled components pl-4 mb-5 flex-column">

          <Navbar.Brand onClick={() => setExpanded(false)} >
            <Link to={"/dashboard"} className="navbar-brand">
              <div className="insider">
                {user.picture ? <img src={process.env.REACT_APP_SERVER_API + "/upload/profiles/" + user.picture}

                  onError={(e) => (e.target.onerror = null, e.target.src = bikerImg)} /> :
                  <img src={bikerImg} alt="logo" />}
                <p>{biker.first_name} {biker.last_name}</p>
                {branch ? <p>{branch.country}{branch.country && branch.city ? ", " : null}{branch.city}</p> : null}

              </div>
            </Link>
          </Navbar.Brand>
          <Nav.Link onClick={() => setExpanded(false)} >
            <NavLink
              style={isActive => ({
                 fontWeight: isActive ? "bold" : "normal"
              })}
              to={"/dashboard"} className="nav-link greenLink mx-0" ismenu="true">
              <i className="fas fa-home" ></i>&nbsp;&nbsp;Home
            </NavLink>
          </Nav.Link>
          <Nav.Link>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="accordionHeader"><i style={{ color: "white" }} className="fa fa-cog"></i>&nbsp;&nbsp;Settings <i style={{ float: 'right' }} className="fas fa-caret-down"></i></Accordion.Header>
                <Accordion.Body>
                  <ul className="list-unstyled" id="homeSubmenu" >


                    <li >

                      <NavLink
                        onClick={() => setExpanded(false)}
                        style={isActive => ({
                           fontWeight: isActive ? "bold" : "normal"
                        })}
                        to={"/profile"} className="nav-link greenLink mx-0" ismenu="true">
                        <i className="fas fa-user" ></i>&nbsp;&nbsp;Profile
                      </NavLink>

                    </li>
                    <li >

                      <NavLink
                        onClick={() => setExpanded(false)}
                        style={isActive => ({
                           fontWeight: isActive ? "bold" : "normal"
                        })}
                        to={"/changepassword"} className="nav-link greenLink mx-0" ismenu="true">
                        <i className="fas fa-unlock-alt"></i>&nbsp;&nbsp;Change Password
                      </NavLink>

                    </li>
                    {user.iagree ?
                      <li >

                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={isActive => ({
                             fontWeight: isActive ? "bold" : "normal"
                          })}
                          to={"/termsandconditions"} className="nav-link greenLink mx-0" ismenu="true">
                          <i className="fas fa-file-signature"></i>&nbsp;&nbsp;Terms and Conditions
                        </NavLink>

                      </li>
                      : ""}

                    {user.iagree ?
                      <li >

                        <NavLink
                          onClick={() => setExpanded(false)}
                          style={isActive => ({
                             fontWeight: isActive ? "bold" : "normal"
                          })}
                          to={"/identitycard"} className="nav-link greenLink mx-0" ismenu="true">
                          <i className="fas fa-id-card"></i>&nbsp;&nbsp;Identity Card
                        </NavLink>

                      </li>
                      : ""}

                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Nav.Link>

          <Nav.Link onClick={() => setExpanded(false)}>
            <NavLink
              style={isActive => ({
                 fontWeight: isActive ? "bold" : "normal"
              })}
              to={"/invoices"} className="nav-link greenLink mx-0" ismenu="true">
              <i className="fas fa-receipt" ></i>&nbsp;&nbsp;Payment History
            </NavLink>
          </Nav.Link>

          <Nav.Link onClick={() => setExpanded(false)}>
            <NavLink
              style={isActive => ({
                 fontWeight: isActive ? "bold" : "normal"
              })}
              to={"/subscriptions"} className="nav-link greenLink mx-0" ismenu="true">
              <i className="fas fa-rocket" ></i>&nbsp;&nbsp;My Subscription Plan
            </NavLink>
          </Nav.Link>
          <Nav.Link onClick={() => setExpanded(false)}>
            <NavLink
              style={isActive => ({
                 fontWeight: isActive ? "bold" : "normal"
              })}
              to={"/journeys"} className="nav-link greenLink mx-0" ismenu="true">
              <i className="fas fa-map-marked-alt" ></i>&nbsp;&nbsp;Journeys
            </NavLink>
          </Nav.Link>
          

          <Nav.Link onClick={() => setExpanded(false)}>
            <NavLink
              style={isActive => ({
                 fontWeight: isActive ? "bold" : "normal"
              })}
              to={"/faqs"} className="nav-link greenLink mx-0" ismenu="true">
              <i className="fas fa-question" ></i>&nbsp;&nbsp;FAQ's
            </NavLink>
          </Nav.Link>

          <Nav.Link onClick={() => setExpanded(false)}>
            <NavLink
              style={isActive => ({
                 fontWeight: isActive ? "bold" : "normal"
              })}
              to={"/helpdesk"} className="nav-link greenLink mx-0" ismenu="true">
              <i className="fas fa-comments" ></i>&nbsp;&nbsp;Contact us
            </NavLink>
          </Nav.Link>

          <Nav.Link onClick={() => setExpanded(false)}>
            <a href="/" className="nav-link greenLink mx-0" onClick={logOut} ismenu="true">
              <i className="fas fa-sign-out-alt"></i>&nbsp;&nbsp;LogOut
            </a>
          </Nav.Link>


        </Nav>

      </Navbar.Collapse>

    </Navbar >
  );
}
