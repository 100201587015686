import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { required, validEmail } from "../../common/Validation";
import UserService from "../../services/user.service";
import Header from "../components/Header";
import { trackPage } from "../components/GoogleAnalytics";

export default function ForgetPassword() {
  const form = useRef();
  const checkBtn = useRef();
  trackPage("Forget Password");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleForgetPassword = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    UserService.sendForgetRequest(email.trim()).then(
      (response) => {
        setLoading(false);
        alert(
          "An email has been sent to you. Please check your inbox, as well as your spam."
        );
        window.location.href =
          window.location.protocol + "//" + window.location.host + "/login";
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };
  const onChangeEmail = (e) => {
    const em = e.target.value;
    setEmail(em.trim());
  };

  return (
    <div>
      <Header title="Forgot Password" extraClass="headerMedium" />
      <div className="col-md-12 login-page-container">
        <div className="landing-login-card">
          <Form onSubmit={handleForgetPassword} ref={form}>
            <div className="landing-login-input-container">
              <svg
                className="landing-login-logo"
                width={30}
                viewBox="0 0 512 512"
              >
                <path d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z" />
              </svg>
              <Input
                autoComplete="off"
                placeholder="E-mail"
                type="text"
                className="landing-login-input"
                name="email"
                value={email}
                onChange={onChangeEmail}
                validations={[required, validEmail]}
              />
            </div>

            <br />
            <br />
            <div id="landing-login-button-container">
              <button id="landing-login-button" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Recover My Password</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}
