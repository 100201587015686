import React from 'react'
import Modal from 'react-bootstrap/Modal'

export default function StayUpdatedModal(props) {

    // Write the logic for "Join Now"

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header id='stay_updated_modal_header'>
                <Modal.Title id="contained-modal-title-vcenter">
                    Join Our Newsletter
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="stay_updated_modal">
                    <input type="text" placeholder='example@wave.bike' />
                    <button onClick={props.onHide}>Join Now</button>
                </div>
            </Modal.Body>

        </Modal>
    );
}
