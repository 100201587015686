import React, { useState, useEffect, useRef, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import Textarea from "react-validation/build/textarea";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import HelpDeskService from "../services/helpdesk.service";
import EventBus from "../common/EventBus";
import commonFunctions from "../common/Functions";
import RequiredLabel from "../common/RequiredLabel";
import { required } from "../common/Validation";
import { getBranches } from "../common/getFunctions";
import Switch from "react-switch";
import { Prompt } from "react-router-dom";



const HelpdeskQuestionForm = (props) => {
	const form = useRef();
	const checkBtn = useRef();
	const currentUser = JSON.parse(localStorage.getItem('user'));
	const [question, setQuestion] = useState("");
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	const [questionId, setQuestionId] = useState(props.match.params.id);
	const [iAmTyping, setIAmTyping] = useState(false);

	const getHelpDeskInfo = async () => {

		if (questionId) {
			HelpDeskService.getQuestionById(questionId).then(
				(response) => {
					let b = response.data;
					setQuestionId(questionId);
					setQuestion(b.question);
				},
				(error) => {
					const _content =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
					alert(_content);
					setMessage(_content);

					if (error.response && error.response.status === 401) {
						EventBus.dispatch("logout");
					}
				}
			);
		}

	}

	const alertUser = e => {
		e.preventDefault()
		e.returnValue = ''
	}

	useEffect(() => {
		async function onReady() {
			window.scrollTo(0, 0);
			getHelpDeskInfo();
			window.addEventListener('beforeunload', alertUser)
			return () => {
				window.removeEventListener('beforeunload', alertUser)
			}
		}

		onReady()
	}, []);

	const handleSaveHelpDesk = (e) => {
		e.preventDefault();
		setIAmTyping(false);
		setMessage("");
		setSuccessful(false);

		form.current.validateAll();

		if (checkBtn.current.context._errors.length === 0) {

			HelpDeskService.postQuestion(questionId, question).then(
				(response) => {
					setMessage("HelpDesk Saved.");
					setSuccessful(true);
					props.history.push('/helpdesk');
				},
				(error) => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setMessage(resMessage);
					setSuccessful(false);
				}
			);



		}
	};



	return (
		<Fragment>
			<Prompt
				when={iAmTyping}
				message={(location) =>
					'Are you sure, you want to leave? All your data will be lost!'
				}
			/>
			<div className="col-md-12">


				<Form onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} ref={form}>
					{!successful && (
						<div>


							<table className="styled-table" style={{ width: "100%" }}>
								<thead>
									<tr>
										<th style={{ width: "25%" }}>How can we help?</th>


									</tr>
								</thead>
								<tbody>


									<tr className="white-background">



										<td>
											<div className="form-group">
												<RequiredLabel htmlFor="question" text="Type in your question here" />
												<Textarea
													className="form-control"
													value={question}
													onChange={e => {
														setQuestion(e.target.value);
														setIAmTyping(true);
													}}
													validations={[required]}

												/>
											</div>
										</td>
									</tr>



								</tbody>
							</table>

							<div className="form-group">
								<button className="btn btn-primary btn-block" onClick={handleSaveHelpDesk} >Save</button>
							</div>
						</div>
					)}

					{message && (
						<div className="form-group">
							<div
								className={
									successful ? "alert alert-success" : "alert alert-danger"
								}
								role="alert"
							>
								{message}
							</div>
						</div>
					)}
					<CheckButton style={{ display: "none" }} ref={checkBtn} />
				</Form>

			</div>
		</Fragment>
	);
};

export default HelpdeskQuestionForm;