import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DateFromatter from "../common/DateFromatter";


export default function GetDateModal(props) {
    const title = props.title;
    const modalShow = props.modalShow;
    const setModalShow = props.setModalShow;
    const onSubmitDate = props.onSubmitDate;

    const [date, setDate] = useState(DateFromatter.getCurrentDate());

    return (
        <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h5>{title}</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <input
                        type="date"
                        className="form-control"
                        name="date"
                        value={date}
                        onChange={e => setDate(e.target.value)}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button onClick={() => onSubmitDate(date)}>Submit</Button>
                <Button onClick={() => setModalShow(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
