import React, { useState, useEffect, useRef, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import Textarea from "react-validation/build/textarea";
import CheckButton from "react-validation/build/button";
import ModelService from "../services/model.service";
import AuthService from "../services/model.service";
import EventBus from "../common/EventBus";
import commonFunctions from "../common/Functions";
import { getBranches, getInventoriesOptions } from "../common/getFunctions";
import { required } from "../common/Validation";
import * as options from '../labels/options_en';
import ImageUploader from "react-images-upload";
import bikePic from "../assets/img/Full-Bike small.png";
import { Prompt } from "react-router-dom";


const ModelForm = (props) => {
	const form = useRef();
	const checkBtn = useRef();
	const currentUser = JSON.parse(localStorage.getItem('user'));
	const [label, setLabel] = useState("");
	const [gears, setGears] = useState("");
	const [type, setType] = useState("bike");
	const [frameSize, setFrameSize] = useState("");
	const [wheelDiameter, setWheelDiameter] = useState("");
	const [maxRiderWeight, setMaxRiderWeight] = useState("");
	const [maxSpeed, setMaxSpeed] = useState("");
	const [modelWeight, setModelWeight] = useState("");
	const [motorType, setMotorType] = useState("");
	const [motorRatedPower, setMotorRatedPower] = useState("");
	const [motorRatedRPM, setMotorRatedRPM] = useState("");
	const [motorRatedVoltage, setMotorRatedVoltage] = useState("");
	const [brakes, setBrakes] = useState("");
	const [loaded, setLoaded] = useState(false);
	const [error, setError] = useState("");
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	const [modelId, setModelId] = useState(props.match.params.id);
	const [branches, setBranches] = useState([]);
	const [branch, setBranch] = useState(currentUser.branch);
	const [inventory, setInventory] = useState({});
	const [picture, setPicture] = useState("");
	const [voltage, setVoltage] = useState("");
	const [ampere, setAmpere] = useState("");
	const [defaultImage, setDefaultImage] = useState("");
	const [picChanged, setPicChanged] = useState(false);
	const [weight, setWeight] = useState("");
	const clone = props.location.pathname.includes('/clone/');


	// inventory pulldown options.
	const [inventoriesOptions, setInventoriesOptions] = useState([]);


	// in new it should be blank, in edit, it is the intial coming from the api.
	const [initialInventories, setInitialInventories] = useState([""]);

	// in new it should be blank, it should save the final list of inventories of the model.
	const [inventories, setInventories] = useState([]);

	// in new, it will be same as inventories.
	// in edit, it will be whatever added to the initialInventories.
	const [addedInventories, setAddedInventories] = useState([]);

	//in new it will be blank array.
	const [removedInventories, setRemovedInventories] = useState([]);

	const [iAmTyping, setIAmTyping] = useState(false);

	/*const updateInventories = () => {
		
		if (!initialInventories.includes(inventory._id)) // this is a new one.
		{
			setAddedInventories([...addedInventories, inventory._id]);
		}

		inventories.push(inventory);
		setInventories(inventories);
	}*/

	const addInventory = () => {
		setInventories([...inventories, ""]);

	}

	const removeInventory = (i) => {

		if (initialInventories.includes(inventories[i])) // inventory has been removed.
		{
			setRemovedInventories([...removedInventories, inventories[i]]);
		}
		const list = [...inventories];
		list.splice(i, 1);
		let invOptions = inventoriesOptions;
		invOptions.push(inventories[i]);
		setInventories(list);
		setInventoriesOptions(invOptions);

	}



	const savePic = (p, path) => {
		setIAmTyping(true);
		setPicture(path[0]);
		setDefaultImage(path[0]);
		setPicChanged(true);
	};

	const setInventoryDetails = (id) => {
		var selectedInventory = inventoriesOptions.filter((inv) => inv._id === id)[0]

		if (!initialInventories.includes(selectedInventory._id)) // this is a new one.
		{
			setAddedInventories([...addedInventories, selectedInventory._id]);
		}

		inventories.unshift(selectedInventory);
		let invOptions = inventoriesOptions.filter((inv) => inv._id !== id);
		setInventoriesOptions(invOptions);
		setInventories(inventories);
		setInventory("");

	}

	const removePic = () => {
		setIAmTyping(true);
		setPicture("");
		setPicChanged(true);
	}
	const getModel = (invOptions) => {
		if (modelId) {
			ModelService.getModelById(modelId).then(
				(response) => {
					let b = response.data;
					setLabel(b.label);
					setType(b.type);
					setVoltage(b.voltage);
					setAmpere(b.ampere);
					setWeight(b.weight);
					setGears(b.gears);
					setFrameSize(b.frameSize);
					setWheelDiameter(b.wheelDiameter);
					setMaxRiderWeight(b.maxRiderWeight);
					setDefaultImage(b.pic ? process.env.REACT_APP_SERVER_API + "/upload/models/" + b.pic : "")
					setModelWeight(b.bikeWeight);
					setMotorType(b.motorType);
					setMotorRatedPower(b.motorRatedPower);
					setMotorRatedRPM(b.motorRatedRPM);
					setMotorRatedVoltage(b.motorRatedVoltage);
					setBrakes(b.brakes);
					setBranch(b.branch);
					setMaxSpeed(b.maxSpeed);
					let invOps = invOptions;
					for (const id of b.inventories) {
						var selectedInventory = invOptions.filter((inv) => inv._id === id)[0]
						inventories.push(selectedInventory);
						setInventories(inventories);
						invOps = invOps.filter((inv) => inv._id !== id);

					}
					setInventoriesOptions(invOps);

					setInitialInventories([...b.inventories]);


					// console.log("available", available)

					if (clone === true) { setModelId(""); } // reset the id to clone
					setLoaded(true);
				},
				(error) => {
					const _content =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
					alert(_content);
					setMessage(_content);

					if (error.response && error.response.status === 401) {
						EventBus.dispatch("logout");
					}
				}
			);
		}
		else {
			setLoaded(true);
			setBranch(currentUser.branch)
		}
	}

	useEffect(() => {

		async function onReady() {
			window.scrollTo(0, 0);
			setBranches(await getBranches());
			let invs = await getInventoriesOptions("", "", "", "", "", true);
			setInventoriesOptions(invs);

			await getModel(invs);
		}

		onReady()

	}, loaded);

	const handleSaveModel = (e) => {
		e.preventDefault();
		setIAmTyping(false);
		setMessage("");
		setSuccessful(false);

		form.current.validateAll();

		if (checkBtn.current.context._errors.length === 0) {
			var inventoriesFinal = [];
			inventories.map((x, i) => {
				if (x != "") { inventoriesFinal.push(x); }
			});

			var addedInventoriesFinal = [];
			addedInventories.map((x, i) => {
				if (x != "") { addedInventoriesFinal.push(x); }
			});


			AuthService.postModel(modelId, label, type, frameSize, wheelDiameter, maxRiderWeight, maxSpeed, modelWeight, motorType, motorRatedPower, motorRatedRPM, motorRatedVoltage, brakes, gears, picture, picChanged, ampere, voltage, weight, branch, commonFunctions.removeDuplicates(inventoriesFinal), commonFunctions.removeDuplicates(addedInventoriesFinal), commonFunctions.removeDuplicates(removedInventories)).then(
				(response) => {
					setMessage("Model saved.");
					setSuccessful(true);
					props.history.push('/models');

				},
				(error) => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					alert(resMessage);
					setSuccessful(false);
				}
			);

		}
	};



	return (
		<Fragment>
			<Prompt
				when={iAmTyping}
				message={(location) =>
					'Are you sure, you want to leave? All your data will be lost!'
				}
			/>
			<div className="col-md-12">


				<Form onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} ref={form}>
					{!successful && (
						<div>


							<table className="styled-table" style={{ width: "100%" }}>
								<thead>
									<tr>
										<th colSpan={2} style={{ width: "50%" }}>Specification</th>
										<th colSpan={2}></th>
									</tr>
								</thead>
								<tbody>

									<tr className="white-background">
										<td colSpan={2}>
											<table border="0">

												{currentUser.type === "admin" ?
													<tr className="white-background">
														<td>
															<div className="form-group">
																<label htmlFor="branch">Branch</label>
																<Select
																	className="form-control"
																	value={branch}
																	onChange={e => {
																		setBranch(e.target.value);
																		setIAmTyping(true);
																	}}
																	validations={[required]}
																>
																	<option value=""></option>
																	{branches && branches.map((m) => (
																		<option value={m._id}>{m.label}</option>
																	))}

																</Select>
															</div>
														</td>
													</tr>
													:
													null
												}

												<tr className="white-background">
													<td>
														<div className="form-group">
															<label htmlFor="label">Label</label>
															<Input
																type="text"
																className="form-control"
																name="label"
																value={label}
																onChange={e => {
																	setLabel(e.target.value);
																	setIAmTyping(true);
																}}
																validations={[required]}
															/>
														</div>
													</td>
												</tr>
												<tr className="white-background">
													<td>
														<div className="form-group">

															<label htmlFor="type">Type</label>
															<Select

																className="form-control"
																disabled={modelId ? true : false}
																value={type}
																onChange={e => {
																	setType(e.target.value);
																	setIAmTyping(true);
																}}
																validations={[required]}

															>
																<option value=""></option>
																{options.model_types.map((item, index) =>
																	<option key={'type_' + index} value={item.key}>{item.value}</option>
																)}

															</Select>
														</div>
													</td>
												</tr>
												{type != "bike" ?
													<>
														<tr className="white-background">
															<td>
																<div className="form-group">
																	<label htmlFor="voltage">Voltage (V)</label>
																	<Input
																		type="text"
																		className="form-control"
																		name="voltage"
																		value={voltage}
																		onChange={e => {
																			setVoltage(e.target.value);
																			setIAmTyping(true);
																		}}

																	/>
																</div>
															</td>

														</tr>
														<tr className="white-background">

															<td>
																<div className="form-group">
																	<label htmlFor="ampere">Ampere ({type == "charger" ? "A" : "AH"})</label>
																	<Input
																		type="text"
																		className="form-control"
																		name="ampere"
																		value={ampere}
																		onChange={e => {
																			setAmpere(e.target.value);
																			setIAmTyping(true);
																		}}

																	/>
																</div>
															</td>
														</tr>
														<tr className="white-background">

															<td>
																<div className="form-group">
																	<label htmlFor="weight">Weight (kg)</label>
																	<Input
																		type="text"
																		className="form-control"
																		name="ampere"
																		value={weight}
																		onChange={e => {
																			setWeight(e.target.value);
																			setIAmTyping(true);
																		}}

																	/>
																</div>
															</td>
														</tr>
													</> : ""}

											</table>

										</td>
										<td colSpan={2} valign="top">

											<ImageUploader
												withIcon={false}
												withPreview={true}
												label=""
												buttonText="Upload picture"
												onChange={savePic}
												accept="image/*"
												maxFileSize={5242880}
												singleImage={true}
												defaultImages={defaultImage ? [defaultImage] : [bikePic]}
												onDelete={removePic}

											/>

										</td>

									</tr>
									{type == "bike" ?
										<>
											<tr className="white-background">

												<td>
													<div className="form-group">
														<label htmlFor="frameSize">Height of Seat Tube (cm)</label>
														<Input
															type="text"
															className="form-control"
															name="frameSize"
															value={frameSize}
															onChange={e => {
																setFrameSize(e.target.value);
																setIAmTyping(true);
															}}

														/>
													</div>
												</td>

												<td>
													<div className="form-group">
														<label htmlFor="wheelDiameter">Wheel Diameter (inch)</label>
														<Input
															type="text"
															className="form-control"
															name="wheelDiameter"
															value={wheelDiameter}
															onChange={e => {
																setWheelDiameter(e.target.value);
																setIAmTyping(true);
															}}

														/>
													</div>
												</td>
												<td>
													<div className="form-group">
														<label htmlFor="maxRiderWeight">Max Rider Weight (Kg)</label>
														<Input
															type="text"
															className="form-control"
															name="maxRiderWeight"
															value={maxRiderWeight}
															onChange={e => {
																setMaxRiderWeight(e.target.value);
																setIAmTyping(true);
															}}

														/>
													</div>
												</td>
											</tr>


											<tr className="white-background">




												<td>
													<div className="form-group">
														<label htmlFor="maxSpeed">Max Speed Allowed (Km/h)</label>
														<Input
															type="text"
															className="form-control"
															name="maxSpeed"
															value={maxSpeed}
															onChange={e => {
																setMaxSpeed(e.target.value);
																setIAmTyping(true);
															}}

														/>
													</div>
												</td>
												<td>
													<div className="form-group">
														<label htmlFor="brakes">Brakes</label>
														<Input
															type="text"
															className="form-control"
															name="brakes"
															value={brakes}
															onChange={e => {
																setBrakes(e.target.value);
																setIAmTyping(true);
															}}

														/>
													</div>
												</td>
												<td>
													<div className="form-group">
														<label htmlFor="gears">Gears</label>
														<Input
															type="text"
															className="form-control"
															name="gears"
															value={gears}
															onChange={e => {
																setGears(e.target.value);
																setIAmTyping(true);
															}}

														/>
													</div>
												</td>
												<td>
													<div className="form-group">
														<label htmlFor="modelWeight">Weight without battery (Kg)</label>
														<Input
															type="text"
															className="form-control"
															name="modelWeight"
															value={modelWeight}
															onChange={e => {
																setModelWeight(e.target.value);
																setIAmTyping(true);
															}}

														/>
													</div>
												</td>
											</tr>
										</>
										:
										""}



								</tbody>
							</table>


							{type == "bike" ?
								<>
									<table className="styled-table" style={{ width: "100%" }}>
										<thead>
											<tr>
												<th colSpan={2} style={{ width: "33%" }}>Motor Specification</th>
												<th></th><th></th>
											</tr>
										</thead>
										<tbody>
											<tr className="white-background">
												<td>
													<div className="form-group">
														<label htmlFor="motorType">Type</label>
														<Input
															type="text"
															className="form-control"
															name="motorType"
															value={motorType}
															onChange={e => {
																setMotorType(e.target.value);
																setIAmTyping(true);
															}}

														/>
													</div>
												</td>
												<td>
													<div className="form-group">
														<label htmlFor="motorRatedPower">Rated Power (W)</label>
														<Input
															type="text"
															className="form-control"
															name="motorRatedPower"
															value={motorRatedPower}
															onChange={e => {
																setMotorRatedPower(e.target.value);
																setIAmTyping(true);
															}}

														/>
													</div>
												</td>
												<td>
													<div className="form-group">
														<label htmlFor="motorRatedRPM">Torque (Nm)</label>
														<Input
															type="text"
															className="form-control"
															name="motorRatedRPM"
															value={motorRatedRPM}
															onChange={e => {
																setMotorRatedRPM(e.target.value);
																setIAmTyping(true);
															}}

														/>
													</div>
												</td>
												<td>
													<div className="form-group">
														<label htmlFor="motorRatedVoltage">Rated Voltage (V)</label>
														<Input
															type="text"
															className="form-control"
															name="motorRatedVoltage"
															value={motorRatedVoltage}
															onChange={e => {
																setMotorRatedVoltage(e.target.value);
																setIAmTyping(true);
															}}

														/>
													</div>
												</td>
											</tr>

										</tbody>
									</table>


									<table className="styled-table" style={{ width: "100%" }}>
										<thead>
											<tr>
												<th style={{ width: "33%" }}>Inventories</th>
												<th style={{ width: "2%" }}></th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													<Select className="form-control" name="inventory" value={inventory._id || ""} onChange={e => { setInventoryDetails(e.target.value); setIAmTyping(true); }}>
														<option value=""></option>
														{inventoriesOptions.map((item, index) =>
															<option value={item._id}>{item.number} - {item.label} - {item.type}</option>
														)}
													</Select>
												</td>
												<td>

													{/*<a href="javascript:void(0)" className="mr10" onClick={() => updateInventories()}>Add</a> */}
												</td>
											</tr>
											{inventories && inventories.map(function (inv, index) {
												if (inv) {
													return (
														<tr key={index}>
															<td>
																{inv.number} - {inv.label} - {inv.type}
															</td>
															<td>

																<a
																	className="mr10" href="javascript:void(0)"
																	onClick={() => removeInventory(index)}>
																	Remove
																</a>

															</td>
														</tr>)

												}

											})}





										</tbody>
									</table>

								</>
								: ""}

							<div className="form-group">
								<button className="btn btn-primary btn-block" onClick={handleSaveModel}>Save</button>
							</div>
						</div>
					)}

					{message && (
						<div className="form-group">
							<div
								className={
									successful ? "alert alert-success" : "alert alert-danger"
								}
								role="alert"
							>
								{message}
							</div>
						</div>
					)}
					<CheckButton style={{ display: "none" }} ref={checkBtn} />
				</Form>

			</div>
		</Fragment>
	);
};

export default ModelForm;
