import React, { useEffect } from "react";
import Header from "../components/Header";
import "../css/careers.css";
import CareersImage from "../assets/images/Careers.jpg";
import { trackPage } from "../components/GoogleAnalytics";
//import CareersPDF from '../assets/pdf/partTime-Mechanic.pdf'

export default function Careers() {
  useEffect(() => {
    window.scrollTo(0, 0);
    trackPage("Careers");
  }, []);
  return (
    <div>
      <Header />
      <div className="isBrowser">
        <br />
        <br />
      </div>

      <h2 style={{ color: "#595959", textAlign: "center" }}>Careers</h2>

      <div className="landing_Careers_section">
        <p>
          Are you just as excited as us about innovative and eco-friendly
          solutions and ambitious to grow with us? We are always on the look for
          talent that fits our team. Check the current job opportunities on our
          LinkedIn profile or send an open application with your motivation
          letter and CV to hello@wave.bike.
          {/*<ul>
                        <li>
                            <a href={CareersPDF} target={"_blank"}>Job opportunity for Maintenance mechanic (part time)</a>
                        </li>
    </ul>*/}
        </p>
      </div>
    </div>
  );
}
