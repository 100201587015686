import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const getPublicInfo = () => {
  return axios.get("https://ipapi.co/json/").then((response) => {
    return response;
  });
};
const getAvailableChargers = (branch, source) => {
  return axios.get(
    API_URL + "/charger/available?branch=" + branch + "&source=" + source,
    { headers: authHeader() }
  );
};

const getFormsList = (branch, typeFilter) => {
  return axios.get(
    API_URL +
      "/survey/form/list?branch=" +
      branch +
      "&typeFilter=" +
      typeFilter,
    { headers: authHeader() }
  );
};
const getAvailableBats = (branch, source) => {
  return axios.get(
    API_URL + "/bat/available?branch=" + branch + "&source=" + source,
    { headers: authHeader() }
  );
};
const getAvailableBikes = (branch, source, onlyNumber, action) => {
  return axios.get(
    API_URL +
      "/bike/available?branch=" +
      branch +
      "&source=" +
      source +
      "&onlyNumber=" +
      onlyNumber +
      "&action=" +
      action,
    { headers: authHeader() }
  );
};

const getInvoicesList = (
  bikerId,
  onlySent,
  branch,
  invType,
  onlyList,
  offset,
  limit,
  keyword,
  start,
  end,
  paid,
  number,
  period,
  plan,
  isDraft,
  searchByFilter
) => {
  return axios.get(
    API_URL +
      "/invoice/list?bikerId=" +
      bikerId +
      "&onlySent=" +
      onlySent +
      "&branch=" +
      branch +
      "&invType=" +
      invType +
      "&offset=" +
      offset +
      "&limit=" +
      limit +
      "&onlyList=" +
      onlyList +
      "&keyword=" +
      keyword +
      "&start=" +
      start +
      "&end=" +
      end +
      "&paid=" +
      paid +
      "&number=" +
      number +
      "&period=" +
      period +
      "&plan=" +
      plan +
      "&isDraft=" +
      isDraft +
      "&searchByFilter=" +
      searchByFilter,
    { headers: authHeader() }
  );
};

const getSubscriptionsList = (
  bikerId,
  noInvoice,
  branch,
  offset,
  limit,
  sent,
  keyword,
  nextRenewal,
  statusFilter,
  planFilter,
  start,
  end,
  source
) => {
  return axios.get(
    API_URL +
      "/admin/usersubscribe/list?bikerId=" +
      bikerId +
      "&noInvoice=" +
      noInvoice +
      "&branch=" +
      branch +
      "&offset=" +
      offset +
      "&limit=" +
      limit +
      "&sent=" +
      sent +
      "&keyword=" +
      keyword +
      "&nextRenewal=" +
      nextRenewal +
      "&statusFilter=" +
      statusFilter +
      "&planFilter=" +
      planFilter +
      "&startDate=" +
      start +
      "&endDate=" +
      end +
      "&source=" +
      source,
    { headers: authHeader() }
  );
};
const getBikerList = (branch, searchKey, isSearchArray) => {
  return axios.get(
    API_URL +
      "/admin/subscribers/list?branch=" +
      branch +
      "&searchKey=" +
      searchKey +
      "&isSearchArray=" +
      isSearchArray,
    { headers: authHeader() }
  );
};

const getBikeList = (branch) => {
  return axios.get(API_URL + "/admin/bikes/options?branch=" + branch, {
    headers: authHeader(),
  });
};
const getPlansList = (branch, parentBranch = "") => {
  return axios.get(
    API_URL +
      "/plan/options?branch=" +
      branch +
      "&parentBranch=" +
      parentBranch,
    { headers: authHeader() }
  );
};

const getModelsList = (label, branch, offset, limit, onlyList, type) => {
  return axios.get(
    API_URL +
      "/model/list?label=" +
      label +
      "&branch=" +
      branch +
      "&offset=" +
      offset +
      "&limit=" +
      limit +
      "&onlyList=" +
      onlyList +
      "&type=" +
      type,
    { headers: authHeader() }
  );
};

const getLastSubscribtion = (id) => {
  return axios.get(API_URL + "/biker/last/subscribe/" + id, {
    headers: authHeader(),
  });
};

const getCountries = () => {
  return axios.get(API_URL + "/country/list/", { headers: authHeader() });
};

const getDefaultPlanByCountry = (country) => {
  return axios.get(API_URL + "/default/plan?country=" + country, {
    headers: authHeader(),
  });
};

const getJourneys = (offset, limit, userId) => {
  return axios.get(
    API_URL +
      "/journey/list?platform=true&offset=" +
      offset +
      "&limit=" +
      limit +
      "&userId=" +
      userId,
    { headers: authHeader() }
  );
};
const getBranchJourneyQuestions = (branchId) => {
  return axios.get(API_URL + "/branch/survey", { headers: authHeader() });
};
const getSuppliers = (branch) => {
  return axios.get(
    API_URL +
      "/company/list?branch=" +
      branch +
      "&isSupplier=true&onlyList=true",
    { headers: authHeader() }
  );
};

const getCashlogCategories = (branch) => {
  return axios.get(API_URL + "/cashLog/categories?branch=" + branch, {
    headers: authHeader(),
  });
};

const getCategories = (branch, usage) => {
  return axios.get(
    API_URL + "/cashLog/categories/list?branch=" + branch + "&usage=" + usage,
    { headers: authHeader() }
  );
};

const getBranchAccounts = (branch) => {
  return axios.get(API_URL + "/branch/accounts/" + branch, {
    headers: authHeader(),
  });
};
const getAccountantTransactions = (start, end, categoryFilter) => {
  return axios.get(
    API_URL +
      "/transactions/import?pass=logs1234&returnedType=obj&start=" +
      start +
      "&end=" +
      end +
      "&categoryFilter=" +
      categoryFilter,
    { headers: authHeader() }
  );
};

const getAccountantInvoices = (start, end, invoiceType, categoryFilter) => {
  return axios.get(
    API_URL +
      "/invoices/import?pass=12345&returnedType=obj&start=" +
      start +
      "&end=" +
      end +
      "&invoiceType=" +
      encodeURIComponent(JSON.stringify(invoiceType)) +
      "&categoryFilter=" +
      categoryFilter,
    { headers: authHeader() }
  );
};

export default {
  getAvailableChargers,
  getAvailableBats,
  getAvailableBikes,
  getInvoicesList,
  getSubscriptionsList,
  getBikerList,
  getBikeList,
  getPlansList,
  getModelsList,
  getLastSubscribtion,
  getPublicInfo,
  getCountries,
  getDefaultPlanByCountry,
  getJourneys,
  getFormsList,
  getBranchJourneyQuestions,
  getSuppliers,
  getCashlogCategories,
  getBranchAccounts,
  getAccountantTransactions,
  getAccountantInvoices,
  getCategories,
};
