import React, { useEffect, useState } from "react";

export default function PullDown(props) {
  const filterkey = props.filterkey || "_id";
  const label = props.label;
  const defaultValue = props.defaultValue;
  const options = props.options;
  const pulldownFor = props.pulldownFor;
  const setValue = props.setValue;
  const extraClass = props.extraClass || "";
  const multiple = props.multiple || false;
  const labelInside = props.labelInside || false;
  const isRequired = props.isRequired || "";
  const labelRef = props.labelRef;
  const validationArr = props.validationArr || [];
  const forceBlank = props.forceBlank || false;
  const labelOption = props.labelOption || false;
  const helmet = props.helmet || false;
  const noOverWrite = props.noOverWrite || false;
  const pulldownName = props.pulldownName || "common";
  const blankLabel = props.blankLabel || "";
  let option1 = "";
  if (forceBlank === true) {
    option1 = <option value="">{blankLabel}</option>;
  } else if (labelOption) {
    option1 = (
      <option disabled="disabled" value="">
        {label} *
      </option>
    );
  }

  const handleOptionChange = async (e) => {
    if (noOverWrite) {
      setValue(e);
    } else {
      if (multiple) {
        setValue(
          Array.from(e.target.selectedOptions, (option) => option.value)
        );
      } else {
        setValue(e.target.value);
      }
    }
  };
  let selectPulldown = (
    <select
      name={pulldownName}
      autoComplete="new-password"
      className={"form-control " + extraClass}
      defaultValue={defaultValue}
      value={defaultValue}
      placeholder={label}
      onChange={(e) => handleOptionChange(e)}
      required={isRequired}
      ref={labelRef}
      validations={validationArr}
      multiple={multiple}
    >
      {option1}
      {options &&
        options.length > 0 &&
        options.map((m) => (
          <option
            value={m[filterkey] || m["key"]}
            selected={
              (multiple &&
                Array.isArray(defaultValue) &&
                defaultValue.includes(m)) ||
              (!multiple &&
                (defaultValue === m[filterkey] || defaultValue === m["key"]))
            }
          >
            {pulldownFor === "company"
              ? m.name
              : pulldownFor === "package"
              ? m.number
              : pulldownFor === "packages"
              ? m.title
              : m.label}
            {helmet ? " - " + m.label : ""}
          </option>
        ))}
    </select>
  );

  if (!labelInside) {
    return selectPulldown;
  } else {
    return (
      <div className="form-group">
        <label htmlFor={defaultValue}>{label}</label>
        {selectPulldown}
      </div>
    );
  }
}
