import React, { useState, useEffect, useRef } from "react";
import SurveyService from "../services/survey.service";
import Input from "react-validation/build/input";
import { required } from '../common/Validation';
import '../LandingPage/css/about.css';
import Header from "../LandingPage/components/Header";
import AuthService from "../services/auth.service";
import 'rc-slider/assets/index.css';
import '../assets/css/survey.css';
import QuestionsView from "../commonComponents/QuestionsView";

const SurveyQuestionsView = (props) => {
    const formId = props.match.params.id;
    const [label, setLabel] = useState("Survey");

    useEffect(() => {

        function onReady() {
            window.scroll(0, 0);
        }

        onReady()

    }, [])




    const currentUser = AuthService.getCurrentUser();

    return (
        <>
            <Header title={label} extraClass={currentUser ? "SurveyHeader" : ""} />
            <div className='landing_About_section' style={{ paddingBottom: 40 }}>
                <div className="card-1 card-2">
                    <div className="card-body">
                        <QuestionsView formId={formId} setLabel={setLabel} />
                    </div>
                </div>
            </div>


        </>
    )
}

export default SurveyQuestionsView;