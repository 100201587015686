import React, { useState, useEffect } from 'react';
import FollowupService from "../services/followup.service.js";
import EventBus from "../common/EventBus";
import { getBranches } from "../common/getFunctions";
import DateFromatter from '../common/DateFromatter.js';
import { NavLink } from "react-router-dom";
import Loader from "../commonComponents/Loader";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Functions from "../common/Functions.js";
import InternalNoteComponent from "../commonComponents/InternalNoteComponent";
import InternalNoteModal from '../commonComponents/InternalNoteModal.jsx';
const currentUser = JSON.parse(localStorage.getItem('user')) || null;


export default function FollowUp() {
    const [loading, setLoading] = useState(true);
    const [weekDays, setWeekDays] = useState([]);
    const [monthDays, setMonthDays] = useState([]);
    //const [month, setMonth] = useState("");
    const [months, setMonths] = useState([]);
    const [cols, setCols] = useState([[], [], [], [], [], [], []]);
    const [finalCols, setFinalCols] = useState([]);
    const [overDue, setOverDue] = useState(true);
    const [birthday, setBirthday] = useState(true);
    const [pickupsConfirmed, setPickupsConfirmed] = useState(true);
    const [followups, setFollowups] = useState(true);
    const [reminders, setReminders] = useState(true);
    const [seventhDay, setSeventhDay] = useState(true);
    const [branchFilter, setBranchFilter] = useState("");
    const [branches, setBranches] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [startDateFilter, setStartDateFilter] = useState("");
    const [renewals, setRenewals] = useState(true);
    const [followupDone, setFollowupDone] = useState(true);
    const [todayPayment, setTodayPayment] = useState(true);
    const [statusFilter, setStatusFilter] = useState("");
    const [internalNoteModal, setInternalNoteModal] = useState(false);
    const [selectedUserByNote, setSelectedUserByNote] = useState("");
    const [oldInternalNote, setOldInternalNote] = useState("");
    const [packageReminders, setPackageReminders] = useState(true);
    const [packageDelays, setPackageDelays] = useState(true);
    const [companyInternalNoteModal, setCompanyInternalNoteModal] = useState(false);


    useEffect(() => {

        async function onReady() {
            window.scrollTo(0, 0);
            setBranches(await getBranches());
            getCurrentDate();
            const info = await getDays(new Date());
            fillLists(info, cols, new Date());


        }

        onReady()

    }, []);

    const getFinalCols = (max) => {
        let finalCols = [];
        for (let i = 0; i < max; i++) {
            finalCols.push(i);
        }
        setFinalCols(finalCols);

    }

    const getLongestArray = (columns) => {
        let max = 0;
        for (let i = 0; i < columns.length; i++) {
            if (columns[i].length > max) max = columns[i].length;
        }
        setCols(columns);
        return max;
    }
    
	const getFollowups = async (subUrl) => {
        let res = await FollowupService.getFollowups(subUrl).then(
            (response) => {

                return response.data.data;
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                alert(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");

                }
            }
        );
        return res;
    }

    const getDays = async (d) => {
        const daysNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        const monthsNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let currentWeekDay = d.getDay() - 1;
        let weekDays = [];
        let monthDays = [];
        let months = [];
        for (let i = 0; i < 7; i++) {
            if (currentWeekDay > 6) {
                currentWeekDay = 0;
            }
            weekDays.push(daysNames[currentWeekDay++]);
            let newDate = DateFromatter.addDays(d, i).split("-");
            monthDays.push(newDate[2]);
            months.push(monthsNames[newDate[1] - 1]);
        }
        let info = { "weekDays": weekDays, "monthDays": monthDays, "months": months };
        setWeekDays(weekDays);
        setMonthDays(monthDays);
        setMonths(months);
        return info;
    }

    const handleStartDateFilterChange = async (d) => {
        setStartDateFilter(d);
        let date = d.split("-");
        let year = date[0];
        let month = date[1] - 1;
        let day = date[2];
        let startDate = new Date(year, month, day);
        setStartDate(startDate);
        const columns = [[], [], [], [], [], [], []];
        setFinalCols([]);
        const info = await getDays(startDate);
        await fillLists(info, columns, startDate);
    }

    const previousDateHandler = async () => {
        const previousDate = startDate;

        previousDate.setDate(previousDate.getDate() - 1);
        setStartDate(previousDate);

        const columns = [[], [], [], [], [], [], []];
        setFinalCols([])
        const info = await getDays(previousDate);
        await fillLists(info, columns, previousDate);
    }

    const nextDateHandler = async () => {
        const nextDate = startDate;

        nextDate.setDate(nextDate.getDate() + 1);
        setStartDate(nextDate);
        const columns = [[], [], [], [], [], [], []];
        setFinalCols([])
        const info = await getDays(nextDate);
        await fillLists(info, columns, nextDate);
    }

    const fillLists = async (info, columns, startDate) => {
        setLoading(true);
        await getFollowups('birthdays?1=1&startDate=' + startDate).then(async (res) => setCols(await Functions.fillList("birthdays", 'Birthday', 'fas fa-birthday-cake icon-color-calendar', res.list, info, columns)));
        await getFollowups('pickups?waitingStatus=Confirmed&startDate=' + startDate).then(async (res) => setCols(await Functions.fillList("confirmedPickups", "Pickup", "pickupDate fas fa-biking", res.list, info, columns)));
        await getFollowups('pickups?waitingStatus=Follow up&startDate=' + startDate).then(async (res) => setCols(await Functions.fillList("followups", "Reservation Follow Up", "followup fas fa-calendar-alt icon-color-calendar", res.list, info, columns)));
        await getFollowups('followup?action=reminder&startDate=' + startDate).then(async (res) => setCols(await Functions.fillList("renewal", "Renewal Reminder", "fas fa-bell icon-color-calendar", res.list, info, columns)));
        await getFollowups('followup?1=1&startDate=' + startDate).then(async (res) => setCols(await Functions.fillList("seventhDay", "7 days Follow Up", "phoneFollow fas fa-phone", res.list, info, columns)));
        await getFollowups('overDue?1=1&startDate=' + startDate).then(async (res) => {
            setCols(await Functions.fillList("overDue", "overDue", "fas fa-clock delayedPayment", res.list.filter(p => p.delay !== 0), info, columns));
            setCols(await Functions.fillList("paymentToday", "Payment Today", "fas fa-file-invoice-dollar icon-color-calendar", res.list.filter(p => p.delay === 0), info, columns));
        });

        await getFollowups('renewals?1=1&startDate=' + startDate).then(async (res) => await Functions.fillList("renewals", "Renewal", "fas fa-exclamation icon-color-calendar", res.list, info, columns));
        await getFollowups('followup?action=packageReminder&startDate=' + startDate).then(async (res) => await Functions.fillList("packageReminder", "Package Reminder", "fas fa-people-carry text-warning", res.list, info, columns));
        await getFollowups('followup?action=packageDelay&startDate=' + startDate).then(async (res) => await Functions.fillList("packageDelay", "Package Delay", "fas fa-people-carry red", res.list, info, columns).then((columns) => getFinalCols(getLongestArray(columns))));

        setLoading(false);
    }

    const getCurrentDate = () => {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        month = ('0' + month).slice("-2");
        day = ('0' + day).slice("-2");
        setStartDateFilter(year + "-" + month + "-" + day);
    }

    const handleAddInternalNoteClick = (id, internalNote, isCompany) => {
        setSelectedUserByNote(id);
        setOldInternalNote(internalNote);
        if (isCompany) setCompanyInternalNoteModal(true);
        else setInternalNoteModal(true);
    }

    const markAsFollowed = async (details) => {
        setCols(await Functions.markAsFollowed(details, cols));
    }

    return (

        <div className="container">
            <InternalNoteModal internalNoteModal={internalNoteModal} setInternalNoteModal={setInternalNoteModal} selectedUserByNote={selectedUserByNote} oldInternalNote={oldInternalNote} list={cols} setList={setCols} isFollowup />
            <InternalNoteModal internalNoteModal={companyInternalNoteModal} setInternalNoteModal={setCompanyInternalNoteModal} selectedUserByNote={selectedUserByNote} oldInternalNote={oldInternalNote} list={cols} setList={setCols} isFollowup isCompany />
            <a className="greenLink mx-0" href="javascript:void(0)" onClick={() => setShowFilters(!showFilters)}>{showFilters ? "Hide" : "Show"} filters</a>&nbsp;&nbsp;&nbsp;
            <table>
                <tr>

                    {showFilters ?
                        <div>
                            <td style={{ paddingRight: 20 }}>
                                {currentUser && currentUser.type === 'admin' ?
                                    <>
                                        <label className="filterLabelInput" htmlFor="branch">Branch</label>

                                        <select
                                            className="form-control filterInput"
                                            value={branchFilter}
                                            onChange={(e) => setBranchFilter(e.target.value)}

                                        >
                                            <option value="" key="0">All</option>
                                            {branches ? branches.map((branch, index) => <option key={index} value={branch._id}>{branch.label}</option>) : null}

                                        </select>
                                    </>
                                    : null}
                            </td>
                            <td style={{ paddingRight: 20 }}>


                                <label className="filterLabelInput" htmlFor="startDateFilter">Start Date</label>

                                <input
                                    type="date"
                                    className="form-control"
                                    value={startDateFilter}
                                    onChange={(e) => handleStartDateFilterChange(e.target.value)}

                                />



                            </td>
                            <td>
                                <label className="filterLabelInput" htmlFor="status">Status</label>

                                <select
                                    className="form-control filterInput"
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}

                                >
                                    <option value="">All</option>
                                    <option value={true}>Followed up</option>
                                    <option value={false}>Not followed up</option>

                                </select>
                            </td>

                            <br />
                            <td valign='bottom'>
                                <label className="myCheckbox">
                                    <input type="checkbox" id="pickupsConfirmed" name="pickupsConfimed" value="pickupsConfirmed" checked={pickupsConfirmed} onClick={() => setPickupsConfirmed(!pickupsConfirmed)} />
                                    Confirmed pickups
                                </label>
                                <label className="myCheckbox">
                                    <input type="checkbox" id="birthday" name="birthday" value="birthday" checked={birthday} onClick={(cb) => setBirthday(!birthday)} />
                                    Birthday
                                </label>
                            </td>
                            <td valign='bottom'>
                                <label className="myCheckbox">
                                    <input type="checkbox" id="overdue" name="overdue" value="overdue" checked={overDue} onClick={(cb) => setOverDue(!overDue)} />
                                    Overdue
                                </label>
                                <label className="myCheckbox">
                                    <input type="checkbox" id="followups" name="followups" value="followups" checked={followups} onClick={(cb) => setFollowups(!followups)} />
                                    Reservation Follow Up
                                </label>
                            </td>
                            <td valign='bottom'>
                                <label className="myCheckbox">
                                    <input type="checkbox" id="reminders" name="reminders" value="reminders" checked={reminders} onClick={(cb) => setReminders(!reminders)} />
                                    <span> Renewal Reminder</span>
                                </label>
                                <label className="myCheckbox">
                                    <input type="checkbox" id="seventhDay" name="seventhDat" value="seventhDay" checked={seventhDay} onClick={(cb) => setSeventhDay(!seventhDay)} />
                                    <span> 7th day follow up</span>
                                </label>
                            </td>
                            <td valign='bottom'>
                                <label className="myCheckbox">
                                    <input type="checkbox" id="renewals" name="renewals" value="renewals" checked={renewals} onClick={(cb) => setRenewals(!renewals)} />
                                    <span> Renewal</span>
                                </label>
                                <label className="myCheckbox">
                                    <input type="checkbox" id="todayPayment" name="todayPayment" value="todayPayment" checked={todayPayment} onClick={(cb) => setTodayPayment(!todayPayment)} />
                                    <span> Payment Today</span>
                                </label>
                            </td>
                            <td valign='bottom'>
                                <label className="myCheckbox">
                                    <input type="checkbox" id="packageReminder" name="packageReminders" value="packageReminders" checked={packageReminders} onClick={(cb) => setPackageReminders(!packageReminders)} />
                                    <span> Package Reminder</span>
                                </label>
                                <label className="myCheckbox">
                                    <input type="checkbox" id="packageDelay" name="packageDelays" value="packageDelays" checked={packageDelays} onClick={(cb) => setPackageDelays(!packageDelays)} />
                                    <span> Package Delay</span>
                                </label>
                            </td>

                        </div> : null}
                </tr>
            </table>
            <div className="followupArrows">
                <a href="javascript:void(0)" onClick={() => previousDateHandler()}>{'<'}</a>&nbsp;&nbsp;
                <span>{monthDays[0]}{' '}{months[0]}{' - '}{monthDays[6]}{' '}{months[6]}</span>&nbsp;&nbsp;
                <a href="javascript:void(0)" onClick={() => nextDateHandler()}>{'>'}</a>
            </div>
            {!loading ? <div className="table-responsive">
                <table className="table table-bordered text-center styled-table">
                    <thead>
                        <tr className="bg-light-gray text-center">
                            {/* <th className="text-uppercase">Time</th> */}
                            <th className="text-uppercase">{weekDays[0]}<br />{monthDays[0]}{' '}{months[0]}</th>
                            <th className="text-uppercase">{weekDays[1]}<br />{monthDays[1]}{' '}{months[1]}</th>
                            <th className="text-uppercase">{weekDays[2]}<br />{monthDays[2]}{' '}{months[2]}</th>
                            <th className="text-uppercase">{weekDays[3]}<br />{monthDays[3]}{' '}{months[3]}</th>
                            <th className="text-uppercase">{weekDays[4]}<br />{monthDays[4]}{' '}{months[4]}</th>
                            <th className="text-uppercase">{weekDays[5]}<br />{monthDays[5]}{' '}{months[5]}</th>
                            <th className="text-uppercase">{weekDays[6]}<br />{monthDays[6]}{' '}{months[6]}</th>
                        </tr>
                    </thead>
                    <tbody>



                        {!loading && finalCols && finalCols.length > 0 ? finalCols.map((index) => (
                            <tr key={index}>
                                {cols && cols.length > 0 && cols.map((c, i) =>
                                    c[index] && c[index] && ((overDue && c[index].title === "overDue") || (birthday && c[index].title === "Birthday") || (pickupsConfirmed && c[index].title === "Pickup") || (followups && c[index].title === "Reservation Follow Up") || (reminders && c[index].title === "Renewal Reminder") || (seventhDay && c[index].title === "7 days Follow Up") || (renewals && c[index].title === "Renewal") || (todayPayment && c[index].title === "Payment Today") || (packageReminders && c[index].title === "Package Reminder") || (packageDelays && c[index].title === "Package Delay")) && (branchFilter === c[index].branch || branchFilter === "") && ((statusFilter === "true" && c[index].followupDone) || (statusFilter === "false" && c[index].followupDone === false) || statusFilter === "") ?
                                        <td className='bg-white' valign="top" style={{ padding: 0, align: "top" }}>
                                            <table border="0" style={{ padding: 0, margin: 0, width: "100%" }}  >
                                                <tr className="white-background" >
                                                    <td align="right" style={{ padding: 0, align: "top", border: 0 }}><input type="checkbox" onClick={(e) => markAsFollowed(c[index].details)} checked={c[index].followupDone} /></td>
                                                </tr>
                                                <tr className="white-background"  >
                                                    <td style={{ border: 0 }} >
                                                        {c[index].details.user ?


                                                            <InternalNoteComponent list={cols} setList={setCols} oldInternalNote={c[index].details.user.internalNote || ""} selectedUserByNote={c[index].details.user._id} clickable={false} note={c[index].details.note || ""} rentedAcc={c[index].details.rentedAcc || ""} />


                                                            :
                                                            c[index].details.company ?


                                                                <InternalNoteComponent list={cols} setList={setCols} oldInternalNote={c[index].details.company.internalNote || ""} selectedCompanyByNote={c[index].details.company._id} clickable={false} note={c[index].details.note || ""} rentedAcc={c[index].details.rentedAcc || ""} />


                                                                : ""}
                                                        <div className="font-size13 text-light-gray leftAlign"><i className={"font-size25 " + c[index].styles}></i> <span className="followupTitle">{c[index].title}</span></div>
                                                        <div className="margin-10px-top font-size14 leftAlign"><NavLink className="text-dark" target="_blank" to={c[index].userId ? "/user/" + c[index].userId : "/company/" + c[index].companyId}>{c[index] ? c[index].name : "Unknown"}</NavLink></div>

                                                        <div className="margin-10px-top font-size14 leftAlign">{c[index].title === "Birthday" ? DateFromatter.getAge(c[index].details.birthday) + " years old" : c[index].title === "overDue" || c[index].title === "Package Delay" ? c[index].details.delay + " days delay" : c[index].title === "Renewal Reminder" ? <><span className='smallText'>Renewal:</span> {DateFromatter.DateFormatterOne(c[index].details.nextRenewal)}</> : null}</div>
                                                        {c[index].title !== "Birthday" ? <div className="margin-10px-top font-size14 leftAlign"><span className="smallText">tel:&nbsp;</span> {c[index].details.user ? c[index].details.user.phone : c[index].details.phone}</div> : null}

                                                        {c[index].details.planTitle && c[index].details.planTitle !== "" ? <div className="margin-10px-top font-size14 leftAlign"><span className="smallText">Plan:&nbsp;</span> {c[index].details.planTitle}</div> : null}

                                                        {c[index].lastFollowup && c[index].lastFollowup !== "" ? <div className="margin-10px-top font-size14 leftAlign"><span className="smallText">Last Followup:&nbsp;</span> {DateFromatter.DateFormatterOne(c[index].lastFollowup)}</div> : null}

                                                        {(c[index].title === "Package Reminder" || c[index].title === "Package Delay") && c[index].details && c[index].details.lastDate ? <div className="margin-10px-top font-size14 leftAlign"><span className="smallText">Last Date:&nbsp;</span> {DateFromatter.DateFormatterOne(c[index].details.lastDate)}</div> : null}

                                                        {c[index].details.subscribeId ? <div className="margin-10px-top font-size14 leftAlign"><NavLink className="text-dark" target="_blank" to={"/subscription/" + c[index].details.subscribeId}>Subscription Details</NavLink></div> : ""}

                                                        {
                                                            c[index].title === "Pickup" ?

                                                                <div className="margin-10px-top font-size14 leftAlign"><NavLink className="text-dark" target="_blank" to={"/subscription/new?branch=" + c[index].branch + "&user=" + c[index].userId} >Create Subscription</NavLink></div> : ""
                                                        }
                                                        {c[index].details ?
                                                            c[index].details.user ?
                                                                <div className="leftAlign">
                                                                    <button className="linkBtn greenLink mx-0 leftAlign" onClick={() => handleAddInternalNoteClick(c[index].details.user._id, c[index].details.user.internalNote)}>Add an internal note</button>
                                                                </div>
                                                                :
                                                                c[index].details.company ?
                                                                    <div className="leftAlign">
                                                                        <button className="linkBtn greenLink mx-0 leftAlign" onClick={() => handleAddInternalNoteClick(c[index].details.company._id, c[index].details.company.internalNote, true)}>Add an internal note</button>
                                                                    </div>
                                                                    : ""
                                                            : ""
                                                        }
                                                    </td>
                                                </tr>
                                            </table>

                                        </td>
                                        :
                                        <td></td>
                                )
                                }
                            </tr>
                        )
                        )
                            : null
                        }






                    </tbody>
                </table>
            </div> : <Loader />}
        </div >
    );
}