import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/logo.png";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import "../css/navBar.css";
import NavBarNavLink from "../components/NavBarNavLink";
import StayUpdatedModal from "./StayUpdatedModal";

export default function NavBar() {
  const [modalStayUpdated, setModalStayUpdated] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const menuBlur = async (e) => {
    if (
      e &&
      e.relatedTarget &&
      e.relatedTarget.attributes &&
      e.relatedTarget.attributes.getNamedItem("ismenu") &&
      e.relatedTarget.attributes.getNamedItem("ismenu").value === "true"
    ) {
      return;
    }
    setExpanded(false);
  };
  return (
    <div>
      <Navbar
        id="landing_NavBar"
        expand="lg"
        expanded={expanded}
        onToggle={() => setExpanded(!expanded)}
        onBlur={(e) => menuBlur(e)}
      >
        <div id="nav_conta">
          <Container className="landing_NavBar_container">
            <NavLink to="/" id="landing_NavBar_logo">
              <img src={logo} alt="Wave Logo" />
            </NavLink>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              id="landing_NavBar_button"
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="menuWaveNav">
                {/* <Nav.Link onClick={() => setExpanded(false)} ><NavBarNavLink path="/whywave" text="WHY WAVE" exact /></Nav.Link>*/}

                <Nav.Link onClick={() => setExpanded(false)}>
                  <NavBarNavLink path="/about" text="ABOUT US" exact />
                </Nav.Link>
                <Nav.Link onClick={() => setExpanded(false)}>
                  <NavBarNavLink path="/wavebike" text="OUR BIKE" exact />
                </Nav.Link>
                <Nav.Link onClick={() => setExpanded(false)}>
                  <NavBarNavLink path="/rides" text="RIDES" exact />
                </Nav.Link>
                <Nav.Link onClick={() => setExpanded(false)}>
                  <NavBarNavLink
                    path="/shortTermRent"
                    text="SHORT TERM RENT"
                    exact
                  />
                </Nav.Link>

                <Nav.Link onClick={() => setExpanded(false)}>
                  <NavBarNavLink
                    path="/csr"
                    text="CSR"
                    exact
                    onClick={() => setExpanded(false)}
                  />
                </Nav.Link>
                <Nav.Link onClick={() => setExpanded(false)}>
                  <NavBarNavLink
                    path="/corporate"
                    text="CORPORATE"
                    exact
                    onClick={() => setExpanded(false)}
                  />
                </Nav.Link>
                {/*<Nav.Link className='style_nav_mobile' onClick={() => setExpanded(false)} ><NavBarNavLink  path="/#landing_home_fees" text="FEES" exact onClick={() => setExpanded(false)} /></Nav.Link>*/}
                {/* <Nav.Link
                            className="style_nav_mobile"
                            onClick={() => setExpanded(false)}
                        >
                            <NavBarNavLink
                                path="/careers"
                                text="CAREERS"
                                exact
                                onClick={() => setExpanded(false)}
                            />
                        </Nav.Link> */}

                <Nav.Link
                  className="style_nav_mobile"
                  onClick={() => setExpanded(false)}
                >
                  <NavBarNavLink
                    path="/faq"
                    text="FAQ"
                    exact
                    onClick={() => setExpanded(false)}
                  />
                </Nav.Link>

                <Nav.Link onClick={() => setExpanded(false)}>
                  <NavBarNavLink
                    activeClass="loginRegister"
                    inactiveClass="loginRegisterInactive"
                    path="/login"
                    text="LOGIN"
                    exact
                    onClick={() => setExpanded(false)}
                  />
                </Nav.Link>

                <StayUpdatedModal
                  show={modalStayUpdated}
                  onHide={() => setModalStayUpdated(false)}
                />
              </Nav>
              <Nav className="subscribeNav">
                <Nav.Link onClick={() => setExpanded(false)}>
                  <NavBarNavLink
                    activeClass="loginRegister"
                    inactiveClass="loginRegisterInactive"
                    extraClass="subscribeLink"
                    path="/signup"
                    text="Subscribe Now"
                    exact
                    onClick={() => setExpanded(false)}
                  />
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </div>
      </Navbar>
    </div>
  );
}
