import React from "react";
import SubscriptionsByUser from "../../commonComponents/SubscriptionsByUser";

const SubscriptionsSection = ({
  userId,
  subscriptionList,
  firstName,
  lastName,
  activeTab,
}) => {
  return (
    <div
      id="subscriptions"
      className={`tab-content ${
        activeTab === "subscriptions" ? "expanded" : "collapsed"
      }`}
    >
      <h2>Subscriptions</h2>
      {subscriptionList.length > 0 ? (
        <SubscriptionsByUser
          userId={userId}
          list={subscriptionList}
          userFullName={`${firstName} ${lastName}`}
          isUserMenu
        />
      ) : (
        <p>There are no subscriptions.</p>
      )}
    </div>
  );
};

export default SubscriptionsSection;
