import React, { useEffect, useState } from "react";

import AnalyticsService from "../services/analytics.service";
import EventBus from "../common/EventBus";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap";
import Loader from "../commonComponents/Loader";
import { CSVLink } from "react-csv";
import DateFormatter from "../common/DateFromatter";
import commonFunctions from "../common/Functions";
import BubbleChart from "@weknow/react-bubble-chart-d3";
import ErrorBoundary from "../common/ErrorBoundary";
import * as labels from "../labels/en";
const AnalyticsTermination = () => {
  const monthsNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const lastYearDate = new Date(
    new Date().getFullYear() - 1,
    new Date().getMonth(),
    new Date().getDate()
  );
  const [fromDate, setFromDate] = useState(lastYearDate);
  const [toDate, setToDate] = useState(new Date());
  const [keyword, setKeyword] = useState("");
  const [repeatedWords, setRepeatedWords] = useState([]);
  const [terminationData, setTerminationData] = useState([]);
  const [terminationLoading, setTerminationLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const terminationHeader = [
    { label: "Waver", key: "waver" },
    { label: "Date", key: "terminateDate" },
    { label: "Reason", key: "terminateReason" },
  ];
  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      await triggerSearch(lastYearDate, new Date(), "");
    }

    onReady();
  }, []);

  const triggerSearch = async (fromDateT, toDateT, keyword) => {
    if (fromDateT) {
      await getTerminateReasons(fromDateT, toDateT, keyword);
    }
  };

  const getTerminateReasons = async (fromDate, toDate, keyword) => {
    setTerminationLoading(true);

    AnalyticsService.getTerminateReasons(fromDate, toDate, keyword).then(
      (response) => {
        let data = response.data.data.list;
        let sentence = "";
        setTerminationData(data);
        for (const d of data) {
          sentence += d.terminateReason + " ";
        }
        setRepeatedWords(commonFunctions.countRepeatedWords(sentence, 1000)); // max words to return
        setTerminationLoading(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        alert(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
        setTerminationLoading(false);
        return _content;
      }
    );
  };

  const resetSearch = async () => {
    setFromDate(lastYearDate);
    setToDate(new Date());
    setKeyword("");
    await triggerSearch(lastYearDate, new Date(), "");
  };
  const getKeywordsOrigin = (label) => {
    console.log("label is: ", label);
    setKeyword(label);
    triggerSearch(fromDate, toDate, label);
  };

  return (
    <div>
      <h3>Terminations (For Monthly)</h3>
      <div className="filterContainer">
        <div className="d-flex flex-row">
          <Col lg={3} className="p-0">
            <label
              htmlFor="keyword"
              className="filterLabelInput maintenanceLabels"
            >
              Keyword
            </label>
            <input
              type="text"
              className="form-control filterInput2"
              name="keyword"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </Col>
          <Col lg={3} className="p-0">
            <label
              htmlFor="start"
              className="filterLabelInput maintenanceLabels"
            >
              From
            </label>
            <DatePicker
              autoComplete="off"
              dateFormat="MM-yyyy"
              className="form-control filterInput2"
              name="start"
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              showMonthYearPicker
            />
          </Col>
          <Col lg={3} className="p-0">
            <label htmlFor="to" className="filterLabelInput maintenanceLabels">
              To
            </label>
            <DatePicker
              autoComplete="off"
              dateFormat="MM-yyyy"
              className="form-control filterInput2"
              name="end"
              selected={toDate}
              onChange={(date) => setToDate(date)}
              showMonthYearPicker
            />
          </Col>
          <Col lg={3} className="p-0">
            <div style={{ marginTop: 35 }} border="0">
              <div className="d-flex flex-row align-items-start">
                <button
                  onClick={(e) => triggerSearch(fromDate, toDate, keyword)}
                  className="btn btn-primary search-button"
                >
                  Go
                </button>
                <button
                  onClick={(e) => resetSearch(e)}
                  className="btn btn-primary reset-button"
                >
                  Reset
                </button>
              </div>
            </div>
          </Col>
        </div>
      </div>
      <br />
      {terminationLoading && <Loader />}
      {repeatedWords && repeatedWords.length ? (
        <>
          <h5 style={{ paddingLeft: 50 }}>Most Repeated words</h5>
          <div className="floatRight ">
            <a
              href="javascript:void(0)"
              className="text-dark midText"
              onClick={() => setShowDetails(!showDetails)}
            >
              {showDetails ? "Hide" : "Show"} Details
            </a>
          </div>
          <div className="floatRight ">
            <CSVLink
              data={terminationData}
              headers={terminationHeader}
              filename={
                fromDate.getMonth() +
                1 +
                "-" +
                fromDate.getFullYear() +
                "" +
                " to " +
                (toDate.getMonth() + 1) +
                "-" +
                toDate.getFullYear() +
                "-" +
                "terminations.csv"
              }
            >
              <i alt="View" title="View" className="fa fa-download"></i> Export
            </CSVLink>
          </div>

          <div className="bubble-chart-container-analtycis">
            <ErrorBoundary message="Problem in index.render: BubbleChart">
              <BubbleChart
                graph={{
                  zoom: 0.4,
                  offsetX: 0.1,
                  offsetY: 0,
                }}
                width={800}
                height={400}
                showLegend={false} // optional value, pass false to disable the legend.
                valueFont={{
                  family: "Arial",
                  size: 12,
                  color: "#fff",
                  weight: "bold",
                }}
                labelFont={{
                  family: "Arial",
                  size: 14,
                  color: "#fff",
                  weight: "bold",
                }}
                bubbleClickFun={getKeywordsOrigin}
                data={repeatedWords}
              />
            </ErrorBoundary>
          </div>
        </>
      ) : (
        <div className="noResultsFound">{labels.NO_RESULTS}</div>
      )}
      {!terminationLoading && terminationData && terminationData.length != 0 ? (
        showDetails ? (
          <>
            <h5 style={{ paddingLeft: 50 }}>Details</h5>

            <div style={{ paddingLeft: 50, marginTop: 50 }}>
              <table className="styled-table">
                <thead>
                  <tr>
                    <th>Waver</th>
                    <th>Date</th>
                    <th>Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {terminationData && terminationData.length != 0
                    ? terminationData.map((t) => {
                        return (
                          <tr>
                            <td>{t.waver}</td>
                            <td>
                              {DateFormatter.DateFormatterOne(t.terminateDate)}
                            </td>
                            <td>{t.terminateReason}</td>
                          </tr>
                        );
                      })
                    : "No record found"}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          ""
        )
      ) : (
        <div> No records found.</div>
      )}
    </div>
  );
};

export default AnalyticsTermination;
