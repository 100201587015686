import React, { useEffect, useState, useRef, Fragment } from "react";
import SurveyService from "../services/survey.service";
import EventBus from "../common/EventBus";
import Form from "react-validation/build/form";
import Select from "react-validation/build/select";
import { getBranches } from "../common/getFunctions";
import RequiredLabel from "../common/RequiredLabel";
import { required } from "../common/Validation";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import OptionalLabel from "../common/OptionalLabel";
import Switch from "react-switch";
import * as options from '../labels/options_en';
import { Prompt } from "react-router-dom";

const currentUser = JSON.parse(localStorage.getItem('user'));

const SurveyForm = (props) => {
    const form = useRef();
    const checkBtn = useRef();
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");
    const [branches, setBranches] = useState([]);
    const [branch, setBranch] = useState(currentUser.branch);
    const [label, setLabel] = useState("");
    const [saveButton, setSaveButton] = useState("Save");
    const [introductionText, setIntroductionText] = useState("");
    const [type, setType] = useState(props.match.params.type || "");
    const [publicCheckbox, setPublicCheckbox] = useState(false);
    const [disabledCheckbox, setDisabledCheckbox] = useState(false);
    const [iAmTyping, setIAmTyping] = useState(false);
    const surveyFormId = props.match.params.id;

    useEffect(() => {
        async function onReady() {
            window.scrollTo(0, 0);
            setBranches(await getBranches());
            getSurveyFormInfo();
        }

        onReady()
    }, []);

    const getSurveyFormInfo = async () => {
        if (surveyFormId) {
            SurveyService.getSurveyFormById(surveyFormId).then(
                (response) => {

                    let u = response.data;
                    setType(u.type);
                    setBranch(u.branch);
                    setLabel(u.label);
                    setSaveButton(u.saveButton ? u.saveButton : "Save");
                    setPublicCheckbox(u.public);
                    setDisabledCheckbox(u.disabled);
                    setIntroductionText(u.introductionText);
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    alert(_content);
                    setMessage(_content);

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        }

    }


    const handleSaveSurveyForm = (e) => {
        e.preventDefault();
        setIAmTyping(false);
        setSuccessful(false);

        form.current.validateAll();


        if (checkBtn.current.context._errors.length === 0) {

            let data = {
                _id: surveyFormId,
                label: label,
                branch: branch,
                type: type,
                public: publicCheckbox,
                disabled: disabledCheckbox,
                saveButton: saveButton,
                introductionText: introductionText
            }

            SurveyService.createSurveyForm(data).then(
                (response) => {
                    setMessage("Record is saved.");
                    setSuccessful(true);
                    props.history.push("/forms");

                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setMessage(resMessage);
                    setSuccessful(false);
                }
            );

        }
    };


    return (
        <Fragment>
            <Prompt
                when={iAmTyping}
                message={(location) =>
                    'Are you sure, you want to leave? All your data will be lost!'
                }
            />
            <div className="col-md-12 font-20">


                <Form onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} ref={form}>
                    {!successful && (
                        <div>

                            <table className="styled-table" style={{ width: "100%", margin: 'auto' }} cellPadding="10">
                                <thead>
                                    <tr>
                                        <th>Create Survey Form</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr className="white-background">

                                        <td className="grid_container_4">
                                            {
                                                currentUser.type === "admin" ?
                                                    <div className="form-group">
                                                        <label htmlFor="branch">Branch</label>
                                                        <Select
                                                            className="form-control"
                                                            value={branch}
                                                            onChange={e => {
                                                                setBranch(e.target.value);
                                                                setIAmTyping(true);
                                                            }}
                                                            validations={[required]}
                                                        >
                                                            <option value=""></option>
                                                            {branches && branches.map((m, index) => (
                                                                <option key={index} value={m._id}>{m.label}</option>
                                                            ))}

                                                        </Select>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className="form-group">
                                                <RequiredLabel htmlFor="label" text="Public Title" />
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="label"
                                                    value={label}
                                                    onChange={e => {
                                                        setLabel(e.target.value);
                                                        setIAmTyping(true);
                                                    }}
                                                    validations={[required]}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <RequiredLabel htmlFor="saveButton" text="Save Button" />
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="saveButton"
                                                    value={saveButton}
                                                    onChange={e => {
                                                        setSaveButton(e.target.value);
                                                        setIAmTyping(true);
                                                    }}

                                                />
                                            </div>



                                            <div className="form-group">
                                                <RequiredLabel htmlFor="type" text="Type" />

                                                <select
                                                    className="form-control filterInput2"
                                                    value={type}
                                                    onChange={(e) => {
                                                        setType(e.target.value);
                                                        setIAmTyping(true);
                                                    }}

                                                >
                                                    {
                                                        options.form_types.map((item, index) =>
                                                            <option value={item.key}>{item.value}</option>
                                                        )}

                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <RequiredLabel htmlFor="introductionText" text="Introduction Text" />
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    name="introductionText"
                                                    value={introductionText}
                                                    onChange={e => {
                                                        setIntroductionText(e.target.value);
                                                        setIAmTyping(true);
                                                    }}
                                                    cols="30"
                                                    rows="4"
                                                />
                                            </div>





                                            <div className="form-group text-center">
                                                <OptionalLabel htmlFor="bottle_checkbox" text="Public" />
                                                <Switch
                                                    checked={publicCheckbox}
                                                    onChange={() => {
                                                        setPublicCheckbox(!publicCheckbox);
                                                        setIAmTyping(true);
                                                    }}

                                                />
                                            </div>

                                            <div className="form-group text-center">
                                                <OptionalLabel htmlFor="disabled_checkbox" text="Disabled" />
                                                <Switch
                                                    checked={disabledCheckbox}
                                                    onChange={() => {
                                                        setDisabledCheckbox(!disabledCheckbox);
                                                        setIAmTyping(true);
                                                    }}

                                                />
                                            </div>
                                        </td>

                                    </tr>


                                </tbody>
                            </table>


                            <button onClick={(e) => handleSaveSurveyForm(e)} value="save" className="btn btn-primary btn-block">Save</button>

                        </div>
                    )}

                    {message && (
                        <div className="form-group">
                            <div
                                className={
                                    successful ? "alert alert-success" : "alert alert-danger"
                                }
                                role="alert"
                            >
                                {message}
                            </div>
                        </div>
                    )}
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>

            </div>
        </Fragment>
    )
}

export default SurveyForm;