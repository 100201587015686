import React, { useState, useRef, useEffect } from "react";
import Form from "react-validation/build/form";
import queryString from "query-string";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import { required, validEmail, vusername } from "../../common/Validation";
import AuthService from "../../services/auth.service";
import CheckButton from "react-validation/build/button";
import { getBranches } from "../../common/getFunctions";
import { NavLink } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CommonService from "../../services/common.service";
import * as labels from "../../labels/en";
import Functions from "../../common/Functions";
import BranchePullDown from "../../commonComponents/BranchePullDown";
import * as options from "../../labels/options_en";
import "../css/signup.css";
import DatePicker from "react-datepicker";
import Accent from "../components/Accent";
import PlanTypes from "../../commonComponents/PlanTypes";
import { trackPage } from "../components/GoogleAnalytics";
import planService from "../../services/plan.service";

export default function SignUp(props) {
  const form = useRef();
  const checkBtn = useRef();
  let queries = queryString.parse(props.location.search);

  const [waitingList, setWaitingList] = useState(true);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [source, setSource] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successful, setSuccessful] = useState(false);

  const [message, setMessage] = useState("");
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState("");
  const [country, setCountry] = useState("");

  const [phoneCode, setPhoneCode] = useState("");
  const [phoneOnly, setPhoneOnly] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [pickupDate, setPickupDate] = useState("");
  const [plan, setPlan] = useState("");
  const [planType, setPlanType] = useState(queries.planType || "monthly");
  const [planId, setPlanId] = useState(queries.planId || "");
  const [planIndex, setPlanIndex] = useState(queries.index || "");
  const [totalBikes, setTotalBikes] = useState(1);
  const [period, setPeriod] = useState(queries.period || 1);
  const [displayedPlan, setDisplayedPlan] = useState(options.plan_register, []);
  useEffect(() => {
    async function onReady() {
      setBranches(await getBranches("onlyParents"));
      await fetchPlans();
      let response = await CommonService.getPublicInfo();
      if (response) {
        let country = response.data ? response.data.country : "";
        setCountry(country);
      }
    }
    trackPage("Sign up");
    window.scrollTo(0, 0);
    onReady();
  }, []);
  const fetchPlans = async () => {
    return await planService.getPublicPlans().then(({ data }) => {
      const systemPlans = data.data;
      const gatheredPlans = [];
      displayedPlan.map((plan, index) => {
        if (systemPlans[index]) {
          plan.value = systemPlans[index].publicTitle;
        }
        gatheredPlans.push(plan);
        if (index == planIndex) {
          setPlan(plan.key);
        }
      });
      setDisplayedPlan(gatheredPlans);
    });
  };

  const handlePlanChange = (value) => {
    setPlanType(value);
    // show hide fields.
  };

  const handleTotalBikes = (event) => {
    setTotalBikes(event.target.value);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    const newUser = {
      email: email.trim(),
      password: password,
      first_name: first_name,
      last_name: last_name,
      phone: phone,
      isandroid: false,
      branch: branch,
      phoneCode: phoneCode,
      phoneOnly: phoneOnly,
      waitingList: waitingList,
      source: source,
      pickupDate: pickupDate,
      plan: plan,
      planId: planId,
      planType: planType,
      totalBikes: totalBikes,
      period: period,
    };

    if (!planType || planType == "monthly") {
      if (password !== confirmPassword) {
        setMessage("password does not match");
        setSuccessful(false);
        document.getElementById("msg").scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
        return false;
      }

      if (!Functions.validatePassword(password)) {
        setMessage(labels.INVALID_PASSWORD);
        setSuccessful(false);
        document.getElementById("msg").scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
        return false;
      }
    }
    if (!phoneOnly) {
      setMessage(labels.PHONE_REQUIRED);
      setSuccessful(false);
      document.getElementById("msg").scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      return false;
    }

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.register(newUser).then(
        (response) => {
          console.log(response.data.data);
          //setMessage("Registeration Completed.");
          if (response.data.data.success != false) {
            setSuccessful(true);
            if (!planType || planType == "monthly") {
              alert(
                "Thank you for your registration!\n\nA verification link has been sent to your email account. After you have verified your email, you will be able to log in to the Wave platform with your email and password."
              );
              window.location.href =
                window.location.protocol +
                "//" +
                window.location.host +
                "/login";
            } else {
              alert(
                "Thanks for your reservation!\nA confirmation email has been sent to your email.\n\n We are looking forward to seeing you soon!"
              );
              window.location.href =
                window.location.protocol + "//" + window.location.host;
            }
          } else {
            setMessage(response.data.data.msg);
            setSuccessful(false);
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(error.response);
          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };
  /*
    const savePic = (p, path) => {
      setPicture(path[0]);
      setDefaultImage(path[0]);
      setPicChanged(true);
    };
  
    const removePic = () => {
      setPicture("");
      setPicChanged(true);
    }
  */
  const handlePhoneChange = (phone, countryData) => {
    setPhone(phone);
    setPhoneOnly(phone.split(countryData.dialCode)[1]);
    setPhoneCode(countryData.dialCode);
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0;
  };

  return (
    <div>
      {/* <Header/> */}

      <div className="col-md-12 Signup-page-container">
        <div className="landing-signup-card">
          <h1
            style={{
              color: "#595959",
              textAlign: "center",
              paddingTop: "80px",
            }}
          >
            <b className="pageTitle">
              <div>
                <Accent>
                  <span>Sign Up</span>
                </Accent>
              </div>
            </b>
          </h1>

          <Form onSubmit={handleRegister} ref={form}>
            <div className="landing-signup-grid-2">
              <div className="landing-signup-input-container ">
                <svg
                  className="landing-signup-logo"
                  width={30}
                  viewBox="0 0 576 512"
                >
                  <path d="M528 32h-480C21.49 32 0 53.49 0 80V96h576V80C576 53.49 554.5 32 528 32zM0 432C0 458.5 21.49 480 48 480h480c26.51 0 48-21.49 48-48V128H0V432zM368 192h128C504.8 192 512 199.2 512 208S504.8 224 496 224h-128C359.2 224 352 216.8 352 208S359.2 192 368 192zM368 256h128C504.8 256 512 263.2 512 272S504.8 288 496 288h-128C359.2 288 352 280.8 352 272S359.2 256 368 256zM368 320h128c8.836 0 16 7.164 16 16S504.8 352 496 352h-128c-8.836 0-16-7.164-16-16S359.2 320 368 320zM176 192c35.35 0 64 28.66 64 64s-28.65 64-64 64s-64-28.66-64-64S140.7 192 176 192zM112 352h128c26.51 0 48 21.49 48 48c0 8.836-7.164 16-16 16h-192C71.16 416 64 408.8 64 400C64 373.5 85.49 352 112 352z" />
                </svg>
                <Input
                  autoComplete="off"
                  placeholder="First Name *"
                  type="text"
                  className="landing-signup-input"
                  name="firstName"
                  value={first_name}
                  onChange={(e) => setFirstName(e.target.value)}
                  validations={[required, vusername]}
                />
              </div>
              <div className="landing-signup-input-container">
                <svg
                  className="landing-signup-logo"
                  width={30}
                  viewBox="0 0 576 512"
                >
                  <path d="M528 32h-480C21.49 32 0 53.49 0 80V96h576V80C576 53.49 554.5 32 528 32zM0 432C0 458.5 21.49 480 48 480h480c26.51 0 48-21.49 48-48V128H0V432zM368 192h128C504.8 192 512 199.2 512 208S504.8 224 496 224h-128C359.2 224 352 216.8 352 208S359.2 192 368 192zM368 256h128C504.8 256 512 263.2 512 272S504.8 288 496 288h-128C359.2 288 352 280.8 352 272S359.2 256 368 256zM368 320h128c8.836 0 16 7.164 16 16S504.8 352 496 352h-128c-8.836 0-16-7.164-16-16S359.2 320 368 320zM176 192c35.35 0 64 28.66 64 64s-28.65 64-64 64s-64-28.66-64-64S140.7 192 176 192zM112 352h128c26.51 0 48 21.49 48 48c0 8.836-7.164 16-16 16h-192C71.16 416 64 408.8 64 400C64 373.5 85.49 352 112 352z" />
                </svg>
                <Input
                  autoComplete="off"
                  placeholder="Last Name *"
                  type="text"
                  className="landing-signup-input"
                  name="lastName"
                  value={last_name}
                  onChange={(e) => setLastName(e.target.value)}
                  validations={[required, vusername]}
                />
              </div>
            </div>
            <div className="landing-signup-grid-2">
              <div className="landing-signup-input-container">
                <PhoneInput
                  country={country.toLowerCase()}
                  autoComplete="off"
                  placeholder="Phone *"
                  type="text"
                  inputClass="landing-signup-input"
                  style={{ paddingLeft: 0, marginTop: 29 }}
                  name="phone"
                  value={phone}
                  onChange={(phone, countryData) =>
                    handlePhoneChange(phone, countryData)
                  }
                  validations={[required]}
                />
              </div>
              <div className="landing-signup-input-container">
                <svg
                  className="landing-signup-logo"
                  width={30}
                  viewBox="0 0 576 512"
                >
                  <path d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z" />
                </svg>
                <Input
                  autoComplete="off"
                  placeholder="E-mail *"
                  type="text"
                  className="landing-signup-input"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.trim())}
                  validations={[required, validEmail]}
                />
              </div>
            </div>
            {!planType || planType == "monthly" ? (
              <div className="landing-signup-grid-2">
                <div className="landing-signup-input-container">
                  <svg
                    className="landing-login-logo"
                    width={27}
                    viewBox="0 0 576 512"
                  >
                    <path d="M80 192V144C80 64.47 144.5 0 224 0C303.5 0 368 64.47 368 144V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80zM144 192H304V144C304 99.82 268.2 64 224 64C179.8 64 144 99.82 144 144V192z" />
                  </svg>
                  <Input
                    autoComplete="current-password"
                    placeholder="Password *"
                    type={showPassword ? "text" : "password"}
                    className="landing-signup-input"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    validations={[required]}
                  />

                  <i
                    className={
                      "eyeIcon fa fa-eye" + (showPassword ? "-slash" : "")
                    }
                    onClick={() => setShowPassword(!showPassword)}
                  ></i>
                </div>

                <div className="landing-signup-input-container confirmPassword">
                  <svg
                    className="landing-login-logo"
                    width={27}
                    viewBox="0 0 576 512"
                  >
                    <path d="M80 192V144C80 64.47 144.5 0 224 0C303.5 0 368 64.47 368 144V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80zM144 192H304V144C304 99.82 268.2 64 224 64C179.8 64 144 99.82 144 144V192z" />
                  </svg>
                  <Input
                    autoComplete="current-password"
                    placeholder="Confirm Password *"
                    type={showConfirmPassword ? "text" : "password"}
                    className="landing-signup-input"
                    name="cpassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    validations={[required]}
                  />
                  <i
                    className={
                      "eyeIcon fa fa-eye" +
                      (showConfirmPassword ? "-slash" : "")
                    }
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  ></i>
                </div>
              </div>
            ) : (
              ""
            )}

            <div
              className={
                !planType || planType == "monthly"
                  ? "landing-signup-grid-2 paddingSignUp"
                  : "landing-signup-grid-2"
              }
            >
              <div className="landing-signup-input-container">
                <svg
                  className="landing-signup-logo"
                  width={30}
                  viewBox="0 0 576 512"
                >
                  <path d="M96 96c-17.7 0-32 14.3-32 32s-14.3 32-32 32s-32-14.3-32-32C0 75 43 32 96 32h97c70.1 0 127 56.9 127 127c0 52.4-32.2 99.4-81 118.4l-63 24.5 0 18.1c0 17.7-14.3 32-32 32s-32-14.3-32-32V301.9c0-26.4 16.2-50.1 40.8-59.6l63-24.5C240 208.3 256 185 256 159c0-34.8-28.2-63-63-63H96zm48 384c-22.1 0-40-17.9-40-40s17.9-40 40-40s40 17.9 40 40s-17.9 40-40 40z" />
                </svg>

                <Select
                  autoComplete="off"
                  placeholder="How did you hear about Wave? *"
                  type="text"
                  className="form-control landing-signup-input "
                  name="source"
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                  validations={[required]}
                >
                  <option disabled="disabled" value="">
                    How did you hear about Wave? *
                  </option>
                  {options.source_register.map((item, index) => (
                    <option key={"soruce" + index} value={item.key}>
                      {item.value}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="landing-signup-input-container hiddenContainer">
                <svg
                  className="landing-signup-logo"
                  style={{ top: 50 }}
                  width={30}
                  viewBox="0 0 576 512"
                >
                  <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                </svg>

                <PlanTypes
                  name="planType"
                  labelInside={true}
                  extraClass="form-control landing-signup-input"
                  addFormGroup="no"
                  forceBlank={false}
                  defaultValue={planType || "monthly"}
                  setValue={handlePlanChange}
                />
              </div>

              {!planType || planType == "monthly" ? (
                <div className="landing-signup-input-container">
                  <svg
                    className="landing-signup-logo"
                    width={30}
                    viewBox="0 0 576 512"
                  >
                    <path d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z" />
                  </svg>

                  <Select
                    autoComplete="off"
                    placeholder="Payment Plan*"
                    type="text"
                    className="form-control landing-signup-input "
                    name="plan"
                    value={plan}
                    onChange={(e) => {
                      setPlan(e.target.value);
                    }}
                    validations={[required]}
                  >
                    <option disabled="disabled" value="">
                      Payment Plan*
                    </option>
                    {displayedPlan.map((item, index) => (
                      <option
                        key={"plan" + index}
                        value={item.key}
                        index={index}
                      >
                        {item.value}
                      </option>
                    ))}
                  </Select>
                </div>
              ) : (
                <div className="landing-signup-input-container">
                  <svg
                    className="landing-signup-logo"
                    width={30}
                    viewBox="0 0 630 512"
                  >
                    <path d="M312 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h25.7l34.6 64H222.9l-27.4-38C191 99.7 183.7 96 176 96H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h43.7l22.1 30.7-26.6 53.1c-10-2.5-20.5-3.8-31.2-3.8C57.3 224 0 281.3 0 352s57.3 128 128 128c65.3 0 119.1-48.9 127-112h49c8.5 0 16.3-4.5 20.7-11.8l84.8-143.5 21.7 40.1C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L375.4 48.8C369.8 38.4 359 32 347.2 32H312zM458.6 303.7l32.3 59.7c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-32.3-59.7c3.6-.6 7.4-.9 11.2-.9c39.8 0 72 32.2 72 72s-32.2 72-72 72s-72-32.2-72-72c0-18.6 7-35.5 18.6-48.3zM133.2 368h65c-7.3 32.1-36 56-70.2 56c-39.8 0-72-32.2-72-72s32.2-72 72-72c1.7 0 3.4 .1 5.1 .2l-24.2 48.5c-9 18.1 4.1 39.4 24.3 39.4zm33.7-48l50.7-101.3 72.9 101.2-.1 .1H166.8zm90.6-128H365.9L317 274.8 257.4 192z" />
                  </svg>
                  <Input
                    type="number"
                    min="1"
                    max="30"
                    className="landing-signup-input hide-input-arrows"
                    placeholder="Total Bikes *"
                    name="totalBikes"
                    value={totalBikes}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleTotalBikes}
                  />
                </div>
              )}
            </div>

            <div className="landing-signup-grid-2">
              <div className="landing-signup-input-container2">
                <DatePicker
                  filterDate={isWeekday}
                  minDate={new Date()}
                  dateFormat="dd-MM-yyyy"
                  name="pickupDate"
                  className="landing-datepicker-input"
                  selected={pickupDate}
                  placeholderText="Pickup Date *"
                  onChange={(date) => {
                    setPickupDate(new Date(date));
                  }}
                />
                <svg
                  className="landing-signup-logo2"
                  width={30}
                  viewBox="0 0 576 512"
                >
                  <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" />
                </svg>
              </div>

              {/*div className="landing-signup-input-container" style={{ height: 80, paddingTop: 30 }}>
                <table border="0" width="100%">
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        id="waitingList"
                        checked={waitingList}
                        onChange={(e) => setWaitingList(e.target.checked)}
                      />
                    </td>
                    <td><label>&nbsp;I wish to book a bike</label>


                    </td>
                  </tr>

                </table>


              </div>*/}
              {waitingList ? (
                <div className="landing-signup-input-container">
                  <svg
                    className="landing-signup-logo"
                    width={25}
                    viewBox="0 0 576 512"
                  >
                    <path d="M160 80C160 112.8 140.3 140.1 112 153.3V241.1C130.8 230.2 152.7 224 176 224H272C307.3 224 336 195.3 336 160V153.3C307.7 140.1 288 112.8 288 80C288 35.82 323.8 0 368 0C412.2 0 448 35.82 448 80C448 112.8 428.3 140.1 400 153.3V160C400 230.7 342.7 288 272 288H176C140.7 288 112 316.7 112 352V358.7C140.3 371 160 399.2 160 432C160 476.2 124.2 512 80 512C35.82 512 0 476.2 0 432C0 399.2 19.75 371 48 358.7V153.3C19.75 140.1 0 112.8 0 80C0 35.82 35.82 0 80 0C124.2 0 160 35.82 160 80V80zM80 104C93.25 104 104 93.25 104 80C104 66.75 93.25 56 80 56C66.75 56 56 66.75 56 80C56 93.25 66.75 104 80 104zM368 56C354.7 56 344 66.75 344 80C344 93.25 354.7 104 368 104C381.3 104 392 93.25 392 80C392 66.75 381.3 56 368 56zM80 456C93.25 456 104 445.3 104 432C104 418.7 93.25 408 80 408C66.75 408 56 418.7 56 432C56 445.3 66.75 456 80 456z" />
                  </svg>
                  <BranchePullDown
                    branch={branch}
                    labelInside={true}
                    setBranch={setBranch}
                    branches={branches}
                    extraClass="landing-signup-input"
                    isRequired={true}
                    labelOption={true}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div style={{ marginTop: "40px" }}>
              <div id="landing-signup-button-container">
                <button id="landing_home_reserve_now_button">
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;Sign Up&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </button>
              </div>

              <div className="landing-login-register">
                Having trouble?{" "}
                <a target="_new" href="https://wa.me/96170632955">
                  Click here
                </a>{" "}
                and we will gladly assist you the soonest possible.
              </div>
              <br />

              <div className="landing-login-register">
                Have an account?&nbsp;
                <span>
                  <NavLink
                    to={"/login"}
                    exact
                    id="landing-login-register-create"
                  >
                    Login
                  </NavLink>
                </span>
              </div>
            </div>
            <div id="msg">
              {message && (
                <div className="form-group">
                  <div
                    className={
                      successful ? "alert alert-success" : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {message}
                  </div>
                </div>
              )}
            </div>
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
      </div>
    </div>
  );
}
