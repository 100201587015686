const questionAnswers = [

    {
        question: "What is Wave?",
        answer: "Wave offers electric bicycle subscription for the most convenient mode of commuting in the city."
    },

    {
        question: "Where are your electric bikes made?",
        answer: "Our parts are coming from Taiwan. They are designed, assembled, and maintained by our Lebanese/Dutch team in Lebanon."
    },

    {
        question: "How long does it take to recharge the battery?",
        answer: "5 hours for a full charge when completely drained. For a shorter charge time try to keep it above 20% charge."
    },

    {
        question: "How fast will electric bikes go?",
        answer: "The support of the electric engine stops at 30km/h in order to keep it safe. However, if you want, you can pedal faster."
    },

    {
        question: "Is the battery waterproof?",
        answer: "It is certainly waterproof, including rain. But don’t go swimming with it!"
    },

    {
        question: "How do I know how much battery is left?",
        answer: "The battery level is shown on the display on the handlebar and you can also see it on the battery itself by pressing the button (and keep it pressed). Checking battery level is simple. Just tap the icon within the app to view available battery life."
    },

    {
        question: "Do you have accident insurance?",
        answer: "Not at the moment"
    },

    {
        question: "How can I charge my bike?",
        answer: "Our bikes are equipped with lockable and swappable batteries, that you can easily take from the bike and charge at home or anywhere where you have a wall plug. You will receive a charger when you get your bike."
    },

    {
        question: "How far can a Wave bike go on a single charge?",
        answer: "It depends on the environment and the way you use the gears/engine support settings. With normal use in the city (Beirut) the range is 40km, but on flat surfaces you should be able to reach 80km."
    },

    {
        question: "Should I completely drain the battery before charging?",
        answer: "For the lifespan of the battery it is even better to keep it charged above 20% most of the time. A second benefit is that charging the battery between 20% and 80% will also be faster than from completely drained. You don’t need to let your electric bike battery discharge completely. Rather try to charge the battery when there is between 30 percent and 60 percent of the capacity remaining."
    },

    {
        question: "Does it matter if the electronics get wet?",
        answer: "The electronic parts on all of our Wave bikes are sealed units, from the motor to battery to head unit and they are all waterproof. Rain is not a problem, but don’t go swimming with the bike."
    },

    {
        question: "Do I need to pedal? (technical)",
        answer: "Yes, it is just like a regular bicycle. However, when you pedal the electric engine will support you. In this way you can effortlessly commute and enjoy the city."
    }

]


export default questionAnswers;