import React from "react";
import EventBus from "../common/EventBus";
import SubscriptionService from "../services/subscription.service";

export default function (props) {
  const id = props.id;
  const name = props.name;
  const list = props.list;
  const setList = props.setList;
  const isClients = props.isClients;
  const handleForceTerminateClick = () => {
    if (
      window.confirm(
        "Are you sure you want to fully terminate the subscription for " +
          name +
          "?"
      )
    ) {
      SubscriptionService.forceTerminate(id).then(
        (res) => {
          if (isClients) {
            setList(
              list.map((s, i) => {
                if (s.usersubscribe && s.usersubscribe._id === id) {
                  s.usersubscribe.isFrozen = false;
                }
                return s;
              })
            );
          } else {
            setList(
              list.map((s, i) => {
                if (s._id === id) {
                  s.isFrozen = false;
                }
                return s;
              })
            );
          }
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.surchagerId &&
            res.data.data.surchagerId != 0
          ) {
            //redirect to the new invoice created.
            alert(
              "A short period surcharge invoice has been created for the client."
            );
            window.open(
              "/invoice/edit/" + res.data.data.surchagerId + "?subscription",
              "_blank"
            );
          }
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.receiptId &&
            res.data.data.receiptId != 0
          ) {
            //redirect to the new invoice created.

            alert(
              "Package has been marked as returned.\n A draft return deposit has been created for the client."
            );
            window.open(
              "/invoice/edit/" + res.data.data.receiptId + "?subscription",
              "_blank"
            );
          }
        },
        (error) => {
          console.log(error);
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          alert(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };
  return (
    <button
      className="linkBtn text-dark small-link midText"
      onClick={handleForceTerminateClick}
    >
      <i className="fa fa-circle-stop brick">
        {" "}
        <span className="faText brick">Force Terminate</span>
      </i>
    </button>
  );
}
