import React, { useEffect, useState, useRef, Fragment } from "react";
import EventService from "../services/event.service";
import EventBus from "../common/EventBus";
import Form from "react-validation/build/form";
import Select from "react-validation/build/select";
import { getBranches, getCompanies, getPackages } from "../common/getFunctions";
import RequiredLabel from "../common/RequiredLabel";
import { required } from "../common/Validation";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import OptionalLabel from "../common/OptionalLabel";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PullDown from "../commonComponents/PullDown";
import ImageUploader from "react-images-upload";
import Resizer from "react-image-file-resizer";
import { Row, Col } from "react-bootstrap";
import BranchePullDown from "../commonComponents/BranchePullDown";
import { Prompt } from "react-router-dom";

const currentUser = JSON.parse(localStorage.getItem('user'));

const EventForm = (props) => {
    const form = useRef();
    const checkBtn = useRef();
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");
    const [branches, setBranches] = useState([]);
    const [branch, setBranch] = useState(currentUser.branch);
    const [title, setTitle] = useState("");
    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());
    const [internalCheckbox, setInternalCheckbox] = useState(false);
    const [company, setCompany] = useState("");
    const [companies, setCompanies] = useState([]);
    const [pack, setPack] = useState("");
    const [packages, setPackages] = useState([]);
    const [budget, setBudget] = useState("");
    const [fees, setFees] = useState("");
    const [summary, setSummary] = useState("");
    const [picture, setPicture] = useState("");
    const [address, setAddress] = useState("");
    const [defaultImage, setDefaultImage] = useState("");
    const [link, setLink] = useState("");
    const [type, setType] = useState("");
    const [disabledCheckbox, setDisabledCheckbox] = useState(false);
    const [notes, setNotes] = useState("");
    const [picChanged, setPicChanged] = useState(false);
    const [eventId, setEventId] = useState(props.match.params.id || "");
    const [iAmTyping, setIAmTyping] = useState(false);
    const clone = props.location.pathname.includes('/clone/');


    useEffect(() => {
        async function onReady() {
            window.scrollTo(0, 0);
            setBranches(await getBranches());
            setCompanies(await getCompanies(currentUser.branch));
            setPackages(await getPackages(currentUser.branch));
            if (eventId) getEventInfo();
        }

        onReady()
    }, []);

    const getEventInfo = () => {
        EventService.getEventById(eventId).then(
            (response) => {
                let e = response.data;
                setBranch(e.branch);
                setDisabledCheckbox(e.disabled);
                setStart(new Date(e.start + e.timezone));
                setEnd(new Date(e.end + e.timezone));
                setBudget(e.budget);
                setCompany(e.company);
                setFees(e.fees);
                setInternalCheckbox(e.internal);
                setLink(e.link);
                setNotes(e.notes);
                setPack(e.package);
                setSummary(e.summary);
                setAddress(e.address);
                setTitle(e.title);
                setType(e.type);

                if (e.picture) {
                    setDefaultImage(process.env.REACT_APP_SERVER_API + "/upload/events/" + e.picture);

                } else {
                    setDefaultImage("");
                }
                if (clone === true) { setEventId(""); } // reset the id to clone
            }
        )
    }


    const handleSaveEvent = (e) => {
        e.preventDefault();
        setIAmTyping(false);
        setSuccessful(false);

        form.current.validateAll();
        if (!end) {
            end = start;
        }

        if (checkBtn.current.context._errors.length === 0) {

            let data = {
                _id: eventId,
                title,
                start: (start.toString().split("GMT")[0]),
                end: (end.toString().split("GMT")[0]),
                timezone: start.toString().split("GMT")[1],
                internal: internalCheckbox,
                company,
                package: pack,
                budget,
                fees,
                summary,
                picture,
                picChanged,
                link,
                branch,
                type: type,
                disabled: disabledCheckbox,
                notes,
                address
            }

            EventService.createEvent(data).then(
                (response) => {
                    setMessage("Record is saved.");
                    setSuccessful(true);
                    props.history.push("/events");

                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setMessage(resMessage);
                    setSuccessful(false);
                }
            );

        }
    };

    const savePic = (p, path) => {
        setIAmTyping(true);

        try {
            Resizer.imageFileResizer(
                p[0],
                300,
                300,
                "JPEG",
                100,
                0,
                (uri) => {
                    let newUri = uri.substring(0, 16) + "name=" + p[0].name + ";" + uri.substring(16, uri.length);
                    setPicture(newUri);
                    setDefaultImage(newUri);
                    setPicChanged(true);
                },
                "base64",
                200,
                200
            );
        } catch (err) {
            console.log(err);
        }

    };

    const removePic = () => {
        setIAmTyping(true);
        setPicture("");
        setPicChanged(true);
    }

    const handleBranchChange = async (b) => {
        setBranch(b);
        setCompanies(await getCompanies(b));
    }



    return (
        <Fragment>
            <Prompt
                when={iAmTyping}
                message={(location) =>
                    'Are you sure, you want to leave? All your data will be lost!'
                }
            />
            <div className="col-md-12 font-20">


                <Form onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} ref={form}>
                    {!successful && (
                        <div>

                            <table className="styled-table" style={{ width: "100%", margin: 'auto' }} cellPadding="10">
                                <thead>
                                    <tr>
                                        <th>Create Event</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <Row className="white-background " style={{ width: "100%", display: "flex", flexDirection: "row", marginLeft: 0, marginRight: 0 }} >



                                        <Col md={12} align="center" >

                                            <Col md={4}>
                                                <ImageUploader
                                                    withIcon={false}
                                                    withPreview={true}
                                                    label=""
                                                    buttonText="Upload picture"
                                                    onChange={savePic}
                                                    accept="image/*"
                                                    singleImage={true}
                                                    defaultImages={defaultImage ? [defaultImage] : ""}
                                                    onDelete={removePic}
                                                    buttonClassName="profileUpload"
                                                />
                                            </Col>

                                        </Col>
                                        <Col md={3}>
                                            {currentUser.type === "admin" ?

                                                <div className="form-group">
                                                    <RequiredLabel htmlFor="branch" text="Branch" />
                                                    <BranchePullDown branch={branch} labelInside={true} forceBlank={true} setBranch={(v) => {handleBranchChange(v);setIAmTyping(true);}} branches={branches} />

                                                </div>

                                                :
                                                null
                                            }
                                        </Col>
                                        <Col md={3}>
                                            <div className="form-group">
                                                <RequiredLabel htmlFor="title" text="Title" />
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    value={title}
                                                    onChange={e => {
                                                        setTitle(e.target.value);
                                                        setIAmTyping(true);
                                                    }}
                                                    validations={[required]}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="form-group">
                                                <OptionalLabel htmlFor="startDate" text="Start Date" />
                                                <DatePicker
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                    className="form-control"
                                                    name="startDate"
                                                    selected={start}
                                                    onChange={date => {
                                                        setStart(date);
                                                        setIAmTyping(true);
                                                    }}
                                                    showTimeSelect
                                                    selectsStart
                                                    startDate={start}
                                                    endDate={end}

                                                />
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="form-group">
                                                <OptionalLabel htmlFor="endDate" text="End Date" />
                                                <DatePicker
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                    className="form-control"
                                                    name="endDate"
                                                    selected={end}
                                                    onChange={date => {
                                                        setEnd(date);
                                                        setIAmTyping(true);
                                                    }}
                                                    showTimeSelect
                                                    selectsEnd
                                                    startDate={start}
                                                    endDate={end}
                                                    minDate={start}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="form-group text-center">
                                                <RequiredLabel htmlFor="internal_checkbox" text="Internal" />
                                                <Switch
                                                    checked={internalCheckbox}
                                                    onChange={() => {
                                                        setInternalCheckbox(!internalCheckbox);
                                                        setIAmTyping(true);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="form-group">
                                                <OptionalLabel htmlFor="company" text="Company" />
                                                <PullDown defaultValue={company} labelInside={false} pulldownFor={"company"} forceBlank={true} setValue={(v) => {setCompany(v);setIAmTyping(true);}} options={companies} />

                                            </div>
                                        </Col>
                                        <Col md={3}>

                                            <div className="form-group">
                                                <OptionalLabel htmlFor="package" text="Package" />
                                                <PullDown defaultValue={pack} labelInside={false} pulldownFor={"packages"} forceBlank={true} setValue={(v) => {setPack(v);setIAmTyping(true);}} options={packages} />

                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="form-group">
                                                <OptionalLabel htmlFor="budget" text="Budget" />
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="budget"
                                                    value={budget}
                                                    onChange={e => {
                                                        setBudget(e.target.value);
                                                        setIAmTyping(true);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="form-group">
                                                <OptionalLabel htmlFor="fees" text="Fees" />
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="fees"
                                                    value={fees}
                                                    onChange={e => {
                                                        setFees(e.target.value);
                                                        setIAmTyping(true);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="form-group">
                                                <OptionalLabel htmlFor="summary" text="Summary" />
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    cols="30"
                                                    rows="4"
                                                    name="summary"
                                                    value={summary}
                                                    onChange={e => {
                                                        setSummary(e.target.value);
                                                        setIAmTyping(true);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="form-group">
                                                <OptionalLabel htmlFor="link" text="Embeded Links" />
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    cols="30"
                                                    rows="4"
                                                    name="link"
                                                    value={link}
                                                    onChange={e => {
                                                        setLink(e.target.value);
                                                        setIAmTyping(true);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="form-group">
                                                <OptionalLabel htmlFor="type" text="Type" />

                                                <select
                                                    className="form-control filterInput2"
                                                    value={type}
                                                    onChange={(e) => {
                                                        setType(e.target.value);
                                                        setIAmTyping(true);
                                                    }}

                                                >
                                                    <option value=""></option>
                                                    <option value="private">Private</option>
                                                    <option value="public">Public</option>
                                                    <option value="waver">Waver</option>
                                                </select>
                                            </div>

                                        </Col>



                                        <Col md={3}>
                                            <div className="form-group text-center">
                                                <OptionalLabel htmlFor="disabled_checkbox" text="Disabled" />
                                                <Switch
                                                    checked={disabledCheckbox}
                                                    onChange={() => {
                                                        setDisabledCheckbox(!disabledCheckbox);
                                                        setIAmTyping(true);
                                                    }}

                                                />
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="form-group">
                                                <OptionalLabel htmlFor="address" text="Address" />
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    name="address"
                                                    cols="30"
                                                    rows="4"
                                                    value={address}
                                                    onChange={e => {
                                                        setAddress(e.target.value);
                                                        setIAmTyping(true);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="form-group">
                                                <OptionalLabel htmlFor="notes" text="Notes" />
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    name="notes"
                                                    cols="30"
                                                    rows="4"
                                                    value={notes}
                                                    onChange={e => {
                                                        setNotes(e.target.value);
                                                        setIAmTyping(true);
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                    </Row>


                                </tbody>
                            </table>


                            <button onClick={(e) => handleSaveEvent(e)} value="save" className="btn btn-primary btn-block">Save</button>

                        </div>
                    )
                    }

                    {
                        message && (
                            <div className="form-group">
                                <div
                                    className={
                                        successful ? "alert alert-success" : "alert alert-danger"
                                    }
                                    role="alert"
                                >
                                    {message}
                                </div>
                            </div>
                        )
                    }
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form >

            </div >
        </Fragment>
    )
}

export default EventForm;