import React from 'react'
import loading from "../images/loading.gif";

export default function Loader(props) {


	return (
	<table border="0" align="center">
			<tr>
				<td><img src={loading} width={500}/></td>
			</tr>
			
			
		</table>
	)
}
