import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api/followup";

const getFollowups = (subUrl) => {
    return axios.get(API_URL + "/" + subUrl, { headers: authHeader() });
};

const markAsFollowed = (userId, companyId) => {
  return axios(API_URL + "/save", { method: 'post', headers: authHeader(), data: { userId: userId, companyId: companyId} });
};


export default {
	getFollowups,
	markAsFollowed
    
};