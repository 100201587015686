import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";


const getChargersList = (branch, number, offset, limit, onlyList, status, model) => {
  return axios.get(API_URL + "/charger/list?branch=" + branch + "&number=" + number + "&offset=" + offset + "&limit=" + limit + "&onlyList=" + onlyList + "&status=" + status + "&model=" + model, { headers: authHeader() });
};

const deleteCharger = (id) => {
  return axios(API_URL + "/charger/" + id, { method: 'delete', headers: authHeader() });
};

const putCharger = (id, number, status, model, branch) => {
  return axios(API_URL + "/charger/save", { method: 'post', headers: authHeader(), data: { _id: id, number: number, status: status, model: model, branch: branch } });
};

const postCharger = (number, status, model, branch) => {
  return axios(API_URL + "/charger/save", { method: 'post', headers: authHeader(), data: { _id: '', number: number, status: status, model: model, branch: branch } });
};


const getChargerById = (id) => {
  return axios.get(API_URL + "/charger/details/" + id, { headers: authHeader() });
};

const markChargerAsStolen = (data) => {
  return axios(API_URL + "/charger/stolen", { method: 'post', headers: authHeader(), data: data });
}

const markChargerAsFound = (data) => {
  return axios(API_URL + "/charger/found", { method: 'post', headers: authHeader(), data: data });
}

export default {
  putCharger,
  postCharger,
  deleteCharger,
  getChargersList,
  getChargerById,
  markChargerAsStolen,
  markChargerAsFound
};
