import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Functions from "../common/Functions.js";
import InternalNoteModal from "./InternalNoteModal";


export default function InternalNoteComponent(props) {
    const selectedUserByNote = props.selectedUserByNote;
    //const setSelectedUserByNote = props.setSelectedUserByNote;
    const list = props.list;
    const setList = props.setList;
    const oldInternalNote = props.oldInternalNote || "";
    const rentedAcc = props.rentedAcc || "";
    const note = props.note || "";
    const clickable = props.clickable || false;
    const isSubscription = props.isSubscription || false;


    //const [internalNote, setInternalNote] = useState("");
    const [internalNoteModal, setInternalNoteModal] = useState(false);



    return (
        <div >
            {clickable ? <InternalNoteModal internalNoteModal={internalNoteModal} setInternalNoteModal={setInternalNoteModal} selectedUserByNote={selectedUserByNote} oldInternalNote={oldInternalNote} list={list} setList={setList} isSubscription={isSubscription} /> : ""}
            <div style={{ float: "right" }}>
                <OverlayTrigger
                    key="left"
                    placement="left"
                    overlay={
                        <Tooltip>
                            {Functions.makeMultipleRows((oldInternalNote ? "Internal Note: " + oldInternalNote : "") + ((note && oldInternalNote) || (rentedAcc && oldInternalNote) ? "\n" : "") + (note ? "Note: " + note : "") + (rentedAcc && note ? "\n" : "") + (rentedAcc ? "Rented Accessories: " + rentedAcc : ""))}
                        </Tooltip>
                    }
                >
                    <svg onClick={() => clickable ? setInternalNoteModal(true) : ""} style={{ width: '30px', height: '30px', color: Functions.getBaloonColor(oldInternalNote ? oldInternalNote : note ? note : rentedAcc) }} aria-hidden="true" focusable="false" data-prefix="far" data-icon="comment-dots" className="svg-inline--fa fa-comment-dots fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M144 208c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm112 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm112 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zM256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29 7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1-20.6-21.8C69.7 314.1 48 282.2 48 240c0-88.2 93.3-160 208-160s208 71.8 208 160-93.3 160-208 160z"></path></svg>
                </OverlayTrigger>
                <br />
            </div>
            {/*!clickable && !noLink ? <><button className="linkBtn greenLink mx-0" onClick={() => setInternalNoteModal(true)}>Add an internal note</button></> : ""*/}
        </div>
    );
}
