// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RemovedInventories_container__2j21g {\n  width: 100%;\n}\n\n.RemovedInventories_removedHeader__-FJBF {\n  margin-bottom: 20px;\n  background: rgba(0, 0, 0, 0)\n    linear-gradient(135deg, rgb(0, 153, 230) 0%, rgb(0, 192, 8) 100%) repeat\n    scroll 0% 0%;\n  text-align: left;\n  font-family: \"Poppins\", Montserrat, Helvetica Neue, Arial, sans-serif;\n  font-size: 14px !important;\n  color: white !important;\n  padding: 10px;\n}\n\n.RemovedInventories_selectContainer__2ghDM {\n  margin-bottom: 20px;\n}\n\n.RemovedInventories_selectControl__3uRln {\n  width: 100%;\n}\n\n.RemovedInventories_removedCardContainer__35xjD {\n  display: flex;\n  flex-wrap: wrap;\n  grid-gap: 15px;\n  gap: 15px;\n}\n\n.RemovedInventories_card__3-fl6 {\n  background-color: #ffffff;\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  padding: 15px;\n  width: calc(33% - 30px); /* Adjust width based on the number of columns */\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n}\n\n.RemovedInventories_cardContent__5xlNY {\n  display: flex;\n  flex-direction: column;\n}\n\n.RemovedInventories_cardContent__5xlNY h3 {\n  margin: 0;\n  font-size: 16px;\n}\n\n.RemovedInventories_cardContent__5xlNY p {\n  margin: 5px 0 15px;\n  font-size: 14px;\n  color: #666;\n}\n\n.RemovedInventories_removeButton__28rBG {\n  background-color: #e74c3c;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  padding: 10px;\n  cursor: pointer;\n  font-size: 14px;\n}\n\n.RemovedInventories_removeButton__28rBG:hover {\n  background-color: #c0392b;\n}\n.RemovedInventories_perfectBike__2L81B {\n  padding-left: 20px;\n  font-size: 10px;\n  color: green;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "RemovedInventories_container__2j21g",
	"removedHeader": "RemovedInventories_removedHeader__-FJBF",
	"selectContainer": "RemovedInventories_selectContainer__2ghDM",
	"selectControl": "RemovedInventories_selectControl__3uRln",
	"removedCardContainer": "RemovedInventories_removedCardContainer__35xjD",
	"card": "RemovedInventories_card__3-fl6",
	"cardContent": "RemovedInventories_cardContent__5xlNY",
	"removeButton": "RemovedInventories_removeButton__28rBG",
	"perfectBike": "RemovedInventories_perfectBike__2L81B"
};
module.exports = exports;
