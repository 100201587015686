import React, { useState, useEffect, useRef, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import BranchService from "../services/branch.service";
import EventBus from "../common/EventBus";
import Switch from "react-switch";
import { required } from "../common/Validation";
import * as options from "../labels/options_en";
import Currency from "../commonComponents/Currency";
import PullDown from "../commonComponents/PullDown";
import RequiredLabel from "../common/RequiredLabel";
import OptionalLabel from "../common/OptionalLabel";
import TimezonePicker from "react-bootstrap-timezone-picker";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import {
  getCountriesList,
  getPlansList,
  getFormsList,
  getSuppliers,
  getBranches,
} from "../common/getFunctions";
import { Prompt } from "react-router-dom";
import BranchePullDown from "../commonComponents/BranchePullDown";

const BranchForm = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [label, setLabel] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [language, setLanguage] = useState("");
  const [timezone, setTimeZone] = useState("");
  const [phone, setPhone] = useState("");
  const [town, setTown] = useState("");
  const [city, setCity] = useState("");
  const [MOF, setMOF] = useState("");
  const [buildingNumber, setBuildingNumber] = useState("");
  const [floor, setFloor] = useState("");
  const [street, setStreet] = useState("");
  const [invoicePrefix, setInvoicePrefix] = useState("");
  const [invoiceCounter, setInvoiceCounter] = useState("1");
  const [transactionPrefix, setTransactionPrefix] = useState("");
  const [transactionCounter, setTransactionCounter] = useState("1");
  const [parentBranch, setParentBranch] = useState(null);
  const [currency, setCurrency] = useState("USD");
  const [currencyRate, setCurrencyRate] = useState("1");
  const [country, setCountry] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [branchId, setBranchId] = useState(props.match.params.id);
  const clone = props.location.pathname.includes("/clone/");
  const [countries, setCountries] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [defaultPlan, setDefaultPlan] = useState("");
  const [journeyForm, setJourneyForm] = useState("");
  const [plans, setPlans] = useState([]);
  const [journeyFormList, setJourneyFormList] = useState([]);
  const [saveTriggered, setSaveTriggered] = useState(false);
  const [iAmTyping, setIAmTyping] = useState(false);
  const [branches, setBranches] = useState([]);
  const [showParentsFields, setShowParentsFields] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const [company, setCompany] = useState("");

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      await listCountries();
      setBranches(await getBranches("onlyParents"));
      await getBranch();

      setJourneyFormList(await getFormsList("", "journey"));
      setSuppliers(await getSuppliers(""));
    }

    onReady();
  }, []);

  const getBranch = async () => {
    if (branchId) {
      BranchService.getBranchById(branchId).then(
        async (response) => {
          let b = response.data;

          setLabel(b.label);
          setDisabled(b.disabled);
          setLanguage(b.language);
          setCurrency(b.currency);
          setTimeZone(b.timezone);
          setCurrencyRate(b.currencyRate);
          setCountry(b.country);
          setPhone(b.phone);
          setTown(b.town);
          setCity(b.city);
          setMOF(b.MOF);
          setParentBranch(b.parentBranch);
          setPlans(await getPlansList(branchId, b.parentBranch));
          if (b.parentBranch != null && b.parentBranch != "") {
            setShowParentsFields(false);
          }
          setBuildingNumber(b.buildingNumber);
          setFloor(b.floor);
          setStreet(b.street);
          setInvoicePrefix(b.invoicePrefix);
          setInvoiceCounter(b.invoice_counter || 1);
          setTransactionPrefix(b.transactionPrefix);
          setTransactionCounter(b.transaction_counter || 1);
          setDefaultPlan(b.defaultPlan ? b.defaultPlan.id : "");
          setJourneyForm(b.journeyform ? b.journeyform.id : "");
          setCompany(b.company ? b.company.id : "");
          if (clone === true) {
            setBranchId("");
          } // reset the id to clone
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          alert(_content);
          setMessage(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    } else {
      setPlans(await getPlansList(branchId, ""));
    }
  };

  const handleSaveBranch = (e) => {
    e.preventDefault();
    setMessage("");
    setSuccessful(false);
    setSaveTriggered(true);
    if (
      (showParentsFields &&
        (invoiceCounter == "" ||
          invoicePrefix == "" ||
          transactionCounter == "" ||
          transactionPrefix == "")) ||
      !country ||
      !timezone
    )
      return;

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      BranchService.postBranch(
        branchId,
        label,
        disabled,
        language,
        currency,
        country,
        timezone,
        phone,
        town,
        city,
        MOF,
        buildingNumber,
        floor,
        street,
        currencyRate,
        invoicePrefix,
        invoiceCounter,
        defaultPlan,
        journeyForm,
        parentBranch,
        company,
        transactionPrefix,
        transactionCounter
      ).then(
        (response) => {
          setMessage("Branch Saved.");
          setSuccessful(true);
          setIAmTyping(false);
          props.history.push("/branches");
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };
  const setDisabledSwitch = () => {
    setDisabled(!disabled);
  };

  const currencyHandler = (e, index) => {
    setCurrency(e.target.value);
  };

  const listCountries = async () => {
    setLoadingCountries(true);
    let data = await getCountriesList();
    setCountries(data);
    setLoadingCountries(false);
  };

  const handleBranchChange = async (bid) => {
    setIAmTyping(true);
    setParentBranch(bid);
    setPlans(await getPlansList(branchId, bid));
    setShowParentsFields(false);
    if (bid == "") {
      setShowParentsFields(true);
    }
  };

  return (
    <Fragment>
      <Prompt
        when={iAmTyping}
        message={(location) =>
          "Are you sure, you want to leave? All your data will be lost!"
        }
      />
      <div className="col-md-12">
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          ref={form}
        >
          {!successful && (
            <div>
              <table className="styled-table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Create a Branch</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="white-background">
                    <td>
                      <div className="form-group form-group-col3">
                        <RequiredLabel htmlFor="label" text="Label" />
                        <Input
                          type="text"
                          className="form-control"
                          name="label"
                          value={label}
                          onChange={(e) => {
                            setLabel(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required]}
                        />
                      </div>
                      <div className="form-group form-group-col3">
                        <BranchePullDown
                          title="Parent Branch"
                          branch={parentBranch}
                          labelInside={false}
                          forceBlank={true}
                          setBranch={handleBranchChange}
                          branches={branches}
                          addFormGroup="no"
                        />
                      </div>

                      <div className="form-group form-group-col3">
                        <RequiredLabel
                          htmlFor="language"
                          text="Default Language"
                        />
                        <Select
                          className="form-control"
                          autoComplete="off"
                          name="language"
                          value={language}
                          onChange={(e) => {
                            setLanguage(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required]}
                        >
                          <option value=""></option>
                          {options.languageOptions.map((item, index) => (
                            <option key={"language" + index} value={item[0]}>
                              {item[1]}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="form-group form-group-col3">
                        <RequiredLabel htmlFor="phone" text="Phone" />
                        <Input
                          type="text"
                          className="form-control"
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required]}
                        />
                      </div>
                      <div className="form-group form-group-col3">
                        <RequiredLabel htmlFor="timezone" text="Time Zone" />

                        <TimezonePicker
                          absolute={true}
                          value={timezone}
                          placeholder="Select timezone..."
                          onChange={(e) => {
                            setTimeZone(e);
                            setIAmTyping(true);
                          }}
                        />
                        {!timezone && saveTriggered ? (
                          <div className="alert alert-danger" role="alert">
                            This field is required!
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="form-group form-group-col3">
                        <RequiredLabel htmlFor="country" text="Country" />
                        <select
                          className="form-control"
                          value={country}
                          onChange={(e) => {
                            setCountry(e.target.value);
                            setIAmTyping(true);
                          }}
                        >
                          <option value="">All</option>
                          {!loadingCountries &&
                          countries &&
                          countries.length > 0
                            ? countries.map((c, index) => (
                                <option key={index} value={c._id}>
                                  {c.name}
                                </option>
                              ))
                            : null}
                        </select>
                        {!country && saveTriggered ? (
                          <div className="alert alert-danger" role="alert">
                            This field is required!
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group form-group-col3">
                        <RequiredLabel htmlFor="town" text="Town" />
                        <Input
                          type="text"
                          className="form-control"
                          value={town}
                          onChange={(e) => {
                            setTown(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required]}
                        />
                      </div>
                      <div className="form-group form-group-col3">
                        <RequiredLabel htmlFor="city" text="City" />
                        <Input
                          type="text"
                          className="form-control"
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required]}
                        />
                      </div>
                      <div className="form-group form-group-col3">
                        <OptionalLabel htmlFor="MOF" text="MOF" />
                        <Input
                          type="text"
                          className="form-control"
                          value={MOF}
                          onChange={(e) => {
                            setMOF(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                      <div className="form-group form-group-col3">
                        <OptionalLabel
                          htmlFor="buildingNumber"
                          text="Building"
                        />
                        <Input
                          type="text"
                          className="form-control"
                          value={buildingNumber}
                          onChange={(e) => {
                            setBuildingNumber(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                      <div className="form-group form-group-col3">
                        <OptionalLabel htmlFor="floor" text="Floor" />
                        <Input
                          type="text"
                          className="form-control"
                          value={floor}
                          onChange={(e) => {
                            setFloor(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                      <div className="form-group form-group-col3">
                        <OptionalLabel htmlFor="street" text="Street" />
                        <Input
                          type="text"
                          className="form-control"
                          value={street}
                          onChange={(e) => {
                            setStreet(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                      {showParentsFields ? (
                        <>
                          <div className="form-group form-group-col3">
                            <RequiredLabel
                              htmlFor="invoicePrefix"
                              text="Invoice Prefix"
                            />
                            <Input
                              type="text"
                              className="form-control"
                              value={invoicePrefix}
                              onChange={(e) => {
                                setInvoicePrefix(e.target.value);
                                setIAmTyping(true);
                              }}
                            />
                            {!invoicePrefix && saveTriggered ? (
                              <div className="alert alert-danger" role="alert">
                                This field is required!
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="form-group form-group-col3">
                            <RequiredLabel
                              htmlFor="invoiceCounter"
                              text="Invoice Counter"
                            />
                            <Input
                              type="number"
                              className="form-control"
                              value={invoiceCounter}
                              onChange={(e) => {
                                setInvoiceCounter(e.target.value);
                                setIAmTyping(true);
                              }}
                            />
                            {!invoiceCounter && saveTriggered ? (
                              <div className="alert alert-danger" role="alert">
                                This field is required!
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="form-group form-group-col3">
                            <RequiredLabel
                              htmlFor="transactionPrefix"
                              text="Transaction Prefix"
                            />
                            <Input
                              type="text"
                              className="form-control"
                              value={transactionPrefix}
                              onChange={(e) => {
                                setTransactionPrefix(e.target.value);
                                setIAmTyping(true);
                              }}
                            />
                            {!transactionPrefix && saveTriggered ? (
                              <div className="alert alert-danger" role="alert">
                                This field is required!
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="form-group form-group-col3">
                            <RequiredLabel
                              htmlFor="transactionCounter"
                              text="Transaction Counter"
                            />
                            <Input
                              type="number"
                              className="form-control"
                              value={transactionCounter}
                              onChange={(e) => {
                                setTransactionCounter(e.target.value);
                                setIAmTyping(true);
                              }}
                            />
                            {!transactionCounter && saveTriggered ? (
                              <div className="alert alert-danger" role="alert">
                                This field is required!
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ) : null}
                      <div className="form-group form-group-col3">
                        <Currency
                          fn={(e) => {
                            currencyHandler(e);
                            setIAmTyping(true);
                          }}
                          name="currency"
                          value={currency}
                          isRequired={true}
                          displayedText={"Invoice Currency"}
                        />
                      </div>

                      <div className="form-group form-group-col3">
                        <RequiredLabel
                          htmlFor="currencyRate"
                          text={"1 USD for each " + currency}
                        />
                        <Input
                          type="text"
                          className="form-control"
                          value={currencyRate}
                          onChange={(e) => {
                            setCurrencyRate(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required]}
                        />
                      </div>

                      <div className="form-group form-group-col3">
                        <OptionalLabel
                          htmlFor="defaultPlan"
                          text="Default Plan"
                        />
                        <PullDown
                          defaultValue={defaultPlan}
                          labelInside={true}
                          pulldownFor={"defaultPlan"}
                          forceBlank={true}
                          setValue={(value) => {
                            setDefaultPlan(value);
                            setIAmTyping(true);
                          }}
                          options={plans}
                        />
                      </div>
                      <div className="form-group form-group-col3">
                        <OptionalLabel
                          htmlFor="journeyForm"
                          text="Journey Form"
                        />
                        <PullDown
                          defaultValue={journeyForm}
                          labelInside={true}
                          pulldownFor={"journeyForm"}
                          forceBlank={true}
                          setValue={(value) => {
                            setJourneyForm(value);
                            setIAmTyping(true);
                          }}
                          options={journeyFormList}
                        />
                      </div>
                      <div className="form-group form-group-col3">
                        <OptionalLabel htmlFor="company" text="Company" />
                        <PullDown
                          defaultValue={company}
                          labelInside={true}
                          pulldownFor={"company"}
                          forceBlank={true}
                          setValue={(value) => {
                            setCompany(value);
                            setIAmTyping(true);
                          }}
                          options={suppliers}
                        />
                      </div>

                      <div className="form-group form-group-col3">
                        <RequiredLabel htmlFor="disabled" text="Disabled" />
                        <Switch
                          onChange={() => {
                            setDisabledSwitch();
                            setIAmTyping(true);
                          }}
                          checked={disabled}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  onClick={handleSaveBranch}
                >
                  Save
                </button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </Fragment>
  );
};

export default BranchForm;
