import React, { useState, useEffect, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import ModelService from "../services/model.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";

const currentUser = JSON.parse(localStorage.getItem('user'));
const Model = (props) => {
	const form = useRef();
	const checkBtn = useRef();

	const [label, setLabel] = useState("");
	const [type, setType] = useState("");
	const [description, setDescription] = useState("");
	const [frameSize, setFrameSize] = useState("");
	const [wheelDiameter, setWheelDiameter] = useState("");
	const [maxRiderWeight, setMaxRiderWeight] = useState("");
	const [maxSpeed, setMaxSpeed] = useState("");
	const [bikeWeight, setbikeWeight] = useState("");
	const [motorType, setMotorType] = useState("");
	const [motorRatedPower, setMotorRatedPower] = useState("");
	const [motorRatedRPM, setMotorRatedRPM] = useState("");
	const [motorRatedVoltage, setMotorRatedVoltage] = useState("");
	const [inventories, setInventories] = useState("");
	const [motorSpeedRange, setMotorSpeedRange] = useState("");
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	const [branch, setBranch] = useState("");
	const [voltage, setVoltage] = useState("");
	const [weight, setWeight] = useState("");
	const [ampere, setAmpere] = useState("");
	const modelId = props.match.params.id;


	useEffect(() => {
		window.scrollTo(0, 0);
		ModelService.getModelObjById(modelId).then(
			(response) => {
				let b = response.data;
				setLabel(b.label);
				setDescription(b.description);
				setType(b.type);
				setFrameSize(b.frameSize);
				setWheelDiameter(b.wheelDiameter ? b.wheelDiameter + " inch" : "");
				setMaxRiderWeight(b.maxRiderWeight ? b.maxRiderWeight + " Kg" : "");
				setMaxSpeed(b.maxSpeed ? b.maxSpeed + " Km/h" : "");
				setbikeWeight(b.bikeWeight ? b.bikeWeight + " Kg" : "");
				setMotorType(b.motorType);
				setMotorRatedPower(b.motorRatedPower ? b.motorRatedPower + " W" : "");
				setMotorRatedRPM(b.motorRatedRPM ? b.motorRatedRPM + " Nm" : "");
				setMotorRatedVoltage(b.motorRatedVoltage ? b.motorRatedVoltage + " V" : "");
				setMotorSpeedRange(b.motorSpeedRange);
				setBranch(b.branch);
				setInventories(b.inventories);
				setWeight(b.weight);
				setVoltage(b.voltage);
				setAmpere(b.ampere)


			},
			(error) => {
				const _content =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();
				alert(_content);
				setMessage(_content);

				if (error.response && error.response.status === 401) {
					EventBus.dispatch("logout");
				}
			}
		);
	}, []);


	return (
		<div className="col-md-12">

			<table className="styled-table" style={{ width: "100%" }}>
				<thead>
					<tr>
						<th style={{ width: "50%" }}>Model Specification</th>
						<th style={{ float: "right" }}>{currentUser.type == "admin" || currentUser.type == "manager" ?
							<NavLink to={"/model/edit/" + modelId} className="text-dark ">Edit</NavLink> : ""}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Label</td>
						<td>{label}</td>
					</tr>
					<tr>
						<td>Type</td>
						<td>{type}</td>
					</tr>

					{type === "bike" ?
						<>
							<tr>
								<td>Height of Seat Tube (cm)</td>
								<td>{frameSize}</td>
							</tr>
							<tr>
								<td>Wheel Diameter</td>
								<td>{wheelDiameter}</td>
							</tr>
							<tr>
								<td>Max Rider Weight</td>
								<td>{maxRiderWeight}</td>
							</tr>
							<tr>
								<td>Max Speed Allowed</td>
								<td>{maxSpeed}</td>
							</tr>
							<tr>
								<td>Weight (without battery)</td>
								<td>{bikeWeight}</td>
							</tr>
						</>
						: type === "battery" ?
							<>
								<tr>
									<td>Weight (Kg)</td>
									<td>{weight}</td>
								</tr>
								<tr>
									<td>Voltage (V)</td>
									<td>{voltage}</td>
								</tr>
								<tr>
									<td>Ampere (A)</td>
									<td>{ampere}</td>
								</tr>
							</>
							:
							<>
								<tr>
									<td>Voltage (V)</td>
									<td>{voltage}</td>
								</tr>
								<tr>
									<td>Ampere (A)</td>
									<td>{ampere}</td>
								</tr>
							</>
					}
					<tr>
						<td>Branch</td>
						<td>{branch.label}</td>
					</tr>
				</tbody>
			</table>



			{type === "bike" ?
				<>
					<table className="styled-table" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Motor Specification</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Type</td>
								<td>{motorType}</td>
							</tr>
							<tr>
								<td>Rated Power</td>
								<td>{motorRatedPower}</td>
							</tr>
							<tr>
								<td>Torque</td>
								<td>{motorRatedRPM}</td>
							</tr>
							<tr>
								<td>Rated Voltage</td>
								<td>{motorRatedVoltage}</td>
							</tr>

						</tbody>
					</table>
					<table className="styled-table" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "33%" }}>Inventories</th>
								<th style={{ width: "33%" }}></th><th></th>
							</tr>
						</thead>
						<tbody>
							{inventories && inventories.map((i) => {
								return (
									<tr>
										<td>
											<a target="_new" href={"/inventory/" + i._id} className="text-dark ">{i.number}</a>

										</td>

										<td>
											{i.label}
										</td>
										<td>
											{i.type}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</>
				: null}





		</div>
	);
};

export default Model;
