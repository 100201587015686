export const user_types = [
  { key: "admin", value: "Admin" },
  { key: "manager", value: "Manager" },
  { key: "technical", value: "Technical" },
  { key: "helpdesk", value: "Help Desk" },
  { key: "subscriber", value: "Subscriber" },
  { key: "registered", value: "Registered" },
  { key: "accountant", value: "Accountant" },
  { key: "reseller", value: "Reseller" },
];

export const invoice_types = [
  { key: "Standard", value: "Subscription Fee" },
  { key: "Receipt Deposit", value: "Receipt Deposit" },
  { key: "Return Deposit", value: "Return Deposit" },
  { key: "Accessories", value: "Accessories" },
  { key: "Accident", value: "Accident Fees" },
  { key: "Penalty", value: "Penalty" },
  { key: "Refund", value: "Refund" },
  { key: "Surchage", value: "Short Period Surchage" },
];

export const invoice_company_types = [
  { key: "Accessories", value: "Accessories" },
  { key: "Accident", value: "Accident Fees" },
  { key: "Penalty", value: "Penalty" },
  { key: "Refund", value: "Refund" },
  { key: "Surchage", value: "Short Period Surchage" },
];
export const bike_status = [
  { key: "NotReady", value: "Test/Maintenance" },
  { key: "Ready", value: "Ready" },
  { key: "In Use", value: "In Use" },
  { key: "Maintenance", value: "Maintenance" },
  { key: "Test", value: "Test" },
  { key: "Stolen", value: "Stolen" },
];

export const bike_edit_status = [
  { key: "Ready", value: "Ready" },
  { key: "In Use", value: "In Use" },
  { key: "Stolen", value: "Stolen" },
];

// Those are the inventory subcategory. Be careful, that whatever added here, should be added under maintenace form.
export const inventoryType = [
  "Inner Tube",
  "Rear hub",
  "Front hub",
  "Tube cap",
  "Tire",
  "Front wheel",
  "Rear wheel",
  "Brake pads",
  "Brake pads clamp",
  "Left brake lever",
  "Right brake lever",
  "Front caliper",
  "Rear caliper",
  "Derailleur",
  "Hanger",
  "Shifter",
  "Chain",
  "Chain missing links",
  "Rear cassette gears",
  "Chain guard",
  "Front fork",
  "Front fender",
  "Rear fender",
  "Fender extender",
  "Kickstand",
  "Saddle",
  "Rear rack",
  "Front rack",
  "Frame",
  "Clamp",
  "Seat post",
  "Handle bar",
  "Stem",
  "Phone holder",
  "Grip left",
  "Grip right",
  "Pedal right",
  "Pedal left",
  "Pedal arm right",
  "Pedal arm left",
  "Crankset",
  "Bell",
  "Blue Gear",
  "Lock",
  "Lock chain",
  "Keys",
  "Front light",
  "Rear light",
  "Front light bracket",
  "Rear light connector",
  "Display",
  "Motor controller",
  "Usb cable",
  "Display cable",
  "Seatpost",
  "Chain ( Old type)",
  "Chain (New type)",
  "Inner Tube(decathlon)",
  "Tube caps",
  "Tire liner",
  "Rim",
  "Spoke",
  "Nipple",
  "Head set - spacers",
  "Brake disc",
  "Straps",
  "Helmet",
  "Bag",
  "Bottle",
];

export const model_types = [
  { key: "bike", value: "Bike" },
  { key: "battery", value: "Battery" },
  { key: "charger", value: "Charger" },
];
export const action_m_types = {
  quickcheckup: "Quick Check up",
  checkup: "Check up",
  repair: "Repair",
  accident: "Accident",
  update: "Update",
};

export const action_taken_fix = [
  { key: "switch", value: "Switch" },
  { key: "update", value: "Update" },
  { key: "fix", value: "Fix on the spot" },
  { key: "takeFromBike", value: "Take from another bike" },
];

export const menu_select_hover_color = "#595959";

export const languageOptions = [
  ["en", "English"],
  ["tr", "Turkish"],
];

export const currencyOptions = [
  ["LBP", "LBP"],
  ["USD", "USD"],
  ["Euro", "Euro"],
];

export const waitings_status = [
  { key: "Not Contacted", value: "Not Contacted" },
  { key: "Lost Interest", value: "Lost Interest" },
  { key: "Follow up", value: "Follow up" },
  { key: "On Top", value: "On Top" },
  { key: "Confirmed", value: "Confirmed" },
  { key: "No Reply", value: "No Reply" },
];

export const add_old_part_to = [
  { key: "stock", value: "Stock" },
  { key: "repair", value: "Repair" },
  { key: "recycle", value: "Recycle" },
  { key: "obsolete", value: "Obsolete" },
  { key: "lost", value: "Lost" },
];

export const faq_categories = [
  { key: "Wave", value: "Wave" },
  { key: "Technical", value: "Technical" },
  { key: "Incidents", value: "Incidents" },
  { key: "Subscription", value: "Subscription" },
  { key: "Safety", value: "Safety" },
  { key: "Maintenance", value: "Maintenance" },
];

export const subscribeFilters = [
  { key: "", value: "All" },
  { key: "active", value: "Active" },
  { key: "terminated", value: "Terminated" },
  { key: "draft", value: "Draft" },
  { key: "pendingReturn", value: "Pending Return" },
  { key: "pendingInvoiceRenewal", value: "Pending Invoice Renewal" },
  { key: "frozen", value: "Frozen" },
];

export const answerTypeOptions = [
  { key: "yes_no", value: "Yes/No" },
  { key: "yes_maybe_no", value: "Yes/Maybe/No" },
  { key: "Radio", value: "Radio" },
  { key: "Checkbox", value: "Checkbox" },
  { key: "slider1to10", value: "Slider 1 to 10" },
  { key: "slider1to5", value: "Slider 1 to 5" },
  { key: "input", value: "Input" },
  { key: "textarea", value: "Textarea" },
  { key: "date", value: "Date" },
];

export const source_register = [
  { key: "Social Media", value: "Social Media" },
  { key: "Referral", value: "Referral" },
  { key: "Google", value: "Google" },
  { key: "Company/University", value: "Company/University" },
  { key: "Spotted a Wave bike", value: "Spotted a Wave bike" },
  { key: "Other", value: "Other" },
];

export const plan_register = [
  { key: "Basic", value: "The Basic Plan", publicOrder: 0 },
  { key: "Student", value: "The Student Plan", publicOrder: 1 },
  { key: "Super", value: "The Super Plan", publicOrder: 2 },
  { key: "Ultimate", value: "The Ultimate Plan", publicOrder: 3 },
  { key: "None", value: "Haven't decided yet", publicOrder: 4 },
];

export const form_types = [
  { key: "journey", value: "Journey" },
  { key: "General", value: "General" },
  { key: "Events", value: "Events" },
];

export const accountTypes = [
  { key: "cash", label: "Cash" },
  { key: "bank", label: "Bank" },
  { key: "safe", label: "Safe" },
];

export const planTypes = [
  { value: "hourly", label: "Hourly" },
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
];
export const categoryUsage = [
  { key: "", label: "" },
  { key: "transactions", label: "Transactions" },
  { key: "termination", label: "Termination" },
];

export const months_opt = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const invoicesTypeList = [
  { value: "Deposit", label: "Deposit" },
  { value: "Sales", label: "Sales" },
  { value: "Cancelled", label: "Cancelled" },
];

export const userSubscribeType = [
  { value: "shortterm", label: "Short-Term" },
  { value: "membership", label: "Membership" },
];
