import React, { useState, useEffect, useRef, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import PlanService from "../services/plan.service";
import EventBus from "../common/EventBus";
import commonFunctions from "../common/Functions";
import { required } from "../common/Validation";
import { getBranches } from "../common/getFunctions";
import Switch from "react-switch";
import Currency from "../commonComponents/Currency";
import RequiredLabel from "../common/RequiredLabel";
import BranchePullDown from "../commonComponents/BranchePullDown";
import { Prompt } from "react-router-dom";
import PlanTypes from "../commonComponents/PlanTypes";
import { Col, Row } from "react-bootstrap";
import OptionalLabel from "../common/OptionalLabel";
import Textarea from "react-validation/build/textarea";
import SinglePlan from "../LandingPage/components/SingelPlan";

const PlanForm = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [label, setLabel] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [internal, setInternal] = useState(false);
  const [period, setPeriod] = useState("");
  const [currency, setCurrency] = useState("");
  const [VAT, setVAT] = useState("");
  const [price, setPrice] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [receiptPrice, setReceiptPrice] = useState("");
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState(currentUser.branch);
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [planId, setPlanId] = useState(props.match.params.id);
  const clone = props.location.pathname.includes("/clone/");
  const [penaltyPerDay, setpenaltyPerDay] = useState("");
  const [batteriesCount, setBatteriesCount] = useState(1);
  const [chargersCount, setChargersCount] = useState(1);
  const [planType, setPlanType] = useState("monthly");
  const [periodLabel, setPeriodLabel] = useState("months");
  const [iAmTyping, setIAmTyping] = useState(false);
  const [activateInvoiceSurcharge, setActivateInvoiceSurcharge] =
    useState(false);
  const [publicTitle, setPublicTitle] = useState("");
  const [showPublic, setShowPublic] = useState(false);
  const [subTitle, setSubTitle] = useState("");
  const [includesDesc, setIncludesDesc] = useState("");
  const [remark, setRemark] = useState("");
  const [titleColor, setTitleColor] = useState("#3CB4E5");
  const [orderonPublic, setOrderOnPublic] = useState(0);

  const getPlanInfo = async () => {
    if (planId) {
      PlanService.getPlanById(planId).then(
        (response) => {
          let b = response.data;
          setLabel(b.label);
          setDisabled(b.disabled);
          setActivateInvoiceSurcharge(b.activateInvoiceSurcharge);
          setOrderOnPublic(b.orderonPublic);
          setPublicTitle(b.publicTitle);
          setShowPublic(b.showPublic);
          setSubTitle(b.subTitle);
          setIncludesDesc(b.includesDesc);
          setRemark(b.remark);
          setTitleColor(b.titleColor || "#3CB4E5");
          setInternal(b.internal);
          setPeriod(b.period);
          setPrice(b.price);
          setTotalPrice(Number(b.price * (1 + b.VAT / 100)).toFixed(2));
          setBranch(b.branch);
          setCurrency(b.currency);
          setReceiptPrice(b.receiptPrice);
          setVAT(b.VAT);
          handlePlanChange(b.planType || "monthly");
          setpenaltyPerDay(b.penalty);
          setBatteriesCount(b.batteriesCount || 1);
          setChargersCount(b.chargersCount || 1);
          if (clone === true) {
            setPlanId("");
          } // reset the id to clone
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          alert(_content);
          setMessage(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  const maxNumber = (value) => {
    if (value < 1 || value > 2) {
      return (
        <div className="alert alert-danger" role="alert">
          The field must be between either 1 or 2
        </div>
      );
    }
  };

  const handlePlanChange = (value) => {
    setPlanType(value);
    let label = "months";
    if (value == "hourly") {
      label = "hours";
    } else if (value == "daily") {
      label = "days";
    } else if (value == "weekly") {
      label = "weeks";
    }
    setPeriodLabel(label);
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
      getPlanInfo();
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }

    onReady();
  }, []);

  const setPrices = (p) => {
    setPrice(Number(p / (1 + VAT / 100)));
    setTotalPrice(p);
  };
  const triggerSetVat = (v) => {
    setVAT(v);
    setPrice(Number(totalPrice / (1 + v / 100)));
  };

  const handleSavePlan = (e) => {
    e.preventDefault();
    setIAmTyping(false);
    setMessage("");
    setSuccessful(false);
    if (planType === "monthly") {
      if (!receiptPrice) {
        alert("Receipt Deposit is required!");
        return;
      }

      if (!penaltyPerDay) {
        alert("Penalty Per Day  is required!");
        return;
      }
    }
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      PlanService.postPlan(
        planId,
        label,
        disabled,
        period,
        price,
        branch,
        VAT,
        receiptPrice,
        currency,
        penaltyPerDay,
        internal,
        chargersCount,
        batteriesCount,
        activateInvoiceSurcharge,
        planType,
        publicTitle,
        showPublic,
        subTitle,
        includesDesc,
        remark,
        orderonPublic,
        titleColor
      ).then(
        (response) => {
          setMessage("Plan Saved.");
          setSuccessful(true);
          props.history.push("/plans");
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };

  const setActivateInvoiceSurchargeSwitch = () => {
    setActivateInvoiceSurcharge(!activateInvoiceSurcharge);
  };
  const setShowPublicSwitch = () => {
    setShowPublic(!showPublic);
  };
  const setDisabledSwitch = () => {
    setDisabled(!disabled);
  };
  const setInternalSwitch = () => {
    setInternal(!internal);
  };

  return (
    <Fragment>
      <Prompt
        when={iAmTyping}
        message={(location) =>
          "Are you sure, you want to leave? All your data will be lost!"
        }
      />
      <div className="col-md-12">
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          ref={form}
        >
          {!successful && (
            <div>
              <table className="styled-table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "25%" }}>Create a Plan</th>
                  </tr>
                </thead>
                <tbody>
                  <Row
                    className="white-background "
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                  >
                    {currentUser.type === "admin" ? (
                      <Col md={3}>
                        <div className="form-group">
                          <RequiredLabel htmlFor="branch" text="Branch" />
                          <BranchePullDown
                            branch={branch}
                            labelInside={true}
                            forceBlank={true}
                            setBranch={setBranch}
                            branches={branches}
                          />
                        </div>
                      </Col>
                    ) : null}

                    <Col md={3}>
                      <div className="form-group">
                        <RequiredLabel htmlFor="label" text="Label" />
                        <Input
                          type="text"
                          className="form-control"
                          name="label"
                          value={label}
                          onChange={(e) => {
                            setLabel(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required]}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <RequiredLabel htmlFor="planType" text="Plan Type" />
                        <PlanTypes
                          name="planType"
                          addFormGroup="no"
                          labelInside={false}
                          forceBlank={false}
                          defaultValue={planType}
                          setValue={handlePlanChange}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <Currency
                          fn={(e) => {
                            setCurrency(e.target.value);
                            setIAmTyping(true);
                          }}
                          name="currency"
                          value={currency}
                          isRequired={true}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="period"
                          text={"Period (in " + periodLabel + ")"}
                        />
                        <Input
                          type="number"
                          className="form-control"
                          name="period"
                          value={period}
                          onChange={(e) => {
                            setPeriod(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="price"
                          text={"Standard Price with " + VAT + "% VAT"}
                        />
                        <Input
                          type="number"
                          className="form-control"
                          value={commonFunctions.CommaFormatted(totalPrice)}
                          // value={price}
                          onChange={(e) => {
                            setPrices(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required]}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <RequiredLabel htmlFor="VAT" text="VAT Standard" />
                        <Input
                          type="number"
                          className="form-control"
                          value={VAT}
                          onChange={(e) => {
                            triggerSetVat(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                    </Col>

                    {planType == "monthly" ? (
                      <Col md={3}>
                        <div className="form-group">
                          <RequiredLabel
                            htmlFor="receiptPrice"
                            text="Receipt Deposit"
                          />
                          <Input
                            type="text"
                            className="form-control"
                            value={commonFunctions.CommaFormatted(receiptPrice)}
                            onChange={(e) => {
                              setReceiptPrice(e.target.value);
                              setIAmTyping(true);
                            }}
                          />
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}

                    {planType == "monthly" ? (
                      <Col md={3}>
                        <div className="form-group">
                          <RequiredLabel
                            htmlFor="penaltyPerDay"
                            text="Penalty Per Day"
                          />
                          <Input
                            type="text"
                            className="form-control"
                            value={penaltyPerDay}
                            onChange={(e) => {
                              setpenaltyPerDay(e.target.value);
                              setIAmTyping(true);
                            }}
                          />
                        </div>{" "}
                      </Col>
                    ) : (
                      ""
                    )}

                    <Col md={3}>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="chargersCount"
                          text="Chargers Count"
                        />
                        <Input
                          type="number"
                          min="1"
                          max="2"
                          className="form-control"
                          value={chargersCount}
                          onChange={(e) => {
                            setChargersCount(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required, maxNumber]}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="batteriesCount"
                          text="Batteries Count"
                        />
                        <Input
                          type="number"
                          min={1}
                          max={2}
                          className="form-control"
                          value={batteriesCount}
                          onChange={(e) => {
                            setBatteriesCount(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required, maxNumber]}
                        />
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="activateInvoiceSurcharge"
                          text="Activate Invoice Surcharge"
                        />
                        <Switch
                          onChange={(e) => {
                            setActivateInvoiceSurchargeSwitch(e);
                            setIAmTyping(true);
                          }}
                          checked={activateInvoiceSurcharge}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      {" "}
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="internal"
                          text="Internal Plan"
                        />
                        <Switch
                          onChange={() => {
                            setInternalSwitch();
                            setIAmTyping(true);
                          }}
                          checked={internal}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <RequiredLabel htmlFor="disabled" text="Disabled" />
                        <Switch
                          onChange={() => {
                            setDisabledSwitch();
                            setIAmTyping(true);
                          }}
                          checked={disabled}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="showPublic"
                          text="Show on Register"
                        />
                        <Switch
                          onChange={(e) => {
                            setShowPublicSwitch(e);
                            setIAmTyping(true);
                          }}
                          checked={showPublic}
                        />
                      </div>
                    </Col>
                  </Row>
                </tbody>
              </table>
              <table className="styled-table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "100%" }}>Plan Public Info</th>
                  </tr>
                </thead>
                <tbody>
                  <Row
                    className="white-background "
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                  >
                    <Col md={3}>
                      <div className="form-group">
                        <OptionalLabel
                          htmlFor="publicTitle"
                          text="Public Title"
                        />
                        <Input
                          type="text"
                          className="form-control"
                          name="publicTitle"
                          value={publicTitle}
                          onChange={(e) => {
                            setPublicTitle(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <OptionalLabel
                          htmlFor="orderonPublic"
                          text="plan Order:"
                        />
                        <Input
                          type="number"
                          className="form-control"
                          name="orderonPublic"
                          value={orderonPublic}
                          onChange={(e) => {
                            setOrderOnPublic(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <OptionalLabel htmlFor="remark" text="Remark" />
                        <Input
                          type="text"
                          className="form-control"
                          name="remark"
                          value={remark}
                          onChange={(e) => {
                            setRemark(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <OptionalLabel
                          htmlFor="titleColor"
                          text="Title Color"
                        />
                        <Input
                          type="text"
                          className="form-control"
                          name="titleColor"
                          value={titleColor}
                          onChange={(e) => {
                            setTitleColor(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group">
                        <OptionalLabel
                          htmlFor="subTitle"
                          text="Sub Title Pargraph"
                        />
                        <Textarea
                          type="text"
                          className="form-control"
                          name="subTitle"
                          value={subTitle}
                          onChange={(e) => {
                            setSubTitle(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group">
                        <OptionalLabel
                          htmlFor="includesDesc"
                          text="Includes Section"
                        />
                        <Textarea
                          type="text"
                          className="form-control"
                          name="includesDesc"
                          value={includesDesc}
                          onChange={(e) => {
                            setIncludesDesc(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div
                      style={{
                        border: "1px solid black;",
                        pointerEvents: "none",
                      }}
                    >
                      <b>Preview:</b>
                      <SinglePlan
                        index={orderonPublic}
                        planTitle={publicTitle}
                        titleColor={titleColor}
                        description={subTitle}
                        includesDescription={includesDesc}
                        remark={remark}
                      />
                    </div>
                  </Row>
                </tbody>
              </table>
              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  onClick={handleSavePlan}
                >
                  Save
                </button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </Fragment>
  );
};

export default PlanForm;
