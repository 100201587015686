import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import AccordionItem from "../components/AccordionItem";
import Header from "../components/Header";
import FaqData from "../assets/data/FaqData.js";
import FaqService from "../../services/faq.service";
import CommonService from "../../services/common.service";
import "../css/faq.css";
import { trackPage } from "../components/GoogleAnalytics.js";

export default function FAQ() {
  const [faqs, setFaqs] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState({
    name: "",
    typing: false,
    typingTimeout: 0,
  });

  useEffect(() => {
    async function onReady() {
      let response = await CommonService.getPublicInfo();
      let country = response.data ? response.data.country : "";
      setCountry(country);
      await fetchFAQS("", country);
    }
    window.scrollTo(0, 0);
    trackPage("FAQ");

    onReady();
  }, []);

  const fetchFAQS = async (name, c) => {
    console.log("name", name);
    return await FaqService.getFaqsList("", name, "", 0, 15, false, c).then(
      ({ data }) => {
        console.log("data", data);
        setFaqs(data.data.list);
      }
    );
  };

  const changeName = (event) => {
    if (state.typingTimeout) {
      clearTimeout(state.typingTimeout);
    }

    const name = event.target.value;

    setState({
      typing: false,
      typingTimeout: setTimeout(async function () {
        await fetchFAQS(name, country);
      }, 500),
    });
  };

  return (
    <div>
      {/* <img className="d-block w-100" src={FAQImg} alt="" /> */}

      <div className="landing_FAQ_search">
        <h1
          style={{
            color: "#595959",
            textAlign: "center",
            paddingTop: "80px",
          }}
        >
          <b className="pageTitle">
            <div className="FAQAccent">
              <div class="accent_container_faq">
                How can
                <div class="accent_logo_container">
                  <div class="accent_rectagle"></div>
                  <div class="accent_circle"></div>
                </div>
              </div>
              <div className="accent_container_faq">&nbsp;we help you?</div>
            </div>
          </b>
        </h1>
        <div className="isBrowser">
          <br />
        </div>

        <div className="isBrowser">
          <input
            style={{ fontSize: 20 }}
            type="text"
            onChange={changeName}
            autoComplete="off"
            name="search"
            id="landing_FAQ_search_input"
            placeholder="Type a keyword (e.g. brakes)"
          />
        </div>
        <div className="isMobile">
          <input
            style={{ fontSize: 15 }}
            type="text"
            onChange={changeName}
            autoComplete="off"
            name="search"
            id="landing_FAQ_search_input"
            placeholder="Type a keyword (e.g. brakes)"
          />
        </div>
        <div className="faqInfo">
          In case you do not find what you are looking for, kindly contact us
          via hello@wave.bike or via our whatsapp number +961 70 632 955
        </div>
      </div>

      <div id="landing_FAQ_container">
        <Accordion style={{ width: "100%" }} alwaysOpen>
          {faqs.length > 0 &&
            faqs.slice(0, 10).map((faq, index) => (
              <div style={{ marginTop: 10 }}>
                <AccordionItem
                  key={faq._id}
                  index={index}
                  question={faq.title}
                  answer={faq.content}
                />
              </div>
            ))}
        </Accordion>
      </div>
    </div>
  );
}
