const googleCode = "G-RXM27ZPC3X";

export function trackPage(pageTitle) {
  document.title = pageTitle;
  let url = window.location.href;

  if (
    typeof gtag === "function" &&
    url.indexOf("wave.bike") != -1 &&
    url.indexOf("beta.wave.bike") == -1
  ) {
    window.dataLayer = window.dataLayer || [];
    window.gtag("js", new Date());
    window.gtag("config", googleCode);

    // Fire a Google Analytics event
    window.ga("send", "event", "Page View", pageTitle);
  }
}
/*ga('send', 'event', {
    'event': args[0],
    'gtm.uniqueEventId': args[1]
  });*/
