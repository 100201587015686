import React from "react";
import "../css/accent.css";

export default function Accent({ children, ...props }) {
  return (
    <div className="accent_container" {...props}>
      {children}

      <div className="accent_logo_container">
        <div className="accent_rectagle"></div>

        <div className="accent_circle"></div>
      </div>
    </div>
  );
}
