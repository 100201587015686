import React, { useEffect } from "react";
import "../css/about.css";

export default function LandingPrivacyStatement() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="public-page-container">
      <div className="landing_About_section">
        <section>
          <div className="isBrowser">
            <br />
            <br />
          </div>
          <b>
            <h2 style={{ color: "#595959", textAlign: "center" }}>
              Privacy Statement
            </h2>
          </b>
          <h4 style={{ color: "#595959", textAlign: "center" }}>
            Progoris B.V. / Wave
          </h4>
          <br />
          <p>
            Wave offers subscriptions for continuous mobility, for example, by
            e-bikes, as well as a mobile application with functionality that
            enhances your cycling experience. To provide our services to you, we
            need to process certain data about you. We take your privacy very
            seriously and will always process your personal data in accordance
            with the guidelines of this document (hereinafter: “Privacy
            Statement”). You can also find information about us, our application
            platform and our legal basis to process your personal data in this
            Privacy Statement. Any capitalized terms you may encounter are
            defined in our Terms & Conditions, available here:
          </p>
          <br />
          <section>
            <b>
              <h4>1. Who does this Privacy Statement apply to?</h4>
            </b>
            <p>
              This Privacy Statement applies to all visitors of the website
              <a href="https://wave.bike/"> www.wave.bike,</a> users of our
              mobile application and renters of our e-bikes that share personal
              information with us. Whether you are a mere visitor of our
              website, contact us directly or rent a Bicycle, we will always
              process your personal data in accordance with this Privacy
              Statement.
            </p>
          </section>

          <section>
            <b>
              <h4>2. Who are we?</h4>
            </b>
            <p>
              The services are offered by Progoris B.V., also trading under the
              name “Wave”, a limited liability company incorporated under Dutch
              law, having its registered office at Molenlaan 253, (3055GE)
              Rotterdam, the Netherlands. Progoris B.V., or any local affiliate
              thereof (if so, the relevant company details will be stated in the
              Agreement) is the controller for the processing of your personal
              data.
              <br />
              You can contact us directly by sending an e-mail to
              hello@wave.bike.
            </p>
          </section>
          <section>
            <b>
              <h4>3. Which types of personal data do we process?</h4>
            </b>
            During your use of our website, our Application the rent of a
            Bicycle, we may process the following personal data of you:
            <br />
            <br />
            a. Personal data you provide when you conclude an Agreement and rent
            a Bicycle, which includes:
            <ul>
              <li>First name and last name;</li>
              <li>Phone number;</li>
              <li>Email address;</li>
              <li>Date of birth;</li>
              <li>
                Address, place of residence and postal code. If we cannot locate
                your position based on these data or you do not have a physical
                address, we may collect and save the coordinates of your
                physical location;
              </li>
              <li>
                Identification document (passport, driver’s license, etc.).
              </li>
            </ul>
            <br />
            b. Personal data you provide when you create an account on our
            website or in our Application:
            <ul>
              <li>First name and last name;</li>
              <li>Phone number;</li>
              <li>Email address;</li>
              <li>Date of birth;</li>
              <li>Photo</li>
            </ul>
            <br />
            c. Personal information we require to process payments:
            <ul>
              <li>First name and last name;</li>
              <li>
                If applicable for the desired payment method: bank account
                information, credit card data and/or address.
              </li>
            </ul>
            <br />
            d. Personal data to contact you for marketing purposes:
            <ul>
              <li>First name and last name;</li>
              <li>Email address and/or phone number</li>
            </ul>
            <br />
            e. Personal data we require to communicate with you to answers
            questions, schedule a service appointment or otherwise related to
            our services:{" "}
            <ul>
              <li>Via email: First name, last name and email address;</li>
              <li>Via telephone: First name, last name, phone number;</li>
              <li>Via text messages: First name, last name, phone number;</li>
              <li>Via WhatsApp: First name, last name, phone number.</li>
            </ul>
            <br />
            f. Personal data we require to offer and improve our location based
            cycling routes functionality in our Application:{" "}
            <ul>
              <li>
                Your location data, in particular the routes taken by you and
                logged in our Application, which will be anonymized and
                aggregated for this purpose.
              </li>
            </ul>
            <br />
            g. Personal data we require to offer location based services in our
            Application and to schedule service appointments at your location:
            <ul>
              <li>Your location data.</li>
            </ul>
            <br />
            h. Personal data you provide when you visit our website:
            <ul>
              <li>IP address; </li>
              <li>
                Information about your device, browser, operating system, and
                network system.
              </li>
            </ul>
            <br />
          </section>
          <section>
            <b>
              <h4>4. For which purposes do we process your personal data?</h4>
            </b>
            We may use your personal data to:
            <br />
            <br />
            <ul>
              <li>Create and administer an account;</li>
              <li>Process payments;</li>
              <li>Conclude a rental agreement;</li>
              <li>
                Give you access to our Application and to provide our services;
              </li>
              <li>Verify your identity;</li>
              <li>
                Provide location based services to enhance your cycling
                experience;
              </li>
              <li>
                Offer and improve our location based cycling routes
                functionality in our Application;
              </li>
              <li>Analyse your use of our website and the Application;</li>
              <li>Provide customer support and make service appointments;</li>
              <li>Send you updates, newsletters, surveys or promotions;</li>
              <li>
                Contact you about other aspects of our services, including your
                satisfaction.
              </li>
            </ul>
            We will not process your personal data for other purposes.
            <br />
            <br />
          </section>
          <section>
            <b>
              <h4>
                5. Which legal grounds apply to our processing of personal data?
              </h4>
            </b>
            We are entitled to process your personal data pursuant to different
            legal grounds. The following table contains an overview of the legal
            grounds we invoke to process specific personal data:
            <br />
            <br />
            <table border="5">
              <tr>
                <td>
                  <b>Processing activity</b>
                </td>
                <td>
                  <b>Legal ground</b>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>Create and administer an account;</li>
                    <li>Process payments;</li>
                    <li>Conclude a rental agreement;</li>
                    <li>Verify your identity;</li>
                    <li>
                      Give you access to our Application and to provide our
                      services;
                    </li>
                    <li>Customer support services.</li>
                  </ul>
                </td>
                <td>Performance of a contract</td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>Marketing activities (promotional communication);</li>
                    <li>
                      Browsing information for profiling/tracking purposes;
                    </li>
                    <li>
                      Provide location based services to enhance your cycling
                      experience;
                    </li>
                    <li>
                      Offer and improve our location based cycling routes
                      functionality in our Application.
                    </li>
                  </ul>
                </td>
                <td>Consent (which you can revoke at any time)</td>
              </tr>
              <tr>
                <td>Browsing information for analytical purposes</td>
                <td>Our legitimate interests</td>
              </tr>
            </table>
            <br />
            <br />
          </section>
          <section>
            <b>
              <h4>6. How long do we retain your personal data? </h4>
            </b>
            We will not retain your personal data longer than necessary for the
            purposes for which we have obtained such data.
            <br />
            <br />
            <table border="5">
              <tr>
                <td>
                  <b>Category</b>
                </td>
                <td>
                  <b>Retention period</b>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>
                      Personal data you provide when you conclude an Agreement
                      and rent a Bicycle;
                    </li>
                    <li>
                      Personal data we require to communicate with you for the
                      performance of the Agreement and to schedule appointments
                      at your location;
                    </li>
                    <li>
                      Personal information we require to process payments.
                    </li>
                  </ul>
                </td>
                <td>For the term of the Agreement</td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>
                      Personal data you provide when you create an account on
                      our website or in our Application;
                    </li>
                    <li>
                      Personal data we require to offer location based services
                      in our Application.
                    </li>
                  </ul>
                </td>
                <td>Until you request us to terminate your account</td>
              </tr>
              <tr>
                <td>Personal data to contact you for marketing purposes; </td>
                <td>Until you unsubscribe from our marketing communications</td>
              </tr>
              <tr>
                <td>Personal data you provide when you visit our website </td>
                <td>Until you clear your browser data, including cookies</td>
              </tr>
            </table>
            <br />
            We will not delete your personal data if we are required to retain
            such data to comply with any statutory obligation. In particular, we
            reserve our rights to retain the information provided for the
            conclusion of an Agreement for 7 years. In addition, we may retain
            personal data that are necessary for the purposes of legal
            proceedings, complaints and disputes for as long as this is
            necessary to protect our legitimate interests.
            <br />
            <br />
          </section>
          <section>
            <b>
              <h4>7. Who has access to your personal data?</h4>
            </b>
            <p>
            Only our authorised personnel shall have access to your personal data on a
need-to-know basis. These persons have undergone background screening before they
were employed and are required to treat the information as highly confidential.
In addition, we may engage third party service providers to provide ancillary services. If
and to the extent such parties will come into the possession of your personal data, such
parties will act as our data processor. These are, for example, cloud service providers. We
will enter into a data processing agreement with these parties, which shall include
arrangements on data security to protect your personal data against data breaches.
Such data processors will only process your personal data to the extent required for the
provision of services for which they are engaged.
We may provide your personal data to competent authorities upon their request to the
extent legally required or to the extent necessary to defend our rights in legal proceedings
or investigations.
            </p>
          </section>
          <section>
            <b>
              <h4>8. Is your personal data secure?</h4>
            </b>
            <p>
            We process your personal data with the greatest possible care. This means we will adopt
appropriate technical and organisational measures to ensure that all the information is
correct, current and complete and to prevent it from being accessed by unauthorised
persons inside and outside our organisation. We use ‘best practices’ to secure your
personal data. For instance, your personal data is encrypted with Secure Sockets Layered
(SSL) technology and our directories and databases are password protected. Only the
individuals with accounts authorized by us shall have access to personal data. Such
accounts have restricted access so that authorized individuals can only access the
personal data they need to access.
            </p>
          </section>
          <section>
            <b>
              <h4>9. What are your rights in respect of your personal data?</h4>
            </b>
            <p>
            You are entitled to ask us if we are processing your personal data. You may at any time,
with reasonable intervals, exercise your right of access to your personal data. Upon your
inspection, you may request us to rectify or erase any inaccurate information or have us
restrict or terminate the processing of your personal data. However, we reserve our right to
deny such a request to restrict or terminate the processing of your personal data if we
have a legitimate interest to continue the processing of your personal data, in particular if
we require the information in (possible) legal proceedings.<br/>You are entitled to request us to transfer (certain of) your personal data to you or to
another party.<br/>If your personal data is being processed based on your consent, you may withdraw your
consent at any time. In order to exercise this right and the rights as mentioned above
please contact us using the contact information set out in this Privacy Statement.
            </p>
          </section>
          <section>
            <b>
              <h4>10. Use of Cookies</h4>
            </b>
            We use cookies when you visit our website. A cookie is a small file that is saved on your
computer, tablet or mobile phone when viewing a website. With the help of cookies user
preferences may be saved for later use, or browsing habits can be tracked for statistical
purposes. A lot of improvements in a website’s user experience and performance are
possible thanks to the implementation of cookies.
            <br />
            We use the following cookies:
            <br />
            <ul>
              <li>Functional cookies to enable your use of our website and our Application, and in
our emails so that we can check the receipt of our emails;</li>
              <li>Analytical cookies to show us which pages you visited and clicked on within our
website. We use the web statistics of this behaviour to improve our services, and
more specifically our search results. The collected data through cookies - including
IP addresses – remains anonymous but can be stored by Google on servers in the
United States. Read more about Google’s Privacy Policy here:
<a href="https://policies.google.com/privacy"> https://www.google.com/policies/privacy/</a></li>
              <li>Tracking cookies (installed with your consent only) to keep track of the web pages
you visit and to create a profile around your browsing behaviour. We use this
information to serve adds of our products or services which are relevant to you on
websites of third parties.</li>
              
            </ul>
            These cookies are saved until you delete them in your Internet browser. You can block the
use of cookies by changing the settings in your browser. This may, however, limit your user
experience. More information on enabling, disabling and deleting cookies can be found in
the Help-function of your browser.            <br />
            <br />
          </section>
          <section>
            <b>
              <h4>11. Questions?</h4>
            </b>
            If you have any questions concerning the manner in which we process your personal data,
or you wish to invoke any of your statutory rights, you may contact us via the email
address provided on the “Contact Us” page on our website.
We may update this Privacy Statement from time to time. We will inform you when we
make changes to our Privacy Statement and you can always find the most recent version
of the Privacy Statement on <a href="https://wave.bike/">www.wave.bike.</a><br/><br/>
            </section>
        </section>
      </div>
    </div>
  );
}
