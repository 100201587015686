import React, { useState, useEffect, useRef, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import CategoryService from "../services/cashLog.service";
import EventBus from "../common/EventBus";
import { required } from "../common/Validation";
import { getBranches } from "../common/getFunctions";
import { Col, Row } from "react-bootstrap";
import RequiredLabel from "../common/RequiredLabel";
import BranchePullDown from "../commonComponents/BranchePullDown";
import { categoryUsage } from "../labels/options_en";
import { Prompt } from "react-router-dom";

const CategoryForm = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [label, setLabel] = useState("");
  const [key, setKey] = useState("");
  const [usage, setUsage] = useState("");
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState(currentUser.branch);
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [categoryId, setCategoryId] = useState(props.match.params.id);
  const clone = props.location.pathname.includes("/clone/");

  const [iAmTyping, setIAmTyping] = useState(false);

  const getCategoryInfo = async () => {
    if (categoryId) {
      CategoryService.getCategoryById(categoryId).then(
        (response) => {
          let b = response.data;
          setLabel(b.label);
          setKey(b.key);
          setUsage(b.usage);
          setBranch(b.branch || "null");
          if (clone === true) {
            setCategoryId("");
          } // reset the id to clone
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          alert(_content);
          setMessage(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
      getCategoryInfo();
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }

    onReady();
  }, []);

  const handleSaveCategory = (e) => {
    e.preventDefault();
    setIAmTyping(false);
    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      CategoryService.postCategory({
        branch,
        categoryId,
        label,
        key,
        usage,
      }).then(
        (response) => {
          setMessage("Category Saved.");
          setSuccessful(true);
          props.history.push("/categories");
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };

  return (
    <Fragment>
      <Prompt
        when={iAmTyping}
        message={(location) =>
          "Are you sure, you want to leave? All your data will be lost!"
        }
      />
      <div className="col-md-12">
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          ref={form}
        >
          {!successful && (
            <div>
              <table className="styled-table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Create a Category</th>
                  </tr>
                </thead>
                <tbody>
                  <Row
                    className="white-background "
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                  >
                    <Col md={3}>
                      <RequiredLabel htmlFor="branch" text="Branch" />
                      <BranchePullDown
                        branch={branch}
                        labelInside={true}
                        forceBlank={false}
                        showAll={true}
                        setBranch={setBranch}
                        branches={branches}
                      />
                    </Col>
                    <Col md={3}>
                      <RequiredLabel htmlFor="usage" text="Usage" />
                      <Select
                        name="usage"
                        className="form-control"
                        value={usage}
                        onChange={(e) => setUsage(e.target.value)}
                        validations={[required]}
                      >
                        {categoryUsage.map((m) => (
                          <option value={m.key}>{m.label}</option>
                        ))}
                      </Select>
                    </Col>
                    <Col md={3}>
                      <RequiredLabel htmlFor="label" text="Label" />
                      <Input
                        type="text"
                        className="form-control"
                        name="label"
                        value={label}
                        onChange={(e) => {
                          setLabel(e.target.value);
                          setKey(e.target.value);
                          setIAmTyping(true);
                        }}
                        validations={[required]}
                      />
                    </Col>
                    {usage == "transactions" ? (
                      <Col md={3}>
                        <RequiredLabel htmlFor="key" text="Accountant Number" />
                        <Input
                          type="text"
                          className="form-control"
                          name="key"
                          value={key}
                          onChange={(e) => {
                            setKey(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={
                            usage == "transactions" ? [required] : []
                          }
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Row></Row>
                </tbody>
              </table>

              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  onClick={handleSaveCategory}
                >
                  Save
                </button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </Fragment>
  );
};

export default CategoryForm;
