import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

// const register = (email, password,first_name,last_name, phone, birthday,gender,isandroid) => {
const register = (user) => {
  return axios.post(API_URL + "/user/create", {
    email: user.email,
    password: user.password,
    first_name: user.first_name,
    last_name: user.last_name,
    birthday: user.birthday,
    gender: user.gender,
    phone: user.phone,
    buildingName: user.buildingName,
    floor: user.floor,
    street: user.street,
    town: user.town,
    branch: user.branch,
    picture: user.picture,
    picChanged: user.picChanged,
    phoneCode: user.phoneCode,
    phoneOnly: user.phoneOnly,
    waitingList: user.waitingList,
    source: user.source,
    pickupDate: user.pickupDate,
    plan: user.plan,
    planType: user.planType,
    totalBikes: user.totalBikes,
    period: user.period,
    planId: user.planId,
  });
};

const login = (userEmailPhone, password) => {
  return axios
    .post(API_URL + "/site/login", {
      userEmailPhone,
      password,
    })
    .then((response) => {
      if (response.data && response.data.data && response.data.success) {
        let data = response.data.data.user;
        let branch = response.data.data.branch;
        if (data) {
          localStorage.setItem("user", JSON.stringify(data));
          localStorage.setItem(
            "bikerInfo",
            JSON.stringify({
              rides: data.rides,
              distance: data.distance,
              avgspeed: data.avgspeed,
              co2: data.co2,
              calories: data.calories,
              costsaved: data.costsaved,
              first_name: data.first_name,
              last_name: data.last_name,
              buildingName: data.buildingName,
              floor: data.floor,
              town: data.town,
              street: data.street,
              phone: data.phone,
            })
          );
          localStorage.setItem(
            "wave_token",
            JSON.stringify(response.data.data.token)
          );
          localStorage.setItem("branch", JSON.stringify(branch));
        } else {
          alert(response.data.data.msg);
          return response.data.data.msg;
        }
      }

      return response.data;
    })
    .catch((err) => {
      if (err.response) {
        console.log(err.response);
        if (err.response.data.message.msg) {
          if (!err.response.data.message.resend)
            alert(err.response.data.message.msg);
        } else alert(err.response.data.message);
        return err.response;
      }
      alert(err);
    });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("wave_token");
};

const getCurrentUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    const isAdmin = user.type == "admin" ? true : false;
    user.isAdmin = isAdmin;
  }
  return user;
};

export default {
  register,
  login,
  logout,
  getCurrentUser,
};
