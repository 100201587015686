import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import NavBar from "./components/NavBar";
import CSR from "./Pages/CSR";
import HOME from "./Pages/Home";
import ABOUT from "./Pages/About";
import FAQ from "./Pages/FAQ";
import CAREERS from "./Pages/Careers";
import Events from "./Pages/Events";
import LandingWork from "./Pages/LandingWork";
import LandingCoupon from "./Pages/LandingCoupon";
import LandingStudent from "./Pages/LandingStudent";
import LOGIN from "./Pages/Login";
import SIGNUP from "./Pages/SignUp";
import RESETPASSWORD from "./Pages/ResetPassword";
import FORGETPASSWORD from "./Pages/ForgetPassword";
import ACTIVATIONCODE from "./Pages/ActivationCode";
import Footer from "./components/Footer";
import WHYWAVE from "./Pages/WhyWave";
import WAVEBIKE from "./Pages/WaveBike";
import { LandingPageProvider } from "./context/LandingPageContext";
import SurveyQuestionsView from "../components/SurveyQuestionsView";
import Corporate from "./Pages/Corporate";
import LandingPrivacyStatement from "./Pages/LandingPrivacyStatement";
import ShortTermRent from "./Pages/ShortTermRent";

export default function Landing() {
  return (
    <Fragment>
      <LandingPageProvider>
        <NavBar />

        <Switch>
          <Route exact path="/landing-work" component={LandingWork} />
          <Route exact path="/landing-student" component={LandingStudent} />
          <Route exact path="/rides" component={Events} />
          <Route exact path="/landing-coupon" component={LandingCoupon} />
          <Route exact path="/csr" component={CSR} />

          <Route exact path="/about" component={ABOUT} />

          <Route exact path="/faq" component={FAQ} />

          <Route exact path="/careers" component={CAREERS} />

          <Route exact path="/login" component={LOGIN} />

          <Route exact path="/signup" component={SIGNUP} />

          <Route exact path="/resetpassword/:id" component={RESETPASSWORD} />

          <Route exact path="/forgetpassword" component={FORGETPASSWORD} />

          <Route
            exact
            path="/activate/:id/:ActivationCode"
            component={ACTIVATIONCODE}
          />

          <Route exact path="/whywave" component={WHYWAVE} />

          <Route exact path="/wavebike" component={WAVEBIKE} />

          <Route exact path="/survey/:id" component={SurveyQuestionsView} />

          <Route exact path="/corporate" component={Corporate} />
          <Route exact path="/shortTermRent" component={ShortTermRent} />

          <Route
            exact
            path="/privacy-statement"
            component={LandingPrivacyStatement}
          />

          <Route path="/*" component={HOME} key={Date.now()} />
        </Switch>

        <Footer />
      </LandingPageProvider>
    </Fragment>
  );
}
