import React, { useEffect, useState } from "react";
import JourneyService from "../services/journey.service";
import EventBus from "../common/EventBus";
import DateFormatter from "../common/DateFromatter";
import { Card, Row, Col } from "react-bootstrap";
import FromToIcons from "../images/from_to_icons.png";

const Journey = (props) => {
    const journeyId = props.match.params.id;

    const [date, setDate] = useState("");
    const [fromLocation, setFromLocation] = useState("");
    const [toLocation, setToLocation] = useState("");
    const [costSaved, setCostSaved] = useState("");
    const [caloriesBurnt, setCaloriesBurnt] = useState("");
    const [co2Saved, setCo2Saved] = useState("");
    const [duration, setDuration] = useState("");
    const [distance, setDistance] = useState("");
    const [averageSpeed, setAverageSpeed] = useState("");
    const [elevationLoss, setElevationLoss] = useState("");
    const [elevationGain, setElevationGain] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        async function onReady() {
            window.scrollTo(0, 0);
            getJourneyInfo();

        }
        onReady();
    }, []);

    const getJourneyInfo = () => {
        if (journeyId) {
            JourneyService.getJourneyById(journeyId).then(
                (response) => {
                    let b = response.data.data;
                    console.log(b);
                    setDate(DateFormatter.DateFormatterOne(b.createdAt));
                    if (b.fromlocation) setFromLocation(b.fromlocation.name);
                    if (b.tolocation) setToLocation(b.tolocation.name);
                    setCostSaved(b.costsaved);
                    setCaloriesBurnt(b.caloriesburnt);
                    setCo2Saved(b.co2)
                    let m = parseInt(b.duration / 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
                    let s = (b.duration % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
                    setDuration(m + ":" + s);
                    setDistance(parseFloat(b.distance).toFixed(2));
                    setAverageSpeed(parseFloat(b.speed).toFixed(2));
                    setElevationLoss(parseFloat(b.loss).toFixed(2));
                    setElevationGain(parseFloat(b.gain).toFixed(2));
                    setStartDate(DateFormatter.convertMsToTime(b.startdate));
                    setEndDate(DateFormatter.convertMsToTime(b.enddate));
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    alert(_content);


                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        }
    }
    return (
        <div className="container" align="center">
            <h3>Journey {date}</h3>
            <Card style={{ minWidth: "95%", padding: 20, display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                <img src={FromToIcons} alt="from to" style={{ marginRight: 20 }} />
                <div align="left" style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        {fromLocation}
                        <div align="right">{startDate}</div>
                    </div>
                    <div><hr /></div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        {toLocation}
                        <div align="right">{endDate}</div>
                    </div>
                </div>
            </Card>
            <Row style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>

                <Card className="dashboardCard" style={{ width: "48%", height: "unset", borderRadius: 25, paddingLeft: "3%", paddingRight: "3%", paddingTop: 0, paddingBottom: 0 }} >
                    <Row style={{ flexDirection: 'row', textAlign: 'center', alignItems: "flex-start" }}>
                        <Col sm={6}>
                            <p><b>Cost Saved <i className="fas fa-dollar-sign"></i></b><br />
                                <span>{costSaved ? costSaved : "0"} $</span>
                            </p>
                        </Col>
                        <Col sm={6}>
                            <p><b>Calories Burnt <i className="fas fa-bacon"></i> </b><br />
                                <span>{caloriesBurnt ? caloriesBurnt : "0"} cal</span>
                            </p>
                        </Col>
                    </Row>
                    <hr />
                    <Row style={{ flexDirection: 'row', textAlign: 'center', alignItems: "flex-start" }}>
                        <Col sm={6}>
                            <p><b>Co2 Saved <i className="fas fa-burn"></i> </b><br />
                                <span>{co2Saved ? co2Saved : "0"} gr</span>
                            </p>
                        </Col>
                        <Col sm={6}>
                            <p><b>Duration <i className="fas fa-hourglass-half"></i></b><br />
                                <span>{duration ? duration : "00:00"}</span>
                            </p>

                        </Col>
                    </Row>


                </Card>

                <Card style={{ width: "48%", paddingLeft: "3%", paddingRight: "3%" }} >
                    <Row style={{ flexDirection: 'row', textAlign: 'center', alignItems: "flex-start" }}>
                        <Col sm={6}>
                            <p><b>Distance <i className="fas fa-road"></i></b><br />
                                <span>{distance ? distance : "0"} Km</span>
                            </p>
                        </Col>
                        <Col sm={6}>
                            <p><b>Average Speed <i className="fas fa-tachometer"></i> </b><br />
                                <span>{averageSpeed ? averageSpeed : "0"} km/h</span>
                            </p>
                        </Col>
                    </Row>
                    <hr />
                    <Row style={{ flexDirection: 'row', textAlign: 'center', alignItems: "flex-start" }}>
                        <Col sm={6}>
                            <p><b>Elevation Loss <i className="fas fa-mountain"></i> </b><br />
                                <span>{elevationLoss ? elevationLoss : "0"} m</span>
                            </p>
                        </Col>
                        <Col sm={6}>
                            <p><b>Elevation Gain <i className="fas fa-mountain"></i></b><br />
                                <span>{elevationGain ? elevationGain : "0"} m</span>
                            </p>

                        </Col>
                    </Row>


                </Card>

            </Row>

        </div>
    )
}

export default Journey;