import React, { useState, useEffect, useRef, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import PackageService from "../services/package.service";
import EventBus from "../common/EventBus";
import { required } from "../common/Validation";
import {
  getBranches,
  getCompanies,
  getAvailableBats,
  getAvailableChargers,
  getAvailableBikes,
} from "../common/getFunctions";
import Currency from "../commonComponents/Currency";
import Switch from "react-switch";
import RequiredLabel from "../common/RequiredLabel";
import OptionalLabel from "../common/OptionalLabel";
import BranchePullDown from "../commonComponents/BranchePullDown";
import PullDown from "../commonComponents/PullDown";
import DatePicker from "react-datepicker";
import { Col, Row } from "react-bootstrap";
import { getInventoriesOptions } from "../common/getFunctions";
import Select from "react-select";
import Functions from "../common/Functions";
import { Prompt } from "react-router-dom";

const PackageForm = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [packageTitle, setPackageTitle] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [automatic, setAutomatic] = useState(false);
  const [price, setPrice] = useState("");
  const [branches, setBranches] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState("");
  const [branch, setBranch] = useState(currentUser.branch);
  const [currency, setCurrency] = useState("");
  const [bikeList, setBikeList] = useState([]);
  const [bikes, setBikes] = useState([]);
  const [bikesObj, setBikesObj] = useState([]);
  const [chargerList, setChagerList] = useState([]);
  const [chargersObj, setChargersObj] = useState([]);
  const [chargers, setChargers] = useState([]);
  const [batList, setBatList] = useState([]);
  const [batteries, setBatteries] = useState([]);
  const [batsObj, setBatsObj] = useState([]);
  const [helmetList, setHelmetList] = useState([]);
  const [bottleList, setBottleList] = useState([]);
  const [bagList, setBagList] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [period, setPeriod] = useState(1);
  const [packageId, setPackageId] = useState(props.match.params.id);
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [helmetSize1, setHelmetSize1] = useState("");
  const [helmetSize2, setHelmetSize2] = useState("");
  const [helmet1, setHelmet1] = useState("");
  const [helmet2, setHelmet2] = useState("");
  const [bagsCount, setBagsCount] = useState(0);
  const [bottlesCount, setBottlesCount] = useState(0);
  const [bikeLoaded, setBikeLoaded] = useState(true);
  const [batLoaded, setBatLoaded] = useState(true);
  const [chargerLoaded, setChargerLoaded] = useState(true);
  const [iAmTyping, setIAmTyping] = useState(false);
  const clone = props.location.pathname.includes("/clone/");

  const getPackageInfo = async () => {
    if (packageId) {
      PackageService.getPackageDetailsById(packageId).then(
        async (response) => {
          let r = response.data.data;
          let c = r.pack;
          let bList = r.bikeList;
          let cList = r.chargerList;
          let baList = r.batteryList;

          setBikesObj(bList);
          setChargersObj(cList);
          setBatsObj(baList);
          //console.log(r)
          setPackageTitle(c.title);
          setCurrency(c.currency);
          setPrice(c.price);
          if (c.branch) {
            setBranch(c.branch._id);
            setCompanies(await getCompanies(c.branch._id));
            let availableBikes = Functions.getOptionsList(
              await getAvailableBikes(c.branch._id, "", true)
            );
            if (bList && bList.length > 0)
              bList.map((bike, i) => {
                if (bike) {
                  availableBikes.push({ value: bike._id, label: bike.number });
                }
              });
            setBikeList(availableBikes);
            let availableBats = Functions.getOptionsList(
              await getAvailableBats(c.branch._id, "", true)
            );
            if (baList && baList.length > 0)
              baList.map((bat, i) => {
                if (bat) {
                  availableBats.push({ value: bat._id, label: bat.number });
                }
              });
            setBatList(availableBats);
            let availableChargers = Functions.getOptionsList(
              await getAvailableChargers(c.branch._id, "", true)
            );
            if (cList && cList.length > 0)
              cList.map((ch, i) => {
                if (ch) {
                  availableChargers.push({ value: ch._id, label: ch.number });
                }
              });
            setChagerList(availableChargers);
          }
          setCompany(c.company);
          setDisabled(c.disabled);
          setAutomatic(c.automatic);
          if (c.start) setStartDate(new Date(c.start.split("T")[0]));

          setBatteries(Functions.getOptionsList(baList));

          //console.log("bikeList: ", [...await getAvailableBikes(c.branch._id), bList]);

          setBikes(Functions.getOptionsList(bList));
          setHelmetList(
            await getInventoriesOptions(
              false,
              "Helmet",
              c.branch._id,
              "",
              "",
              true
            )
          );
          if (c.helmetId1) setHelmet1(c.helmetId1._id);
          if (c.helmetId2) setHelmet2(c.helmetId2._id);
          setHelmetSize1(c.helmetSize1);
          setHelmetSize2(c.helmetSize2);
          setBagsCount(c.bags);
          setBottlesCount(c.bottles);
          setChargers(Functions.getOptionsList(cList));
          setBagList(
            await getInventoriesOptions(
              false,
              "Bag",
              c.branch._id,
              "",
              "",
              true
            )
          );
          setBottleList(
            await getInventoriesOptions(
              false,
              "Bottle",
              c.branch._id,
              "",
              "",
              true
            )
          );

          if (clone === true) {
            setPackageId("");
          } // reset the id to clone
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          alert(_content);
          setMessage(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    } else {
      setCompanies(await getCompanies(currentUser.branch));
      setBatList(
        Functions.getOptionsList(
          await getAvailableBats(currentUser.branch, "", true)
        )
      );
      setBikeList(
        Functions.getOptionsList(
          await getAvailableBikes(currentUser.branch, "", true)
        )
      );
      setChagerList(
        Functions.getOptionsList(
          await getAvailableChargers(currentUser.branch, "", true)
        )
      );
      setBottleList(
        await getInventoriesOptions(
          false,
          "Bottle",
          currentUser.branch,
          "",
          "",
          true
        )
      );
      setHelmetList(
        await getInventoriesOptions(
          false,
          "Helmet",
          currentUser.branch,
          "",
          "",
          true
        )
      );
      setBagList(
        await getInventoriesOptions(
          false,
          "Bag",
          currentUser.branch,
          "",
          "",
          true
        )
      );
    }
  };

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
      getPackageInfo();
    }

    onReady();
  }, []);

  const handleSavePackage = (e) => {
    e.preventDefault();

    if (!startDate) {
      setMessage("Start date is required!");
      setSuccessful(false);
      return;
    }

    if (!currency) {
      setMessage("Currency is required!");
      setSuccessful(false);
      return;
    }
    setIAmTyping(false);
    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      let finalBikes = [];
      bikes.map((b, i) => {
        if (b.value) {
          finalBikes.push(b.value);
        } else finalBikes.push(b); // this is the id.
      });

      let finalChargers = [];
      chargers.map((c, i) => {
        if (c.value) {
          finalChargers.push(c.value);
        } else finalChargers.push(c); // this is the id.
      });

      let finalBats = [];
      batteries.map((b, i) => {
        if (b.value) {
          finalBats.push(b.value);
        } else finalBats.push(b); // this is the id.
      });

      PackageService.postPackage({
        _id: packageId,
        title: packageTitle,
        price: price,
        bikes: finalBikes,
        company: company,
        branch: branch,
        disabled: disabled,
        helmetSize1: helmetSize1,
        helmetSize2: helmetSize2,
        helmetId1: helmet1,
        helmetId2: helmet2,
        bagId: bagList[0] ? bagList[0]._id : null,
        bags: bagsCount,
        bottleId: bottleList[0] ? bottleList[0]._id : null,
        bottles: bottlesCount,
        bats: finalBats,
        chargers: finalChargers,
        start: startDate,
        currency: currency,
        automatic: automatic,
      }).then(
        (response) => {
          setMessage("Package Saved.");
          setSuccessful(true);
          props.history.push("/packages");
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };
  const setDisabledSwitch = () => {
    setDisabled(!disabled);
  };
  const setAutomaticSwitch = () => {
    setAutomatic(!automatic);
  };
  const handleBranchChange = async (b) => {
    setIAmTyping(true);
    setBranch(b);
    setCompanies(await getCompanies(b));
  };

  const refreshBikeList = async (e) => {
    e.preventDefault();
    setBikeLoaded(false);

    if (bikesObj && bikesObj.length) {
      setBikeList(
        Functions.getOptionsList([
          ...(await getAvailableBikes(branch, "", true)),
          bikesObj,
        ])
      );
    } else {
      setBikeList(
        Functions.getOptionsList([
          ...(await getAvailableBikes(branch, "", true)),
          bikes,
        ])
      );
    }

    setBikeLoaded(true);
  };
  const refreshBatList = async (e) => {
    e.preventDefault();
    setBatLoaded(false);
    if (batsObj && batsObj.length) {
      setBatList(
        Functions.getOptionsList([
          ...(await getAvailableBats(branch, "", true)),
          batsObj,
        ])
      );
    } else {
      setBatList(
        Functions.getOptionsList([
          ...(await getAvailableBats(branch, "", true)),
          batteries,
        ])
      );
    }

    setBatLoaded(true);
  };
  const refreshChargerList = async (e) => {
    e.preventDefault();
    setChargerLoaded(false);
    if (chargersObj && chargersObj.length) {
      setChagerList(
        Functions.getOptionsList([
          ...(await getAvailableChargers(branch, "", true)),
          chargersObj,
        ])
      );
    } else {
      setChagerList(
        Functions.getOptionsList([
          ...(await getAvailableChargers(branch, "", true)),
          chargers,
        ])
      );
    }
    setChargerLoaded(true);
  };

  return (
    <Fragment>
      <Prompt
        when={iAmTyping}
        message={(location) =>
          "Are you sure, you want to leave? All your data will be lost!"
        }
      />
      <div className="col-md-12">
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          ref={form}
        >
          {!successful && (
            <div>
              <table className="styled-table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "100%" }}>Package Form</th>
                  </tr>
                </thead>
                <tbody>
                  <Row
                    className="white-background "
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                  >
                    <Col md={3}>
                      {currentUser.type === "admin" ? (
                        <div className="form-group">
                          <RequiredLabel htmlFor="branch" text="Branch" />
                          <BranchePullDown
                            branch={branch}
                            labelInside={true}
                            forceBlank={true}
                            setBranch={handleBranchChange}
                            branches={branches}
                          />
                        </div>
                      ) : null}
                    </Col>

                    <Col md={3}>
                      <div className="form-group">
                        <RequiredLabel htmlFor="packageTitle" text="Title" />
                        <Input
                          type="text"
                          className="form-control"
                          name="packageTitle"
                          value={packageTitle}
                          onChange={(e) => {
                            setPackageTitle(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required]}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <OptionalLabel htmlFor="company" text="Company" />
                        <PullDown
                          defaultValue={company}
                          labelInside={false}
                          pulldownFor={"company"}
                          forceBlank={true}
                          setValue={(value) => {
                            setCompany(value);
                            setIAmTyping(true);
                          }}
                          options={companies}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <RequiredLabel htmlFor="startDate" text="Start Date" />

                        <DatePicker
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          name="startDate"
                          selected={startDate}
                          onChange={(date) => {
                            setStartDate(date);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                    </Col>
                    <div
                      style={{ width: "100%" }}
                      className="d-flex align-items-start"
                    >
                      <Col md={3}>
                        <div className="form-group">
                          <RequiredLabel
                            htmlFor="startDate"
                            text="Period (months)"
                          />
                          <input
                            type="text"
                            className="form-control"
                            name="period"
                            value={period}
                            onChange={(e) => {
                              setPeriod(e.target.value);
                              setIAmTyping(true);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="form-group">
                          <OptionalLabel
                            htmlFor="bikes"
                            text="Bikes"
                            showRefresh={true}
                            onclickAction={(e) => refreshBikeList(e)}
                            loaded={bikeLoaded}
                          />
                          <Select
                            value={bikes}
                            onChange={(selectedBikes) => {
                              setBikes(selectedBikes);
                              setIAmTyping(true);
                            }}
                            options={bikeList}
                            isMulti
                            isClearable={false}
                            closeMenuOnSelect={false}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="form-group">
                          <OptionalLabel
                            htmlFor="chargers"
                            text="Chargers"
                            showRefresh={true}
                            onclickAction={(e) => refreshChargerList(e)}
                            loaded={chargerLoaded}
                          />
                          <Select
                            value={chargers}
                            onChange={(selectedChargers) => {
                              setChargers(selectedChargers);
                              setIAmTyping(true);
                            }}
                            options={chargerList}
                            isMulti
                            isClearable={false}
                            closeMenuOnSelect={false}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="form-group">
                          <OptionalLabel
                            htmlFor="batteries"
                            text="Batteries"
                            showRefresh={true}
                            onclickAction={(e) => refreshBatList(e)}
                            loaded={batLoaded}
                          />
                          <Select
                            value={batteries}
                            onChange={(selectedBatteries) => {
                              setBatteries(selectedBatteries);
                              setIAmTyping(true);
                            }}
                            options={batList}
                            isMulti
                            isClearable={false}
                            closeMenuOnSelect={false}
                          />
                        </div>
                      </Col>
                    </div>
                    <Col md={3}>
                      <div className="form-group">
                        <OptionalLabel htmlFor="helmet1" text="Helmet 1" />
                        <PullDown
                          defaultValue={helmet1}
                          labelInside={true}
                          multiple={false}
                          pulldownFor={"package"}
                          forceBlank={true}
                          setValue={(value) => {
                            setHelmet1(value);
                            setIAmTyping(true);
                          }}
                          options={helmetList}
                          helmet
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <OptionalLabel
                          htmlFor="helmetSize1"
                          text="Helmet 1 Count"
                        />
                        <Input
                          type="Number"
                          className="form-control"
                          value={helmetSize1}
                          onChange={(e) => {
                            setHelmetSize1(e.target.value);
                            setIAmTyping(true);
                          }}
                          disabled={!helmet1}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <OptionalLabel htmlFor="helmet2" text="Helmet 2" />
                        <PullDown
                          defaultValue={helmet2}
                          labelInside={true}
                          multiple={false}
                          pulldownFor={"package"}
                          forceBlank={true}
                          setValue={(value) => {
                            setHelmet2(value);
                            setIAmTyping(true);
                          }}
                          options={helmetList}
                          helmet
                        />
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="form-group">
                        <OptionalLabel
                          htmlFor="helmetSize2"
                          text="Helmet 2 Count"
                        />
                        <Input
                          type="Number"
                          className="form-control"
                          value={helmetSize2}
                          onChange={(e) => {
                            setHelmetSize2(e.target.value);
                            setIAmTyping(true);
                          }}
                          disabled={!helmet2}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <OptionalLabel htmlFor="bagsCount" text="Bags count" />
                        <Input
                          type="Number"
                          className="form-control"
                          value={bagsCount}
                          onChange={(e) => {
                            setBagsCount(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <OptionalLabel
                          htmlFor="bottlesCount"
                          text="Bottles count"
                        />
                        <Input
                          type="Number"
                          className="form-control"
                          value={bottlesCount}
                          onChange={(e) => {
                            setBottlesCount(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <RequiredLabel htmlFor="price" text="Price" />
                        <Input
                          type="Number"
                          className="form-control"
                          value={price}
                          onChange={(e) => {
                            setPrice(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required]}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <Currency
                          fn={(e) => {
                            setCurrency(e.target.value);
                            setIAmTyping(true);
                          }}
                          name="currency"
                          value={currency}
                          isRequired={true}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <RequiredLabel htmlFor="disabled" text="Disabled" />
                        <Switch
                          onChange={(v) => {
                            setDisabledSwitch(v);
                            setIAmTyping(true);
                          }}
                          checked={disabled}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="automatic"
                          text="Automatic Renewal"
                        />
                        <Switch
                          onChange={(v) => {
                            setAutomaticSwitch(v);
                            setIAmTyping(true);
                          }}
                          checked={automatic}
                        />
                      </div>
                    </Col>
                    <Col md={3}></Col>
                  </Row>
                  <Row></Row>
                </tbody>
              </table>

              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  onClick={handleSavePackage}
                >
                  Save
                </button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </Fragment>
  );
};

export default PackageForm;
