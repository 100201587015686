import React from "react";

const TimePicker = ({ time, setTime }) => {
  const hours = [];
  for (let i = 1; i <= 12; i++) {
    i = i.toString().padStart(2, "0");
    hours.push(<option value={i}>{i}</option>);
  }

  const minutes = [];
  minutes.push(<option value="00">00</option>);
  for (let i = 15; i < 60; i += 15) {
    minutes.push(<option value={i}>{i}</option>);
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <select
        className="form-control"
        name="hour"
        value={time.split(":")[0]}
        onChange={(e) =>
          setTime(
            `${e.target.value}:${time.split(":")[1]}:${time.split(":")[2]}`
          )
        }
      >
        {hours}
      </select>
      <select
        name="minute"
        className="form-control"
        value={time.split(":")[1]}
        onChange={(e) =>
          setTime(
            `${time.split(":")[0]}:${e.target.value}:${time.split(":")[2]}`
          )
        }
      >
        {minutes}
      </select>
      <select
        name="ampm"
        className="form-control"
        value={time.split(":")[2]}
        onChange={(e) =>
          setTime(
            `${time.split(":")[0]}:${time.split(":")[1]}:${e.target.value}`
          )
        }
      >
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </select>
    </div>
  );
};

export default TimePicker;
