const Constants = {
    RTL: false,
    Language: 'Arabic',
  serverlink:'https://wave-api.imperiumcode.com/',
 //  serverlink:'http://139.162.237.172:3011/',
    serverlink: 'http://localhost:3000',
    timeout: 40000,
    GOOGLEAPIKEY: "AIzaSyDEBPLItA1bzKTw3jl9AYE-n6y5xo4jD5Q", // AIzaSyA1CXf4QFyGdoirCtIP6M_nzfhRX1jnhHo
};

export default Constants;
