import React, { useState, useEffect } from "react";
import ResellerService from "../services/reseller.service";
import EventBus from "../common/EventBus";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../commonComponents/Loader";
import * as labels from "../labels/en";
import PullDown from "../commonComponents/PullDown";
import { getBranches, getSuppliers } from "../common/getFunctions";
import { Card, Row, Col, Table } from "react-bootstrap";
import DateFromatter from "../common/DateFromatter";
const currentUser = JSON.parse(localStorage.getItem("user"));

const MaintenanceRequests = (props) => {
  const [error, setError] = useState("");
  const [mRequests, setMaintenanceRequests] = useState([]);
  const [branchFilter, setBranchFilter] = useState();
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resetMsg, setResetMsg] = useState("");
  const [numberFilter, setNumberFilter] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const pageSizes = [9, 18, 24, 30, 36, 60];
  const [companyList, setCompanyList] = useState([]);
  const [company, setCompany] = useState("");
  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
	  setBranchFilter(currentUser.branch)
	  setCompanyList(await getSuppliers(currentUser.branch));
      await listMaintenanceRequests(currentUser.branch, "","", page, itemsPerPage);
    }

    onReady();
  }, []);

  const handleNumberChange = async (s) => {
    setNumberFilter(s);
    resetResults();
  };

  const listMaintenanceRequests = async (
    branchFilter,
    numberFilter,
	companyFilter,
    page,
    itemsPerPage
  ) => {
    setLoading(true);
    ResellerService.getMaintenanceRequests(
      branchFilter,
      numberFilter,
	  companyFilter,
      parseInt(page - 1) * itemsPerPage,
      itemsPerPage,
      false
    ).then(
      (response) => {
        let data = response.data.data;
        setMaintenanceRequests(data.list);
        setTotal(data ? data.total : 0);
        setCount(Math.ceil(data.total / itemsPerPage));
        setResetMsg("");
        setLoading(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setError(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
    await listMaintenanceRequests(
      branchFilter,
      numberFilter,
	  company,
      value,
      itemsPerPage
    );
  };

  const handlePageSizeChange = async (newItemsPerPage) => {
    setitemsPerPage(newItemsPerPage);
    setPage(1);
    await listMaintenanceRequests(
      branchFilter,
      numberFilter,
	  company,
      1,
      newItemsPerPage
    );
  };
  const resetSearch = async () => {
    await handleBranchChange(currentUser.branch);	
    setNumberFilter("");
	setCompany("")
    await listMaintenanceRequests(currentUser.branch, "","", 1, itemsPerPage);
  };
  const triggerSearch = async () => {
    await setPage(1); // return to first page
    await listMaintenanceRequests(branchFilter, numberFilter,company, 1, itemsPerPage);
  };
  const handleBranchChange = async (bid) => {
    setBranchFilter(bid);
	setCompany("")
	setCompanyList(await getSuppliers(bid))
    resetResults();
  };
  const resetResults = () => {
    setTotal(0);
    setCount(0);
    setMaintenanceRequests([]);
    setResetMsg(labels.RESET_MSG);
  };

  const deleteRequest = async (event, id) => {
    if (window.confirm("Are you sure you want to delete this request?")) {
      ResellerService.deleteRequest(id).then(
        (response) => {
          alert("Request deleted!");
          let oldList = mRequests;
          var data = oldList.filter(function (obj) {
            return obj._id !== id;
          });
          setMaintenanceRequests(data);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  const createMaintenance = async (event, id, action) => {
    if (
      window.confirm("Are you sure you want to process this to Maintenance?")
    ) {
      ResellerService.processRequest({ id: id, action: action }).then(
        (response) => {
          alert("Request processed!");

          if (response.data && response.data.data) {
            props.history.push("/maintenance/fix/" + response.data.data.id);
          }
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      triggerSearch(e);
    }
  };
  return (
    <div className="container">
      {currentUser && currentUser.type === "reseller" ? (
        <>
          <br /> <br />
        </>
      ) : (
        ""
      )}
      <h3> Maintenance Requests</h3>
      <div className="filterContainer">
        {currentUser && currentUser.type == "admin" ? (
          <Col md={2.5}>
            <label className="filterLabelInput maintenanceLabels"htmlFor="branch">
              Branch
            </label>

            <select
              className="form-control filterInput"
              value={branchFilter}
              onChange={(e) => handleBranchChange(e.target.value)}
            >
              <option value="" key="0">
                All
              </option>
              {branches.map((branch, index) => (
                <option key={index} value={branch._id}>
                  {branch.label}
                </option>
              ))}
            </select>
          </Col>
        ) : null}
		{currentUser && currentUser.type !== "reseller"?
		<Col md={3} >
			<label className="filterLabelInput maintenanceLabels" htmlFor="company">
              Company
            </label>
			<PullDown
			  defaultValue={company}
			  labelInside={true}
			  pulldownFor={"company"}
			  forceBlank={true}
			  setValue={(value) => {
				setCompany(value);
			  }}
			  options={companyList}
			/>
		</Col>
		:""}
        <Col md={3} style={{ width: 160 }}>
          <label
            htmlFor="numberFilter"
            className="filterLabelInput maintenanceLabels"
          >
            Number
          </label>
          <input
            style={{ border: "1px solid #ced4da", width: "95%", height: 38 }}
            type="text"
            className="form-control filterInput"
            name="numberFilter"
            value={numberFilter}
            onKeyDown={(e) => handleKeyDown(e)}
            onChange={(e) => handleNumberChange(e.target.value)}
          />
        </Col>

        <Col md={2.5}>
          <table style={{ marginTop: 35 }} border="0">
            <tr>
              <td>
                <button
                  onClick={(e) => triggerSearch(e)}
                  className="btn btn-primary btn-block search-button "
                >
                  Go
                </button>
              </td>
              <td>
                <button
                  onClick={(e) => resetSearch(e)}
                  className="btn btn-primary btn-block reset-button "
                >
                  Reset
                </button>
              </td>
            </tr>
          </table>
        </Col>
      </div>

      {error ? (
        <header className="jumbotron">
          {" "}
          <h3>{error}</h3>{" "}
        </header>
      ) : null}
      {!error && !loading && mRequests && mRequests.length != 0 ? (
        <div>
          <Row>
            <Col>
              <span style={{ float: "right", marginLeft: 10 }}>
                {total} results
              </span>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "first baseline",
            }}
          >
            {mRequests.map((b) => (
              <div style={{ float: "left", marginLeft: 20 }}>
                <Card
                  style={{
                    marginLeft: 0,
                    minHeight: 200,
                    paddingLeft: 10,
                    align: "top",
                    maxWidth: 200,
                  }}
                >
                  <Card.Body>
                    <Card.Title>
                      {b.type ? b.type : "Bike"} &nbsp;
                      {b.processed ? (
                        <span className="green">Processed</span>
                      ) : (
                        <a
                          href="javascript:void(0)"
                          className="text-dark midText"
                          onClick={(e) => deleteRequest(e, b._id)}
                        >
                          <i
                            alt="Delete"
                            title="Delete"
                            className="fa fa-trash"
                          ></i>
                        </a>
                      )}
                      {currentUser &&
                      currentUser.type !== "reseller" &&
                      !b.processed ? (
                        <>
                          {" "}
                          <br />
                          <a
                            href="javascript:void(0)"
                            className="text-dark midText"
                            onClick={(e) =>
                              createMaintenance(e, b._id, "repair")
                            }
                          >
                            Repair
                          </a>
                          &nbsp;
                          <a
                            href="javascript:void(0)"
                            className="text-dark midText"
                            onClick={(e) =>
                              createMaintenance(e, b._id, "quickcheckup")
                            }
                          >
                            Quick Checkup
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                    </Card.Title>
                    <Card.Text>
                      <span className="tinyCardLabel">Number:&nbsp;</span>
                      <span className="midText">{b.number}</span>
                      <br />
                      <span className="tinyCardLabel">Created Date:&nbsp;</span>
                      <span className="midText">
                        {DateFromatter.DateFormatterOne(b.createdAt)}
                      </span>
                      {b.company &&
                      b.company.name &&
                      currentUser.type !== "reseller" ? (
                        <>
                          <br />
                          <span className="tinyCardLabel">Company:&nbsp;</span>
                          <span className="midText">{b.company.name}</span>
                        </>
                      ) : (
                        ""
                      )}
                      {b.user &&
                      b.user.full_name &&
                      currentUser.type !== "reseller" ? (
                        <>
                          <br />
                          <span className="tinyCardLabel">Waver:&nbsp;</span>
                          <span className="midText">{b.user.full_name}</span>
                        </>
                      ) : (
                        ""
                      )}
                      <br />
                      <span className="tinyCardLabel">Description:&nbsp;</span>
                      <span className="midText">{b.comments}</span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </Row>

          <div className="mt-3">
            {"Items per Page: "}
            <select
              onChange={(e) => handlePageSizeChange(e.target.value)}
              value={itemsPerPage}
            >
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>

            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingtotal={1}
              boundarytotal={1}
              variant="outlined"
              color="primary"
              shape="rounded"
              onChange={handlePageChange}
              size="medium"
            />
          </div>
        </div>
      ) : loading ? (
        <Loader />
      ) : resetMsg != "" ? (
        <div className="defaultMsg">{resetMsg}</div>
      ) : (
        <div className="noResultsFound">{labels.NO_RESULTS}</div>
      )}
    </div>
  );
};

export default MaintenanceRequests;
