import React, { useEffect, useState } from "react";

export default function SupplierPullDown(props) {
  const supplier = props.supplier;
  const suppliers = props.suppliers;
  const setSupplier = props.setSupplier;
  const setAccounts = props.setAccounts;
  const extraClass = props.extraClass;
  const extraClassLabel = props.extraClassLabel;
  const labelInside = props.labelInside || false;
  const label = props.title || "Supplier";
  const isRequired = props.isRequired || "";
  const supplierRef = props.supplierRef;
  const validationArr = props.validationArr || [];
  const forceBlank = props.forceBlank || false;
  const labelOption = props.labelOption || false;
  const addFormGroup = props.addFormGroup;

  const handleSupplierChange = (value) => {
    setSupplier(value);
    const supplierSelected = suppliers.filter(function (obj) {
      return obj._id == value;
    });
    setAccounts(supplierSelected ? supplierSelected[0]?.accounts : []);
  };

  let option1 = "";
  if (forceBlank === true) {
    option1 = <option value=""></option>;
  } else if (labelOption) {
    option1 = (
      <option disabled="disabled" value="">
        {label} *
      </option>
    );
  }

  /*if (suppliers.length === 1)
        return null */
  let selectPulldown = (
    <select
      className={"form-control " + extraClass}
      value={supplier}
      Placeholder={label}
      onChange={(e) => handleSupplierChange(e.target.value)}
      required={isRequired}
      ref={supplierRef}
      validations={validationArr}
    >
      {option1}
      {suppliers &&
        suppliers.map((m) => <option value={m._id}>{m.name}</option>)}
    </select>
  );

  if (labelInside) {
    return selectPulldown;
  } else {
    if (addFormGroup != "no") {
      return (
        <div className="form-group">
          <label htmlFor="supplier" className={extraClassLabel}>
            {label}
          </label>
          {selectPulldown}
        </div>
      );
    } else {
      return (
        <>
          <label htmlFor="supplier" className={extraClassLabel}>
            {label}
          </label>
          {selectPulldown}
        </>
      );
    }
  }
}
