import React, { useState, useRef } from "react";
import * as labels from '../labels/en';
import userService from "../services/user.service";
import EventBus from "../common/EventBus";
import { Button } from "react-bootstrap";

const TermsAndConditions = (props) => {

    const currentUser = JSON.parse(localStorage.getItem('user')) || null;
    const [agree, setAgree] = useState(currentUser.iagree);
    const [iAgree, set_iAgree] = useState("")
    const [iAgree2, set_iAgree2] = useState("")
    const [showAgree1, setShowAgree1] = useState(true);



    const agreeTerms = () => {
        userService.signIagree().then(
            () => {
                alert("Terms and conditions agreed.")
                var localUser = JSON.parse(localStorage.getItem('user'));
                localUser.iagree = true;
                setAgree(true);
                localStorage.setItem('user', JSON.stringify(localUser));
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                alert(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");

                }
            }
        )
    }

    const checkBoxHandler = () => {
        if (document.getElementById("checkBox-profile").checked)
            document.getElementById("checkBox-profile-label").style.color = "rgb(52, 58, 64)";
        set_iAgree(!iAgree)
        setShowAgree1(false);
        window.scrollTo(0, 0);
    }

    const checkBoxHandler2 = () => {
        if (document.getElementById("checkBox-cardId").checked)
            document.getElementById("checkBox-cardId-label").style.color = "rgb(52, 58, 64)";
        set_iAgree2(!iAgree2)
        agreeTerms();
        setShowAgree1(true);
        window.scrollTo(0, 0);
    }

    const handleNextClick = () => {
        setShowAgree1(false);
        window.scrollTo(0, 0);
    }

    const handlePreviousClick = () => {
        setShowAgree1(true);
        window.scrollTo(0, 0);
    }

    return (
        <div className="container" style={{ paddingBottom: 30 }}>
            <h3>Terms And Conditions</h3>

            {showAgree1 ?
                <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ textAlign: "left" }} className="iAgreeText">
                        {!agree ?
                            <h5 className="agreeSentence">Please carefully read the following Terms and Conditions</h5>
                            :
                            <h5 className="agreeSentence">You have signed the following terms and conditions:</h5>
                        }
                        {labels.iAgree1}
                    </div>
                    {!agree ?
                        <div className="checkBoxMargin" style={{ display: 'flex' }}>
                            <input id="checkBox-profile" onChange={checkBoxHandler} style={{ width: 'auto', margin: '5px' }} type="checkbox" />
                            <label id="checkBox-profile-label" style={{ width: 'auto', margin: '0px', padding: '0px', color: '#343a40' }}>I Agree to terms and conditions</label>
                        </div>
                        : ""}
                </div>
                : ""}
            {!showAgree1 ?
                <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ textAlign: "left" }} className="iAgreeText">
                        {agree ? labels.iAgree2_signed_intro : labels.iAgree2_not_signed_intro}
                        {labels.iAgree2}
                    </div>
                    {!agree ?

                        <div className="checkBoxMargin" style={{ display: 'flex' }}>
                            <input id="checkBox-cardId" onChange={checkBoxHandler2} style={{ width: 'auto', margin: '5px' }} type="checkbox" />
                            <label id="checkBox-cardId-label" style={{ width: 'auto', margin: '0px', padding: '0px', color: '#343a40' }}>I Agree to terms and conditions</label>
                        </div>

                        : ""}
                </div>
                : ""}
            <br />
            {agree ?
                showAgree1 ?
                    <div align="center"><Button style={{ width: "30%" }} onClick={handleNextClick}>Next</Button></div> : <div align="center"><Button style={{ width: "30%" }} onClick={handlePreviousClick}>Previous</Button></div>
                : ""}
            {/*{agree === false ? <a href="javascript:void(0)" className="greenLink mx-0" onClick={handleAgreeTermsClick}>I agree to the terms and conditions</a> : ""}*/}
        </div>
    )
}

export default TermsAndConditions;