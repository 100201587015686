import React from 'react'
import { PutOutWaitingList } from "../common/PutInWaitingList";


export default function WaitingsClient(props) {

    const currentUser = JSON.parse(localStorage.getItem('user')) || null;
    if (!currentUser) { window.location.href = window.location.protocol + "//" + window.location.host + "/login"; }


    const PutInWaitingListHandler = () => {
        props.setAddMeToWaitingListModal(true);
    }

    const PutOutWaitingListHandler = async () => {
        await PutOutWaitingList();
    }



    return (<>
        {!currentUser.waitingList ?
            <button onClick={PutInWaitingListHandler} className="btn btn-primary btn-block dashboard-btn">I wish to book a bike</button>
            :
            <button onClick={PutOutWaitingListHandler} className="btn-primary btn-block dashboard-btn">Remove me from waiting list</button>
        }
    </>)
}
