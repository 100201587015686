import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const getBranchesList = (searchKey, fullList) => {
  return axios.get(
    API_URL + "/branch/list?searchKey=" + searchKey + "&fullList=" + fullList,
    {
      headers: authHeader(),
    }
  );
};

const deleteBranch = (id) => {
  return axios(API_URL + "/branch/" + id, {
    method: "delete",
    headers: authHeader(),
  });
};

const postBranch = (
  id,
  label,
  disabled,
  language,
  currency,
  country,
  timezone,
  phone,
  town,
  city,
  MOF,
  buildingNumber,
  floor,
  street,
  currencyRate,
  invoicePrefix,
  invoiceCounter,
  defaultPlan,
  journeyform,
  parentBranch,
  company,
  transactionPrefix,
  transactionCounter
) => {
  return axios(API_URL + "/branch/save", {
    method: "post",
    headers: authHeader(),
    data: {
      _id: id,
      label,
      disabled,
      language,
      currency,
      country,
      timezone,
      phone,
      town,
      city,
      MOF,
      buildingNumber,
      floor,
      street,
      currencyRate,
      invoicePrefix,
      invoiceCounter,
      defaultPlan,
      journeyform,
      parentBranch,
      company,
      transactionPrefix,
      transactionCounter,
    },
  });
};

const getBranchById = (id) => {
  return axios.get(API_URL + "/branch/" + id, { headers: authHeader() });
};

export default {
  postBranch,
  deleteBranch,
  getBranchesList,
  getBranchById,
};
