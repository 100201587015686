import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { Col, Row, Modal } from "react-bootstrap";
import DateFormatter from "../common/DateFromatter";
import WaitingsClient from "./WaitingsClient";
import SubscriptionInfo from "./SubscriptionInfo";
import SubscriptionService from "../services/subscription.service";
import { viewPDF } from "../common/pdf";
import { getCategories, getLastSubscribtion } from "../common/getFunctions";
import EventBus from "../common/EventBus";
import UserService from "../services/user.service";
import Loader from "../commonComponents/Loader";
import bikePic from "../assets/img/Full-Bike small.png";
import { PutInWaitingList } from "../common/PutInWaitingList";
import { NavLink } from "react-router-dom";
import { getBranches } from "../common/getFunctions";
import BranchePullDown from "../commonComponents/BranchePullDown";
import AdminDashboard from "./AdminDashboard";
import ResellerDashboard from "./ResellerDashboard";
import AccountantDashboard from "./AccountantDashboard";
import PullDown from "../commonComponents/PullDown";
import RequiredLabel from "../common/RequiredLabel";

export default function Dashboard(props) {
  const currentUser = AuthService.getCurrentUser();
  if (!currentUser) {
    window.location.href =
      window.location.protocol + "//" + window.location.host + "/login";
  }
  const filled =
    currentUser.cardId &&
    currentUser.iagree &&
    currentUser.buildingName &&
    currentUser.town &&
    currentUser.street &&
    currentUser.floor;

  const [user, setUser] = useState(currentUser);
  const [lastSubscribe, setLastSubscribe] = useState([]);
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [terminateSubscriptionModal, setTerminateSubscriptionModal] =
    useState(false);
  const [reason, setReason] = useState("");
  const [terminationCategory, setTerminationCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedUserByReason, setSelectedUserByReason] = useState("");
  const [addMeToWaitingListModal, setAddMeToWaitingListModal] = useState(false);
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState(currentUser.branch);
  let biker = JSON.parse(localStorage.getItem("bikerInfo"));
  if (!biker) {
    biker = props.user;
  }
  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      if (filled) setBranches(await getBranches());
      getBikerLastSubscribtion().then(() => getUser());
      setProfileLoaded(true);
    }

    onReady();
  }, []);
  const [selectedBranchObj, setBranchObj] = useState({});

  const setSelectedBranch = async (id) => {
    setBranch(id);
    setBranchObj(branches.filter((branch) => branch._id === id)[0]);
  };

  const getUser = async () => {
    if (
      currentUser.type === "registered" ||
      currentUser.type === "subscriber"
    ) {
      let res = await UserService.getUser().then(
        (response) => {
          let data = response.data.data;
          let biker = JSON.parse(localStorage.getItem("bikerInfo"));
          if (biker) {
            localStorage.setItem(
              "bikerInfo",
              JSON.stringify({
                rides: data.rides,
                distance: data.distance,
                avgspeed: data.avgspeed,
                co2: data.co2,
                calories: data.calories,
                costsaved: data.costsaved,
                first_name: biker.first_name,
                last_name: biker.last_name,
                buildingName: biker.buildingName,
                floor: biker.floor,
                town: biker.town,
                street: biker.street,
                phone: biker.phone,
              })
            );
            if (data.rides !== biker.rides) {
              window.location.reload();
            }
          }
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          alert(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
      return res;
    }
  };

  const getBikerLastSubscribtion = async () => {
    if (
      currentUser.type === "registered" ||
      currentUser.type === "subscriber"
    ) {
      let data = await getLastSubscribtion(user._id);
      setLastSubscribe(data);
      setCategories(await getCategories(currentUser.branch, "termination"));
    }
  };

  const handleReasonModalShow = (id) => {
    setSelectedUserByReason(id);

    setTerminateSubscriptionModal(true);
  };

  const handleTerminateSubscriptionModalClose = () => {
    setSelectedUserByReason("");
    setReason("");
    setTerminationCategory("");
    setTerminateSubscriptionModal(false);
  };

  const sendReasonHandler = () => {
    var data = {
      id: selectedUserByReason,
      terminateReason: reason,
    };

    SubscriptionService.terminateSubscription(data).then(async ({ data }) => {
      console.log(data);
      setLastSubscribe(
        lastSubscribe.map((s, i) => {
          if (s.id === selectedUserByReason) {
            s.terminated = true;
            s.terminateReason = reason;
          }
          return s;
        })
      );
    });

    handleTerminateSubscriptionModalClose();
  };

  const handleAddMeToWaitingListModalClose = () => {
    setAddMeToWaitingListModal(false);
  };

  const editProfileHandler = () => {
    window.location.href =
      window.location.protocol +
      "//" +
      window.location.host +
      "/editprofile?complete";
  };

  const handleAddMeToWaitingListClick = async () => {
    await PutInWaitingList(branch, selectedBranchObj);
  };

  return (
    <>
      <Modal
        show={terminateSubscriptionModal}
        onHide={handleTerminateSubscriptionModalClose}
      >
        <Modal.Header>
          <Modal.Title>Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col>
            <RequiredLabel htmlFor="terminationCategory" text="Category" />
            <PullDown
              filterkey="key"
              defaultValue={terminationCategory}
              labelInside={false}
              pulldownFor={"terminationCategory"}
              forceBlank={true}
              setValue={setTerminationCategory}
              options={categories}
              blankLabel="Others"
            />
          </Col>
          <Col>
            <textarea
              style={{
                width: "-webkit-fill-available",
                borderColor: "#dee2e6",
              }}
              onChange={(e) => setReason(e.target.value)}
              cols="30"
              rows="10"
              defaultValue={reason}
            />
          </Col>
        </Modal.Body>
        <button
          style={{ width: "auto", margin: "10px" }}
          variant="primary"
          onClick={sendReasonHandler}
        >
          Save Changes
        </button>
      </Modal>
      <Modal
        show={addMeToWaitingListModal}
        onHide={() => setAddMeToWaitingListModal(false)}
      >
        <Modal.Header>
          <Modal.Title>
            {filled ? "Notice!" : "Missing Information!"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {filled ? (
            <>
              Please make sure to review our{" "}
              <NavLink target="_blank" to="/termsandconditions">
                terms and conditions
              </NavLink>{" "}
              before you proceed. <br />
              <BranchePullDown
                branch={branch}
                labelInside={false}
                setBranch={setSelectedBranch}
                branches={branches}
                extraClass="grid-select genderProfile"
                isRequired={true}
                forceBlank={false}
              />
            </>
          ) : (
            <>
              Please go to your profile and fill these required information
              before you proceed:
              <ul>
                {!currentUser.town ||
                !currentUser.floor ||
                !currentUser.buildingName ||
                !currentUser.street ? (
                  <li>Address</li>
                ) : (
                  ""
                )}
                {!currentUser.iagree ? (
                  <li>Terms and conditions are not signed.</li>
                ) : (
                  ""
                )}
                {!currentUser.cardId ? <li>Identity Card</li> : ""}
                {!currentUser.branch ? <li>Branch</li> : ""}
              </ul>
            </>
          )}
        </Modal.Body>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <button
            style={{ margin: "10px" }}
            variant="primary"
            onClick={
              filled ? handleAddMeToWaitingListClick : editProfileHandler
            }
          >
            {filled ? "I wish to book a bike" : "Edit Profile"}
          </button>

          <button
            style={{ margin: "10px" }}
            variant="primary"
            onClick={() => setAddMeToWaitingListModal(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
      {/*<Modal show={addMeToWaitingListModal} onHide={handleAddMeToWaitingListModalClose}>
        <Modal.Header>
          <Modal.Title>Notice!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please make sure to review our  <link>terms and conditions</link> before you proceed.
        </Modal.Body>
        <button style={{ width: 'auto', margin: '10px' }} variant="primary" onClick={handleAddMeToWaitingListClick}>
          Add Me To Waiting List
        </button>
        <button style={{ width: 'auto', margin: '10px' }} variant="primary" onClick={handleAddMeToWaitingListModalClose}>Cancel</button>
      </Modal>*/}

      {currentUser.type === "subscriber" ||
      currentUser.type === "registered" ? (
        profileLoaded ? (
          <div className="dashboardContainer">
            <Row
              className="dashboardRow"
              style={{
                flexDirection: "row",
                textAlign: "left",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <Col lg={6}>
                <div className="dashboardCard">
                  <Row
                    style={{
                      flexDirection: "row",
                      textAlign: "left",
                      alignItems: "flex-start",
                    }}
                  >
                    <Col
                      sm={12}
                      style={{ marginLeft: "0px", textAlign: "left" }}
                    >
                      <span style={{ fontSize: 22 }}>
                        Hello {biker.first_name}!
                      </span>
                      <br />
                      {lastSubscribe &&
                      lastSubscribe.length > 0 &&
                      lastSubscribe[0].bike &&
                      !lastSubscribe[0].packageReturned ? (
                        <>
                          <br /> This is your current subscription plan:
                          <br />
                          <br />
                        </>
                      ) : null}
                    </Col>
                  </Row>
                  {lastSubscribe &&
                  lastSubscribe.length > 0 &&
                  !lastSubscribe[0].packageReturned ? (
                    <>
                      <Row
                        style={{
                          flexDirection: "row",
                          textAlign: "left",
                          alignItems: "flex-start",
                          marginTop: "-15px",
                        }}
                      >
                        <Col
                          sm={7}
                          style={{ marginLeft: "0px", textAlign: "left" }}
                        >
                          Plan:{" "}
                          {lastSubscribe[0].plan
                            ? lastSubscribe[0].plan.label
                            : "Not set"}
                          <br />
                          Started:{" "}
                          {DateFormatter.DateFormatterOne(
                            lastSubscribe[0].start
                          )}{" "}
                          <br />
                          {lastSubscribe[0].nextRenewal &&
                          lastSubscribe[0].nextRenewal !== "None" ? (
                            <>
                              Next Renewal:{" "}
                              {DateFormatter.DateFormatterOne(
                                lastSubscribe[0].nextRenewal
                              )}
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          {lastSubscribe[0].invoice &&
                          !lastSubscribe[0].invoice.draft ? (
                            <>
                              Last Invoice:{" "}
                              <a
                                href="javascript:void(0)"
                                onClick={() =>
                                  viewPDF(
                                    lastSubscribe[0].invoice._id,
                                    lastSubscribe[0].invoice.fileName
                                  )
                                }
                                className="text-light midText "
                              >
                                <i className="fas fa-file"></i>
                              </a>
                            </>
                          ) : (
                            ""
                          )}
                          <br />
                        </Col>

                        <Col
                          sm={5}
                          style={{ marginLeft: "0px", textAlign: "left" }}
                        >
                          <img
                            style={{ width: 150 }}
                            className="rowPic"
                            src={
                              lastSubscribe[0].bike &&
                              lastSubscribe[0].bike.model &&
                              lastSubscribe[0].bike.model.pic
                                ? process.env.REACT_APP_SERVER_API +
                                  "/upload/models/" +
                                  lastSubscribe[0].bike.model.pic
                                : bikePic
                            }
                          />
                        </Col>
                      </Row>
                      {lastSubscribe[0].terminated ? (
                        <Row className="strong">
                          {" "}
                          Your subscription has been terminated, please return
                          the bike before{" "}
                          {DateFormatter.DateFormatterOne(
                            lastSubscribe[0].lastDate
                          )}{" "}
                        </Row>
                      ) : null}
                      {/*} <Row>{!lastSubscribe[0].terminated ? <button onClick={() => handleReasonModalShow(lastSubscribe[0]._id)} className="btn-primary btn-block dashboard-btn">Terminate subscription</button> : ""}</Row>*/}
                    </>
                  ) : (
                    <>
                      {currentUser.waitingList &&
                      currentUser.waitingStatus == "Confirmed" ? (
                        <p style={{ marginTop: "10px" }}>
                          We have great news for you! Your Wave bike is ready,
                          and waiting for you!
                          <br />
                          <br />
                          Please pass by the Wave hub in Geitawi on{" "}
                          {DateFormatter.DateFormatterOne(
                            currentUser.pickupDate
                          )}{" "}
                          between 9am and 5pm.
                          <br />
                          The fees include a monthly subscription of $49, along
                          with a fully refundable deposit of $150, that we will
                          keep until you terminate your subscription.
                          <br />
                          <br /> You will receive a Wave helmet that will be
                          free of charge after the completion of 3 months, in
                          case you subscribe for less, the helmet cost will be
                          20$.
                          <br />
                          <br />
                          If you wish to reschedule please{" "}
                          <a
                            target="_new"
                            className="text-light"
                            href="https://wa.me/96170632955"
                          >
                            contact
                          </a>{" "}
                          us.
                          <br />
                          See you soon!
                        </p>
                      ) : (
                        <>
                          {currentUser.waitingList ? (
                            <p style={{ marginTop: "10px" }}>
                              You have been added to the waiting list starting{" "}
                              <b>
                                {DateFormatter.DateFormatterOne(
                                  currentUser.waitingSince
                                )}
                              </b>
                              . <br />
                              <br />
                              We are super excited to have you onboard
                              supporting our mission to create a cleaner and
                              more efficient way of commuting.
                              <br />
                              We'll make sure a bike is ready for you!
                            </p>
                          ) : (
                            <p style={{ marginTop: "10px" }}>
                              Thank you for registering to Wave!
                              <br />
                              <br />
                              We are super excited to have you onboard
                              supporting our mission to create a cleaner and
                              more efficient way of commuting.
                              <br />
                              If you wish to book a bike, kindly do so below and
                              we'll make sure a bike is ready for you!
                            </p>
                          )}
                          <WaitingsClient
                            userId={user._id}
                            setAddMeToWaitingListModal={
                              setAddMeToWaitingListModal
                            }
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="dashboardCard">
                  <Row
                    style={{
                      flexDirection: "row",
                      textAlign: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <Col sm={12} style={{ fontSize: 22 }}>
                      Your Recorded trips so far
                      <br />
                      <div className="red">
                        Coming Soon!
                        <br />
                      </div>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      flexDirection: "row",
                      textAlign: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <Col sm={6}>
                      <p>
                        <b>
                          Rides <i className="fas fa-biking"></i>
                        </b>
                        <br />
                        <span>{biker.rides ? biker.rides : "0"}</span>
                      </p>
                    </Col>
                    <Col sm={6}>
                      <p>
                        <b>
                          Distance <i className="fas fa-road"></i>
                        </b>
                        <br />
                        <span>
                          {biker.distance
                            ? parseFloat(biker.distance).toFixed(2)
                            : "0"}{" "}
                          Km
                        </span>
                      </p>
                    </Col>
                  </Row>
                  <hr />
                  <Row
                    style={{
                      flexDirection: "row",
                      textAlign: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <Col sm={6}>
                      <p>
                        <b>
                          Avg.Speed <i className="fas fa-tachometer-alt"></i>
                        </b>
                        <br />
                        <span>
                          {biker.avgspeed
                            ? parseFloat(biker.avgspeed).toFixed(2)
                            : "0"}{" "}
                          Km/h
                        </span>
                      </p>
                    </Col>
                    <Col sm={6}>
                      <p>
                        <b>
                          Co2 Saved <i className="fas fa-burn"></i>
                        </b>
                        <br />
                        <span>
                          {biker.co2 ? parseFloat(biker.co2).toFixed(2) : "0"}{" "}
                          gr
                        </span>
                      </p>
                    </Col>
                  </Row>
                  <hr />

                  <Row
                    style={{
                      flexDirection: "row",
                      textAlign: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <Col sm={6}>
                      <p>
                        <b>
                          Cal Burned <i className="fas fa-bacon"></i>
                        </b>
                        <br />
                        <span>
                          {biker.calories
                            ? parseFloat(biker.calories).toFixed(2)
                            : "0"}{" "}
                          cal
                        </span>
                      </p>
                    </Col>
                    <Col sm={6}>
                      <p>
                        <b>
                          Cost Saved <i className="fas fa-dollar-sign"></i>
                        </b>
                        <br />
                        <span>
                          {biker.costsaved
                            ? parseFloat(biker.costsaved).toFixed(2)
                            : "0"}
                        </span>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <SubscriptionInfo />
          </div>
        ) : (
          <Loader />
        )
      ) : currentUser.type === "accountant" ? (
        <div>
          <AccountantDashboard />
        </div>
      ) : currentUser.type === "reseller" ? (
        <div>
          <ResellerDashboard />
        </div>
      ) : (
        <div>
          <AdminDashboard />
        </div>
      )}
    </>
  );
}
