import React, { useState, useEffect } from "react";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import logo from '../images/logo.png';
import EventBus from "../common/EventBus";
import UserMenu from "./UserMenu";
import ClientMenu from "./ClientMenu";
import AccountantMenu from "./AccountantMenu";
import TechnicalMenu from "./TechnicalMenu";


export default function MenuController(props) {

  const [user, setUser] = useState("")
  const [type, setType] = useState("")
  const [company, setCompany] = useState("")
  const path = window.location.pathname;

  useEffect(() => {

    const user = AuthService.getCurrentUser() || "";
    setUser(user)
    setType(user.type)
	setCompany(user.company)
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
    setUser(undefined);
    window.location.href = window.location.protocol + "//" + window.location.host + "/login";
  };
  if (path.split("/")[1] !== "survey") {
    if (type === "admin" || type === "manager" || type === "helpdesk")
      return <UserMenu type={type} logOut={logOut} user={user} />

    else if (type === "accountant")
      return <AccountantMenu type={type} logOut={logOut} user={user} />

    else if (type === "registered" || type === "subscriber")
      return <ClientMenu type={type} logOut={logOut} user={user} />

    else if (type === "technical" || type === "reseller")
      return <TechnicalMenu type={type} logOut={logOut} user={user} company={company} />
    else
      return <></>
  }
  else return <></>


}



