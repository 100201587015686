import React from 'react'
import { isEmail } from "validator";

export const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};


export const fieldLimit = (value) => {
    if (value.length < 3) {
        return (
            <div className="alert alert-danger" role="alert">
                The field must be between more than 3 characters.
            </div>
        );
    }
};


export const validEmail = (value) => {
    if (value && !isEmail(value.trim())) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};


export const vusername = (value) => {
    if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                The field must be between 3 and 20 characters.
            </div>
        );
    }
};



export const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};