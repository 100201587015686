import React from "react";
import Testimonial from "../components/Testimonial";
import Accent from "../components/Accent";

export default function TestimonialSection(props) {
  return (
    <section className="inner_container padding_container">
      <div id="testimonials">
        <div className="landing_titles">
          <h1>
            <b className="pageTitle">
              <Accent>Feedback from Wavers</Accent>
            </b>
          </h1>
        </div>
        <Testimonial />
        <div className="button_container">
          <button
            id="landing_home_reserve_now_button"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "/signup";
            }}
          >
            Join the community
          </button>{" "}
        </div>
      </div>
    </section>
  );
}
