import React, { useState, useEffect, useRef, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import Textarea from "react-validation/build/textarea";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import FaqService from "../services/faq.service";
import EventBus from "../common/EventBus";
import commonFunctions from "../common/Functions";
import RequiredLabel from "../common/RequiredLabel";
import { required } from "../common/Validation";
import { getBranches } from "../common/getFunctions";
import Switch from "react-switch";
import { faq_categories } from "../labels/options_en";
import { Prompt } from "react-router-dom";



const FaqForm = (props) => {
	const form = useRef();
	const checkBtn = useRef();
	const currentUser = JSON.parse(localStorage.getItem('user'));
	const [title, setTitle] = useState("");
	const [answer, setAnswer] = useState("");
	const [branches, setBranches] = useState([]);
	const [branch, setBranch] = useState("");
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	const [faqId, setFaqId] = useState(props.match.params.id);
	const [category, setCategory] = useState("");
	const [iAmTyping, setIAmTyping] = useState(false);
	const clone = props.location.pathname.includes('/clone/');

	const getFaqInfo = async () => {
		if (faqId) {
			FaqService.getFaqById(faqId).then(
				(response) => {
					let b = response.data;
					setTitle(b.title);
					setBranch(b.branch);
					setAnswer(b.content);
					setCategory(b.category);

					if (clone === true) { setFaqId(""); } // reset the id to clone
				},
				(error) => {
					const _content =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
					alert(_content);
					setMessage(_content);

					if (error.response && error.response.status === 401) {
						EventBus.dispatch("logout");
					}
				}
			);
		} else {
			setBranch(currentUser.branch);

		}

	}

	useEffect(() => {
		async function onReady() {
			window.scrollTo(0, 0);
			setBranches(await getBranches());
			getFaqInfo();
		}

		onReady()
	}, []);

	const handleSaveFaq = (e) => {
		e.preventDefault();
		setIAmTyping(false);
		setMessage("");
		setSuccessful(false);

		form.current.validateAll();

		if (checkBtn.current.context._errors.length === 0) {

			FaqService.postFaq(faqId, title, answer, 1, branch, category).then(
				(response) => {
					setMessage("Faq Saved.");
					setSuccessful(true);
					props.history.push('/faqs');
				},
				(error) => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setMessage(resMessage);
					setSuccessful(false);
				}
			);



		}
	};



	return (
		<Fragment>
			<Prompt
				when={iAmTyping}
				message={(location) =>
					'Are you sure, you want to leave? All your data will be lost!'
				}
			/>
			<div className="col-md-12">


				<Form onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} ref={form}>
					{!successful && (
						<div>


							<table className="styled-table" style={{ width: "100%" }}>
								<thead>
									<tr>
										<th style={{ width: "25%" }}>Create a FAQ</th>


									</tr>
								</thead>
								<tbody>
									{currentUser.type === "admin" ?
										<tr className="white-background">


											<td>
												<div className="form-group">
													<label htmlFor="branch">Branch</label>
													<Select
														className="form-control"
														value={branch}
														onChange={e => {
															setBranch(e.target.value);
															setIAmTyping(true);
														}}

													>
														<option value=""></option>
														{branches && branches.map((m) => (

															<option value={m._id}>{m.label}</option>
														))}

													</Select>
												</div>

											</td>

										</tr>
										:
										null
									}

									<tr className="white-background">
										<td>
											<div className="form-group">
												<label htmlFor="label">Category</label>
												<Select
													className="form-control"
													value={category}
													onChange={e => {
														setCategory(e.target.value);
														setIAmTyping(true);
													}}

												>
													<option value=""></option>
													{faq_categories && faq_categories.map((m) => (

														<option value={m.key}>{m.value}</option>
													))}

												</Select>
											</div>
										</td>


									</tr>
									<tr className="white-background">
										<td>
											<div className="form-group">
												<label htmlFor="label">Question</label>
												<Input
													type="text"
													className="form-control"
													name="title"
													value={title}
													onChange={e => {
														setTitle(e.target.value);
														setIAmTyping(true);
													}}
													validations={[required]}
												/>
											</div>
										</td>


									</tr>

									<tr className="white-background">



										<td>
											<div className="form-group">
												<RequiredLabel htmlFor="price" text="Answer" />
												<Textarea
													className="form-control"
													value={answer}
													onChange={e => {
														setAnswer(e.target.value);
														setIAmTyping(true);
													}}
													validations={[required]}

												/>
											</div>
										</td>
									</tr>



								</tbody>
							</table>

							<div className="form-group">
								<button className="btn btn-primary btn-block" onClick={handleSaveFaq} >Save</button>
							</div>
						</div>
					)}

					{message && (
						<div className="form-group">
							<div
								className={
									successful ? "alert alert-success" : "alert alert-danger"
								}
								role="alert"
							>
								{message}
							</div>
						</div>
					)}
					<CheckButton style={{ display: "none" }} ref={checkBtn} />
				</Form>

			</div>
		</Fragment>
	);
};

export default FaqForm;