import React, { useState, useEffect } from "react";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../commonComponents/Loader";
import * as labels from '../labels/en';
import { Row, Col, Card } from "react-bootstrap";
import SurveyService from "../services/survey.service";
import * as options from '../labels/options_en';

const SurveyForms = (props) => {
    const [error, setError] = useState("");
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const [loading, setLoading] = useState(true);
    const [resetMsg, setResetMsg] = useState(true);
    const [itemsPerPage, setitemsPerPage] = useState(9);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [count, setCount] = useState(0);
    const [surveyForms, setSurveyForms] = useState([]);
    const [typeFilter, setTypeFilter] = useState(mapFormType(props.match.params.type));


    const pageSizes = [9, 18, 24, 30, 36, 60];
    useEffect(() => {
        async function onReady() {
            window.scrollTo(0, 0);
            await getSurveyForms(typeFilter, page, itemsPerPage);


        }

        onReady()
    }, []);

	function mapFormType(key){
		if (key == "journeys") return "journey";
		else if (key == "events") return "Events";
		else if (key == "general") return "General";
		else return "";
	}
	
    const getSurveyForms = async (typeFilter, page, itemsPerPage) => {
        setLoading(true);
        SurveyService.getSurveyFormsList(typeFilter, (page - 1) * itemsPerPage, itemsPerPage).then(
            (response) => {
                if (response && response.data && response.data.data && response.data.data.list) {
                    let data = response.data.data;
                    console.log(response);
                    setSurveyForms(data.list);
                    setTotal(data ? data.total : 0);
                    setCount(Math.ceil(data.total / itemsPerPage));
                    setResetMsg("");
                    setLoading(false);
                }


            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                alert(_content);
                setLoading(false);
                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");

                }
            }
        );

    }





    const handlePageChange = async (event, value) => {
        setPage(value);
        await getSurveyForms(typeFilter, value, itemsPerPage);

    };

    const handlePageSizeChange = async (newItemsPerPage) => {
        setitemsPerPage(newItemsPerPage);
        setPage(1);
        await getSurveyForms(typeFilter, 1, newItemsPerPage);
    };

    const triggerSearch = async () => {
        setPage(1); // return to first page
        await getSurveyForms(typeFilter, page, itemsPerPage);
    }

    const resetSearch = async () => {
        setTypeFilter("");
        await getSurveyForms("", page, itemsPerPage);
    }

    const downloadFile = (data, fileName) => {
        if (data) {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName + '.csv')
            document.body.appendChild(link)
            link.click()
        }
        else { alert("Nothing to export!") }
    }
    const exportAnswers = (id, formName) => {
        SurveyService.exportAnswers(id).then(
            (response) => {
                downloadFile(response.data, formName);

            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                alert(_content);
                setLoading(false);
                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");

                }
            }
        )
    }


    const handleDeleteForm = (id) => {
        if (window.confirm("Are you sure you want to delete this form?")) {
            SurveyService.deleteForm(id).then(
                (response) => {
                    setSurveyForms(surveyForms.filter((f, i) => f._id !== id));
                    alert("The form has been deleted.");
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    alert(_content);
                    setLoading(false);
                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");

                    }
                }
            )
        }
    }

    const handleDeleteSurveys = (id) => {
        if (window.confirm("Are you sure you want to delete all the surveys for this form?")) {
            SurveyService.deleteSurveys(id).then(
                (response) => {
                    alert("Surveys have been deleted.");
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    alert(_content);
                    setLoading(false);
                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");

                    }
                }
            )
        }
    }






    return (
        <div className="container">
            {currentUser.type === 'admin' || currentUser.type === 'manager' || currentUser.type === 'helpdesk' ? <div className="floatRight "><NavLink to={"/form/new/" + (typeFilter ? typeFilter : "journey") } className="greenLink mx-0">Add Form</NavLink></div> : ""}

            {error ? <header className="jumbotron"> <h3>{error}</h3>  </header> : null}

            <div>
                <h3> Survey Forms List </h3>

                <div className="filterContainer">


                    <Col md={3}>
                        <label className="filterLabelInput" htmlFor="branch">Type</label>

                        <select
                            className="form-control filterInput2"
                            value={typeFilter}
                            onChange={(e) => setTypeFilter(e.target.value)}

                        >
                            <option value="" key="0">All</option>
                            {
                                options.form_types.map((item, index) =>
                                    <option value={item.key}>{item.value}</option>
                                )}
                        </select>
                    </Col>
                    <Col>
                        <table style={{ marginTop: 30 }} border="0">
                            <tr>
                                <td><button onClick={(e) => triggerSearch(e)} className="btn btn-primary btn-block search-button ">Go</button></td>
                                <td><button onClick={(e) => resetSearch(e)} className="btn btn-primary btn-block reset-button ">Reset</button></td>
                            </tr>
                        </table>
                    </Col>

                </div>

                {!error && !loading && surveyForms.length !== 0 ?
                    <div>
                        <Row>
                            <Col><span style={{ float: "right", marginLeft: 10 }}>{total} results</span></Col>
                        </Row>
                        <Row style={{ display: "flex", flexDirection: "row", alignItems: "first baseline" }}>
                            {surveyForms && surveyForms.length !== 0 ? surveyForms.map((u) => (
                                <div style={{ float: "left", marginLeft: 20 }}>
                                    <Card style={{ marginLeft: 0, width: 300, minHeight: 250, paddingLeft: 10, align: "top" }}>
                                        <Card.Body>
                                            <Card.Title style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                {u.label}

                                            </Card.Title>
                                            <NavLink to={"/form/edit/" + u._id} className="text-dark midText"><i alt="Edit User" title="Edit User" className="fa fa-pen" aria-hidden="true"></i></NavLink>
                                            &nbsp;&nbsp;
                                            <NavLink to={"/form/" + u._id} className="text-dark midText"><i alt="View Form" title="View Form" className="fa fa-binoculars" aria-hidden="true"></i></NavLink>
                                            &nbsp;&nbsp;
                                            <button onClick={() => handleDeleteForm(u._id)} className="midText linkBtn"><i alt="Delete" title="Delete" className="fa fa-trash" ></i></button>
                                            <br />
                                            <NavLink to={"/questions/" + u._id} className="text-dark midText"><span className="greenLink"><i className="fa fa-list-check" > <span className="faText">Manage Questions</span></i></span></NavLink><br />
                                            <NavLink to={"/survey/" + u._id} target="_blank" className="text-dark midText"><span className="greenLink"><i className="fa fa-magnifying-glass" > <span className="faText">Preview</span></i></span></NavLink><br />
                                            <NavLink to={"/answers/" + u._id} className="text-dark midText"><i className="fa fa-pen-to-square" > <span className="faText">Answers</span></i></NavLink><br />
                                            <NavLink to={"/analytics/form/" + u._id} className="text-dark midText"><i className="fa fa-pie-chart" ><span className="faText"> Analytics</span></i></NavLink><br />
                                            <button onClick={() => exportAnswers(u._id, u.label)} className="linkBtn greenLink"><i alt="Export" title="Export" className="fa fa-download" > <span className="faText">Export Answers</span></i></button>  <br />
                                            <button onClick={() => handleDeleteSurveys(u._id)} className="linkBtn greenLink"><i className="fa fa-eraser" > <span className="faText">Delete Surveys</span></i></button><br />
                                            {currentUser.type === 'admin' ? <><span className="tinyCardLabel">Branch:&nbsp;</span><span className="midText">{u.branch ? u.branch.label : ""}</span><br /></> : ""}
                                            <span className="tinyCardLabel">Type:&nbsp;</span><span className="midText">{u.type}</span><br />
                                            <span className="tinyCardLabel">Public:&nbsp;</span><span className="midText">{u.public ? "Yes" : "No"}</span><br />
                                            <span className="tinyCardLabel">Disabled:&nbsp;</span><span className="midText">{u.disabled ? "Yes" : "No"}</span><br />
                                        </Card.Body>
                                    </Card>
                                </div>
                            )) : "No recod found"}
                        </Row>

                        <div className="mt-3">
                            {"Items per Page: "}
                            <select onChange={(e) => handlePageSizeChange(e.target.value)} value={itemsPerPage}>
                                {pageSizes.map((size) => (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select>

                            <Pagination
                                className="my-3"
                                count={count}
                                page={page}
                                siblingtotal={1}
                                boundarytotal={1}
                                variant="outlined"
                                color="primary"
                                shape="rounded"
                                onChange={handlePageChange}
                                size="medium"

                            />
                        </div>
                    </div>
                    : loading ? <Loader /> :
                        resetMsg != "" ? <div className='defaultMsg'>{resetMsg}</div> :
                            <div className="noResultsFound">{labels.NO_RESULTS}</div>
                }

            </div>
        </div>
    );
};

export default SurveyForms;
