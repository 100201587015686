import React from "react";
import TermsAndConditions from "../assets/pdf/wave-terms-and-conditions.pdf";
import PaymentPlans from "../components/PaymentPlans";
import Accent from "../components/Accent";

export default function PaymentPlansSections(props) {
  return (
    <section className="inner_container">
      <div className="payment_plans_container">
        <div id="landing_home_fees">
          <div className="landing_titles">
            <h1>
              <b className="pageTitle">
                <Accent>Payment Plans</Accent>
              </b>
            </h1>
          </div>
          <PaymentPlans />
        </div>
        <div className="deposit_warning">
          <p
            style={{
              textAlign: "center",
              fontSize: "12px",
              color: "#595959",
              margin: 0,
            }}
          >
            A deposit of $150 is required upon pick up of bike and refundable
            upon termination of subscription.
            <br />{" "}
            <a
              style={{ textDecoration: "underline" }}
              href={TermsAndConditions}
              target="_blank"
            >
              Terms & conditions apply.
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}
