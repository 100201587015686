import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import UserService from "../services/user.service";

const TestimonialModal = (props) => {

    const testimonialModal = props.testimonialModal;
    const setTestimonialModal = props.setTestimonialModal;
    const selectedUserByTestimonial = props.selectedUserByTestimonial;
    const setSelectedUserByTestimonial = props.setSelectedUserByTestimonial;
    const list = props.list;
    const setList = props.setList;
    const type = props.type;

    const [testimonial, setTestimonial] = useState("");



    const handleTestimonialModalClose = () => {
        setSelectedUserByTestimonial("")
        setTestimonial("")
        setTestimonialModal(false)
    };

    const sendTestimonialHandler = () => {

        var data = {
            userid: selectedUserByTestimonial,
            testimonial: testimonial,
        }

        UserService.addTestimonial(data).then(async ({ data }) => {
            setList(list.map((u, i) => {
                if (type === "subscriptions") {
                    if (u.user === selectedUserByTestimonial) u.user.testimonial = testimonial;
                }
                else if (u.id === selectedUserByTestimonial) {
                    u.testimonial = testimonial;
                }
                return u;
            }))
        })

        handleTestimonialModalClose();
    }

    return (
        <Modal show={testimonialModal} onHide={handleTestimonialModalClose}>
            <Modal.Header>
                <Modal.Title>Testimonial</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <textarea
                    style={{ width: '100%', borderColor: '#dee2e6' }}
                    onChange={(e) => setTestimonial(e.target.value)}
                    rows="10"
                    defaultValue={testimonial || props.oldTestimonial}
                />
            </Modal.Body>
            <button style={{ width: 'auto', margin: '10px' }} variant="primary" onClick={sendTestimonialHandler}>
                Save Changes
            </button>
        </Modal>
    )
}

export default TestimonialModal;