import React, {useEffect} from 'react';
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import AuthService from "../services/auth.service";

export default function TechnicalDashboard() {

    return (
        <div>
        </div>
    )
}
