import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../images/logo2.png";
import Accordion from "react-bootstrap/Accordion";
import { menu_select_hover_color } from "../labels/options_en";
import { Navbar, Nav } from "react-bootstrap";

export default function UserMenu(props) {
  const user = props.user;
  const type = props.type;
  const logOut = props.logOut;

  const showHelpDeskExpand = 0;
  const [expanded, setExpanded] = useState(false);
  return (
    <Navbar
      id="sidebar"
      expand="xl"
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
    >
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="pt-5">
        <Nav className="list-unstyled components mb-5 p-4 pt-5 flex-column">
          <Navbar.Brand onClick={() => setExpanded(false)}>
            <Link to={"/dashboard"} className="navbar-brand pb-4">
              <img className="logoImage" src={logo} alt="logo" />
            </Link>
          </Navbar.Brand>

          <Nav.Link>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Settings{" "}
                  <i
                    style={{ float: "right" }}
                    className="fas fa-caret-down"
                  ></i>
                </Accordion.Header>
                <Accordion.Body>
                  <ul className="list-unstyled" id="homeSubmenu">
                    <li>
                      <NavLink
                        onClick={() => setExpanded(false)}
                        style={(isActive) => ({
                          fontWeight: isActive ? "bold" : "normal",
                        })}
                        to={"/profile"}
                        className="nav-link greenLink"
                      >
                        <i className="fas fa-user"></i>&nbsp;&nbsp;Profile
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => setExpanded(false)}
                        style={(isActive) => ({
                          fontWeight: isActive ? "bold" : "normal",
                        })}
                        to={"/changepassword"}
                        className="nav-link greenLink"
                      >
                        <i className="fas fa-unlock-alt"></i>&nbsp;&nbsp;Change
                        Password
                      </NavLink>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Nav.Link>

          <Nav.Link>
            <Accordion defaultActiveKey={showHelpDeskExpand}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Files{" "}
                  <i
                    style={{ float: "right" }}
                    className="fas fa-caret-down"
                  ></i>
                </Accordion.Header>

                <Accordion.Body>
                  <ul className="list-unstyled" id="homeSubmenu">
                    <li>
                      <NavLink
                        onClick={() => setExpanded(false)}
                        style={(isActive) => ({
                          fontWeight: isActive ? "bold" : "normal",
                        })}
                        to={"/invoices"}
                        className="nav-link greenLink mx-0"
                      >
                        <i className="fas fa-receipt"></i>&nbsp;&nbsp;Invoices
                      </NavLink>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Nav.Link>

          <Nav.Link>
            <Accordion defaultActiveKey={showHelpDeskExpand}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Analytics
                  <i
                    style={{ float: "right" }}
                    className="fas fa-caret-down"
                  ></i>
                </Accordion.Header>

                <Accordion.Body>
                  <ul className="list-unstyled" id="homeSubmenu">
                    <li>
                      <NavLink
                        onClick={() => setExpanded(false)}
                        style={(isActive) => ({
                          color: isActive ? menu_select_hover_color : "white",
                        })}
                        to={"/analytics/revenue"}
                        className="nav-link greenLink mx-0"
                      >
                        <i className="fas fa-dollar-sign"></i>
                        &nbsp;&nbsp;Revenue
                      </NavLink>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Nav.Link>

          <Nav.Link>
            <Accordion defaultActiveKey={showHelpDeskExpand}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Transactions
                  <i
                    style={{ float: "right" }}
                    className="fas fa-caret-down"
                  ></i>
                </Accordion.Header>

                <Accordion.Body>
                  <ul className="list-unstyled" id="homeSubmenu">
                    <li>
                      <NavLink
                        onClick={() => setExpanded(false)}
                        style={(isActive) => ({
                          color: isActive ? menu_select_hover_color : "white",
                        })}
                        to={"/cashlogs"}
                        className="nav-link greenLink mx-0"
                      >
                        <i className="fas fa-list"></i>&nbsp;&nbsp;List
                      </NavLink>

                      <NavLink
                        onClick={() => setExpanded(false)}
                        style={(isActive) => ({
                          color: isActive ? menu_select_hover_color : "white",
                        })}
                        to={"/cashlog/summary"}
                        className="nav-link greenLink mx-0"
                      >
                        <i className="fas fa-money-bill"></i>&nbsp;&nbsp;Summary
                      </NavLink>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Nav.Link>

          <Nav.Link>
            <a href="/" className="nav-link greenLink mx-0" onClick={logOut}>
              <i className="fas fa-sign-out-alt"></i>&nbsp;&nbsp;LogOut
            </a>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
