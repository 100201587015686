import React from "react";
import { planTypes } from "../labels/options_en";
export default function PlanTypes(props) {
  const name = props.name;
  const label = props.label;
  const defaultValue = props.defaultValue;
  const setValue = props.setValue;
  const extraClass = props.extraClass || "";
  const multiple = props.multiple || false;
  const labelInside = props.labelInside || false;
  const isRequired = props.isRequired || "";
  const forceBlank = props.forceBlank || false;
  const blankLabel = props.blankLabel || "";
  const filterkey = "value";
  let option1 = "";
  if (forceBlank === true) {
    option1 = <option value="">{blankLabel}</option>;
  }

  const handleOptionChange = async (e) => {
    if (multiple) {
      setValue(Array.from(e.target.selectedOptions, (option) => option.value));
    } else {
      setValue(e.target.value);
    }
  };
  let selectPlanTypes = (
    <select
      name={name}
      autoComplete="new-password"
      className={"form-control " + extraClass}
      defaultValue={defaultValue}
      value={defaultValue}
      placeholder={label}
      onChange={(e) => handleOptionChange(e)}
      required={isRequired}
      multiple={multiple}
    >
      {option1}
      {planTypes &&
        planTypes.length > 0 &&
        planTypes.map((m) => (
          <option
            value={m[filterkey]}
            selected={
              (multiple &&
                Array.isArray(defaultValue) &&
                defaultValue.includes(m)) ||
              (!multiple && defaultValue === m[filterkey])
            }
          >
            {m.label}
          </option>
        ))}
    </select>
  );

  if (!labelInside) {
    return selectPlanTypes;
  } else {
    return (
      <div className="form-group">
        <label htmlFor={defaultValue}>{label}</label>
        {selectPlanTypes}
      </div>
    );
  }
}
