import React from "react";

const MaintenancesSection = ({ maintenances, activeTab }) => {
  return (
    <div
      id="maintenances"
      className={`tab-content ${
        activeTab === "maintenances" ? "expanded" : "collapsed"
      }`}
    >
      <table className="styled-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Number</th>
            <th>Category</th>
            <th>Subcategory</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {maintenances.map((maintenance, index) => (
            <tr key={index}>
              <td className="upperCase">{maintenance.type}</td>
              <td>{maintenance.number}</td>
              <td>{maintenance.category}</td>
              <td>
                {maintenance.type === "bike" ? maintenance.subcategory : "N/A"}
              </td>
              <td>{maintenance.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MaintenancesSection;
