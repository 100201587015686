import React, { useEffect } from "react";
import "../css/home.css";
import WaveUsersImage from "../assets/images/WaveUsers.jpg";
import WaveTeamImage from "../assets/images/WaveTeam.png";
import Accent from "../components/Accent";
import money from "../assets/images/Vector.svg";
import environmental from "../assets/images/environmental.svg";
import health from "../assets/images/health.svg";
import { trackPage } from "../components/GoogleAnalytics";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
    trackPage("About Wave");
  }, []);

  const whyWave = [
    {
      image: environmental,
      content: "18x less polluting than driving a car",
    },
    {
      image: money,
      content: " Time efficient & financially accessible",
    },
    {
      image: health,
      content: " Offers required activity for a healthy lifestyle",
    },
  ];
  return (
    <div className="outer_container">
      <div className="inner_container">
        <div className="about-header">
          <div>
            <div className="about-header-mobile">
              <h1>
                <b className="pageTitle">
                  <Accent>
                    <span>Our mission</span>
                  </Accent>
                </b>
              </h1>
              <p className="text-center-mobile" style={{ fontSize: "14px" }}>
                Wave is a Lebanese-Dutch social enterprise on a mission to
                enable cycling in the dense cities of the greater MENA region.
                <br />
                Affordable and proper transport is the bedrock of a
                well-functioning society. Cycling will positively impact the
                community, economy, and environment.
                <br />
                <br />
                Join our cause!
              </p>
              <button
                id="landing_home_reserve_now_button"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/signup";
                }}
              >
                Subscribe Now
              </button>{" "}
            </div>
          </div>
          <div>
            <div>
              <img
                src={WaveUsersImage}
                style={{ borderRadius: "20px" }}
                alt="WaveUsers"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Section 2: Why Wave */}
      <section className="inner_container">
        <div id="why_wave_desktop_version">
          <div id="landing_about_why_wave" className="padding_container">
            <div className="landing_titles">
              <h1>
                <b className="pageTitle">
                  <Accent className="accent_container">Why Wave</Accent>
                </b>
              </h1>
            </div>

            <div className="align_why_wave">
              {whyWave.map((step, index) => {
                return (
                  <div key={`step-${index}`}>
                    {step.image && (
                      <div>
                        <img
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px",
                            alignSelf: "stretch",
                            width: "50px",
                            height: "50px",
                            textAlign: "center",
                            margin: "0 auto",
                          }}
                          src={step.image}
                          alt="Wave Logo"
                        />
                      </div>
                    )}
                    <p
                      style={{
                        // lineHeight:"10px",
                        fontSize: "14px",
                        fontWeight: 500,
                        textAlign: "center",
                        margin: "0 auto",
                        color: "#595959",
                      }}
                      dangerouslySetInnerHTML={{ __html: step.content }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {/* Section 3: The team */}

      <div className="inner_container">
        <div className="about-section padding_container">
          <div>
            <div>
              <img src={WaveTeamImage} alt="OurWaveTeam" width={"100%"} />
            </div>
          </div>
          <div>
            <div className="team-section-container">
              <h1>
                <b className="pageTitle">
                  <Accent>
                    <span>The team</span>
                  </Accent>
                </b>
              </h1>
              <p className="text-center-mobile" style={{ fontSize: "14px" }}>
                Our Wave family consists of members committed to its values and
                driven by a passion to provide our community with the best
                commuting experience. Together, we continuously look for ways to
                keep improving the quality of our service, product, and team
                skills.
                <br />
                <br />
                We kick-started our operations in Beirut in 2021 with 75 bikes.
                The full fleet was rented out in no time! With such a high
                demand, we increased the fleet to 325 bikes in Fall 2022 and we
                are now spreading to locations all over Lebanon and the greater
                MENA region.
                <br />
                <br />
                <span style={{ color: "#3CB4E5" }}>
                  Join the trend, let’s get riding!
                </span>
              </p>
              <button
                id="landing_home_reserve_now_button"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/signup";
                }}
              >
                Subscribe Now
              </button>{" "}
            </div>
          </div>
        </div>
      </div>
      {/* why wave mobile version */}
      <div id="why_wave_mobile_version">
        <section className="inner_container">
          <div id="landing_about_why_wave" className="padding_container">
            <div className="landing_titles">
              <h1>
                <b className="pageTitle">
                  <Accent className="accent_container">Why Wave</Accent>
                </b>
              </h1>
            </div>

            <div className="align_why_wave">
              {whyWave.map((step, index) => {
                return (
                  <div key={`step-${index}`}>
                    {step.image && (
                      <div>
                        <img
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px",
                            alignSelf: "stretch",
                            width: "50px",
                            height: "50px",
                            textAlign: "center",
                            margin: "0 auto",
                          }}
                          src={step.image}
                          alt="Wave Logo"
                        />
                      </div>
                    )}
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: 500,
                        textAlign: "center",
                        margin: "0 auto",
                        color: "#595959",
                      }}
                      dangerouslySetInnerHTML={{ __html: step.content }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>

      <section className="inner_container padding_container">
        <p id="centering_the_text">
          We truly believe cycling will positively impact everyone’s life and at
          Wave we share the hope of seeing more and more cyclists on the streets
          of our cities.
        </p>
      </section>
    </div>
  );
}
