import React, { useState, useEffect } from "react";
import authHeader from "../services/auth-header";
import FaqService from "../services/faq.service";
import EventBus from "../common/EventBus";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import DateFormatter from "../common/DateFromatter";
import { getBranches } from "../common/getFunctions";
import * as labels from '../labels/en';
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../commonComponents/Loader";
import Switch from "react-switch";
import { Card, Table, Col, Row } from "react-bootstrap";
import { faq_categories } from "../labels/options_en"

const currentUser = JSON.parse(localStorage.getItem('user'));
const Faqs = () => {
	const [error, setError] = useState("");
	const [faqs, setFaqs] = useState([]);
	const [branchFilter, setBranchFilter] = useState();
	const [keywordFilter, setKeywordFilter] = useState("");
	const [branches, setBranches] = useState([]);
	const [noAnswerFilter, setNoAnswerFilter] = useState("");
	const [loading, setLoading] = useState(true);
	const [resetMsg, setResetMsg] = useState("");
	const [currentPage, setcurrentPage] = useState(1);
	const [itemsPerPage, setitemsPerPage] = useState(9);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [count, setCount] = useState(0);
	const [categoryFilter, setCategoryFilter] = useState("");
	const pageSizes = [9, 18, 24, 30, 36, 60];

	useEffect(() => {
		async function onReady() {
			window.scrollTo(0, 0);
			setBranches(await getBranches());
			await listFaqs("", "", "", page, itemsPerPage, "");
		}

		onReady()
	}, []);

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			triggerSearch(e);
		}
	}
	const getFaqsList = async (branch, keyword, noAnswerFilter, offset, limit, category) => {
		let res = await FaqService.getFaqsList(branch, keyword, noAnswerFilter, offset, limit, false, "", category).then(
			({ data }) => {

				return data.data;
			},
			(error) => {
				const _content =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();
				alert(_content);
				if (error.response && error.response.status === 401)
					EventBus.dispatch("logout");
			});

		return res;
	}
	const listFaqs = async (branchFilter, keywordFilter, noAnswerFilter, page, itemsPerPage, category) => {
		setLoading(true);
		let data = await getFaqsList(branchFilter, keywordFilter, noAnswerFilter, parseInt(page - 1) * itemsPerPage, itemsPerPage, category);
		setFaqs(data.list);
		setTotal(data ? data.total : 0);
		setCount(Math.ceil(data.total / itemsPerPage));
		setResetMsg("");
		setLoading(false);
	}
	const handlePageChange = async (event, value) => {
		setPage(value);
		await listFaqs(branchFilter, keywordFilter, noAnswerFilter, value, itemsPerPage, categoryFilter);

	};

	const handlePageSizeChange = async (newItemsPerPage) => {

		setitemsPerPage(newItemsPerPage);
		setPage(1);
		await listFaqs(branchFilter, keywordFilter, noAnswerFilter, 1, newItemsPerPage, categoryFilter);

	};

	const triggerSearch = async () => {
		await setPage(1); // return to first page
		await listFaqs(branchFilter, keywordFilter, noAnswerFilter, 1, itemsPerPage, categoryFilter);

	}
	const resetSearch = async () => {
		setBranchFilter("");
		setKeywordFilter("");
		setNoAnswerFilter(false);
		await listFaqs("", "", "", page, itemsPerPage, "");
	}
	const handleBranchChange = async (bid) => {
		setBranchFilter(bid);
		resetResults();
	}

	const handleCategoryChange = async (category) => {
		setCategoryFilter(category);
		resetResults();
	}

	const handleKeyChange = async (n) => {
		setKeywordFilter(n);
		resetResults();
	}
	const resetResults = () => {
		setTotal(0);
		setCount(0);
		setFaqs([]);
		setResetMsg(labels.RESET_MSG);
	}

	const setNoAnswerSwitchFilter = () => {
		setNoAnswerFilter(!noAnswerFilter);
		resetResults();
	}

	const deleteFaq = async (event, id) => {

		if (window.confirm("Are you sure you want to delete this faq?")) {
			FaqService.deleteFaq(id).then(
				(response) => {

					alert("Faq deleted!");
					let oldList = faqs;
					var data = oldList.filter(function (obj) {
						return obj._id !== id;
					});
					setFaqs(data);


				},
				(error) => {
					const _content =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					alert(_content);

					if (error.response && error.response.status === 401) {
						EventBus.dispatch("logout");

					}
				}
			);
		}
	}

	return (
		<div className="container">
			{(currentUser.type == 'admin' || currentUser.type == 'manager') ? <div className="floatRight "><Link to={"/faq/new"} className="greenLink mx-0">Add FAQ</Link></div> : ""}
			<h3> FAQ List </h3>
			<div className="filterContainer">
				<Row style={{ display: "flex", flexDirection: "row" }}>

					{currentUser && currentUser.type == 'admin' ?
						<Col>
							<label className="filterLabelInput maintenanceLabels" htmlFor="branch">Branch</label>

							<select
								className="form-control filterInput"
								value={branchFilter}
								onChange={(e) => handleBranchChange(e.target.value)}

							>
								<option value="" key="0">All</option>
								{branches.map((branch, index) => <option key={index} value={branch._id}>{branch.label}</option>)}

							</select>
						</Col>
						: null}

					<Col>
						<label className="filterLabelInput maintenanceLabels" htmlFor="keywordFilter">Keyword</label>

						<input
							style={{ border: "1px solid #ced4da", width: "95%", height: 38 }}
							className="form-control filterInput"
							type="text"
							value={keywordFilter}
							onKeyDown={e => handleKeyDown(e)}
							onChange={(e) => handleKeyChange(e.target.value)}
						/>
					</Col>
					<Col>
						<label className="filterLabelInput maintenanceLabels" htmlFor="categoryFilter">Category</label>

						<select
							className="form-control filterInput"
							value={categoryFilter}
							onChange={(e) => handleCategoryChange(e.target.value)}

						>
							<option value="" key="0">All</option>
							{faq_categories && faq_categories.map((c, index) => <option key={index} value={c.key}>{c.value}</option>)}

						</select>
					</Col>
					<Col>
						<table style={{ marginTop: 50 }} border="0">
							<tr>
								<td><button onClick={(e) => triggerSearch(e)} className="btn btn-primary btn-block search-button ">Go</button></td>
								<td><button onClick={(e) => resetSearch(e)} className="btn btn-primary btn-block reset-button ">Reset</button></td>
							</tr>
						</table>
					</Col>
				</Row>
			</div>


			{error ? <header className="jumbotron"> <h3>{error}</h3>  </header> : null}
			{
				!error && !loading && faqs && faqs.length != 0 ?
					<div>
						<Row>
							<Col><span style={{ float: "right", marginLeft: 10 }}>{total} results</span></Col>
						</Row>
						<Row style={{ display: "flex", flexDirection: "row" }}>
							{faqs.map((b) =>
								<div style={{ float: "left", marginLeft: 20 }}>
									<Card style={{ marginLeft: 0, maxWidth: 250, height: 310, paddingLeft: 10, align: "top" }}>
										<Card.Body>
											<Card.Title>{b.title}<br />
												{currentUser.type == 'admin' || currentUser.type == 'manager' ?
													<>&nbsp;&nbsp;&nbsp;
														<NavLink to={"/faq/edit/" + b._id} className="text-dark midText"><i alt="Edit" title="Edit" className="fa fa-pen" ></i></NavLink>&nbsp;&nbsp;&nbsp;<NavLink to={"/faq/clone/" + b._id} className="text-dark midText"><i alt="Clone" title="Clone" className="fa fa-clone" ></i></NavLink>&nbsp;&nbsp;&nbsp; <a href="#" className="text-dark midText" onClick={(e) => deleteFaq(e, b._id)} ><i alt="Delete" title="Delete" className="fa fa-trash" ></i></a></> : ""}
												{currentUser.type == 'admin' || currentUser.type == 'manager' ? <br /> : ""}
												{currentUser.type == 'admin' ? <><span className="tinyCardLabel">Branch:&nbsp;</span><span className="midText">{b.branch ? b.branch.label : "All"}</span><br /></> : ""}
												<span className="tinyCardLabel">Category:&nbsp;</span><span className="midText">{b.category ? b.category : "All"}</span><br />

											</Card.Title>
											<Card.Text><div className="bigConent">{b.content}</div></Card.Text>

										</Card.Body>
									</Card>
								</div>
							)}
						</Row>


						<div className="mt-3">
							{"Items per Page: "}
							<select onChange={(e) => handlePageSizeChange(e.target.value)} value={itemsPerPage}>
								{pageSizes.map((size) => (
									<option key={size} value={size}>
										{size}
									</option>
								))}
							</select>

							<Pagination
								className="my-3"
								count={count}
								page={page}
								siblingtotal={1}
								boundarytotal={1}
								variant="outlined"
								color="primary"
								shape="rounded"
								onChange={handlePageChange}
								size="medium"

							/>
						</div>
					</div>
					: loading ? <Loader /> :
						resetMsg != "" ? <div className='defaultMsg'>{resetMsg}</div> :
							<div className="noResultsFound">{labels.NO_RESULTS}</div>
			}


		</div >
	);
};

export default Faqs;
