import React, { useState, useLayoutEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';

export default function SurveyQuestionAccordionItem(props) {

    const question = props.question;
    const index = props.index;
    const answer = props.answer;
    const questionId = props.questionId;
    const deleteQuestion = props.deleteQuestion;
    const closeOthers = props.closeOthers;
    const isActive = props.isActive;
    const setIsActive = props.setIsActive;

    //const [isActive, setIsAvtive] = useState(props.isActive);

    // useLayoutEffect(() => {
    //   setIsAvtive(false)
    //}, [])

    const handleHeaderClick = () => {
        if (isActive) setIsActive(false);
        else {
            setIsActive(true);
            closeOthers(index);
        }
    }


    return (
        <div className='Accordion_items_container'>
            <Accordion.Item eventKey={index}>

                <Accordion.Header onClick={handleHeaderClick}>


                    {!isActive ? <div>{question}</div> : <div></div>}
                    <div className="d-flex flex-row align-items-center  zindex-3"><button style={{ padding: 0 }} onClick={() => deleteQuestion(questionId, index)} className="midText linkBtn"><i alt="Delete" title="Delete" className="fa fa-trash" ></i></button><span style={{ marginLeft: 20 }} >{isActive ? "-" : "+"}</span></div>
                </Accordion.Header>


                <div className='Accordion_items_body bg-white border-0' style={{ paddingBottom: isActive ? 20 : 0 }}>

                    <Accordion.Body>
                        {answer}
                    </Accordion.Body>
                </div>

            </Accordion.Item>
        </div>
    )
}
