import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import EventBus from "../common/EventBus";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import * as labels from "../labels/en";

export default function IAgreeModal(props) {
  const iAgreeModal = props.iAgreeModal;
  const setiAgreeModal = props.setiAgreeModal;
  const callBack = props.callBack;
  const listInnerRef = useRef();
  const [iAgree, setIAgree] = useState("");
  const [reachBottom, setReachBottom] = useState(false);
  const handleiAgreeModalClose = () => {
    setiAgreeModal(false);
  };
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setReachBottom(true);
      }
    }
  };
  const checkBoxHandler = () => {
    if (document.getElementById("checkBox-profile").checked)
      document.getElementById("checkBox-profile-label").style.color =
        "rgb(52, 58, 64)";
    
    callBack();
    setiAgreeModal(false); //close it
    
    setIAgree(false);
  };

  const IAgreeForm = (
    <div
      style={{
        height: 400,
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px solid #f0ede6",
      }}
    >
      <div
        onScroll={onScroll}
        ref={listInnerRef}
        style={{ width: "95%", textAlign: "left" }}
        className="scrollbar"
      >
        {labels.iAgree1}
      </div>
      <div style={{ display: "flex", width: "93%", marginTop: 10 }}>
        {reachBottom ? (
          <>
            <input
              id="checkBox-profile"
              onChange={checkBoxHandler}
              style={{ width: "auto", margin: "5px" }}
              type="checkbox"
            />
            <label
              id="checkBox-profile-label"
              style={{
                width: "auto",
                margin: "0px",
                padding: "0px",
                color: "#343a40",
              }}
            >
              I Agree to terms and conditions
            </label>
          </>
        ) : (
          <>
            <input
              id="checkBox-profile"
              disabled
              style={{ width: "auto", margin: "5px", borderColor: "lightgray" }}
              type="checkbox"
            />
            <label
              id="checkBox-profile-label"
              style={{ width: "auto", margin: "0px", padding: "0px" }}
            >
              I Agree to terms and conditions
            </label>
          </>
        )}
      </div>
    </div>
  );

  return (
    <Modal show={iAgreeModal} onHide={handleiAgreeModalClose}>
      <Modal.Header>
        <Modal.Title>
          Please carefully read the following Terms and Conditions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="input-group">{IAgreeForm}</div>
      </Modal.Body>
      <div className="d-flex flex-row justify-content-around">
        <button
          style={{ margin: "10px", fontSize: 16, width: 150 }}
          onClick={handleiAgreeModalClose}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}
