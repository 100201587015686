import React, { useState, useEffect, useRef, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import CompanyService from "../services/company.service";
import EventBus from "../common/EventBus";
import { required } from "../common/Validation";
import { getBranches } from "../common/getFunctions";
import Switch from "react-switch";
import RequiredLabel from "../common/RequiredLabel";
import OptionalLabel from "../common/OptionalLabel";
import BranchePullDown from "../commonComponents/BranchePullDown";
import { Col, Row } from "react-bootstrap";
import { Prompt } from "react-router-dom";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CompanyAccounts from "../commonComponents/CompanyAccounts";

const CompanyForm = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [companyName, setCompanyName] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [building, setBuilding] = useState("");
  const [floor, setFloor] = useState("");
  const [address, setAddress] = useState("");
  const [industry, setIndustry] = useState("");
  const [accounts, setAccounts] = useState([
    { key: "cashbox", label: "Cash Box", disabled: true },
  ]);
  const [notes, setNotes] = useState("");
  const [MOF, setMOF] = useState("");
  const [VAT, setVAT] = useState("");
  const [vatTax, setVatTax] = useState(11);
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [supplier, setSupplier] = useState(false);
  const [reseller, setReseller] = useState(false);
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState(currentUser.branch);
  const [companyId, setCompanyId] = useState(props.match.params.id);
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [iAmTyping, setIAmTyping] = useState(false);
  const [hourlyPrice, setHourlyPrice] = useState(0);
  const [halfHourPrice, setHalfHourPrice] = useState(0);
  const [dailyPrice, setDailyPrice] = useState(0);
  const [weeklyPrice, setWeeklyPrice] = useState(0);
  const [wavePercent, setWavePercent] = useState(0);
  const [invoicePrefix, setInvoicePrefix] = useState("");
  const [invoiceCounter, setInvoiceCounter] = useState("1");
  const clone = props.location.pathname.includes("/clone/");

  const getCompanyInfo = async () => {
    if (companyId) {
      CompanyService.getCompanyById(companyId).then(
        (response) => {
          let c = response.data;
          setCompanyName(c.name);
          setPhone(c.phone);
          setEmail(c.email);
          setAddress(c.address);
          setBuilding(c.building);
          setFloor(c.floor);
          setSupplier(c.isSupplier);
          setReseller(c.isReseller);
          setAccounts(c.accounts);
          const contentBlock = htmlToDraft(c.notes);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setNotes(editorState);
          }

          setIndustry(c.industry);
          setDisabled(c.disabled);
          setMOF(c.MOF);
          setVAT(c.VAT);
          setVatTax(c.vatTax);
          setContactEmail(c.contactEmail);
          setContactPhone(c.contactPhone);
          setContactName(c.contactName);
          setHourlyPrice(c.hourlyPrice || 0);
          setHalfHourPrice(c.halfHourPrice || 0);
          setDailyPrice(c.dailyPrice || 0);
          setWeeklyPrice(c.weeklyPrice || 0);
          setWavePercent(c.wavePercent || 0);
          setInvoicePrefix(c.invoicePrefix);
          setInvoiceCounter(c.invoice_counter || 1);
          if (clone === true) {
            setCompanyId("");
          } // reset the id to clone
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          alert(_content);
          setMessage(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
      getCompanyInfo();
    }

    onReady();
  }, []);
  const notValidNumber = (number) => {
    if (number === 0 || number === "" || number === undefined || !number) {
      return true;
    }
    return false;
  };
  const handleSaveCompany = (e) => {
    e.preventDefault();
    setIAmTyping(false);
    setMessage("");
    setSuccessful(false);

    form.current.validateAll();
    if (
      reseller &&
      (notValidNumber(hourlyPrice) ||
        notValidNumber(halfHourPrice) ||
        notValidNumber(weeklyPrice) ||
        notValidNumber(dailyPrice) ||
        notValidNumber(wavePercent))
    ) {
      alert("Please fill all resellers fields!");
    } else if (checkBtn.current.context._errors.length === 0) {
      CompanyService.postCompany({
        _id: companyId,
        name: companyName,
        phone: phone,
        email: email,
        address: address,
        industry: industry,
        notes: notes
          ? draftToHtml(convertToRaw(notes.getCurrentContent()))
          : "",
        branch: branch,
        disabled: disabled,
        MOF: MOF,
        VAT: VAT,
        vatTax: vatTax,
        contactName: contactName,
        contactEmail: contactEmail,
        contactPhone: contactPhone,
        building: building,
        floor: floor,
        hourlyPrice: hourlyPrice,
        halfHourPrice: halfHourPrice,
        dailyPrice: dailyPrice,
        weeklyPrice: weeklyPrice,
        wavePercent: wavePercent,
        supplier: supplier,
        reseller: reseller,
        accounts: accounts,
        invoicePrefix: invoicePrefix,
        invoiceCounter: invoiceCounter,
      }).then(
        (response) => {
          setMessage("Company Saved.");
          setSuccessful(true);
          props.history.push("/companies");
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };
  const setDisabledSwitch = () => {
    setDisabled(!disabled);
  };
  const setSupplierSwitch = () => {
    setSupplier(!supplier);
  };
  const setResellerSwitch = () => {
    setReseller(!reseller);
  };
  const onEditorStateChange = (editorChange) => {
    setNotes(editorChange);
    setIAmTyping(true);
  };

  return (
    <Fragment>
      <Prompt
        when={iAmTyping}
        message={(location) =>
          "Are you sure, you want to leave? All your data will be lost!"
        }
      />
      <div className="col-md-12">
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          ref={form}
        >
          {!successful && (
            <div>
              <table className="styled-table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "100%" }}>Company Form</th>
                  </tr>
                </thead>
                <tbody>
                  <Row
                    className="white-background "
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                  >
                    <Col md={4}>
                      {currentUser.type === "admin" ? (
                        <div className="form-group">
                          <RequiredLabel htmlFor="branch" text="Branch" />
                          <BranchePullDown
                            branch={branch}
                            labelInside={true}
                            forceBlank={true}
                            setBranch={(value) => {
                              setBranch(value);
                              setIAmTyping(true);
                            }}
                            branches={branches}
                          />
                        </div>
                      ) : null}
                    </Col>
                    <Col md={4}>
                      <RequiredLabel
                        htmlFor="companyName"
                        text="Company Name"
                      />
                      <Input
                        type="text"
                        className="form-control"
                        name="companyName"
                        value={companyName}
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                          setIAmTyping(true);
                        }}
                        validations={[required]}
                      />
                    </Col>
                    <Col md={4}>
                      <RequiredLabel htmlFor="phone" text="Company Phone" />
                      <Input
                        type="text"
                        className="form-control"
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                          setIAmTyping(true);
                        }}
                        validations={[required]}
                      />
                    </Col>
                    <Col md={4}>
                      <RequiredLabel htmlFor="email" text="Company Email" />
                      <Input
                        type="text"
                        className="form-control"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setIAmTyping(true);
                        }}
                        validations={[required]}
                      />
                    </Col>
                    <Col md={4} className="verticalTop">
                      <OptionalLabel
                        htmlFor="MOF"
                        text="Commercial register number"
                      />
                      <Input
                        type="text"
                        className="form-control"
                        value={MOF}
                        onChange={(e) => {
                          setMOF(e.target.value);
                          setIAmTyping(true);
                        }}
                      />
                    </Col>
                    <Col md={4} className="verticalTop">
                      <OptionalLabel htmlFor="VAT" text="VAT Number" />
                      <Input
                        type="text"
                        className="form-control"
                        value={VAT}
                        onChange={(e) => {
                          setVAT(e.target.value);
                          setIAmTyping(true);
                        }}
                      />
                    </Col>
                    <Col md={4} className="verticalTop">
                      <OptionalLabel
                        htmlFor="industry"
                        text="Company Industry"
                      />
                      <Input
                        type="text"
                        className="form-control"
                        value={industry}
                        onChange={(e) => {
                          setIndustry(e.target.value);
                          setIAmTyping(true);
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <RequiredLabel htmlFor="disabled" text="Disabled" />
                      <Switch
                        onChange={(value) => {
                          setDisabledSwitch(value);
                          setIAmTyping(true);
                        }}
                        checked={disabled}
                      />
                    </Col>
                    <Col md={2}>
                      <RequiredLabel htmlFor="reseller" text="Reseller" />
                      <Switch
                        onChange={(value) => {
                          setResellerSwitch(value);
                          setIAmTyping(true);
                        }}
                        checked={reseller}
                      />
                    </Col>

                    <Col md={2}>
                      <RequiredLabel htmlFor="supplier" text="Supplier" />
                      <Switch
                        onChange={(value) => {
                          setSupplierSwitch(value);
                          setIAmTyping(true);
                        }}
                        checked={supplier}
                      />
                    </Col>
                  </Row>
                  <Row
                    className="white-background "
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                  >
                    <Col md={12}>
                      <OptionalLabel htmlFor="notes" text="notes" />
                      <Editor
                        editorState={notes}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName "
                        onEditorStateChange={onEditorStateChange}
                      />
                    </Col>
                  </Row>
                  <Row></Row>
                </tbody>
              </table>
              <table className="styled-table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "100%" }}>Address Fields</th>
                  </tr>
                </thead>
                <tbody>
                  <Row
                    className="white-background "
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                  >
                    <Col md={4}>
                      <OptionalLabel
                        htmlFor="building"
                        text="Company Building"
                      />
                      <Input
                        type="text"
                        className="form-control"
                        value={building}
                        onChange={(e) => {
                          setBuilding(e.target.value);
                          setIAmTyping(true);
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <OptionalLabel htmlFor="building" text="Company Floor" />
                      <Input
                        type="text"
                        className="form-control"
                        value={floor}
                        onChange={(e) => {
                          setFloor(e.target.value);
                          setIAmTyping(true);
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <OptionalLabel htmlFor="address" text="Company Address" />
                      <Input
                        type="text"
                        className="form-control"
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                          setIAmTyping(true);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row></Row>
                </tbody>
              </table>
              <table className="styled-table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "100%" }}>Personal Contanct Fields</th>
                  </tr>
                </thead>
                <tbody>
                  <Row
                    className="white-background "
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                  >
                    <Col md={4} className="verticalTop">
                      <OptionalLabel
                        htmlFor="contactName"
                        text="Contact Name"
                      />
                      <Input
                        type="text"
                        className="form-control"
                        value={contactName}
                        onChange={(e) => {
                          setContactName(e.target.value);
                          setIAmTyping(true);
                        }}
                      />
                    </Col>
                    <Col md={4} className="verticalTop">
                      <OptionalLabel
                        htmlFor="contactEmail"
                        text="Contact Email"
                      />
                      <Input
                        type="text"
                        className="form-control"
                        value={contactEmail}
                        onChange={(e) => {
                          setContactEmail(e.target.value);
                          setIAmTyping(true);
                        }}
                      />
                    </Col>
                    <Col md={4} className="verticalTop">
                      <OptionalLabel
                        htmlFor="contactPhone"
                        text="Contact Phone"
                      />
                      <Input
                        type="text"
                        className="form-control"
                        value={contactPhone}
                        onChange={(e) => {
                          setContactPhone(e.target.value);
                          setIAmTyping(true);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row></Row>
                </tbody>
              </table>
              <table className="styled-table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "100%" }}>Reseller Fields</th>
                  </tr>
                </thead>
                <tbody>
                  <Row
                    className="white-background "
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                  >
                    <Col md={4} className="verticalTop">
                      <RequiredLabel htmlFor="VAT" text="VAT Standard" />
                      <Input
                        type="number"
                        className="form-control"
                        value={vatTax}
                        onChange={(e) => {
                          setVatTax(e.target.value);
                          setIAmTyping(true);
                        }}
                      />
                    </Col>
                    <Col md={4} className="verticalTop">
                      <RequiredLabel
                        htmlFor="invoicePrefix"
                        text="Invoice Prefix"
                      />
                      <Input
                        type="text"
                        className="form-control"
                        value={invoicePrefix}
                        onChange={(e) => {
                          setInvoicePrefix(e.target.value);
                          setIAmTyping(true);
                        }}
                        validations={[required]}
                      />
                    </Col>
                    <Col md={4} className="verticalTop">
                      <RequiredLabel
                        htmlFor="invoiceCounter"
                        text="Invoice Counter"
                      />
                      <Input
                        type="number"
                        className="form-control"
                        value={invoiceCounter}
                        onChange={(e) => {
                          setInvoiceCounter(e.target.value);
                          setIAmTyping(true);
                        }}
                        disabled={true}
                        validations={[required]}
                      />
                    </Col>
                    <Col md={4} className="verticalTop">
                      <OptionalLabel
                        htmlFor="halfHourPrice"
                        text="USD Half Hourly Rent Price"
                      />
                      <Input
                        type="number"
                        className="form-control"
                        value={halfHourPrice}
                        onChange={(e) => {
                          setHalfHourPrice(e.target.value);
                          setIAmTyping(true);
                        }}
                      />
                    </Col>
                    <Col md={4} className="verticalTop">
                      <OptionalLabel
                        htmlFor="hourlyPrice"
                        text="USD Hourly Rent Price"
                      />
                      <Input
                        type="number"
                        className="form-control"
                        value={hourlyPrice}
                        onChange={(e) => {
                          setHourlyPrice(e.target.value);
                          setIAmTyping(true);
                        }}
                      />
                    </Col>
                    <Col md={4} className="verticalTop">
                      <OptionalLabel
                        htmlFor="dailyPrice"
                        text="USD Daily Rent Price"
                      />
                      <Input
                        type="number"
                        className="form-control"
                        value={dailyPrice}
                        onChange={(e) => {
                          setDailyPrice(e.target.value);
                          setIAmTyping(true);
                        }}
                      />
                    </Col>
                    <Col md={4} className="verticalTop">
                      <OptionalLabel
                        htmlFor="weeklyPrice"
                        text="USD Weekly Rent Price"
                      />
                      <Input
                        type="number"
                        className="form-control"
                        value={weeklyPrice}
                        onChange={(e) => {
                          setWeeklyPrice(e.target.value);
                          setIAmTyping(true);
                        }}
                      />
                    </Col>
                    <Col md={4} className="verticalTop">
                      <OptionalLabel
                        htmlFor="wavePercent"
                        text="Wave Percent"
                      />
                      <Input
                        type="number"
                        className="form-control"
                        value={wavePercent}
                        onChange={(e) => {
                          setWavePercent(e.target.value);
                          setIAmTyping(true);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row></Row>
                </tbody>
              </table>
              <CompanyAccounts
                accounts={accounts}
                disableField={false}
                setAccounts={setAccounts}
              />

              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  onClick={handleSaveCompany}
                >
                  Save
                </button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </Fragment>
  );
};

export default CompanyForm;
