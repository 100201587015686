import React, { useEffect } from "react";
import "../css/home.css";
import Accent from "../components/Accent";
import RideImg from "../assets/Ride.jpg";
import BikeLessonsImg from "../assets/BikeLessons .png";
import { trackPage } from "../components/GoogleAnalytics";

export default function Events() {
  useEffect(() => {
    window.scrollTo(0, 0);
    trackPage("Rides");
  }, []);
  return (
    <div className="outer_container">
      {/* rides header on desktop */}
      <div className="desktop_version">
        <div className="inner_container">
          <div className="about-header">
            <div>
              <div className="about-header-mobile">
                <h1>
                  <b className="pageTitle">
                    <Accent>
                      <span>Group ride</span>
                    </Accent>
                  </b>
                </h1>
                <p
                  className="text-center-mobile"
                  style={{
                    fontSize: "17px",
                    fontWeight: "500px",
                    paddingRight: "30px",
                  }}
                >
                  The coolest way to familiarize yourself with riding an e-bike
                  in Beirut is by trying it with a group!
                  <br />
                  <br />
                  <span style={{ fontSize: "14px", fontWeight: "400px" }}>
                    Looking to commute by bicycle but worried about safety and
                    city traffic? Or simply looking to try the Wave bike and
                    enjoy a fun ride with some friends? Join our weekly rides
                    today
                  </span>
                  <br />
                  <br />
                </p>
                <button
                  id="landing_home_reserve_now_button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      "https://www.wave.bike/survey/638af5e77a09349e66145d5e";
                  }}
                >
                  Book a ride
                </button>{" "}
                <br />
                <br />
                <p
                  className="text-center-mobile"
                  style={{ fontSize: "14px", fontWeight: "400px" }}
                >
                  Who can join? <br />
                  Anyone who is 18+ and with experience in riding a regular bike
                  <br /> The ride is free of charge - Wave bike and helmet
                  available
                  <br />
                  <a
                    style={{ color: "#3CB4E5" }}
                    href="//www.google.com/maps/place/Wave/@33.8919643,35.5307855,18.94z/data=!4m5!3m4!1s0x151f17711b93c4ff:0x6a6c9a22d10b2c7e!8m2!3d33.8922325!4d35.5310995?hl=en"
                    target="_blank"
                  >
                    Meeting point: Wave hub Geitawi
                  </a>
                </p>
              </div>
            </div>
            <div>
              <img
                src={RideImg}
                style={{ borderRadius: "20px" }}
                alt="Wavers at a ride"
              />
            </div>
          </div>
        </div>
      </div>
      {/* rides header on mobile */}
      <div id="why_wave_mobile_version">
        <div className="inner_container">
          <div className="about-header">
            <div className="about-header-mobile">
              <h1>
                <b className="pageTitle">
                  <Accent>
                    <span>Group ride</span>
                  </Accent>
                </b>
              </h1>
              <p
                className="text-center-mobile"
                style={{ fontSize: "17px", fontWeight: "500px" }}
              >
                The coolest way to familiarize yourself with riding an e-bike in
                Beirut is by trying it with a group!
                <br />
                <br />
                <span style={{ fontSize: "14px", fontWeight: "400px" }}>
                  Looking to commute by bicycle but worried about safety and
                  city traffic? Or simply looking to try the Wave bike and enjoy
                  a fun ride with some friends? Join our weekly rides today
                </span>
                <br />
                <br />
              </p>
              <div className="shifting_text_with_images">
                <div>
                  <button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "37px",
                    }}
                    id="landing_home_reserve_now_button"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href =
                        "https://www.wave.bike/survey/638af5e77a09349e66145d5e";
                    }}
                  >
                    Book a ride
                  </button>{" "}
                  <p
                    className="text-center-mobile"
                    style={{ fontSize: "14px", fontWeight: "400px" }}
                  >
                    Who can join? <br />
                    Anyone who is 18+ and with experience in riding a regular
                    bike
                    <br /> The ride is free of charge - Wave bike and helmet
                    available
                    <br />
                    <a
                      style={{ color: "#3CB4E5" }}
                      href="//www.google.com/maps/place/Wave/@33.8919643,35.5307855,18.94z/data=!4m5!3m4!1s0x151f17711b93c4ff:0x6a6c9a22d10b2c7e!8m2!3d33.8922325!4d35.5310995?hl=en"
                      target="_blank"
                    >
                      Meeting point: Wave hub Geitawi
                    </a>
                  </p>
                </div>

                <img
                  src={RideImg}
                  style={{ borderRadius: "20px" }}
                  alt="Wavers at a ride"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* instructional videos section on desktop */}

      <div className="inner_container desktop_version">
        <h1>
          <b className="pageTitle">
            <Accent>
              <span>Instructional videos</span>
            </Accent>
          </b>
        </h1>
        <div>
          <div className="styling_videos_rides">
            <iframe src="https://www.youtube.com/embed/TZkxMRIW2fU?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Fwww.wave.bike&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1"></iframe>
            <iframe src="https://www.youtube.com/embed/naxjIdVJXx0?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Fwww.wave.bike&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1"></iframe>
            <iframe src="https://www.youtube.com/embed/d7X8u-lPmas?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Fwww.wave.bike&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1"></iframe>
          </div>
          <div className="styling_videos_rides padding_container">
            <iframe src="https://www.youtube.com/embed/on2xLkzdynI?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Fwww.wave.bike&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1"></iframe>
            <iframe src="https://www.youtube.com/embed/VQI3XsG85xs?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Fwww.wave.bike&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1"></iframe>
            <iframe src="https://www.youtube.com/embed/uno-5gLS_ZM?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Fwww.wave.bike&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1"></iframe>
          </div>
        </div>
      </div>

      {/*instructional videos section on mobile  */}
      <div id="why_wave_mobile_version" className="about-header-mobile">
        <div className="inner_container">
          <h1>
            <b
              style={{ color: "#595959", fontSize: "23px" }}
              className="about-header-mobile"
            >
              <Accent>
                <span>Instructional videos</span>
              </Accent>
            </b>
          </h1>
          <div>
            <div className="styling_videos_rides">
              <iframe src="https://www.youtube.com/embed/TZkxMRIW2fU?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Fwww.wave.bike&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1"></iframe>
            </div>
            <div className="styling_videos_rides">
              <iframe src="https://www.youtube.com/embed/naxjIdVJXx0?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Fwww.wave.bike&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1"></iframe>
            </div>
            <div className="styling_videos_rides">
              <iframe src="https://www.youtube.com/embed/d7X8u-lPmas?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Fwww.wave.bike&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1"></iframe>
            </div>
            <div className="styling_videos_rides ">
              <iframe src="https://www.youtube.com/embed/VQI3XsG85xs?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Fwww.wave.bike&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1"></iframe>
            </div>
          </div>
        </div>
      </div>
      {/* cycling lessons on desktop */}
      <div className="inner_container desktop_version">
        <div className="rides-header padding_container">
          <div>
            <div>
              <img src={BikeLessonsImg} alt="Bike lessons" />
            </div>
          </div>
          <div>
            <div>
              <h1>
                <b className="pageTitle">
                  <Accent>
                    <span>Cycling lessons</span>
                  </Accent>
                </b>
              </h1>
              <p style={{ fontSize: "17px", fontWeight: "500px" }}>
                First time on a bicycle?
                <br />
                <br />
                <span style={{ fontSize: "14px", fontWeight: "400px" }}>
                  No Problem. Register for a cycling lesson and our team would
                  be happy to teach you, even if you've never ridden a bike
                  before!
                </span>
                <br />
                <br />
              </p>
              <button
                id="landing_home_reserve_now_button"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href =
                    "https://www.wave.bike/survey/638afd787a09349e66145dbc";
                }}
              >
                Book a Lesson
              </button>{" "}
              <br />
              <br />
              <p style={{ fontSize: "14px", fontWeight: "400px" }}>
                Who can join? <br />
                Anyone who is 18+ and with experience in riding a regular bike
                <br /> The ride is free of charge - Wave bike and helmet
                available
                <br />
                <a
                  style={{ color: "#3CB4E5" }}
                  href="//www.google.com/maps/place/Wave/@33.8919643,35.5307855,18.94z/data=!4m5!3m4!1s0x151f17711b93c4ff:0x6a6c9a22d10b2c7e!8m2!3d33.8922325!4d35.5310995?hl=en"
                  target="_blank"
                >
                  Meeting point: Wave hub Geitawi
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* cycling lessons on mobile */}
      <div id="why_wave_mobile_version">
        <div className="inner_container">
          <div className="about-header">
            <div className="about-header-mobile">
              <h1>
                <b className="pageTitle">
                  <Accent>
                    <span>Cycling lessons</span>
                  </Accent>
                </b>
              </h1>
              <p
                className="text-center-mobile"
                style={{ fontSize: "17px", fontWeight: "500px" }}
              >
                First time on a bicycle?
                <br />
                <br />
                <span style={{ fontSize: "14px", fontWeight: "400px" }}>
                  No Problem. Register for a cycling lesson and our team would
                  be happy to teach you, even if you've never ridden a bike
                  before!
                </span>
                <br />
                <br />
              </p>
              <div className="shifting_text_with_images">
                <div>
                  <button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "37px",
                    }}
                    id="landing_home_reserve_now_button"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href =
                        "https://www.wave.bike/survey/638afd787a09349e66145dbc";
                    }}
                  >
                    Book a Lesson
                  </button>{" "}
                  <p
                    className="text-center-mobile"
                    style={{ fontSize: "14px", fontWeight: "400px" }}
                  >
                    Who can join? <br />
                    Anyone who is 18+ and with experience in riding a regular
                    bike
                    <br /> The ride is free of charge - Wave bike and helmet
                    available
                    <br />
                    <a
                      style={{ color: "#3CB4E5" }}
                      href="//www.google.com/maps/place/Wave/@33.8919643,35.5307855,18.94z/data=!4m5!3m4!1s0x151f17711b93c4ff:0x6a6c9a22d10b2c7e!8m2!3d33.8922325!4d35.5310995?hl=en"
                      target="_blank"
                    >
                      Meeting point: Wave hub Geitawi
                    </a>
                  </p>
                </div>

                <img
                  src={BikeLessonsImg}
                  style={{ borderRadius: "20px" }}
                  alt="Bike lessons"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* the end section */}
      <section className="inner_container padding_container">
        <p id="centering_the_text">
          Your safety is our top priority.
          <br /> The Wave bike brings you securely to your destination.
        </p>
      </section>
    </div>
  );
}
