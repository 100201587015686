import React, { useEffect } from "react";
import "../css/home.css";
import SDG3 from "../assets/SDG3.png";
import SDG5 from "../assets/SDG5.png";
import SDG8 from "../assets/SDG8.png";
import SDG11 from "../assets/SDG11.png";
import SDG12 from "../assets/SDG12.png";
import SDG13 from "../assets/SDG13.png";
import GirlOnBike from "../assets/GirlOnBike.jpeg";
import CodeofConductImg from "../assets/CodeofConduct.png";
import CodeofConductImgMobile from "../assets/coc_mobile.png";
import Accent from "../components/Accent";
import { trackPage } from "../components/GoogleAnalytics";

export default function CSR() {
  useEffect(() => {
    window.scrollTo(0, 0);
    trackPage("CSR");
  }, []);
  return (
    <div className="outer_container">
      <div className="inner_container desktop_version">
        <div className="about-header">
          <div>
            <div>
              <h1>
                <b className="pageTitle">
                  <Accent>
                    <span>CSR</span>
                  </Accent>
                </b>
              </h1>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400px",
                  paddingRight: "30px",
                }}
              >
                Wave is committed to the Sustainable Development Goals of the
                United Nations and these goals are part of every decision we
                make. Especially sustainable cities and communities, responsible
                consumption and production, and climate action are the core
                focus of our operation.
                <br />
                <br />
                <b>How are we contributing to a lower carbon footprint?</b>
                <br />
                We are passionate about creating innovative, high-quality e-bike
                solutions. Our products also help meet the need for
                climate-friendly transport and reduce traffic challenges in
                urban areas. With our e-bikes, we combine the needs for
                efficient transportation with environmentally friendly and
                sustainable development. Cycling is one of the cleanest forms of
                transportation available today and one of the most
                energy-efficient modes of transportation. At Wave, we consider
                the need for sustainable and environmentally friendly
                transportation an urgent challenge. With our innovative e-bike
                solutions, we are contributing to the reduction of energy
                consumption and CO2 emissions. We want to assist in taking care
                of our planet and want to help shape future modes of
                transportation and the mobility of tomorrow. Our bikes run on
                electricity which results in an emission of 20 times less CO2
                than cars and 17 times less than buses.
              </p>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <img
                src={SDG3}
                style={{ borderTopLeftRadius: "20px" }}
                alt="Wave electric bike sustainable development goal #3"
              />
              <img
                src={SDG5}
                alt="Wave electric bike sustainable development goal #5"
              />
              <img
                src={SDG8}
                style={{ borderTopRightRadius: "20px" }}
                alt="Wave electric bike sustainable development goal #8"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                gap: "15px",
                paddingTop: "15px",
              }}
            >
              <img
                src={SDG11}
                style={{ borderBottomLeftRadius: "20px" }}
                alt="Wave electric bike sustainable development goal #11"
              />
              <img
                src={SDG12}
                alt="Wave electric bike sustainable development goal #12"
              />
              <img
                src={SDG13}
                style={{ borderBottomRightRadius: "20px" }}
                alt="Wave electric bike sustainable development goal #13"
              />
            </div>
          </div>
        </div>
      </div>
      {/* csr header on mobile */}
      <div id="why_wave_mobile_version" className="inner_container">
        <div className="about-header">
          <div className="about-header-mobile">
            <h1>
              <b className="pageTitle">
                <Accent>
                  <span>CSR</span>
                </Accent>
              </b>
            </h1>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400px",
                textAlign: "center",
              }}
            >
              Wave is committed to the Sustainable Development Goals of the
              United Nations and these goals are part of every decision we make.
              Especially sustainable cities and communities, responsible
              consumption and production, and climate action are the core focus
              of our operation.
            </p>
          </div>
          <div className="shifting_text_with_images">
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400px",
                textAlign: "center",
              }}
            >
              We are passionate about creating innovative, high-quality e-bike
              solutions. Our products also help meet the need for
              climate-friendly transport and reduce traffic challenges in urban
              areas. With our e-bikes, we combine the needs for efficient
              transportation with environmentally friendly and sustainable
              development. Cycling is one of the cleanest forms of
              transportation available today and one of the most
              energy-efficient modes of transportation. At Wave, we consider the
              need for sustainable and environmentally friendly transportation
              an urgent challenge. With our innovative e-bike solutions, we are
              contributing to the reduction of energy consumption and CO2
              emissions. We want to assist in taking care of our planet and want
              to help shape future modes of transportation and the mobility of
              tomorrow. Our bikes run on electricity which results in an
              emission of 20 times less CO2 than cars and 17 times less than
              buses.
            </p>
            <br />
            <b style={{ textAlign: "center", paddingTop: "45px" }}>
              How are we contributing to a lower carbon footprint?
            </b>

            <div className="about-header__image-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <img
                  src={SDG3}
                  style={{ borderTopLeftRadius: "20px" }}
                  alt="Wave electric bike sustainable development goal #3"
                />
                <img
                  src={SDG5}
                  alt="Wave electric bike sustainable development goal #5"
                />
                <img
                  src={SDG8}
                  style={{ borderTopRightRadius: "20px" }}
                  alt="Wave electric bike sustainable development goal #8"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  gap: "15px",
                  paddingTop: "15px",
                }}
              >
                <img
                  src={SDG11}
                  style={{ borderBottomLeftRadius: "20px" }}
                  alt="Wave electric bike sustainable development goal #11"
                />
                <img
                  src={SDG12}
                  alt="Wave electric bike sustainable development goal #12"
                />
                <img
                  src={SDG13}
                  style={{ borderBottomRightRadius: "20px" }}
                  alt="Wave electric bike sustainable development goal #13"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* csr image and call to action on desktop */}
      <div className="inner_container">
        <div className="designing_the_home_page_img">
          <img src={GirlOnBike} alt="Girl riding the bike" />
          <div className="button_container desktop_version">
            <button
              id="landing_home_how_it_Works_button"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/signup";
              }}
            >
              Subscribe Now
            </button>
          </div>
        </div>
      </div>
      {/* Road safety on desktop */}
      <div className="inner_container desktop_version">
        <div className="rides-header  padding_container">
          <div>
            <div>
              <iframe
                className="rides-header__iframe"
                src="https://www.youtube.com/embed/ekIWKNa70Ec?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Fwww.wave.bike&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1"
              ></iframe>
            </div>
          </div>
          <div>
            <div>
              <h1>
                <b className="pageTitle">
                  <Accent>
                    <span>Road Safety</span>
                  </Accent>
                </b>
              </h1>
              <p style={{ fontSize: "14px", fontWeight: "400px" }}>
                We are committed to our mission to enable citizens to commute in
                a more affordable and better way. At Wave, Safety comes first.
                Our main target is minimizing risks while maximizing riding
                enjoyment. We are committed to responsible and safe biking. This
                is why the app we have developed includes the safest cycling
                routes through the city via our unique algorithm. And we offer
                free cycling training, both to learn cycling and to cycle safely
                through dense traffic. We are also partnering with different
                organizations to create a broad cycling community. Together with
                The Chain Effect and YASA, we created a safety video to show
                some basic safety advice for cycling in cities. Enjoy watching!
              </p>
              <button
                id="landing_home_reserve_now_button"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/signup";
                }}
              >
                Subscribe Now
              </button>{" "}
              <button
                id="landing_home_reserve_now_button"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href =
                    "https://wave.bike/survey/64e59c5dec972c31ea69cc72";
                }}
              >
                Book a Test Ride
              </button>{" "}
            </div>
          </div>
        </div>
      </div>
      {/* Road safety on mobile */}
      <div id="why_wave_mobile_version" className="inner_container ">
        <div className="rides-header shifting_text_with_images">
          <div className="center" style={{ paddingBottom: "75px" }}>
            <button
              id="landing_home_reserve_now_button"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/signup";
              }}
            >
              Subscribe Now
            </button>{" "}
            <button
              id="landing_home_reserve_now_button"
              onClick={(e) => {
                e.preventDefault();
                window.location.href =
                  "https://wave.bike/survey/64e59c5dec972c31ea69cc72";
              }}
            >
              Book a Test Ride
            </button>{" "}
          </div>
          <div className="csr_video">
            <iframe src="https://www.youtube.com/embed/ekIWKNa70Ec?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Fwww.wave.bike&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1"></iframe>
          </div>

          <div>
            <div className="about-header-mobile">
              <h1>
                <b className="pageTitle">
                  <Accent>
                    <span>Road Safety</span>
                  </Accent>
                </b>
              </h1>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400px",
                  textAlign: "center",
                }}
              >
                We are committed to our mission to enable citizens to commute in
                a more affordable and better way. At Wave, Safety comes first.
                Our main target is minimizing risks while maximizing riding
                enjoyment. We are committed to responsible and safe biking. This
                is why the app we have developed includes the safest cycling
                routes through the city via our unique algorithm. And we offer
                free cycling training, both to learn cycling and to cycle safely
                through dense traffic. We are also partnering with different
                organizations to create a broad cycling community. Together with
                The Chain Effect and YASA, we created a safety video to show
                some basic safety advice for cycling in cities.
              </p>
              <div className="desktop_version">
                <button
                  id="landing_home_reserve_now_button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/signup";
                  }}
                >
                  Subscribe Now
                </button>{" "}
                <button
                  id="landing_home_reserve_now_button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      "https://wave.bike/survey/64e59c5dec972c31ea69cc72";
                  }}
                >
                  Book a Test Ride
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Code of conduct on desktop */}

      <div className="inner_container desktop_version">
        <div className="coc-header padding_container">
          <div>
            <div>
              <h1>
                <b className="pageTitle">
                  <Accent>
                    <span>Code of Conduct</span>
                  </Accent>
                </b>
              </h1>
              <p className="coc-header__content">
                Wave has zero tolerance approach towards corruption and is
                committed to respecting the highest standards in terms of
                efficiency, responsibility and transparency in its activities.
                Wave refuses to support fraudulent actions directly or
                indirectly and undertakes to minimize risks of corruption in the
                implementation of its activities. Wave commits to be fully
                accountable and transparent towards its customers, partners, and
                investors by providing access to information regarding the
                allocation and management of its funds.
              </p>
            </div>
          </div>
          <div>
            <div>
              <img src={CodeofConductImg} alt="Bike lessons" />
            </div>
          </div>
        </div>
      </div>
      {/* Code of conduct on mobile */}
      <div className="inner_container" id="why_wave_mobile_version">
        <div className="coc-header padding_container">
          <div>
            <div>
              <h1>
                <b className="pageTitle">
                  <Accent>
                    <span>Code of Conduct</span>
                  </Accent>
                </b>
              </h1>
              <p className="coc-header__content">
                Wave has zero tolerance approach towards corruption and is
                committed to respecting the highest standards in terms of
                efficiency, responsibility and transparency in its activities.
                Wave refuses to support fraudulent actions directly or
                indirectly and undertakes to minimize risks of corruption in the
                implementation of its activities. Wave commits to be fully
                accountable and transparent towards its customers, partners, and
                investors by providing access to information regarding the
                allocation and management of its funds.
              </p>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div>
              <img src={CodeofConductImgMobile} alt="Bike lessons" />
            </div>
          </div>
        </div>
      </div>
      <section className="inner_container padding_container">
        <p id="centering_the_text">
          Our growing community deserves a safe, hassle free, and enjoyable
          experience, and that remains one of our main goals.
        </p>
      </section>
    </div>
  );
}
