import React from 'react'
import * as options from '../labels/options_en';
import RequiredLabel from "../common/RequiredLabel";
import OptionalLabel from "../common/OptionalLabel";

export default function Currency(props) {

    const isRequired = props.required;
    var isOptional = props.optional;
	const disableField = props.disableField || false;
    const name = props.name;
    const currencyValue = props.value ? props.value : "";
    const setCurrency = props.fn;
    const index = props.index;
	const displayedText = props.displayedText ? props.displayedText : "Currency";
    var LABEL = <RequiredLabel htmlFor={name} text={displayedText} />;

    if (isRequired){ // in case the developer passes required and optional.
        isOptional = false;
    }
    else if(isOptional){
        LABEL = <OptionalLabel htmlFor={name} text={displayedText} />;
    }


    return (
        <>
            {LABEL}
            <select
                className="form-control"
                value={currencyValue}
                autoComplete="off"
                name={name}
				disabled = {disableField}
				readOnly = {disableField}
                onChange={e => setCurrency(e, index)}
            >
                <option value=""></option>
                {options.currencyOptions.map((item, index) =>
                    <option key={'currency_' + index} value={item[0]}>{item[1]}</option>
                )}
            </select>
        </>
    )
}