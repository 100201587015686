import React from "react";
import { Redirect, Route } from "react-router-dom";

function ProtectedRoute({ component: Component, ...restOfProps }) {
    const user = JSON.parse(localStorage.getItem('user'));
    const authorized = restOfProps.authorized || []

    var isAuthorized = false;

    if (user)
        authorized.forEach(type => {
            if (type === user.type || type === "public")
                isAuthorized = true
        });


    return (
        <Route
            {...restOfProps}
            render={(props) =>
                isAuthorized ? <Component {...props}  key={Date.now()}/> : <Redirect to="/dashboard" />
            }
        />
    );
}

export default ProtectedRoute;