import React, { useState, useEffect, useRef, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import BatService from "../services/bat.service";
import AuthService from "../services/bat.service";
import EventBus from "../common/EventBus";
import * as options from "../labels/options_en";
import { getBranches, getModels } from "../common/getFunctions";
import { required } from "../common/Validation";
import RequiredLabel from "../common/RequiredLabel";
import { Prompt } from "react-router-dom";

const BatForm = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [number, setNumber] = useState("");
  const [status, setStatus] = useState("Ready");
  const [successful, setSuccessful] = useState(false);
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState(currentUser.branch);
  const [models, setModels] = useState([]);
  const [model, setModel] = useState("");
  const [message, setMessage] = useState("");
  const [iAmTyping, setIAmTyping] = useState(false);
  const [batId, setBatId] = useState(props.match.params.id);
  const clone = props.location.pathname.includes("/clone/");

  const handleBranchChange = async (bid) => {
    setBranch(bid);
    setModels(await getModels("", bid, "", "", true, "battery"));
  };

  const getBatInfo = async () => {
    if (batId) {
      BatService.getBatById(batId).then(
        async (response) => {
          let b = response.data;
          setNumber(b.number);
          setStatus(b.status);
          setBranch(b.branch ? b.branch._id : "");
          setModels(await getModels("", b.branch._id, "", "", true, "battery"));
          setModel(b.model ? b.model._id : "");

          if (clone === true) {
            setBatId("");
            setStatus("Ready");
          } // reset the id to clone
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          alert(_content);
          setMessage(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    } else {
      setBranch(currentUser.branch);
      setModels(
        await getModels("", currentUser.branch, "", "", true, "battery")
      );
    }
  };

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
      await getBatInfo();
    }

    onReady();
  }, []);

  const handleSaveBat = (e) => {
    e.preventDefault();
    setIAmTyping(false);
    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      if (batId) {
        AuthService.putBat(batId, number, status, model, branch).then(
          (response) => {
            setMessage("Bat Updated.");
            setSuccessful(true);
            props.history.push("/bats");
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            setMessage(resMessage);
            setSuccessful(false);
          }
        );
      } else {
        AuthService.postBat(number, status, model, branch).then(
          (response) => {
            setMessage("Battery Saved.");
            setSuccessful(true);
            props.history.push("/bats");
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            setMessage(resMessage);
            setSuccessful(false);
          }
        );
      }
    }
  };

  return (
    <Fragment>
      <Prompt
        when={iAmTyping}
        message={(location) =>
          "Are you sure, you want to leave? All your data will be lost!"
        }
      />
      <div className="col-md-12">
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          ref={form}
        >
          {!successful && (
            <div>
              <table className="styled-table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Battery Specification</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="white-background">
                    <td>
                      <div className="form-group">
                        <label htmlFor="status">Status</label>
                        {status == "In Use" ||
                        status == "Ready" ||
                        status == "Stolen" ? (
                          <>
                            <select
                              className="form-control"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              {options.bike_edit_status.map((item, index) => (
                                <option
                                  key={"bike_status_" + index}
                                  value={item.key}
                                >
                                  {item.value}
                                </option>
                              ))}
                            </select>
                          </>
                        ) : (
                          status
                        )}
                      </div>
                    </td>
                  </tr>
                  {currentUser.type === "admin" ? (
                    <tr className="white-background">
                      <td>
                        <div className="form-group">
                          <label htmlFor="branch">Branch</label>
                          <Select
                            className="form-control"
                            value={branch}
                            onChange={(e) => {
                              handleBranchChange(e.target.value);
                              setIAmTyping(true);
                            }}
                            validations={[required]}
                          >
                            <option value=""></option>
                            {branches &&
                              branches.map((m) => (
                                <option value={m._id}>{m.label}</option>
                              ))}
                          </Select>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr className="white-background">
                    <td>
                      <div className="form-group">
                        <RequiredLabel htmlFor="number" text="Number" />
                        <Input
                          type="text"
                          className="form-control"
                          name="number"
                          value={number}
                          onChange={(e) => {
                            setNumber(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required]}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="white-background">
                    <td>
                      <div className="form-group">
                        <RequiredLabel htmlFor="model" text="Model" />
                        <Select
                          className="form-control"
                          value={model}
                          onChange={(e) => {
                            setModel(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required]}
                        >
                          <option value=""></option>
                          {models &&
                            models.map((m) => (
                              <option value={m._id}>{m.label}</option>
                            ))}
                        </Select>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  onClick={handleSaveBat}
                >
                  Save
                </button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </Fragment>
  );
};

export default BatForm;
