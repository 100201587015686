import React, { useState, useEffect } from "react";
import SurveyService from "../services/survey.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import DateFormatter from "../common/DateFromatter";
import Pagination from "@material-ui/lab/Pagination";

const SurveysList = (props) => {
    const [surveys, setSurveys] = useState([]);
	const [itemsPerPage, setitemsPerPage] = useState(9);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const pageSizes = [9, 18, 24, 30, 36, 60];
	const form_id = props.match.params.id;

    useEffect(() => {

        async function onReady() {
            await fetchSurveys(page, itemsPerPage);
        }

        onReady()

    }, [])

    const fetchSurveys = (page, itemsPerPage) => {
        SurveyService.getSurveysList(parseInt(page - 1) * itemsPerPage, itemsPerPage, form_id).then(
            (response) => {
				let res = response.data.data;
                setSurveys(res.list);
                setCount(Math.ceil(res.total / itemsPerPage));
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                alert(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        )
    }

    const handleDeleteSurvey = (id) => {
        if (window.confirm("Are you sure you want to delete this survey?")) {
            SurveyService.deleteSurvey(id).then(
                (response) => {
                    setSurveys(surveys.filter(s => s._id !== id));
                    alert("Survey has been deleted.");
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    alert(_content);

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                }
            )
        }
    }
	
	const handlePageChange = async (event, value) => {
        setPage(value);
        await fetchSurveys(value, itemsPerPage);

    };

    const handlePageSizeChange = async (newItemsPerPage) => {

        setitemsPerPage(newItemsPerPage);
        setPage(1);
        await fetchSurveys(1, newItemsPerPage);

    };


    return (
        <div>
            <h3>Survey Answers</h3><br />
            {surveys.map((s, i) =>
                <>
                    <h6>
                        <NavLink to={"/answer/" + s._id}>Survey {i + 1} {s.user ? <> | {s.user.full_name} | </> : "" }({DateFormatter.DateFormatterOne(s.createdDate)})</NavLink>
                        &nbsp;&nbsp;<button onClick={() => handleDeleteSurvey(s._id)} className="text-dark midText linkBtn"  ><i alt="Delete" title="Delete" className="fa fa-trash" ></i></button>
                    </h6>
                    <br />
                </>
            )}
		<Pagination
                            className="my-3"
                            count={count}
                            page={page}
                            siblingtotal={1}
                            boundarytotal={1}
                            variant="outlined"
                            color="primary"
                            shape="rounded"
                            onChange={handlePageChange}
                            size="medium"

                        />
        </div>
    )
}

export default SurveysList;