import React, { useState, useEffect } from "react";
import MaintenanceService from "../services/maintenance.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../commonComponents/Loader";
import * as labels from "../labels/en";
import * as options from "../labels/options_en";
import { getBranches } from "../common/getFunctions";
import { Card, Table, Col, Row } from "react-bootstrap";
import DateFromatter from "../common/DateFromatter";
import Switch from "react-switch";
import BranchePullDown from "../commonComponents/BranchePullDown";

const MaintenanceList = (props) => {
  const readyFunction = props.readyFunction || null;
  const isView = props.isView || false;
  const classnoResultsFound = props.noResultsFound || "noResultsFound";
  const bikeId = props.bikeId || "";
  const chargerId = props.chargerId || "";
  const batId = props.batId || "";
  const showFilters = props.showFilters || false;
  const currentUser = props.currentUser;
  const [error, setError] = useState("");
  const [maintenances, setMaintenances] = useState([]);
  const [branches, setBranches] = useState([]);
  const [branchFilter, setBranchFilter] = useState();
  const [typeFilter, setTypeFilter] = useState(props.typeFilter || "");
  const [keywordFilter, setKeywordFilter] = useState(props.keywordFilter || "");
  const [numberFilter, setNumberFilter] = useState(props.numberFilter || "");
  const [statusFilter, setStatusFilter] = useState(
    props.statusFilter || "NotReady"
  );
  const [loading, setLoading] = useState(true);
  const [resetMsg, setResetMsg] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [actionFilter, setActionFilter] = useState("");
  const actionsList = options.action_m_types;
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [listView, setListView] = useState(props.listView || false);

  const pageSizes = [9, 18, 24, 30, 36, 60];

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      if (!isView) {
        setBranches(await getBranches());
      } else {
        readyFunction();
      }
      await listMaintenances(
        "",
        statusFilter,
        typeFilter,
        numberFilter,
        page,
        itemsPerPage,
        "",
        bikeId,
        chargerId,
        batId,
        keywordFilter
      );
    }

    onReady();
  }, []);

  const listMaintenances = async (
    branchFilter,
    statusFilter,
    typeFilter,
    numberFilter,
    page,
    itemsPerPage,
    actionFilter,
    bikeId,
    chargerId,
    batId,
    keywordFilter
  ) => {
    setLoading(true);

    if (statusFilter == "all") {
      statusFilter = "";
    }
    MaintenanceService.getMaintenancesList(
      branchFilter,
      statusFilter,
      parseInt(page - 1) * itemsPerPage,
      itemsPerPage,
      typeFilter,
      numberFilter,
      actionFilter,
      bikeId,
      chargerId,
      batId,
      keywordFilter
    ).then(
      (response) => {
        let data = response.data.data;
        setMaintenances(data.list);
        setTotal(data ? data.total : 0);
        setCount(Math.ceil(data.total / itemsPerPage));
        setResetMsg("");
        setLoading(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setError(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
    await listMaintenances(
      branchFilter,
      statusFilter,
      typeFilter,
      numberFilter,
      value,
      itemsPerPage,
      actionFilter,
      bikeId,
      chargerId,
      batId,
      keywordFilter
    );
  };

  const handlePageSizeChange = async (newItemsPerPage) => {
    setitemsPerPage(newItemsPerPage);
    setPage(1);
    await listMaintenances(
      branchFilter,
      statusFilter,
      typeFilter,
      numberFilter,
      1,
      newItemsPerPage,
      actionFilter,
      bikeId,
      chargerId,
      batId,
      keywordFilter
    );
  };

  const triggerSearch = async () => {
    await setPage(1); // return to first page
    await listMaintenances(
      branchFilter,
      statusFilter,
      typeFilter,
      numberFilter,
      1,
      itemsPerPage,
      actionFilter,
      bikeId,
      chargerId,
      batId,
      keywordFilter
    );
  };

  const handleBranchChange = async (bid) => {
    setBranchFilter(bid);
    resetResults();
  };
  const handleStatusChange = async (s) => {
    setStatusFilter(s);
    resetResults();
  };
  const handleTypeChange = async (s) => {
    setTypeFilter(s);
    resetResults();
  };
  const setKeywordFilterChange = async (s) => {
    setKeywordFilter(s);
    resetResults();
  };
  const handleActionChange = async (s) => {
    setActionFilter(s);
    resetResults();
  };
  const handleNumberChange = async (s) => {
    setNumberFilter(s);
    resetResults();
  };
  const resetResults = () => {
    setTotal(0);
    setCount(0);
    setMaintenances([]);
    setResetMsg(labels.RESET_MSG);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      triggerSearch(e);
    }
  };
  const setListviewSwitch = () => {
    setListView(!listView);
  };
  const resetSearch = async () => {
    setBranchFilter("");
    setTypeFilter("");
    setNumberFilter("");
    setStatusFilter("");
    await listMaintenances(
      "",
      "",
      "",
      "",
      page,
      itemsPerPage,
      "",
      "",
      "",
      "",
      ""
    );
  };

  const deleteMaintenance = async (event, id) => {
    if (window.confirm("Are you sure you want to delete this maintenance?")) {
      MaintenanceService.deleteMaintenance(id).then(
        (response) => {
          alert("Maintenance deleted!");
          let oldList = maintenances;
          var data = oldList.filter(function (obj) {
            return obj._id !== id;
          });
          setMaintenances(data);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  return (
    <div className="container">
      {showFilters ? (
        <>
          {currentUser.type == "admin" ||
          currentUser.type == "manager" ||
          currentUser.type == "helpdesk" ? (
            <div className="floatRight ">
              <NavLink to={"/maintenance/new"} className="greenLink mx-0">
                Add Maintenance
              </NavLink>
              &nbsp;
              {(currentUser.type == "admin" ||
                currentUser.type == "helpdesk") && (
                <NavLink
                  to={"/dashboard/maintenance"}
                  className="greenLink mx-0"
                >
                  Dashboard
                </NavLink>
              )}
            </div>
          ) : (
            ""
          )}
          <h3 className="maintenanceTitle"> Maintenance List </h3>
          <div
            style={{ display: "flex", flexDirection: "column" }}
            className="filterContainer"
          >
            <Row style={{ display: "flex", flexDirection: "row" }}>
              <Col md={2.5} style={{ width: 160 }}>
                <label
                  htmlFor="numberFilter"
                  className="filterLabelInput maintenanceLabels"
                >
                  Number
                </label>
                <input
                  style={{
                    border: "1px solid #ced4da",
                    width: "95%",
                    height: 38,
                  }}
                  type="text"
                  className="form-control filterInput"
                  name="numberFilter"
                  value={numberFilter}
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={(e) => handleNumberChange(e.target.value)}
                />
              </Col>

              <Col md={2.5}>
                <label
                  htmlFor="typeFilter"
                  className="filterLabelInput maintenanceLabels"
                >
                  Type
                </label>
                <select
                  className="form-control filterInput"
                  value={typeFilter}
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={(e) => handleTypeChange(e.target.value)}
                >
                  <option value="">All</option>
                  {options.model_types.map((item, index) => (
                    <option key={"type_" + index} value={item.key}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </Col>

              <Col md={2.5}>
                <table style={{ marginTop: 50 }} border="0">
                  <tr>
                    <td>
                      <button
                        onClick={(e) => triggerSearch(e)}
                        className="btn btn-primary btn-block search-button "
                      >
                        Go
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={(e) => resetSearch(e)}
                        className="btn btn-primary btn-block reset-button "
                      >
                        Reset
                      </button>
                    </td>
                    <td className="listViewSwitch" htmlFor="listView">
                      List View?
                    </td>
                    <Switch
                      className="filterInput3"
                      onChange={setListviewSwitch}
                      checked={listView}
                    />
                  </tr>
                </table>
              </Col>
              <Col style={{ marginTop: 40 }} md={3}>
                <a
                  href="javascript:void(0)"
                  onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
                  className="greenLink mx-0"
                >
                  {showAdvancedFilters ? "Hide" : "Show"} Advanced
                </a>
              </Col>
            </Row>
            {showAdvancedFilters ? (
              <Row style={{ display: "flex", flexDirection: "row" }}>
                <Col md={3.6}>
                  <label
                    htmlFor="invoice_type"
                    className="filterLabelInput maintenanceLabels"
                  >
                    Status
                  </label>

                  <select
                    className="form-control filterInput"
                    value={statusFilter}
                    onKeyDown={(e) => handleKeyDown(e)}
                    onChange={(e) => handleStatusChange(e.target.value)}
                    style={{ width: 200 }}
                  >
                    {options.bike_status.map((item, index) => (
                      <option key={"bike_status_" + index} value={item.key}>
                        {item.value}
                      </option>
                    ))}
                    <option value="">All</option>
                  </select>
                </Col>

                <Col md={2.5}>
                  <label
                    htmlFor="actionFilter"
                    className="filterLabelInput maintenanceLabels"
                  >
                    Action
                  </label>
                  <select
                    className="form-control filterInput"
                    value={actionFilter}
                    onKeyDown={(e) => handleKeyDown(e)}
                    onChange={(e) => handleActionChange(e.target.value)}
                  >
                    <option value="">All</option>
                    {Object.keys(actionsList).map((key, index) => {
                      return (
                        <option key={"action_" + index} value={key}>
                          {actionsList[key]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                {currentUser && currentUser.type == "admin" ? (
                  <Col md={2.5}>
                    <BranchePullDown
                      branch={branchFilter}
                      labelInside={false}
                      forceBlank={true}
                      setBranch={handleBranchChange}
                      branches={branches}
                      extraClass="filterInput"
                      extraClassLabel="filterLabelInput maintenanceLabels"
                      addFormGroup="no"
                    />
                  </Col>
                ) : null}
                <Col md={2.5}>
                  <label
                    htmlFor="typeFilter"
                    className="filterLabelInput maintenanceLabels"
                  >
                    Comments
                  </label>
                  <input
                    type="text"
                    value={keywordFilter}
                    className="form-control filterInput"
                    onKeyDown={(e) => handleKeyDown(e)}
                    onChange={(e) => setKeywordFilterChange(e.target.value)}
                  />
                </Col>
              </Row>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
      {error ? (
        <header className="jumbotron">
          {" "}
          <h3>{error}</h3>{" "}
        </header>
      ) : null}
      {!error && !loading && maintenances && maintenances.length !== 0 ? (
        <div>
          <Row>
            <Col>
              <span style={{ float: "right", marginLeft: 10 }}>
                {total} results
              </span>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "first baseline",
            }}
          >
            {!listView &&
              maintenances.map((b) => (
                <div style={{ float: "left", marginLeft: 20 }}>
                  <Card
                    style={{
                      marginLeft: 0,
                      maxWidth: 200,
                      minHeight: 360,
                      paddingLeft: 10,
                      align: "top",
                    }}
                  >
                    <Card.Body>
                      <Card.Title>
                        {b.type ? b.type : "Bike"}
                        {b.action ? (
                          <>
                            {" "}
                            |{" "}
                            <NavLink
                              to={"/maintenance/fix/" + b._id}
                              className="text-dark "
                            >
                              {" "}
                              {actionsList[b.action]}
                            </NavLink>
                          </>
                        ) : (
                          ""
                        )}
                        {currentUser.type !== "helpdesk" ? (
                          <NavLink
                            to={"/maintenance/fix/" + b._id}
                            className="text-dark "
                            style={{ float: "right" }}
                          >
                            <i
                              alt="Fix"
                              title="Fix"
                              className="fa fa-wrench"
                            ></i>
                          </NavLink>
                        ) : (
                          ""
                        )}
                        {b.category ? (
                          <div className="card-subtitle">
                            {" "}
                            {b.category}{" "}
                            {b.subcategory ? " | " + b.subcategory : ""}{" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </Card.Title>
                      <Card.Text>
                        <Table>
                          <tr>
                            <th className="headerTableTitle">Number:</th>
                            <td>
                              <NavLink
                                target="_new"
                                to={
                                  b.type == "battery"
                                    ? "/bat/" + b.battery
                                    : b.type == "charger"
                                    ? "/charger/" + b.charger
                                    : "/bike/" + b.bikeId
                                }
                                className="text-dark midText"
                              >
                                {b.number}
                              </NavLink>
                            </td>
                          </tr>
                          <tr>
                            <th className="headerTableTitle">Status:</th>
                            <td>{b.status}</td>
                          </tr>
                          <tr>
                            <th className="headerTableTitle">Biker:</th>
                            <td>
                              {b.usersubscribe ? b.usersubscribe.full_name : ""}
                            </td>
                          </tr>
                          <tr>
                            <th className="headerTableTitle">Created Date:</th>
                            <td>
                              {DateFromatter.DateFormatterOne(b.createdAt)}
                            </td>
                          </tr>
                          <tr>
                            <th className="headerTableTitle">Last Updated:</th>
                            <td>
                              {DateFromatter.DateFormatterOne(b.updatedAt)}
                            </td>
                          </tr>

                          <tr>
                            <th className="headerTableTitle">Actions:</th>
                            <td>
                              <NavLink
                                to={"/maintenance/" + b._id}
                                className="text-dark "
                              >
                                <i
                                  alt="View"
                                  title="View"
                                  className="fa fa-binoculars"
                                ></i>
                              </NavLink>
                              &nbsp;&nbsp;&nbsp;
                              <NavLink
                                to={"/maintenance/edit/" + b._id}
                                className="text-dark "
                              >
                                <i
                                  alt="Edit"
                                  title="Edit"
                                  className="fa fa-pen"
                                ></i>
                              </NavLink>
                              &nbsp;&nbsp;&nbsp;
                              {/*<a href="javascript:void(0)" className="text-dark" onClick={(e) => deleteMaintenance(e, b._id)} ><i alt="Delete" title="Delete" className="fa fa-trash" ></i></a>*/}
                            </td>
                          </tr>
                        </Table>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              ))}

            {listView && maintenances && maintenances.length != 0 ? (
              <div>
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th>{!isView ? "Type" : "Details"}</th>
                      {!isView ? <th>Number</th> : ""}
                      <th>Status</th>
                      <th>Biker</th>
                      <th>Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {maintenances && maintenances.length != 0
                      ? maintenances.map((b) => (
                          <tr>
                            <td>
                              {b.type ? b.type : "Bike"} |
                              {b.action ? (
                                <>
                                  {" "}
                                  <NavLink
                                    to={"/maintenance/fix/" + b._id}
                                    className="text-dark "
                                  >
                                    {" "}
                                    {actionsList[b.action]}
                                  </NavLink>
                                </>
                              ) : (
                                ""
                              )}
                              {b.category ? (
                                <div className="card-subtitle">
                                  {" "}
                                  {b.category}{" "}
                                  {b.subcategory ? " | " + b.subcategory : ""}{" "}
                                </div>
                              ) : (
                                ""
                              )}
                            </td>
                            {!isView ? (
                              <td>
                                <NavLink
                                  target="_new"
                                  to={
                                    b.type == "battery"
                                      ? "/bat/" + b.battery
                                      : b.type == "charger"
                                      ? "/charger/" + b.charger
                                      : "/bike/" + b.bikeId
                                  }
                                  className="text-dark midText"
                                >
                                  {b.number}
                                </NavLink>
                              </td>
                            ) : (
                              ""
                            )}
                            <td>{b.status}</td>
                            <td>
                              {b.usersubscribe ? b.usersubscribe.full_name : ""}
                            </td>
                            <td>
                              {DateFromatter.DateFormatterOne(b.createdAt)}
                            </td>
                            <td>
                              <NavLink
                                to={"/maintenance/" + b._id}
                                className="text-dark "
                              >
                                <i
                                  alt="View"
                                  title="View"
                                  className="fa fa-binoculars"
                                ></i>
                              </NavLink>
                              &nbsp;&nbsp;&nbsp;
                              <NavLink
                                to={"/maintenance/edit/" + b._id}
                                className="text-dark "
                              >
                                <i
                                  alt="Edit"
                                  title="Edit"
                                  className="fa fa-pen"
                                ></i>
                              </NavLink>
                              &nbsp;&nbsp;&nbsp;
                              {currentUser.type !== "helpdesk" ? (
                                <NavLink
                                  to={"/maintenance/fix/" + b._id}
                                  className="text-dark "
                                  style={{ float: "right" }}
                                >
                                  <i
                                    alt="Fix"
                                    title="Fix"
                                    className="fa fa-wrench"
                                  ></i>
                                </NavLink>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))
                      : "No record found"}
                  </tbody>
                </table>
              </div>
            ) : (
              <div> </div>
            )}
          </Row>
          <div className="mt-3">
            {"Items per Page: "}
            <select
              onChange={(e) => handlePageSizeChange(e.target.value)}
              value={itemsPerPage}
            >
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>

            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingtotal={1}
              boundarytotal={1}
              variant="outlined"
              color="primary"
              shape="rounded"
              onChange={handlePageChange}
              size="medium"
            />
          </div>
        </div>
      ) : loading ? (
        <Loader />
      ) : resetMsg != "" ? (
        <div className="defaultMsg">{resetMsg}</div>
      ) : (
        <div className={classnoResultsFound}>{labels.NO_RESULTS}</div>
      )}
    </div>
  );
};

export default MaintenanceList;
