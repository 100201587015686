import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const getList = (status, offset, limit) => {
  return axios.get(
    API_URL +
      "/reseller/subscribe/list?status=" +
      status +
      "&offset=" +
      offset +
      "&limit=" +
      limit,
    { headers: authHeader() }
  );
};
const checkin = (data) => {
  return axios(API_URL + "/reseller/checkin", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const saveUser = (data) => {
  return axios(API_URL + "/reseller/user/save", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const checkout = (data) => {
  return axios(API_URL + "/reseller/checkout", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};
const requestMaintenance = (data) => {
  return axios(API_URL + "/reseller/maintenance", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};
const getMaintenanceRequests = (branch, number, company, offset, limit) => {
  return axios.get(
    API_URL +
      "/reseller/maintenance/list?branch=" +
      branch +
      "&number=" +
      number +
      "&company=" +
      company +
      "&offset=" +
      offset +
      "&limit=" +
      limit,
    { headers: authHeader() }
  );
};

/**
 *
 * @param {*} branch
 * @param {*} number
 * @param {*} company
 * @param {*} start
 * @param {*} end
 * @param {String} statusFilter
 * @param {*} offset
 * @param {*} limit
 * @returns
 */
const getResellerInvoices = (
  branch,
  number,
  company,
  start,
  end,
  statusFilter,
  offset,
  limit
) => {
  return axios.get(
    API_URL +
      "/reseller/invoices/list?branch=" +
      branch +
      "&number=" +
      number +
      "&start=" +
      start +
      "&end=" +
      end +
      "&company=" +
      company +
      "&statusFilter=" +
      statusFilter +
      "&offset=" +
      offset +
      "&limit=" +
      limit,
    { headers: authHeader() }
  );
};
const deleteRequest = (id) => {
  return axios(API_URL + "/reseller/maintenance/" + id, {
    method: "delete",
    headers: authHeader(),
  });
};
const deleteInvoice = (id) => {
  return axios(API_URL + "/reseller/invoice/delete/" + id, {
    method: "delete",
    headers: authHeader(),
  });
};

const processRequest = (data) => {
  return axios(API_URL + "/reseller/process/maintenance", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const sendInvoice = (invoice) => {
  return axios(API_URL + "/reseller/invoice/send", {
    method: "post",
    headers: authHeader(),
    data: invoice,
  });
};

export default {
  getList,
  checkin,
  checkout,
  saveUser,
  requestMaintenance,
  getMaintenanceRequests,
  getResellerInvoices,
  deleteRequest,
  processRequest,
  deleteInvoice,
  sendInvoice,
};
