import React, { useState, useEffect } from "react";
import HelpDeskService from "../services/helpdesk.service";
import EventBus from "../common/EventBus";
import Modal from 'react-bootstrap/Modal'
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { getBranches } from "../common/getFunctions";
import * as labels from '../labels/en';
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../commonComponents/Loader";
import { Card, Row, Col } from "react-bootstrap";
import DateFormatter from "../common/DateFromatter";

const currentUser = JSON.parse(localStorage.getItem('user'));
const HelpdeskQuestions = (props) => {
	const [error, setError] = useState("");
	const [questionId, setQuestionId] = useState("");
	const [questionTitle, setQuestionTitle] = useState("");
	const [answer, setAnswer] = useState("");
	const [questions, setQuestions] = useState([]);
	const [questionModal, setQuestionModal] = useState(false);
	const [branchFilter, setBranchFilter] = useState();
	const [keywordFilter, setKeywordFilter] = useState("");
	const [branches, setBranches] = useState([]);
	const [noAnswerFilter, setNoAnswerFilter] = useState("");
	const [loading, setLoading] = useState(true);
	const [resetMsg, setResetMsg] = useState("");
	//const [currentPage, setcurrentPage] = useState(1);
	const [itemsPerPage, setitemsPerPage] = useState(9);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [count, setCount] = useState(0);
	const pageSizes = [9, 18, 24, 30, 36, 60];

	useEffect(() => {
		async function onReady() {
			window.scrollTo(0, 0);
			if (currentUser.type === "admin") { setBranches(await getBranches()); }
			await listHelpdeskQuestions("", "", "", page, itemsPerPage);
		}

		onReady()
	}, []);

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			triggerSearch(e);
		}
	}
	const getHelpdeskQuestionsList = async (branch, keyword, noAnswerFilter, offset, limit) => {
		let res = await HelpDeskService.getHelpdeskQuestionsList(branch, keyword, noAnswerFilter, offset, limit, false, currentUser.type).then(
			({ data }) => {

				return data.data;
			},
			(error) => {
				const _content =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();
				alert(_content);
				if (error.response && error.response.status === 401)
					EventBus.dispatch("logout");
			});

		return res;
	}
	const listHelpdeskQuestions = async (branchFilter, keywordFilter, noAnswerFilter, page, itemsPerPage) => {
		setLoading(true);
		let data = await getHelpdeskQuestionsList(branchFilter, keywordFilter, noAnswerFilter, parseInt(page - 1) * itemsPerPage, itemsPerPage);
		if (data){
			setQuestions(data.list);
			console.log(data.list);
			setTotal(data ? data.total : 0);
			setCount(Math.ceil(data.total / itemsPerPage));
			setResetMsg("");
			setLoading(false);
		}
	
	}
	const handlePageChange = async (event, value) => {
		setPage(value);
		await listHelpdeskQuestions(branchFilter, keywordFilter, noAnswerFilter, value, itemsPerPage);

	};

	const handlePageSizeChange = async (newItemsPerPage) => {

		setitemsPerPage(newItemsPerPage);
		setPage(1);
		await listHelpdeskQuestions(branchFilter, keywordFilter, noAnswerFilter, 1, newItemsPerPage);

	};

	const triggerSearch = async () => {
		setPage(1); // return to first page
		await listHelpdeskQuestions(branchFilter, keywordFilter, noAnswerFilter, 1, itemsPerPage);

	}
	const resetSearch = async () => {
		setBranchFilter("");
		setKeywordFilter("");
		setNoAnswerFilter(false);
		await listHelpdeskQuestions("", "", "", page, itemsPerPage);
	}
	const handleBranchChange = async (bid) => {
		setBranchFilter(bid);
		resetResults();
	}

	const handleKeyChange = async (n) => {
		setKeywordFilter(n);
		resetResults();
	}
	const resetResults = () => {
		setTotal(0);
		setCount(0);
		setQuestions([]);
		setResetMsg(labels.RESET_MSG);
	}
	/*
		const setNoAnswerSwitchFilter = () => {
			setNoAnswerFilter(!noAnswerFilter);
			resetResults();
		}
	*/
	const deleteQuestion = async (event, id) => {

		if (window.confirm("Are you sure you want to delete this question?")) {
			HelpDeskService.deleteQuestion(id).then(
				(response) => {

					alert("Question has been deleted!");
					let oldList = questions;
					var data = oldList.filter(function (obj) {
						return obj._id !== id;
					});
					setQuestions(data);


				},
				(error) => {
					const _content =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					alert(_content);

					if (error.response && error.response.status === 401) {
						EventBus.dispatch("logout");

					}
				}
			);
		}
	}

	const handleAnswerModalShow = (id) => {
		var questionObject = questions.find((q) => q._id === id);
		setAnswer(questionObject.solution);
		setQuestionTitle(questionObject.question);
		setQuestionId(id);
		setQuestionModal(true)
	};


	const handleAnswerModalClose = () => {
		setQuestionId("");
		setQuestionModal(false)
	};

	const saveAnswer = () => {

		var data = {
			_id: questionId,
			solution: answer,
		}

		HelpDeskService.postAnswer(data).then(async ({ data }) => {
			//fix the questions array.
			setQuestions(questions.map((q, i) => {
				if (q._id === questionId) {
					q.solution = answer;
				}
				return q;
			}))
		})

		handleAnswerModalClose();
	}
	
	const requestHelp = () => {
		props.history.push('/question/new')
			
	}
	
	return (
		<>
			<Modal show={questionModal} onHide={handleAnswerModalClose}>
				<Modal.Header>
					<Modal.Title>{questionTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<textarea
						style={{ width: '-webkit-fill-available', borderColor: '#dee2e6' }}
						onChange={(e) => setAnswer(e.target.value)}
						cols="30"
						rows="10"
						defaultValue={answer}
					/>
				</Modal.Body>
				<button style={{ width: 'auto', margin: '10px' }} variant="primary" onClick={saveAnswer}>
					Answer
				</button>
			</Modal>

			<div className="container">

				{currentUser.type === 'registered' || currentUser.type === 'subscriber' ? 
					<> <h3>We would love to hear from you!</h3>
				<Row className="helpInfo">
					<Col><div className="card-title h5">Contact us on</div></Col>
					<Col>
					<Col><i className="fa fa-envelope" ></i> <a target="_new"  className="text-dark card-text link_no_line" href="mailto:hello@wave.bike">hello@wave.bike</a></Col>
					<Col><i className="fa fa-phone"></i> <a target="_new"  className="text-dark card-text link_no_line" href="https://wa.me/96170632955">+961 70 632955</a></Col>
					</Col>
					<Col style={{marginTop:20}} ><div className="card-text">or visit us at the Wave hub any day between Monday to Friday from 9am to 5pm</div></Col>
					
					<Col style={{marginTop:20}} ><div className="card-title h5">The Hub:</div></Col>
					<Col>
					<a href="//www.google.com/maps/place/Wave/@33.8919643,35.5307855,18.94z/data=!4m5!3m4!1s0x151f17711b93c4ff:0x6a6c9a22d10b2c7e!8m2!3d33.8922325!4d35.5310995?hl=en" className="text-dark leftText card-text link_no_line" target="_blank">
					<Col>Kishishian building, basement</Col>
					<Col>14 St. Louis Street, Geitawi</Col>
					<Col>Beirut, Lebanon <i className="fa fa-location-dot"></i></Col>
					</a>
					</Col>
					
				</Row>
			
				<Row style={{ display: "flex", flexDirection: "row", alignItems: "first baseline" , marginTop:20 }}>
					<Col md={4}>
						<button onClick={() => requestHelp()} className="helpbutton">Request help</button>
					</Col>
				</Row>
				
				
				</>
				: <h3>Clients Questions</h3>
				}
				<div className="filterContainer"  >
					<Row style={{ display: "flex", flexDirection: "row" }}>

						{currentUser && currentUser.type === 'admin' ?
							<Col>
								<label className="filterLabelInput maintenanceLabels" htmlFor="branch">Branch</label>

								<select
									className="form-control filterInput"
									value={branchFilter}
									onChange={(e) => handleBranchChange(e.target.value)}

								>
									<option value="" key="0">All</option>
									{branches.map((branch, index) => <option key={index} value={branch._id}>{branch.label}</option>)}

								</select>
							</Col>
							: null}

						<Col>

							<label className="filterLabelInput maintenanceLabels" htmlFor="keywordFilter">{currentUser.type === 'registered' || currentUser.type === 'subscriber' ? "Search your previous enquiries" : "Keyword"} </label>

							<input
								style={{ border: "1px solid #ced4da", width: "95%", height: 38 }}
								className="form-control filterInput"
								type="text"
								value={keywordFilter}
								onKeyDown={e => handleKeyDown(e)}
								onChange={(e) => handleKeyChange(e.target.value)}
							/>
						</Col>
						<Col>

							<table style={{ marginTop: 50 }} border="0">
								<tr>
									<td><button onClick={(e) => triggerSearch(e)} className="btn btn-primary btn-block search-button ">Go</button></td>
									<td><button onClick={(e) => resetSearch(e)} className="btn btn-primary btn-block reset-button ">Reset</button></td>
								</tr>
							</table>
						</Col>
					</Row>
				</div>


				{error ? <header className="jumbotron"> <h3>{error}</h3>  </header> : null}
				{!error && !loading && questions && questions.length !== 0 ?

					<div>
						{currentUser.type === 'registered' || currentUser.type === 'subscriber' ?  "" : 
						<Row>
							<Col><span style={{ float: "right", marginLeft: 10 }}>{total} results</span></Col>
						</Row>
						}
						<Row style={{ display: "flex", flexDirection: "row" }}>

							{questions.map((q) =>
								<div style={{ float: "left", marginLeft: 20 }}>
									<Card style={{ marginLeft: 0, maxWidth: 250, minHeight: 260, paddingLeft: 10, align: "top" }}>
										<Card.Body>
											<Card.Title>{q.question}
												{currentUser.type === 'admin' || currentUser.type === 'manager' ? <br /> : ""}
												{currentUser.type !== 'subscriber' && currentUser.type !== 'registered' ? <><span className="tinyCardLabel">Date:&nbsp;</span><span className="midText">{q.createdAt ? DateFormatter.DateFormatterOne(q.createdAt) : ""}</span><br /></> : ""}
												{currentUser.type !== 'subscriber' && currentUser.type !== 'registered' ? <><span className="tinyCardLabel">Client:&nbsp;</span><span className="midText">{q.user ? q.user.full_name : ""}</span></> : ""}
												{(currentUser.type === 'registered' || currentUser.type === 'subscriber') ? q.solution !== "" ?
													<>&nbsp;&nbsp;&nbsp;<NavLink to={"/question/edit/" + q._id} className="text-dark midText"><i alt="Edit" title="Edit" className="fa fa-pen" ></i></NavLink>&nbsp;&nbsp;&nbsp; <button className="linkBtn text-dark midText" onClick={(e) => deleteQuestion(e, q._id)} ><i alt="Delete" title="Delete" className="fa fa-trash" ></i></button></> : "" : <>&nbsp;&nbsp;&nbsp;<button onClick={() => handleAnswerModalShow(q._id)} className="linkBtn text-dark pointer midText"><i alt="Answer" title="Answer" className="fa fa-reply" ></i></button></>}
											</Card.Title>
											<Card.Text><div className="bigConent">{q.solution ? q.solution : 'Our team will get back to you asap.'}</div></Card.Text>

										</Card.Body>
									</Card>
								</div>
							)}

						</Row>

						<div className="mt-3">
							{"Items per Page: "}
							<select onChange={(e) => handlePageSizeChange(e.target.value)} value={itemsPerPage}>
								{pageSizes.map((size) => (
									<option key={size} value={size}>
										{size}
									</option>
								))}
							</select>

							<Pagination
								className="my-3"
								count={count}
								page={page}
								siblingtotal={1}
								boundarytotal={1}
								variant="outlined"
								color="primary"
								shape="rounded"
								onChange={handlePageChange}
								size="medium"

							/>
						</div>
					</div>
					: loading ? <Loader /> :
						resetMsg !== "" ? <div className='defaultMsg'>{resetMsg}</div> :
							<div className="noResultsFound">{labels.NO_RESULTS}</div>
				}


			</div>
		</>
	);
};

export default HelpdeskQuestions;
