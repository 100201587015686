import React, { useState, useEffect, useRef, Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-validation/build/select";
import Switch from "react-switch";
import NewSelect from "react-select";
import { Prompt } from "react-router-dom";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import * as options from "../labels/options_en";
import DateFormatter from "../common/DateFromatter";
// built in functions.
import RequiredLabel from "../common/RequiredLabel";
import OptionalLabel from "../common/OptionalLabel";
import commonFunctions from "../common/Functions";
import { getCompanyResellerDetails } from "../common/getFunctions";
import ResellerService from "../services/reseller.service";
import IAgreeModal from "../commonComponents/IAgreeModal";

export default function ResellerDashboard(props) {
  const currentUser = JSON.parse(localStorage.getItem("user")) || null;
  if (!currentUser) {
    window.location.href =
      window.location.protocol + "//" + window.location.host + "/login";
  }
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [bike, setBike] = useState("");
  const [userId, setUserId] = useState("");
  const [battery, setBattery] = useState("");
  const [charger, setCharger] = useState("");
  const [helmet, setHelmet] = useState(false);
  const [bikeList, setBikeList] = useState([]);
  const [chargerList, setChargerList] = useState([]);
  const [batList, setBatList] = useState([]);
  const [bikeLoaded, setBikeLoaded] = useState(true);
  const [batLoaded, setBatLoaded] = useState(true);
  const [chargerLoaded, setChargerLoaded] = useState(true);
  const [iAmTyping, setIAmTyping] = useState(false);
  const [message, setMessage] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [checkinList, setCheckinList] = useState([]);
  const [checkInLoaded, setCheckInLoaded] = useState(false);
  const [checkoutUser, setCheckoutUser] = useState({});
  const [bikeOdometer, setBikeOdometer] = useState("");
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [checkoutMessage, setCheckoutMessage] = useState("");
  const [company, setCompany] = useState({});
  const [subscribers, setSubscribers] = useState({});
  const [iAgreeModal, setiAgreeModal] = useState(false);
  const checkinForm = useRef();
  const checkoutForm = useRef();
  const checkInBtn = useRef();
  const checkOutBtn = useRef();

  // Maintenance Fields
  const mainForm = useRef();
  const mainBtn = useRef();
  const [mainLoading, setMainLoading] = useState(false);
  const [mainMessage, setMainMessage] = useState("");
  const [mainSuccessful, setMainSuccessful] = useState(false);
  const [type, setType] = useState("bike");
  const [comments, setComments] = useState("");
  const [typeList, setTypeList] = useState([]);
  const [number, setNumber] = useState({ value: "", label: "" });
  const [checkoutObj, setCheckoutObj] = useState({});
  const [tabToShow, setTabToShow] = useState(1);
  useEffect(() => {
    async function onReady() {
      await getDashboard();
      setLoading(false);
    }

    onReady();
  }, []);

  if (currentUser.type !== "reseller") {
    return null;
  }

  const getDashboard = async () => {
    let resellerObj = await getCompanyResellerDetails();
    const newBikeList = commonFunctions.getBikeOptionsList(resellerObj.bikes);
    setCompany(resellerObj.company);
    setBikeLoaded(false);
    setBatLoaded(false);
    setChargerLoaded(false);
    setBatList(commonFunctions.getOptionsList(resellerObj.bats));
    setBikeList(newBikeList);
    setTypeList(newBikeList);
    setChargerList(commonFunctions.getOptionsList(resellerObj.chargers));
    setBikeLoaded(true);
    setBatLoaded(true);
    setChargerLoaded(true);
    await triggersetCheckinList();
  };

  const triggersetCheckinList = async () => {
    setCheckInLoaded(false);
    const list = await ResellerService.getList("checkin");
    const users = list.data.data.list;
    let optionsList = [];
    if (users) {
      users.map((l) => {
        if (l) {
          optionsList.push({
            value: l._id,
            label: l.fullName,
          });
        }
      });
      setSubscribers(users);
      setCheckinList(optionsList);
      setCheckInLoaded(true);
    }
  };
  const handleBikeChange = (bid) => {
    setBike(bid);
  };
  const setHelmetSwitch = () => {
    setHelmet(!helmet);
    setIAmTyping(true);
  };

  const refreshCheckinList = async (e) => {
    e.preventDefault();
    await triggersetCheckinList();
  };

  const clearCheckinForm = (e) => {
    e.preventDefault();
    clearFieldsCheckin();
  };
  const clearFieldsCheckin = () => {
    setFullName("");
    setEmail("");
    setBike("");
    setBattery("");
    setPhone("");
    setCharger("");
    setHelmet(false);
  };

  const clearFieldsCheckout = () => {
    setCheckoutUser("");
    setBikeOdometer("");
    setCheckoutObj({});
  };
  const clearCheckoutForm = (e) => {
    e.preventDefault();
    clearFieldsCheckout();
  };

  /**
   * confirm to get id from client, then show the iagree popup
   * @param {*} e
   * @returns
   */
  const checkin = (e) => {
    e.preventDefault();
    setIAmTyping(false);
    setMessage("");
    setSuccessful(false);

    checkinForm.current.validateAll();
    if (
      fullName == "" ||
      email == "" ||
      phone == "" ||
      !bike ||
      !bike.value ||
      !battery ||
      !battery.value
    ) {
      alert("Please fill all required fields!");
      return;
    }
    const completeName = fullName.split(" ");
    if (completeName[0] && !completeName[1]) {
      alert(
        "Please enter a complete name. Both first and last names are required!"
      );
      return;
    }
    const msg =
      "Did the rentee leave an ID?\n \nIf YES, then click OK, otherwise CANCEL and get the ID as a deposit first.";
    if (
      checkInBtn.current.context._errors.length === 0 &&
      window.confirm(msg)
    ) {
      setiAgreeModal(true);
    }
  };

  /**
   * After signing the iagree call this.
   * @param {*} e
   */
  const forceCheckin = (e) => {
    const indexOfSpace = fullName.indexOf(" ");
    const firstName = fullName.slice(0, indexOfSpace);
    const lastName = fullName.slice(indexOfSpace + 1);

    ResellerService.saveUser({
      _id: userId,
      first_name: firstName,
      last_name: lastName,
      fullName: fullName,
      email: email,
      phone: phone,
    }).then((userResponse) => {
      let startDate = new Date();
      startDate =
        startDate.getFullYear() +
        "-" +
        DateFormatter.padTo2Digits(startDate.getMonth() + 1) +
        "-" +
        DateFormatter.padTo2Digits(startDate.getDate()) +
        "T" +
        DateFormatter.padTo2Digits(startDate.getHours()) +
        ":" +
        DateFormatter.padTo2Digits(startDate.getMinutes()) +
        ":" +
        DateFormatter.padTo2Digits(startDate.getSeconds());
      ResellerService.checkin({
        user: userResponse.data.data.id,
        first_name: firstName,
        last_name: lastName,
        fullName: fullName,
        email: email,
        phone: phone,
        charger: charger ? charger.value : "",
        chargerNumber: charger ? charger.label : "",
        bike: bike.value,
        bikeNumber: bike.label,
        battery: battery.value,
        batteryNumber: battery.label,
        helmet: helmet,
        start: startDate,
        formatedStart: DateFormatter.fullNiceDate(new Date()),
      }).then(
        async (response) => {
          alert("Checkin saved!");
          //setMessage("Checkin Saved.");
          setSuccessful(true);
          //update checkout user pulldown!!
          await triggersetCheckinList();
          clearFieldsCheckin();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    });
  };

  const triggerCheckoutUser = (selectedUser) => {
    setCheckoutObj({});
    setCheckoutUser(selectedUser);
    setIAmTyping(true);
    if (selectedUser) {
      const subscriber = subscribers.find((c) => c._id === selectedUser.value);
      const bikeObj = bikeList.find((c) => c.value === subscriber.bike);

      const start = new Date(subscriber.start);
      let endDate = new Date();
      endDate =
        endDate.getFullYear() +
        "-" +
        DateFormatter.padTo2Digits(endDate.getMonth() + 1) +
        "-" +
        DateFormatter.padTo2Digits(endDate.getDate()) +
        "T" +
        DateFormatter.padTo2Digits(endDate.getHours()) +
        ":" +
        DateFormatter.padTo2Digits(endDate.getMinutes()) +
        ":" +
        DateFormatter.padTo2Digits(endDate.getSeconds());
      const end = new Date(endDate);
      let differenceValue = Math.abs((start.getTime() - end.getTime()) / 1000);
      differenceValue /= 60;
      //to do !! if value of hours is more than days use days, if value of days more than that of the week use week.
      const hours = Math.floor((differenceValue / 60) % 24);
      let days = Math.floor(differenceValue / 60 / 24);
      const weeks = Math.floor(days / 7);
      const minutes = Math.floor(differenceValue % 60);
      const halfHourPrice = company.halfHourPrice || company.hourlyPrice; // count them the same
      days = days - weeks * 7;
      /*alert(
        weeks +
          " weeks " +
          days +
          "day " +
          hours +
          " hours " +
          minutes +
          " minutes"
      );*/
      const vat = company.vatTax != "" ? company.vatTax : 0;
      //company.dailyPrice, company.hourlyPrice -- decide what to use.

      let total_amount = 0;
      let timeHR = 0;
      if (weeks != 0) {
        total_amount = weeks * company.weeklyPrice;

        timeHR = weeks * 7 * 24; // 7 days 24hrs
        if (days != 0) {
          // add the days for the weeks
          let total_amount_days = days * company.dailyPrice;
          if (
            total_amount_days > company.weeklyPrice &&
            company.weeklyPrice != 0
          ) {
            // if total price per hours greater than that of day, count it as one week rent
            total_amount_days = company.weeklyPrice;
          }
          total_amount = total_amount + total_amount_days;
          timeHR = timeHR + days * 24;
        }
      } else if (days != 0) {
        total_amount = days * company.dailyPrice;
        timeHR = days * 24;

        if (hours > 2) {
          // give a space of 2 hours of delay
          let total_amount_hours = hours * company.hourlyPrice;
          if (
            total_amount_hours > company.dailyPrice &&
            company.dailyPrice != 0
          ) {
            // if total price per hours greater than that of day, count it as one day rent
            total_amount_hours = company.dailyPrice;
          }
          total_amount = total_amount + total_amount_hours;
          timeHR = timeHR + hours;
        }
        if (total_amount > company.weeklyPrice && company.weeklyPrice != 0) {
          // if total price per hours greater than that of day, count it as one week rent
          total_amount = company.weeklyPrice;
        }
      } else if (hours != 0) {
        total_amount = hours * company.hourlyPrice;
        timeHR = hours;
        if (minutes > 20 && minutes <= 30) {
          // add 30 mins.
          total_amount = total_amount + halfHourPrice;
          timeHR = hours + 0.5;
        } else if (minutes > 30) {
          // add an hour
          total_amount = (hours + 1) * company.hourlyPrice;
          timeHR = hours + 1;
        }
        if (total_amount > company.dailyPrice && company.dailyPrice != 0) {
          // if total price per hours greater than that of day, count it as one day rent
          total_amount = company.dailyPrice;
        }
      } else {
        if (minutes <= 30) {
          //count it as half an  hr
          total_amount = halfHourPrice;
          timeHR = 0.5;
        } else {
          total_amount = company.hourlyPrice;
          timeHR = 1;
        }
      }

      let amount = total_amount / (1 + vat / 100); // total without vatTax
      setCheckoutObj({
        amount: amount,
        timeHR: timeHR,
        total_amount: total_amount,
        bikeNumber: subscriber.bikeNumber,
        chargerNumber: subscriber.chargerNumber,
        batteryNumber: subscriber.batteryNumber,
        helmet: subscriber.helmet,
        vat: vat,
        end: endDate,
        bikeId: subscriber.bike,
        kilometers: bikeObj.kilometers,
      });
    }
  };

  const checkout = (e) => {
    e.preventDefault();
    setIAmTyping(false);
    setCheckoutMessage("");
    setSuccessful(false);

    /*if (bikeOdometer == "") {
      alert("Odometer is required!");
      return;
    }*/
    if (checkoutUser == "" || !checkoutUser) {
      alert("Please select a user!");
      return;
    }
    setCheckoutLoading(true);

    checkoutForm.current.validateAll();
    if (checkOutBtn.current.context._errors.length === 0) {
      ResellerService.checkout({
        _id: checkoutUser.value,
        odometer: bikeOdometer == "" ? 0 : bikeOdometer,
        vat: checkoutObj.vat,
        amount: checkoutObj.amount,
        vatAmount: Math.abs(
          Number(checkoutObj.total_amount) - Number(checkoutObj.amount)
        ),
        end: checkoutObj.end,
        discount: 0,
        total_amount: checkoutObj.total_amount,
        bike: checkoutObj.bikeId,
        billingDate: new Date(),
        timeHR: checkoutObj.timeHR,
      }).then(
        async (response) => {
          setCheckoutMessage("Check out is completed.");
          setSuccessful(true);
          await triggersetCheckinList();
          clearFieldsCheckout();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setCheckoutMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
    setCheckoutLoading(false);
  };

  const triggerSetType = async (type) => {
    setNumber("");
    setType(type);
    if (type === "bike") {
      setTypeList(bikeList);
    } else if (type === "charger") {
      setTypeList(chargerList);
    } else if (type === "battery") {
      setTypeList(batList);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setComments(comments + "\n");
    }
  };

  const requestMaintenance = (e) => {
    e.preventDefault();
    setIAmTyping(false);
    setMainMessage("");
    setMainSuccessful(false);
    if (number == "" || type == "" || comments == "") {
      alert("Please fill all required fields");
      return;
    }

    setMainLoading(true);
    ResellerService.requestMaintenance({
      number: number.label,
      numberId: number.value,
      type: type,
      comments: comments,
    }).then(
      async (response) => {
        setMainMessage("Request Sent.");
        setMainSuccessful(true);
        clearFieldsMain();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMainMessage(resMessage);
        setMainSuccessful(false);
      }
    );

    setMainLoading(false);
  };

  const clearMainForm = (e) => {
    e.preventDefault();
    clearFieldsMain();
  };

  const clearFieldsMain = () => {
    setType("bike");
    setTypeList(bikeList);
    setNumber("");
    setComments("");
  };

  const triggerSetOdometer = (kmReached) => {
    setBikeOdometer(kmReached);
    let tmp = checkoutObj;
    tmp.distance = kmReached - checkoutObj.kilometers;
    setCheckoutObj(tmp);
  };
  return (
    <Fragment>
      <Prompt
        when={iAmTyping}
        message={(location) =>
          "Are you sure, you want to leave? All your data will be lost!"
        }
      />

      <IAgreeModal
        iAgreeModal={iAgreeModal}
        setiAgreeModal={setiAgreeModal}
        callBack={forceCheckin}
      />
      <div style={{ position: "relative" }}>
        <div className="resellerDashboard">
          <table border="0" cellpadding="5" align="center">
            <tr>
              <td>
                <a
                  className={
                    "resellerTab " + (tabToShow == 1 ? "resellerTabActive" : "")
                  }
                  href="javascript:void(0)"
                  onClick={() => setTabToShow(1)}
                >
                  CHECK IN
                </a>
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  className={
                    "resellerTab " + (tabToShow == 2 ? "resellerTabActive" : "")
                  }
                  onClick={() => setTabToShow(2)}
                >
                  CHECK OUT
                </a>
              </td>
              <td>
                <a
                  className={
                    "resellerTab " + (tabToShow == 3 ? "resellerTabActive" : "")
                  }
                  href="javascript:void(0)"
                  onClick={() => setTabToShow(3)}
                >
                  MAINTENANCE REQUEST
                </a>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                {tabToShow == 1 ? (
                  <div style={{ width: "98%", padding: 20 }}>
                    <Form ref={checkinForm}>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="fullName"
                          text="Full Name"
                          className="resellerLabel"
                        />
                        <input
                          type="text"
                          className="form-control resellerInput"
                          name="fullName"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="phoneNumber"
                          text="Phone #"
                          className="resellerLabel"
                        />
                        <input
                          type="text"
                          className="form-control resellerInput"
                          name="phone"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="email"
                          text="Email:"
                          className="resellerLabel"
                        />
                        <input
                          type="text"
                          className="form-control resellerInput"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="bike"
                          text="Bike #"
                          showRefresh={false}
                          loaded={bikeLoaded}
                          className="resellerLabel"
                        />

                        <NewSelect
                          value={bike}
                          onChange={(selectedB) => {
                            handleBikeChange(selectedB);
                            setIAmTyping(true);
                          }}
                          options={bikeList}
                          className="resellerInput"
                          isClearable
                        />
                      </div>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="battery"
                          text="Battery #"
                          showRefresh={false}
                          loaded={batLoaded}
                          className="resellerLabel"
                        />

                        <NewSelect
                          value={battery}
                          onChange={(selectedB) => {
                            setBattery(selectedB);
                            setIAmTyping(true);
                          }}
                          options={batList}
                          isClearable
                          className="resellerInput"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="charger" className="resellerLabel">
                          Charger #
                        </label>
                        <NewSelect
                          value={charger}
                          onChange={(selectedB) => {
                            setCharger(selectedB);
                            setIAmTyping(true);
                          }}
                          options={chargerList}
                          isClearable
                          className="resellerInput"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="helmet" className="resellerLabel">
                          Helmet
                        </label>

                        <Switch
                          checked={helmet}
                          onChange={(value) => {
                            setHelmetSwitch(value);
                          }}
                        />
                      </div>

                      <table border="0" cellpading="10" align="center">
                        <tr>
                          <td>
                            {" "}
                            <button
                              className="btn btn-primary btn-block"
                              onClick={(e) => clearCheckinForm(e)}
                              disabled={loading}
                            >
                              <span>Clear</span>
                            </button>
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>
                          <td>
                            <button
                              className="btn btn-primary btn-block"
                              onClick={(e) => checkin(e)}
                              disabled={loading}
                            >
                              {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                              )}
                              <span>Start</span>
                            </button>
                          </td>
                        </tr>
                      </table>

                      {message && (
                        <div className="form-group">
                          <div className="alert alert-danger" role="alert">
                            {message}
                          </div>
                        </div>
                      )}
                      <CheckButton
                        style={{ display: "none" }}
                        ref={checkInBtn}
                      />
                    </Form>
                  </div>
                ) : (
                  ""
                )}

                {/** checkout form */}

                {tabToShow == 2 ? (
                  <div style={{ width: "98%", padding: 20 }}>
                    <Form ref={checkoutForm}>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="checkoutUser"
                          text="Select a name:"
                          showRefresh={true}
                          onclickAction={(e) => refreshCheckinList(e)}
                          loaded={checkInLoaded}
                        />

                        <NewSelect
                          value={checkoutUser}
                          onChange={(selected) => {
                            triggerCheckoutUser(selected);
                          }}
                          options={checkinList}
                          isClearable
                        />
                      </div>
                      <div className="form-group">
                        <OptionalLabel
                          htmlFor="bikeOdometer"
                          text="Odometer (km):"
                        />
                        <input
                          type="text"
                          className="form-control"
                          name="bikeOdometer"
                          value={bikeOdometer}
                          onChange={(e) => {
                            triggerSetOdometer(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                      {checkoutObj && checkoutObj.total_amount ? (
                        <div className="form-group">
                          <h6>Checkout Summary</h6>
                          <span className="tinyCardLabel">
                            <b>Total Hours:</b>&nbsp;
                          </span>
                          <span className="midText">{checkoutObj.timeHR}</span>

                          <span className="tinyCardLabel">
                            &nbsp; - &nbsp;<b>Price:</b>&nbsp;
                          </span>
                          <span className="midText">
                            {checkoutObj.total_amount} USD
                          </span>
                          {checkoutObj.distance ? (
                            <>
                              <span className="tinyCardLabel">
                                &nbsp; - &nbsp;<b>Distance:</b>&nbsp;
                              </span>
                              <span className="midText">
                                {checkoutObj.distance} KM
                              </span>
                            </>
                          ) : (
                            ""
                          )}

                          <br />
                          <span className="midText red">
                            <b>
                              Is the following returned in a good condition?
                            </b>
                            <br />
                            <span className="tinyCardLabel">
                              <b>Bike:</b>&nbsp;
                            </span>
                            <span className="midText">
                              {checkoutObj.bikeNumber}
                            </span>
                            <span className="tinyCardLabel">
                              &nbsp; - &nbsp;<b>Battery:</b>&nbsp;
                            </span>
                            <span className="midText">
                              {checkoutObj.batteryNumber}
                            </span>
                            <br />
                            keys &nbsp; - &nbsp; lock{" "}
                            {checkoutObj.helmet ? (
                              <>&nbsp; - &nbsp; Helmet</>
                            ) : (
                              ""
                            )}
                            {checkoutObj.chargerNumber ? (
                              <>
                                &nbsp; - &nbsp; Charger{" "}
                                {checkoutObj.chargerNumber}
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      <table border="0" cellpading="10" align="center">
                        <tr>
                          <td>
                            <button
                              className="btn btn-primary btn-block"
                              onClick={(e) => clearCheckoutForm(e)}
                              disabled={checkoutLoading}
                            >
                              <span>Clear</span>
                            </button>
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>

                          <td>
                            <button
                              className="btn btn-primary btn-block"
                              onClick={(e) => checkout(e)}
                              disabled={checkoutLoading}
                            >
                              {checkoutLoading && (
                                <span className="spinner-border spinner-border-sm"></span>
                              )}
                              <span>Finish</span>
                            </button>
                          </td>
                        </tr>
                      </table>

                      {checkoutMessage && (
                        <div className="form-group">
                          <br />
                          <div className="alert alert-danger" role="alert">
                            {checkoutMessage}
                          </div>
                        </div>
                      )}
                      <CheckButton
                        style={{ display: "none" }}
                        ref={checkOutBtn}
                      />
                    </Form>
                  </div>
                ) : (
                  ""
                )}
                {tabToShow == 3 ? (
                  <div style={{ width: "98%", padding: 20 }}>
                    <Form ref={mainForm}>
                      <div className="form-group">
                        <RequiredLabel htmlFor="type" text="Type" />
                        <Select
                          className="form-control"
                          value={type}
                          onChange={(e) => {
                            triggerSetType(e.target.value);
                            setIAmTyping(true);
                          }}
                        >
                          <option value=""></option>
                          {options.model_types.map((item, index) => (
                            <option key={"type_" + index} value={item.key}>
                              {item.value}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="form-group">
                        <RequiredLabel htmlFor="number" text="Number" />
                        <NewSelect
                          value={number}
                          onChange={(selectedNumber) => {
                            setNumber(selectedNumber);
                            setIAmTyping(true);
                          }}
                          options={typeList}
                          isClearable
                        />
                      </div>

                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="comments"
                          text="Describe issue:"
                        />
                        <textarea
                          className="form-control"
                          name="comments"
                          autoComplete="off"
                          onKeyDown={(e) => handleKeyDown(e)}
                          onChange={(e) => {
                            setComments(e.target.value);
                            setIAmTyping(true);
                          }}
                          cols="30"
                          rows="4"
                          value={comments}
                        />
                      </div>
                      <table border="0" cellpading="10" align="center">
                        <tr>
                          <td>
                            <button
                              className="btn btn-primary btn-block"
                              onClick={(e) => clearMainForm(e)}
                              disabled={mainLoading}
                            >
                              <span>Clear</span>
                            </button>
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>
                          <td>
                            <button
                              className="btn btn-primary btn-block"
                              onClick={(e) => requestMaintenance(e)}
                              disabled={mainLoading}
                            >
                              {mainLoading && (
                                <span className="spinner-border spinner-border-sm"></span>
                              )}
                              <span>Request</span>
                            </button>
                          </td>
                        </tr>
                      </table>
                      {mainMessage && (
                        <div className="form-group">
                          <div className="alert alert-danger" role="alert">
                            {mainMessage}
                          </div>
                        </div>
                      )}
                      <CheckButton style={{ display: "none" }} ref={mainBtn} />
                    </Form>
                  </div>
                ) : (
                  ""
                )}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </Fragment>
  );
}
