import React, { useEffect, useState } from "react";
import DateFormatter from "../common/DateFromatter";
import Modal from "react-bootstrap/Modal";
import SubscriptionService from "../services/subscription.service";
import { NavLink } from "react-router-dom";
import { viewPDF } from "../common/pdf";
import * as labels from "../labels/en";
import { Card, Row, Col } from "react-bootstrap";

export default function SubscriptionsByUser(props) {
  const userId = props.userId;
  const border = props.border || false;
  const setSubscriptionList = props.setSubscriptionList;

  const subscriptionList = props.list || [];

  return (
    <div>
      <div className="profile-container">
        {!props.isUserMenu ? <h3> My Subscription Plans </h3> : ""}
        {subscriptionList && subscriptionList.length > 0 ? (
          <div>
            <Row style={{ display: "flex", flexDirection: "row" }}>
              {subscriptionList.map((subscription, index) => (
                <div style={{ float: "left", marginLeft: 20 }}>
                  <Card
                    style={{
                      marginLeft: 0,
                      maxWidth: 250,
                      minHeight: 260,
                      paddingLeft: 10,
                      align: "top",
                    }}
                  >
                    <Card.Body>
                      <Card.Title>
                        {subscription.plan ? (
                          <NavLink
                            to={"/subscription/" + subscription._id}
                            className="text-dark "
                          >
                            {subscription.plan.label} subscription
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </Card.Title>
                      <span className="midText">
                        {subscription.invoice ? (
                          <>
                            {DateFormatter.DateFormatterOne(
                              subscription.invoice.start
                            )}{" "}
                            -{" "}
                            {DateFormatter.DateFormatterOne(
                              subscription.invoice.end
                            )}
                          </>
                        ) : null}{" "}
                      </span>
                      <br />
                      {subscription.invoice &&
                      subscription.invoice.paid == true ? (
                        <>
                          <span className="tinyCardLabel">
                            Last Invoice:&nbsp;
                          </span>
                          <span className="midText">
                            {subscription.invoice &&
                            !subscription.invoice.draft ? (
                              <a
                                href="javascript:void(0)"
                                onClick={() =>
                                  viewPDF(
                                    subscription.invoice._id,
                                    subscription.invoice.fileName
                                  )
                                }
                                className="text-dark midText"
                              >
                                <i className="fas fa-file"></i>
                              </a>
                            ) : (
                              "None"
                            )}
                          </span>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      <span className="tinyCardLabel">Terminated:&nbsp;</span>
                      <span className="midText">
                        {subscription.terminated ? (
                          <span style={{ color: "red" }}>Yes</span>
                        ) : (
                          <span style={{ color: "green" }}>No</span>
                        )}
                        <br />
                      </span>
                      <br />
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </Row>
          </div>
        ) : (
          <div className="noResultsFound">{labels.NO_RESULTS}</div>
        )}
      </div>
    </div>
  );
}
