import React, { useState, useEffect, useRef, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import EventBus from "../common/EventBus";
import InvoiceService from "../services/invoice.service";
import {
  getPlansList,
  getBikersList,
  getInventoriesOptions,
  getCompanies,
} from "../common/getFunctions";
import commonFunctions from "../common/Functions";
import RequiredLabel from "../common/RequiredLabel";
import { required } from "../common/Validation";
import * as options from "../labels/options_en";
import BranchService from "../services/branch.service";
import Currency from "../commonComponents/Currency";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AccessoriesSelectList from "../commonComponents/AccessoriesSelectList";
import DateFormatter from "../common/DateFromatter";
import PackageService from "../services/package.service";
import { NumericFormat } from "react-number-format";
import { Prompt } from "react-router-dom";
import InventoriesSelectList from "../commonComponents/InventoriesSelectList";

const InvoiceForm = (props) => {
  const [pack, setPack] = useState(
    window.location.search.indexOf("?package") !== -1
  );
  const [packageId, setPackageId] = useState(
    pack ? window.location.search.split("=")[1] : ""
  );
  const form = useRef();
  const checkBtn = useRef();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [type, setType] = useState("");
  const [bikerId, setBikerId] = useState("");
  const [number, setNumber] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentNumber, setPaymentNumber] = useState("");
  const [vat, setVat] = useState(pack ? 11 : 0);
  const [currency, setCurrency] = useState("");
  const [branchCurrency, setBranchCurrency] = useState("");
  const [displayedCurrency, setDisplayedCurrency] = useState("");
  const [currencyRate, setCurrencyRate] = useState("");
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [plan, setPlan] = useState();
  const [planPeriod, setPlanPeriod] = useState(1);
  const [buildingName, setBuildingName] = useState("");
  const [floor, setFloor] = useState("");
  const [street, setStreet] = useState("");
  const [town, setTown] = useState("");
  const [branch, setBranch] = useState(currentUser.branch);
  const [message, setMessage] = useState("");
  const [penaltyDays, setPenaltyDays] = useState(1);
  const [amount, setAmount] = useState(0);
  const [total_amount, setTotalAmount] = useState();
  const [successful, setSuccessful] = useState(false);
  const [alreadySaved, setAlreadySaved] = useState(false);
  const [invoiceId, setInvoicetId] = useState(props.match.params.id || "");
  const [isMonthly, setIsMonthly] = useState(true);
  const [planTypeString, setPlanTypeString] = useState("month");
  const [roundPayment, setRoundPayment] = useState("");
  const [vatCurrency, setVatCurrency] = useState("USD");
  const [bikerList, setBikerList] = useState([
    {
      _id: "",
      first_name: "",
      last_name: "",
      fullpicture: "",
      full_name: "",
      id: "",
    },
  ]);
  const [companyList, setCompanyList] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const [digitsAfterComma, setDigitsAfterComma] = useState(
    digitsAfterCommaCal(0)
  );
  const [period, setPeriod] = useState(1);
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [description, setDescription] = useState("");
  const [inventoriesOptions, setInventoriesOptions] = useState([]);
  const [fullInventoriesOptions, setFullInventoriesOptions] = useState([]);
  const [subscription, setSubscription] = useState();
  const [typesOptions, setTypesOptions] = useState([]);
  const [companyTypesOptions, setCompanyTypesOptions] = useState([]);

  const clone = props.location.pathname.includes("/clone/");
  const [accessories, setAccessories] = useState([]);
  const re = /^[0-9][0-9]*(\.[0-9]{1,2})?$/;
  const defaultType = "Receipt Deposit";
  const [quantity, setQuantity] = useState("1");
  const [companyAddress, setCompanyAddress] = useState("");
  const [invoiceFor, setInvoiceFor] = useState("client");
  const [companyId, setCompanyId] = useState("");
  const [iAmTyping, setIAmTyping] = useState(false);

  function digitsAfterCommaCal(digits) {
    if (digits <= 0) return 1;

    var res = "1";
    while (digits > 0) {
      digits = digits - 1;
      res = res + "0";
    }

    return res;
  }

  const getInvoiceInfo = async () => {
    InvoiceService.getInvoiceById(invoiceId).then(
      async (response) => {
        let b = response.data;
        if (b.user && b.user._id) {
          setInvoiceFor("client");
        } else {
          setInvoiceFor("company");
          setCompanyId(b.company?._id);
        }
        setSubscription(b.usersubscribe);
        if (b.package) {
          setPack(true);
          setPackageId(b.package);
          getPackageInfo(
            b.package,
            b.currency,
            b.currencyRate,
            b.paymentMethod
          );
        }
        setNumber(b.number);
        setType(b.type);
        setPaymentMethod(b.paymentMethod);
        runPaymentMethodLogic(b.paymentMethod);
        setPaymentNumber(b.paymentNumber);
        setVat(b.vat);
        setCurrency(b.currency);
        setVatCurrency(b.vatCurrency || "USD");
        setCurrencyRate(b.currencyRate);

        if (b.start) {
          setStart(new Date(b.start.split("T")[0]));
        }

        if (b.end && b.plan) {
          const tmpPlanTypeString = returnPlanTypeString(b.plan.planType);
          setEnd(
            new Date(
              DateFormatter.getEndDate(
                new Date(b.start.split("T")[0]),
                b.plan.period * (b.period || 1),
                tmpPlanTypeString
              )
            )
          );
        }
        const plansListT = await getPlansList(b.branch.id);
        setPlansList(plansListT);
        if (b.plan) setPlan(b.plan.id);
        if (b.user) {
          setBikerId(b.user.id);
          setBuildingName(b.user.buildingName || "");
          setFloor(b.user.floor || "");
          setStreet(b.user.street || "");
          setTown(b.user.town || "");
        }
        setBranch(b.branch.id);
        setBranchCurrency(b.branch.currency);
        if (b.amount) {
          triggerRoundPayment(b.roundPayment, b.amount);
        } else setAmount(0);
        setPenaltyDays(b.penaltyDays);
        setTotalAmount(b.total_amount);
        setAlreadySaved(!b.draft);
        setPeriod(b.period ? b.period : 1);
        if (b.plan) {
          triggerPlanType(plansListT, b.plan.id);
        }
        setDescription(b.description);
        setQuantity(b.quantity || 1);
        setAccessories(b.accessories);
        if (clone === true) {
          setInvoicetId("");
        } // reset the id to clone
        if (b.date) setInvoiceDate(new Date(b.date.split("T")[0]));
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content);
        setMessage(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const loadDefaults = async () => {
    const plans = await getPlansList();
    setPlansList(plans);
    setInvoiceFor("client");
    if (plans.length > 0) {
      let defaultPlan = plans[0];
      planHandler(defaultPlan._id, plans, defaultType, 1, 1);
    }
    var currnetDate = DateFormatter.getCurrentDate();
    setStart(new Date(currnetDate));

    setEnd(
      new Date(
        DateFormatter.getEndDate(
          currnetDate,
          planPeriod * period,
          planTypeString
        )
      )
    );
  };

  const loadOptions = async () => {
    var types = options.invoice_types.filter(function (option) {
      return option.key !== "Standard";
    });
    if (window.location.search === "?subscription") {
      setTypesOptions(options.invoice_types);
    } else {
      setTypesOptions(types);
      if (!type) setType(defaultType);
    }
    setCompanyTypesOptions(options.invoice_company_types);
    setInventoriesOptions(
      await getInventoriesOptions(true, "", "", "", "", true)
    );
    setFullInventoriesOptions(
      await getInventoriesOptions(false, "", "", "", "", true)
    );
  };
  async function onReady() {
    window.scrollTo(0, 0);
    setBikerList(await getBikersList());
    setCompanyList(await getCompanies());
    await loadOptions();

    if (pack) {
      getPackageInfo(packageId);
    } else {
      if (invoiceId) {
        await getInvoiceInfo();
      } else {
        await loadDefaults();
      }
    }
  }

  useEffect(() => {
    async function onChange() {
      setBikerList(await getBikersList());
      setCompanyList(await getCompanies());
      await loadOptions();
    }
    onChange();
  }, [invoiceFor]);

  useEffect(() => {
    onReady();
  }, []);

  const handleInvoiceFor = (forType) => {
    setInvoiceFor(forType);
    setIAmTyping(true);
  };
  const handleSaveInvoice = (e) => {
    e.preventDefault();
    setIAmTyping(false);
    var currentDate = "";
    var draft = false;

    if (e.target.value === "draft") draft = true;
    else {
      var today = new Date();
      currentDate =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    }

    if (amount === "") setAmount(0);

    setMessage("");
    setSuccessful(false);

    if (invoiceFor == "client") {
      if (!bikerId) {
        alert("Please select a biker!");
        return;
      }
      if (!plan) {
        alert("Please select a plan!");
        return;
      }
      if (isMonthly && (!town || !floor || !street || !buildingName)) {
        alert("Please fill all address information!");
        return;
      }
    } else {
      if (!companyId) {
        alert("Please select a company!");
        return;
      }
    }
    if (currency === "") {
      alert("Currency is a required field!");
      return;
    }
    let invoice = {
      _id: invoiceId,
      type: type,
      draft: draft,
      bikerId: bikerId,
      number: number,
      paymentMethod: paymentMethod,
      paymentNumber: paymentNumber,
      vat: vat,
      currency: currency,
      vatCurrency: currency,
      start: start,
      end: end,
      branch: branch,
      plan: plan,
      date: invoiceDate,
      buildingName: buildingName,
      floor: floor,
      street: street,
      town: town,
      amount:
        (type === "Refund" || type === "Return Deposit") && amount > 0
          ? commonFunctions.removeComma(amount * -1)
          : commonFunctions.removeComma(amount),
      total_amount:
        (type === "Refund" || type === "Return Deposit") && total_amount > 0
          ? commonFunctions.removeComma(total_amount * -1)
          : commonFunctions.removeComma(total_amount),
      currencyRate: commonFunctions.removeComma(currencyRate),
      penaltyDays: penaltyDays,
      period: period,
      description: description,
      accessories: accessories,
      quantity: 1,
      roundPayment: roundPayment,
      companyId: companyId,
      invoiceFor: invoiceFor,
    };

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      InvoiceService.postInvoice(invoice).then(
        (res) => {
          setMessage("Invoice Saved.");
          setSuccessful(true);
          props.history.push("/invoices");
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };

  const bikerInfoHandler = async (id) => {
    if (id === "") {
      setBranch("");
      return false;
    }

    setBikerId(id);
    var selectedUser = bikerList.filter((biker) => biker._id === id)[0];

    setBranch(selectedUser.branch._id);
    setBuildingName(selectedUser.buildingName ? selectedUser.buildingName : "");
    setFloor(selectedUser.floor ? selectedUser.floor : "");
    setStreet(selectedUser.street ? selectedUser.street : "");
    setTown(selectedUser.town ? selectedUser.town : "");

    //if (selectedUser.branch._id !== currentUser.branch) {

    await setPlansList(await getPlansList(selectedUser.branch._id));
    //}

    // get the plans depending on the biker branch.
    await BranchService.getBranchById(selectedUser.branch._id).then(
      ({ data }) => {
        setCurrencyRate(data.currencyRate);
        setNumber(data.invoicePrefix + "" + data.invoice_counter);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content);
        setMessage(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const companyInfoHandler = async (id) => {
    if (id === "") {
      setBranch("");
      return false;
    }

    setCompanyId(id);
    var selectedCompany = companyList.filter((c) => c._id === id)[0];
    if (selectedCompany) {
      setBranch(selectedCompany.branch._id);
      setCompanyAddress(selectedCompany.address);

      //if (selectedUser.branch._id !== currentUser.branch) {

      // get the plans depending on the company branch.
      await BranchService.getBranchById(selectedCompany.branch._id).then(
        ({ data }) => {
          setCurrencyRate(data.currencyRate);
          setNumber(data.invoicePrefix + "" + data.invoice_counter);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          alert(_content);
          setMessage(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };
  const triggerPaymentMethod = (e) => {
    runPaymentMethodLogic(e.target.value, currency);
    setIAmTyping(true);
  };
  const runPaymentMethodLogic = (val, currency) => {
    let displayedC = branchCurrency;
    if (val === "Cash-Euro") displayedC = "Euro";
    else if (val === "Cash-LBP") displayedC = "LBP";
    else if (val === "Cash-USD") displayedC = "USD";
    setDisplayedCurrency(displayedC);
    setCurrency(currency);
    setPaymentMethod(val);
    if (displayedC === currency) setCurrencyRate(1);
  };
  const typeHandler = (v) => {
    setType(v);

    planHandler(plan, plansList, v, penaltyDays, period);
  };
  const planHandler = async (id, plansList, planType, days, selectedPeriod) => {
    if (planType == "") {
      planType = defaultType;
    }
    setType(planType);
    setRoundPayment(roundPayment);
    if (id === "") {
      setPlan("");
      setAmount(0);
      setCurrency("");
      setVatCurrency("USD");
      setVat(0);
      setTotalAmount(0);
      setPlanPeriod(1);
      return false;
    }
    setPenaltyDays(days);
    setPlan(id);
    var selectedPrice = 0;
    var selectedVAT = 0;
    var selectedPlan = plansList.filter((plan) => plan._id === id)[0];
    if (selectedPlan) {
      setPlanPeriod(selectedPlan.period);
      setCurrencyRate(selectedPlan.branch.currencyRate);
      setBranchCurrency(selectedPlan.branch.currency);
      setDisplayedCurrency(selectedPlan.branch.currency);
      setCurrency(selectedPlan.currency);
      setVatCurrency(selectedPlan.currency);
    } else {
      selectedVAT = 11;
    }

    if (planType === "Accessories" || planType === "Accident") {
      selectedPrice = 0;
    } else if (planType === "Penalty") {
      selectedPrice = selectedPlan.penalty ? selectedPlan.penalty * days : 0;
    } else if (planType === "Standard" || planType === "Surchage") {
      selectedPrice = selectedPlan.price * selectedPeriod;
    } else if (
      planType === "Return Deposit" ||
      planType === "Receipt Deposit"
    ) {
      selectedPrice = selectedPlan.receiptPrice;
    }

    triggerPlanType(plansList, id);
    if (selectedPrice === undefined) {
      selectedPrice = 0;
    }

    if (planType !== "Receipt Deposit" && planType !== "Return Deposit") {
      selectedVAT = selectedPlan?.VAT || 11;
    }
    setVat(selectedVAT);

    startDateHandler(start);
    const pp = selectedPlan ? selectedPlan.period * period : period;
    const pt = selectedPlan ? selectedPlan.planType : "monthly";
    setEnd(
      new Date(DateFormatter.getEndDate(start, pp, returnPlanTypeString(pt)))
    );

    amountHandler(
      selectedPrice * (1 + selectedVAT / 100),
      selectedVAT,
      planType,
      selectedPeriod,
      days,
      selectedPlan ? selectedPlan.period : 1
    );
  };

  const triggerPlanType = (plansList, id) => {
    const selectedplan = plansList.filter((plan) => plan._id === id)[0];
    const selectedplanType = selectedplan?.planType || "monthly";

    setIsMonthly(selectedplanType === "monthly");
    setPlanTypeString(returnPlanTypeString(selectedplanType));
  };
  const returnPlanTypeString = (pType) => {
    switch (pType) {
      case "daily":
        return "day";
      case "weekly":
        return "week";
      case "hourly":
        return "hour";
      default:
        return "month";
    }
  };
  const setInvoiceDescription = async (
    invoicePlan,
    invoiceTotalPrice,
    invoicePeriod,
    invoiceDays,
    planPeriod
  ) => {
    if (pack) return;
    let displayedPrice = Math.round(invoiceTotalPrice / invoicePeriod);
    let currencySymbol = "$";
    if (currency == "Euro") {
      currencySymbol = "€";
    } else if (currency == "LBP") {
      currencySymbol = "LBP";
    }
    if (invoicePlan === "Accessories" || invoicePlan === "Accident") {
      setDescription(
        invoicePlan +
          " " +
          currencySymbol +
          Number(invoiceTotalPrice).toFixed(2)
      );
    } else if (invoicePlan === "Penalty") {
      setDescription(
        "Extra charge for payment delay" +
          " " +
          currencySymbol +
          Math.round(invoiceTotalPrice / invoiceDays)
      );
    } else if (invoicePlan === "Standard") {
      setDescription(
        "Bike rent" +
          " " +
          currencySymbol +
          displayedPrice +
          " package, " +
          planPeriod +
          " " +
          planTypeString +
          (planPeriod > 1 ? "s" : "")
      );
    } else if (invoicePlan === "Return Deposit") {
      setDescription("End of bike rent - return deposit");
    } else if (invoicePlan === "Receipt Deposit") {
      setDescription(
        "Bike rent - deposit" + " " + currencySymbol + displayedPrice
      );
    } else if (invoicePlan === "Surchage") {
      setDescription(
        "Short Period Surcharge" + " " + currencySymbol + displayedPrice
      );
    }
  };

  const amountHandler = async (
    value,
    vat,
    planType,
    selectedPeriod,
    days,
    planPeriod
  ) => {
    setInvoiceDescription(planType, value, selectedPeriod, days, planPeriod);
    setTotalAmount(value);

    if (value === "" || value <= 0) {
      setAmount(0);
      return;
    }

    if (vat < 0) {
      triggerRoundPayment(roundPayment, value);
    } else {
      triggerRoundPayment(roundPayment, value / (1 + vat / 100));
    }
  };

  const vatHandler = (value) => {
    if (re.test(value)) {
      setVat(value);
      amountHandler(
        commonFunctions.removeComma(total_amount),
        value,
        type,
        period,
        penaltyDays,
        planPeriod
      );
    }
  };

  const startDateHandler = (date) => {
    setStart(date);

    setEnd(
      new Date(
        DateFormatter.getEndDate(date, planPeriod * period, planTypeString)
      )
    );
    // setEnd(DateFormatter.addDays(date, 30))
  };

  const periodChangeHandler = (p) => {
    // if (re.test(p)) {
    if (p >= 0) {
      setPeriod(p);
      if (!pack) planHandler(plan, plansList, type, penaltyDays, p);
      setEnd(
        new Date(
          DateFormatter.getEndDate(start, planPeriod * p, planTypeString)
        )
      );
    } else {
      setPeriod(1);
    }
    //}
  };
  const triggerRoundPayment = (value, total_amount) => {
    setIAmTyping(true);
    setRoundPayment(value);
    if (value === "up") {
      total_amount = Math.ceil(total_amount);
    } else if (value === "down") {
      total_amount = Math.floor(total_amount);
    }
    setAmount(total_amount);
  };
  const totalChangeHandler = (value) => {
    if (value === "" || re.test(commonFunctions.removeComma(value)))
      amountHandler(
        commonFunctions.removeComma(value),
        vat,
        type,
        period,
        penaltyDays,
        planPeriod
      );
  };

  const getPackageInfo = (
    packageId,
    currency,
    currencyRate,
    displayedPayment
  ) => {
    PackageService.getPackageDetailsById(packageId).then(
      async (response) => {
        //console.log(response);
        let c = response.data.data.pack;
        setCompanyId(c.company._id);
        setCompanyAddress(c.company.address);
        setTotalAmount(c.price);
        setVat(11);
        totalChangeHandler(c.price);
        setPaymentMethod(displayedPayment || "Cash-USD");
        setCurrency(currency || c.currency);
        setDescription(c.title);
        setType("Standard");
        setBranch(c.branch._id);
        setCurrencyRate(currencyRate || c.branch.currencyRate);
        setPeriod(c.period);
        setQuantity(c.bikes.length);

        if (c.invoice && c.nextRenewal && c.nextRenewal != "None") {
          startDateHandler(new Date(c.nextRenewal));
        } else {
          startDateHandler(new Date(c.start));
        }
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content);
        setMessage(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const handleSavePackageInvoice = (e) => {
    e.preventDefault();

    var draft = false;

    if (e.target.value === "draft") draft = true;

    setIAmTyping(false);
    setMessage("");
    setSuccessful(false);
    if (currency === "") {
      alert("Currency is a required field!");
      return;
    }

    let invoice = {
      _id: invoiceId,
      packageId: packageId,
      branch: branch,
      companyId: companyId,
      draft: draft,
      paymentMethod: paymentMethod,
      vat: vat,
      amount: commonFunctions.removeComma(amount),
      total_amount: commonFunctions.removeComma(total_amount),
      currency: currency,
      vatCurrency: vatCurrency,
      currencyRate: commonFunctions.removeComma(currencyRate),
      start: start,
      end: end,
      description: description,
      penaltyDays: penaltyDays,
      period: period,
      date: invoiceDate,
      quantity: Number(quantity),
      roundPayment: roundPayment,
    };

    form.current.validateAll();

    //if (checkBtn.current.context._errors.length === 0) {

    PackageService.savePackageInvoice(invoice).then(
      (res) => {
        setMessage("Invoice Saved.");
        setSuccessful(true);
        props.history.push("/invoices");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage(resMessage);
        setSuccessful(false);
      }
    );

    //}
  };

  return (
    <Fragment>
      <Prompt
        when={iAmTyping}
        message={(location) =>
          "Are you sure, you want to leave? All your data will be lost!"
        }
      />
      <div className="col-md-12">
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          ref={form}
        >
          {!successful && (
            <div>
              <table
                className="styled-table"
                style={{ width: "100%", margin: "auto" }}
                cellPadding="10"
              >
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>Invoice Form</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="white-background">
                    <td>
                      <div className="form-group">
                        <label htmlFor="number">
                          Invoice Number: {number}
                          {subscription ? (
                            <>
                              &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; Started:{" "}
                              {DateFormatter.DateFormatterOne(
                                subscription.start
                              )}
                              {subscription.startTime &&
                              (planTypeString == "day" ||
                                planTypeString == "hour") ? (
                                <> at {subscription.startTime}</>
                              ) : (
                                ""
                              )}
                              {subscription.nextRenewal &&
                              subscription.nextRenewal != "None" ? (
                                <>
                                  &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; Next
                                  Renewal:{" "}
                                  {DateFormatter.DateFormatterOne(
                                    subscription.nextRenewal
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr className="white-background">
                    <td className="grid_container_4">
                      <div className="form-group">
                        <RequiredLabel htmlFor="invoiceFor" text="For" />
                        <Select
                          className="form-control"
                          value={invoiceFor}
                          onChange={(e) => {
                            handleInvoiceFor(e.target.value);
                          }}
                          onBlur={(e) => {
                            handleInvoiceFor(e.target.value);
                          }}
                          validations={[required]}
                        >
                          <option value="client">Client</option>
                          <option value="company">Company</option>
                        </Select>
                      </div>
                      {!pack && invoiceFor == "client" ? (
                        <div className="form-group">
                          <RequiredLabel htmlFor="bikerId" text="Biker Name" />
                          <Select
                            className="form-control"
                            value={bikerId}
                            onChange={(e) => {
                              bikerInfoHandler(e.target.value);
                              setIAmTyping(true);
                            }}
                          >
                            <option value=""></option>
                            {bikerList && bikerList.length > 0
                              ? bikerList.map((biker, index) => (
                                  <option key={index} value={biker._id}>
                                    {biker.full_name}
                                  </option>
                                ))
                              : ""}
                          </Select>
                        </div>
                      ) : (
                        <div className="form-group">
                          <RequiredLabel
                            htmlFor="companyId"
                            text="Company Name"
                          />
                          <Select
                            className="form-control"
                            value={companyId}
                            onChange={(e) => {
                              companyInfoHandler(e.target.value);
                              setIAmTyping(true);
                            }}
                          >
                            <option value=""></option>
                            {companyList && companyList.length > 0
                              ? companyList.map((c, index) => (
                                  <option key={index} value={c._id}>
                                    {c.name}
                                  </option>
                                ))
                              : ""}
                          </Select>
                        </div>
                      )}

                      {!pack && invoiceFor == "client" ? (
                        <div className="form-group">
                          <RequiredLabel htmlFor="plan" text="Plan" />
                          <Select
                            name="plan"
                            className="form-control"
                            value={plan}
                            onChange={(e) => {
                              planHandler(
                                e.target.value,
                                plansList,
                                type,
                                penaltyDays,
                                period
                              );
                              setIAmTyping(true);
                            }}
                          >
                            <option value=""></option>
                            {plansList && plansList.length > 0
                              ? plansList.map((plan, index) => (
                                  <option key={index} value={plan._id}>
                                    {plan.label}
                                  </option>
                                ))
                              : null}
                          </Select>
                        </div>
                      ) : (
                        ""
                      )}
                      {!pack && invoiceFor == "client" ? (
                        <div className="form-group">
                          <RequiredLabel htmlFor="status" text="Type" />
                          <Select
                            className="form-control"
                            value={type}
                            onChange={(e) => {
                              typeHandler(e.target.value);
                              setIAmTyping(true);
                            }}
                            validations={[required]}
                          >
                            <option value=""></option>
                            {typesOptions.map((item, index) => (
                              <option
                                key={"invoice_type_" + index}
                                value={item.key}
                              >
                                {item.value}
                              </option>
                            ))}
                          </Select>
                        </div>
                      ) : !pack ? (
                        <div className="form-group">
                          <RequiredLabel htmlFor="type" text="Type" />
                          <Select
                            className="form-control"
                            value={type}
                            onChange={(e) => {
                              typeHandler(e.target.value);
                              setIAmTyping(true);
                            }}
                            validations={[required]}
                          >
                            <option value=""></option>
                            {companyTypesOptions.map((item, index) => (
                              <option
                                key={"company_invoice_type_" + index}
                                value={item.key}
                              >
                                {item.value}
                              </option>
                            ))}
                          </Select>
                        </div>
                      ) : (
                        ""
                      )}

                      {type === "Standard" ? (
                        <div className="form-group">
                          <label htmlFor="period">Period</label>
                          <input
                            type="text"
                            className="form-control"
                            name="period"
                            value={period}
                            onChange={(e) => {
                              periodChangeHandler(e.target.value);
                              setIAmTyping(true);
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {type === "Penalty" ? (
                        <div className="form-group">
                          <label htmlFor="penaltyDays">
                            Total Days Delayed
                          </label>
                          <Input
                            type="number"
                            className="form-control"
                            name="penaltyDays"
                            value={penaltyDays}
                            onChange={(e) => {
                              planHandler(
                                plan,
                                plansList,
                                type,
                                e.target.value,
                                period
                              );
                              setIAmTyping(true);
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="form-group">
                        <RequiredLabel htmlFor="total_amount" text="Total" />
                        <NumericFormat
                          type="text"
                          className="form-control"
                          name="total_amount"
                          thousandsGroupStyle="thousand"
                          thousandSeparator=","
                          decimalSeparator="."
                          value={total_amount}
                          onChange={(e) => {
                            totalChangeHandler(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required]}
                        />
                      </div>
                      {pack ? (
                        <div className="form-group">
                          <label htmlFor="quantity">Quantity</label>
                          <input
                            type="text"
                            className="form-control"
                            name="quantity"
                            value={quantity}
                            onChange={(e) => {
                              setQuantity(e.target.value);
                              setIAmTyping(true);
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="form-group">
                        <label htmlFor="vat">VAT</label>
                        <input
                          type="text"
                          className="form-control"
                          name="vat"
                          value={vat}
                          onChange={(e) => {
                            vatHandler(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="amount"
                          text="Amount without VAT"
                        />
                        <Input
                          type="text"
                          className="form-control"
                          name="amount"
                          disabled={true}
                          value={amount}
                        />
                      </div>
                      {pack ? (
                        <div className="form-group">
                          <Currency
                            fn={(e) => {
                              setVatCurrency(e.target.value);
                              setIAmTyping(true);
                            }}
                            name="vatCurrency"
                            displayedText="VAT Currency"
                            value={vatCurrency}
                            isRequired={false}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="form-group">
                        <Currency
                          fn={(e) => {
                            runPaymentMethodLogic(
                              paymentMethod,
                              e.target.value
                            );
                            setIAmTyping(true);
                          }}
                          name="currency"
                          value={currency}
                          isRequired={true}
                        />
                      </div>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="paymentMethod"
                          text="Displayed Payment Method"
                        />
                        <Select
                          name="paymentMethod"
                          className="form-control"
                          value={paymentMethod}
                          onChange={triggerPaymentMethod}
                          validations={[required]}
                        >
                          <option value=""></option>
                          <option value="Cash-LBP">Cash-LBP</option>
                          <option value="Cash-USD">Cash-USD</option>
                          <option value="Cash-Euro">Cash-Euro</option>
                          <option value="Sponsorship">Sponsorship</option>
                        </Select>
                      </div>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="currencyRate"
                          text={
                            "1 " + currency + " for each " + displayedCurrency
                          }
                        />
                        <NumericFormat
                          type="text"
                          className="form-control"
                          name="currencyRate"
                          thousandsGroupStyle="thousand"
                          thousandSeparator=","
                          decimalSeparator="."
                          value={currencyRate}
                          onChange={(e) => {
                            setCurrencyRate(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required]}
                        />
                      </div>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="Round Payment"
                          text="Rounding"
                        />
                        <Select
                          name="roundPayment"
                          className="form-control"
                          value={roundPayment}
                          onChange={(e) => {
                            triggerRoundPayment(
                              e.target.value,
                              total_amount / (1 + vat / 100)
                            );
                          }}
                        >
                          <option value="">None</option>
                          <option value="up">Up</option>
                          <option value="down">Down</option>
                        </Select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="voltage">Invoice Date</label>
                        <DatePicker
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          name="startDate"
                          selected={invoiceDate}
                          onChange={(date) => {
                            setInvoiceDate(date);
                            setIAmTyping(true);
                          }}
                        />
                      </div>

                      {type === "Standard" || type === "Accessories" ? (
                        <>
                          <div className="form-group">
                            <label htmlFor="voltage">Start Date</label>
                            <DatePicker
                              dateFormat="dd-MM-yyyy"
                              className="form-control"
                              name="startDate"
                              selected={start}
                              onChange={(date) => {
                                startDateHandler(date);
                                setIAmTyping(true);
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="voltage">End Date</label>
                            <DatePicker
                              dateFormat="dd-MM-yyyy"
                              className="form-control"
                              name="endDate"
                              selected={end}
                              onChange={(date) => {
                                setEnd(date);
                                setIAmTyping(true);
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="form-group">
                        <label htmlFor="paymentNumber">Payment Number</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="paymentNumber"
                          value={paymentNumber}
                          onChange={(e) => {
                            setPaymentNumber(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="paymentNumber">Description</label>
                        <Textarea
                          className="form-control"
                          name="description"
                          value={description}
                          onChange={(e) => {
                            setDescription(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                      {!pack && invoiceFor == "client" && isMonthly ? (
                        <>
                          <div className="form-group">
                            <RequiredLabel
                              htmlFor="buildingName"
                              text="Building Name"
                            />
                            <Input
                              type="text"
                              className="form-control"
                              name="buildingName"
                              value={buildingName}
                              onChange={(e) => {
                                setBuildingName(e.target.value);
                                setIAmTyping(true);
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <RequiredLabel htmlFor="floor" text="Floor" />
                            <Input
                              type="text"
                              className="form-control"
                              name="floor"
                              value={floor}
                              onChange={(e) => {
                                setFloor(e.target.value);
                                setIAmTyping(true);
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <RequiredLabel htmlFor="street" text="Street" />
                            <Input
                              type="text"
                              className="form-control"
                              name="street"
                              value={street}
                              onChange={(e) => {
                                setStreet(e.target.value);
                                setIAmTyping(true);
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <RequiredLabel htmlFor="town" text="Town" />
                            <Input
                              type="text"
                              className="form-control"
                              name="town"
                              value={town}
                              onChange={(e) => {
                                setTown(e.target.value);
                                setIAmTyping(true);
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {pack || invoiceFor == "company" ? (
                        <div className="form-group">
                          <RequiredLabel
                            htmlFor="companyAddress"
                            text="Company Address"
                          />
                          <Textarea
                            className="form-control"
                            name="companyAddress"
                            value={companyAddress}
                            onChange={(e) => {
                              setCompanyAddress(e.target.value);
                              setIAmTyping(true);
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              {!pack &&
              invoiceFor == "client" &&
              (type === "Accessories" ||
                type === "Standard" ||
                type === "Surchage") ? (
                <AccessoriesSelectList
                  showPeriod={type === "Accessories" ? true : false}
                  accessories={accessories}
                  inventoriesOptions={inventoriesOptions}
                  setAccessories={setAccessories}
                  totalPayment={amount}
                  setDescription={setDescription}
                  setAmount={setAmount}
                  setTotalAmount={setTotalAmount}
                  setVat={setVat}
                  vat={vat}
                  isSubscription="false"
                  disableField={
                    type === "Accessories" || type === "Surchage" ? false : true
                  }
                  invoiceType={type}
                  endDate={end}
                  setEndDate={setEnd}
                />
              ) : (
                ""
              )}

              {type === "Accident" ? (
                <InventoriesSelectList
                  showPeriod={false}
                  inventories={accessories}
                  inventoriesOptions={fullInventoriesOptions}
                  setInventories={setAccessories}
                  totalPayment={amount}
                  setDescription={setDescription}
                  setAmount={setAmount}
                  setTotalAmount={setTotalAmount}
                  setVat={setVat}
                  vat={vat}
                  isSubscription="false"
                  disableField={false}
                  invoiceType={type}
                  endDate={end}
                  setEndDate={setEnd}
                />
              ) : (
                ""
              )}

              <div className="form-group">
                <table style={{ width: "100%" }}>
                  <thead></thead>
                  <tbody>
                    <tr>
                      {alreadySaved ? (
                        ""
                      ) : (
                        <td style={{ width: "50%" }}>
                          <button
                            onClick={(e) =>
                              pack
                                ? handleSavePackageInvoice(e)
                                : handleSaveInvoice(e)
                            }
                            value="draft"
                            className="btn btn-primary btn-block"
                          >
                            Save as Draft
                          </button>
                        </td>
                      )}
                      <td>
                        <button
                          onClick={(e) =>
                            pack
                              ? handleSavePackageInvoice(e)
                              : handleSaveInvoice(e)
                          }
                          value="save"
                          className="btn btn-primary btn-block"
                        >
                          Save
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </Fragment>
  );
};

export default InvoiceForm;
