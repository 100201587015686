import React from "react";
import Accent from "../components/Accent";
import money from "../assets/images/Vector.svg";
import maintenance from "../assets/images/maintenance.svg";
import insurance from "../assets/images/assurance.svg";
import people from "../assets/images/people.svg";
import phone from "../assets/images/phone.svg";
import bike from "../assets/bike.svg";

export default function WhyWaveSection(props) {
  const whyWaveSteps = [
    {
      image: bike,
      content: "A smart electric trendy bicycle",
    },
    {
      image: money,
      content: "An easy monthly settlement of fees module with no hidden costs",
    },
    {
      image: maintenance,
      content: "Free maintenance and bike replacement if needed",
    },
    {
      image: phone,
      content: "A supportive digital platform and IOS/Android apps",
    },
    {
      image: insurance,
      content: "Theft insurance",
    },
    {
      image: people,
      content: "Community events and rides around Lebanon",
    },
  ];
  return (
    <section className="inner_container">
      <div id="landing_home_why_wave">
        <div className="landing_titles">
          <h1>
            <b className="pageTitle">
              <Accent className="accent_container">Why Wave</Accent>
            </b>
          </h1>
        </div>

        <div className="get_why_wave_steps">
          {whyWaveSteps.map((step, index) => {
            return (
              <div key={`step-${index}`}>
                {step.image && (
                  <div>
                    <img
                      style={{
                        width: "40",
                        height: "40px",
                        margin: "0 auto",
                        display: "block",
                      }}
                      src={step.image}
                      alt="Wave Logo"
                    />
                  </div>
                )}
                <p dangerouslySetInnerHTML={{ __html: step.content }} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
