import React, { useState, useEffect } from "react";
import ImageUploader from "react-images-upload";
import { Row, Col } from "react-bootstrap";
import UserService from "../services/user.service";
import Resizer from "react-image-file-resizer";
import * as labels from '../labels/en';
const IdentityCard = (props) => {

    //const currentUser = JSON.parse(localStorage.getItem('user')) || null;
    const maxFileSize = 2097152;
    const fileSizeErrorMsg = "Please upload a smaller image. Max size allowed is 2MB.";
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('user')) || null);
    const [cardId, setCardId] = useState("");
    const [cardId2, setCardId2] = useState("");
    const [defaultCardId, setDefaultCardId] = useState("");
    const [defaultCardId2, setDefaultCardId2] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
        if (currentUser.cardId) {
            setDefaultCardId(process.env.REACT_APP_SERVER_API + "/upload/cardIds/" + currentUser.cardId);

        } else {
            setDefaultCardId("");
        }
        if (currentUser.cardId2) {
            setDefaultCardId2(process.env.REACT_APP_SERVER_API + "/upload/cardIds/" + currentUser.cardId2);

        } else {
            setDefaultCardId2("");
        }
    })

    const saveCardId = (p, path, isSecondCard) => {
        try {
            Resizer.imageFileResizer(
                p[0],
                300,
                300,
                "JPEG",
                100,
                0,
                (uri) => {
                    let newUri = uri.substring(0, 16) + "name=" + p[0].name + ";" + uri.substring(16, uri.length);
                    if (isSecondCard) setCardId2(newUri);
                    else setCardId(newUri);
                    const data = {
                        cardId: newUri
                    }
                    if (isSecondCard) data.isSecondCard = true;
                    UserService.uploadCardId(data).then(
                        (res) => {
                            if (isSecondCard) currentUser.cardId2 = res.data.data.cardId;
                            else currentUser.cardId = res.data.data.cardId;
                            localStorage.setItem('user', JSON.stringify(currentUser))
                            let defCard = process.env.REACT_APP_SERVER_API + "/upload/cardIds/" + res.data.data.cardId;
                            if (isSecondCard) setDefaultCardId2(defCard);
                            else setDefaultCardId(defCard);
                        },
                        (error) => {
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            alert("Please try again with smaller image.")
                        }
                    )
                },
                "base64",
                200,
                200
            );
        } catch (err) {
            console.log(err);
        }





    };


    return (
        <div className="container" style={{ paddingBottom: 30 }}>
            <h3>Identity Card

            </h3>

            <Row className="cardIdUploader flex-row justify-content-around">

                {!defaultCardId ? <Col md={4} id="cardId">
                    <ImageUploader
                        withIcon={false}
                        withPreview={false}
                        label=""
                        buttonText="Upload Identity Card"
                        buttonStyles={{ padding: 0, display: currentUser.cardId ? "none" : "block" }}
                        onChange={(p, path) => saveCardId(p, path, false)}
                        accept="image/*"
                        singleImage={true}
                        defaultImages={defaultCardId ? [defaultCardId] : []}
                        onDelete={() => setCardId("")}
                        buttonClassName="profileUpload"
                        className={currentUser.cardId ? "hideCloseButton" : "showCloseButton"}
                    />
                    {!currentUser.cardId ? <span className='midText'>Max size allowed 2MB</span> : ""}

                </Col>
                    :
                    <Row>
                        <img width={450} style={{ maxHeight: 400 }} src={defaultCardId} alt="Identity Card" />

                    </Row>}
                {!defaultCardId2 ?
                    <Col md={4} id="cardId2">
                        <ImageUploader
                            withIcon={false}
                            withPreview={false}
                            label=""
                            buttonText="Upload Identity Card 2"
                            buttonStyles={{ padding: 0, display: currentUser.cardId2 ? "none" : "block" }}
                            onChange={(p, path) => saveCardId(p, path, true)}
                            accept="image/*"
                            singleImage={true}
                            defaultImages={defaultCardId2 ? [defaultCardId2] : []}
                            onDelete={() => setCardId2("")}
                            buttonClassName="profileUpload"
                            className={currentUser.cardId2 ? "hideCloseButton" : "showCloseButton"}
                        />
                        {!currentUser.cardId2 ? <span className='midText'>Max size allowed 2MB</span> : ""}

                    </Col>
                    : <img width={450} style={{ maxHeight: 400 }} src={defaultCardId2} alt="Identity Card" />}

            </Row>

            <Row><div className="normalText" style={{ paddingTop: 30 }}><span className="red">*</span>  {labels.CARD_SHARE_INTERNAL}</div></Row>

        </div>
    )
}

export default IdentityCard;