import React, { useState, useEffect } from "react";
import authHeader from "../services/auth-header";
import ModelService from "../services/model.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import * as labels from '../labels/en';
import * as options from '../labels/options_en';
import { getModels, getBranches } from "../common/getFunctions";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../commonComponents/Loader";
import { Input } from "@material-ui/core";
import bikePic from "../assets/img/Full-Bike small.png";
import { Card, Row, Col } from "react-bootstrap";

const currentUser = JSON.parse(localStorage.getItem('user'));
const Models = () => {
	const [error, setError] = useState("");
	const [models, setModels] = useState([]);
	const [branches, setBranches] = useState([]);
	const [branchFilter, setBranchFilter] = useState("");
	const [labelFilter, setLabelFilter] = useState("");
	const [typeFilter, setTypeFilter] = useState("");
	const [loading, setLoading] = useState(true);
	const [resetMsg, setResetMsg] = useState(true);
	const [currentPage, setcurrentPage] = useState(1);
	const [itemsPerPage, setitemsPerPage] = useState(9);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [count, setCount] = useState(0);

	const pageSizes = [9, 18, 24, 30, 36, 60];

	const listModels = async (label, branchFilter, page, itemsPerPage, typeFilter) => {

		let data = await getModels(label, branchFilter, parseInt(page - 1) * itemsPerPage, itemsPerPage, false, typeFilter);
		setModels(data.list);
		setTotal(data ? data.total : 0);
		setCount(Math.ceil(data.total / itemsPerPage));
		setResetMsg("");
		setLoading(false);

	}

	useEffect(() => {

		async function onReady() {
			window.scrollTo(0, 0);
			setBranches(await getBranches());
			await listModels("", "", page, itemsPerPage);
		}

		onReady()

	}, []);


	const handlePageChange = async (event, value) => {

		setPage(value);
		await listModels(labelFilter, branchFilter, page, itemsPerPage);

	};

	const handlePageSizeChange = async (newItemsPerPage) => {

		setitemsPerPage(newItemsPerPage);
		setPage(1);
		await listModels(labelFilter, branchFilter, page, itemsPerPage);

	};

	const triggerSearch = async () => {

		setPage(1); // return to first page
		await listModels(labelFilter, branchFilter, page, itemsPerPage, typeFilter);

	}

	const handleBranchChange = async (bid) => {
		setBranchFilter(bid);
		resetResults();
	}
	const handleLabelChange = async (bid) => {
		setLabelFilter(bid);
		resetResults();
	}
	const handleTypeChange = async (s) => {
		setTypeFilter(s);
		resetResults();
	}
	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			triggerSearch(e);
		}
	}

	const resetResults = () => {
		setTotal(0);
		setCount(0);
		setModels([]);
		setResetMsg(labels.RESET_MSG);
	}
	const resetSearch = async () => {
		setBranchFilter("");
		setTypeFilter("");
		setLabelFilter("");
		await listModels("", "", page, itemsPerPage, "");
	}
	const deleteModel = async (event, id) => {

		if (window.confirm("Are you sure you want to delete this model?")) {
			ModelService.deleteModel(id).then(
				(response) => {

					alert("Model deleted!");
					let oldList = models;
					var data = oldList.filter(function (obj) {
						return obj._id !== id;
					});
					setModels(data);


				},
				(error) => {
					const _content =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					alert(_content);

					if (error.response && error.response.status === 401) {
						EventBus.dispatch("logout");

					}
				}
			);
		}
	}

	return (
		<div className="container">
			{(currentUser.type == 'admin' || currentUser.type == 'manager') ? <div className="floatRight "><NavLink to={"/model/new"} className="greenLink mx-0">Add Model</NavLink></div> : ""}

			<div>
				<h3> Models List </h3>
				<div className="filterContainer">

					{currentUser && currentUser.type == 'admin' ?
						<>
							<label className="filterLabelInput" htmlFor="branch">Branch</label>

							<select
								className="form-control filterInput"
								value={branchFilter}
								onChange={(e) => handleBranchChange(e.target.value)}
							>
								<option value="" key="0">All</option>
								{branches.map((branch, index) => <option key={index} value={branch._id}>{branch.label}</option>)}

							</select>
						</>
						: null}

					<label className="filterLabelInput" htmlFor="label">Label</label>

					<input
						className="form-control filterInput"
						type="text"
						value={labelFilter}
						onKeyDown={e => handleKeyDown(e)}
						onChange={(e) => handleLabelChange(e.target.value)}
					/>

					<label htmlFor="typeFilter" className="filterLabelInput">Type</label>
					<select
						className="form-control filterInput"
						value={typeFilter}
						onKeyDown={e => handleKeyDown(e)}
						onChange={e => handleTypeChange(e.target.value)}
					>
						<option value="">All</option>
						{options.model_types.map((item, index) =>
							<option key={'type_' + index} value={item.key}>{item.value}</option>
						)}

					</select>

					<table border="0">
						<tr>
							<td><button onClick={(e) => triggerSearch(e)} className="btn btn-primary btn-block search-button ">Go</button></td>
							<td><button onClick={(e) => resetSearch(e)} className="btn btn-primary btn-block reset-button ">Reset</button></td>
						</tr>
					</table>
				</div>
			</div>

			{error ? <header className="jumbotron"> <h3>{error}</h3>  </header> : null}
			{!error && !loading && models && models.length != 0 ?

				<div>
					<Row>
						<Col><span style={{ float: "right", marginLeft: 10 }}>{total} results</span></Col>
					</Row>
					<Row style={{ display: "flex", flexDirection: "row", alignItems: "first baseline" }}>
						{models && models.map((b, index) => (
							<div style={{ float: "left", marginLeft: 20 }}>
								<Card style={{ marginLeft: 0, maxWidth: 250, minHeight: 300, paddingLeft: 10, align: "top" }}>
									<Card.Body>
										<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
											<div>
												<Card.Title>{b.label}</Card.Title>
												<NavLink to={"/model/" + b._id} className="text-dark midText"><i alt="View" title="View" className="fa fa-binoculars" ></i></NavLink>&nbsp;&nbsp;&nbsp;<NavLink to={"/model/edit/" + b._id} className="text-dark midText"><i alt="Edit" title="Edit" className="fa fa-pen" ></i></NavLink>&nbsp;&nbsp;&nbsp;<NavLink to={"/model/clone/" + b._id} className="text-dark midText"><i alt="Clone" title="Clone" className="fa fa-clone" ></i></NavLink>&nbsp;&nbsp;&nbsp;<a href="#" className="text-dark midText" onClick={(e) => deleteModel(e, b._id)} ><i alt="Delete" title="Delete" className="fa fa-trash" ></i></a>
											</div>
											<img className="rowPic" src={b.pic ? process.env.REACT_APP_SERVER_API + "/upload/models/" + b.pic : bikePic} />

										</div>
										<span className="tinyCardLabel">Type:&nbsp;</span><span className="midText">{b.type}</span><br />

										{b.type == "bike" ?
											<>

												{b.wheelDiameter ? <><span className="tinyCardLabel">Wheel Diameter:</span> <span className="midText">{b.wheelDiameter} inch</span><br /></> : ""}
												{b.maxRiderWeight ? <><span className="tinyCardLabel">Max Rider Weight:</span> <span className="midText">{b.maxRiderWeight} kg</span><br /></> : ""}
												{b.maxSpeed ? <><span className="tinyCardLabel">Max Speed:</span> <span className="midText">{b.maxSpeed} km/h</span><br /></> : ""}
												{b.bikeWeight ? < ><span className="tinyCardLabel">Weight without battery:</span> <span className="midText">{b.bikeWeight} kg</span><br /></> : ""}
											</> :
											<>
												{
													b.type === "charger" ?
														<>
															{b.voltage ? <><span className="tinyCardLabel">Voltage:</span> <span className="midText">{b.voltage} V</span><br /></> : ""}
															{b.ampere ? <><span className="tinyCardLabel">Ampere:</span> <span className="midText">{b.ampere} A</span><br /></> : ""}
														</>
														:

														<>
															{b.voltage ? <><span className="tinyCardLabel">Voltage:</span> <span className="midText">{b.voltage} V</span><br /></> : ""}
															{b.ampere ? <><span className="tinyCardLabel">Ampere:</span> <span className="midText">{b.ampere} AH</span><br /></> : ""}
															{b.ampere ? <><span className="tinyCardLabel">Weight:</span> <span className="midText">{b.weight} Kg</span><br /></> : ""}
														</>
												}
											</>
										}
										{currentUser.type == 'admin' ? <><span className="tinyCardLabel">Branch:&nbsp;</span><span className="midText">{b.branch.label}</span><br /></> : ""}
										<br />
									</Card.Body>
								</Card>
							</div>
						))}
					</Row>


					<div className="mt-3">
						{"Items per Page: "}
						<select onChange={(e) => handlePageSizeChange(e.target.value)} value={itemsPerPage}>
							{pageSizes.map((size) => (
								<option key={size} value={size}>
									{size}
								</option>
							))}
						</select>

						<Pagination
							className="my-3"
							count={count}
							page={page}
							siblingtotal={1}
							boundarytotal={1}
							variant="outlined"
							color="primary"
							shape="rounded"
							onChange={handlePageChange}
							size="medium"

						/>
					</div>
				</div>
				: loading ? <Loader /> :
					resetMsg != "" ? <div className='defaultMsg'>{resetMsg}</div> :
						<div className="noResultsFound">{labels.NO_RESULTS}</div>
			}


		</div>
	);
};

export default Models;
