import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";


const getHelpdeskQuestionsList = async (branch, keyword,onlyNoAnswer, offset, limit, onlyList, userType) => {
	
	if (userType =="registered" || userType =="subscriber"){
		return await getBikerQuestionsList(keyword,onlyNoAnswer, offset, limit, onlyList);
	}
	else{
		return await getQuestionsList(branch, keyword,onlyNoAnswer, offset, limit, onlyList)
	}

};

const postQuestion = (_id,question) => {
    return axios(API_URL + "/helpdesk/notify", { method: 'post', headers: authHeader(), data: { _id, question} });
};

const postAnswer = (data) => {
    return axios(API_URL + "/helpdesk/answer", { method: 'post', headers: authHeader(), data: data });
};

const getBikerQuestionsList = (keyword,onlyNoAnswer, offset, limit, onlyList) => {
    return axios.get(API_URL + "/helpdesk/me?keyword=" + keyword + "&onlyNoAnswer=" + onlyNoAnswer + "&offset=" + offset + "&limit=" + limit + "&onlyList=" + onlyList, { headers: authHeader() });
};

const getQuestionsList = (branch, keyword, onlyNoAnswer, offset, limit, onlyList) => {
    return axios.get(API_URL + "/helpdesk/list?branch=" + branch + "&keyword=" + keyword + "&onlyNoAnswer=" + onlyNoAnswer + "&offset=" + offset + "&limit=" + limit + "&onlyList=" + onlyList, { headers: authHeader() });
};


const getQuestionById = (id) => {
  return axios.get(API_URL + "/helpdesk/" + id, { headers: authHeader() });
};

const deleteQuestion = (id) => {
  return axios(API_URL + "/helpdesk/" + id, { method: 'delete', headers: authHeader() });
};

export default {
    postQuestion,
    getBikerQuestionsList,
    getQuestionsList,
	getHelpdeskQuestionsList,
    postAnswer,
	getQuestionById,
	deleteQuestion
}