import React, { useState, useEffect, useRef, Fragment } from "react";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ImageUploader from "react-images-upload";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PutInWaitingList } from "../common/PutInWaitingList";
import { getLastSubscribtion } from "../common/getFunctions";
import * as labels from "../labels/en";
import Resizer from "react-image-file-resizer";
import { getBranches } from "../common/getFunctions";
import BranchePullDown from "../commonComponents/BranchePullDown";
import DateFromatter from "../common/DateFromatter";
import { Prompt } from "react-router-dom";

export default function EditProfile(props) {
  const complete = window.location.search === "?complete";
  const currentUser = AuthService.getCurrentUser();
  const maxFileSize = 2097152;
  const fileSizeErrorMsg =
    "Please upload a smaller image. Max size allowed is 2MB.";

  if (!currentUser) {
    window.location.href =
      window.location.protocol + "//" + window.location.host + "/login";
  }

  const [user, setUser] = useState(currentUser);
  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [phone, set_phone] = useState("");
  const [birthday, set_birthday] = useState("");
  const [gender, set_gender] = useState("");
  const [buildingName, set_buildingName] = useState("");
  const [floor, set_floor] = useState("");
  const [street, set_street] = useState("");
  const [town, set_town] = useState("");
  const [iAgree, set_iAgree] = useState("");
  const [iAgree2, set_iAgree2] = useState("");
  const [validated, setValidated] = useState(false);
  const [reachBottom, setReachBottom] = useState(false);
  const [reachBottom2, setReachBottom2] = useState(false);
  const [picture, setPicture] = useState("");
  const [picChanged, setPicChanged] = useState(false);
  const [defaultImage, setDefaultImage] = useState("");
  const [cardId, setCardId] = useState("");
  const [cardId2, setCardId2] = useState("");
  const [defaultCardId, setDefaultCardId] = useState("");
  const [defaultCardId2, setDefaultCardId2] = useState("");
  const [lastSubscribe, setLastSubscribe] = useState([]);
  const [phoneCode, setPhoneCode] = useState("");
  const [waitingList, setWaitingList] = useState(false);
  const [phoneOnly, setPhoneOnly] = useState("");
  const listInnerRef = useRef();
  const listInnerRef2 = useRef();
  const [email, set_email] = useState("");
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const phoneRef = useRef();
  const branchRef = useRef();
  const emailRef = useRef();
  const townRef = useRef();
  const streetRef = useRef();
  const buildingRef = useRef();
  const floorRef = useRef();
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState(currentUser.branch);
  const [selectedBranchObj, setBranchObj] = useState({});
  const [iAmTyping, setIAmTyping] = useState(false);

  function checkImage(url) {
    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.send();

    request.onload = function () {
      console.log(request);
      if (request.status == 200) {
        //if(statusText == OK)
        return true;
      } else {
        return false;
      }
    };
  }
  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      set_iAgree(user.iagree);
      set_iAgree2(user.iagree);
      set_first_name(user.first_name);
      set_last_name(user.last_name);
      set_phone(user.phone);
      if (
        user.birthday &&
        user.birthday != "NaNNaN" &&
        user.birthday != "--T00:00:00.000Z"
      ) {
        set_birthday(new Date(user.birthday.split("T")[0]));
      }
      set_gender(user.gender);
      set_buildingName(user.buildingName);
      set_floor(user.floor);
      set_street(user.street);
      set_town(user.town);
      set_email(user.email);
      setBranch(user.branch);
      setWaitingList(user.waitingList);
      if (user.picture) {
        setDefaultImage(
          process.env.REACT_APP_SERVER_API + "/upload/profiles/" + user.picture
        );
      } else {
        setDefaultImage("");
      }
      if (user.cardId) {
        setDefaultCardId(
          process.env.REACT_APP_SERVER_API + "/upload/cardIds/" + user.cardId
        );
      } else {
        setDefaultCardId("");
      }
      if (user.cardId2) {
        setDefaultCardId2(
          process.env.REACT_APP_SERVER_API + "/upload/cardIds/" + user.cardId2
        );
      } else {
        setDefaultCardId2("");
      }
      setBranches(await getBranches());
      getBikerLastSubscribtion();
    }
    onReady();
  }, []);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setReachBottom(true);
      }
    }
  };
  const onScroll2 = () => {
    if (listInnerRef2.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef2.current;

      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setReachBottom2(true);
      }
    }
  };

  const checkBoxHandler = () => {
    if (document.getElementById("checkBox-profile").checked)
      document.getElementById("checkBox-profile-label").style.color =
        "rgb(52, 58, 64)";
    setIAmTyping(true);
    set_iAgree(!iAgree);
  };

  const checkBoxHandler2 = () => {
    if (document.getElementById("checkBox-cardId").checked)
      document.getElementById("checkBox-cardId-label").style.color =
        "rgb(52, 58, 64)";
    setIAmTyping(true);
    set_iAgree2(!iAgree2);
  };

  const setSelectedBranch = async (id) => {
    setIAmTyping(true);
    setBranch(id);
    setBranchObj(branches.filter((branch) => branch._id === id)[0]);
  };

  const IAgreeForm = (
    <div
      style={{
        height: 400,
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px solid #f0ede6",
      }}
    >
      <div
        onScroll={onScroll}
        ref={listInnerRef}
        style={{ width: "95%", textAlign: "left" }}
        className="scrollbar"
      >
        {labels.iAgree1}
      </div>
      <div style={{ display: "flex", width: "93%", marginTop: 10 }}>
        {reachBottom ? (
          <>
            <input
              id="checkBox-profile"
              onChange={checkBoxHandler}
              style={{ width: "auto", margin: "5px" }}
              type="checkbox"
            />
            <label
              id="checkBox-profile-label"
              style={{
                width: "auto",
                margin: "0px",
                padding: "0px",
                color: "#343a40",
              }}
            >
              I Agree to terms and conditions
            </label>
          </>
        ) : (
          <>
            <input
              id="checkBox-profile"
              disabled
              style={{ width: "auto", margin: "5px", borderColor: "lightgray" }}
              type="checkbox"
            />
            <label
              id="checkBox-profile-label"
              style={{ width: "auto", margin: "0px", padding: "0px" }}
            >
              I Agree to terms and conditions
            </label>
          </>
        )}
      </div>
    </div>
  );

  const IAgreeForm2 = (
    <div
      style={{
        height: 400,
        width: "100%",
        marginTop: "100px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px solid #f0ede6",
      }}
    >
      <div
        onScroll={onScroll2}
        ref={listInnerRef2}
        style={{ width: "95%", textAlign: "left" }}
        className="scrollbar"
      >
        {labels.iAgree2_not_signed_intro}
        {labels.iAgree2}
      </div>
      <div style={{ display: "flex", width: "93%", marginTop: 10 }}>
        {reachBottom2 ? (
          <>
            <input
              id="checkBox-cardId"
              onChange={checkBoxHandler2}
              style={{ width: "auto", margin: "5px" }}
              type="checkbox"
            />
            <label
              id="checkBox-cardId-label"
              style={{
                width: "auto",
                margin: "0px",
                padding: "0px",
                color: "#343a40",
              }}
            >
              I Agree to terms and conditions
            </label>
          </>
        ) : (
          <>
            <input
              id="checkBox-cardId"
              disabled
              style={{ width: "auto", margin: "5px", borderColor: "lightgray" }}
              type="checkbox"
            />
            <label
              id="checkBox-cardId-label"
              style={{ width: "auto", margin: "0px", padding: "0px" }}
            >
              I Agree to terms and conditions
            </label>
          </>
        )}
      </div>
    </div>
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    var CheckBox = document.getElementById("checkBox-profile");

    if (CheckBox != null)
      if (!CheckBox.checked) {
        document.getElementById("checkBox-profile-label").style.color = "red";
      }

    var CheckBox2 = document.getElementById("checkBox-cardId");

    if (CheckBox2 != null)
      if (!CheckBox2.checked) {
        document.getElementById("checkBox-cardId-label").style.color = "red";
      }

    var cardIdElement = document.getElementById("cardId");

    if (cardIdElement) {
      if (!cardId) {
        cardIdElement.style.border = "2px solid red";
      } else {
        cardIdElement.style.border = "0px";
      }
    }

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);

    var allFilledandValidated =
      first_name !== "" &&
      last_name !== "" &&
      phone !== "" &&
      town !== "" &&
      street !== "" &&
      buildingName !== "" &&
      floor !== "" &&
      (CheckBox == null ? true : CheckBox.checked) &&
      (CheckBox2 == null ? true : CheckBox2.checked) &&
      (cardIdElement == null ? true : cardId !== "" || defaultCardId !== "") &&
      email !== "";

    if ((complete && allFilledandValidated) || !complete) {
      const data = {
        _id: currentUser._id,
        email: email,
        first_name: first_name,
        last_name: last_name,
        phone: phone,
        birthday: birthday
          ? birthday.getFullYear() +
            "-" +
            DateFromatter.padTo2Digits(birthday.getMonth() + 1) +
            "-" +
            birthday.getDate()
          : "",
        type: currentUser.type,
        gender: gender,
        branch: branch,
        password: "",
        buildingName: buildingName,
        floor: floor,
        street: street,
        town: town || "",
        iagree: iAgree,
        phoneCode: phoneCode,
        phoneOnly: phoneOnly,
      };
      UserService.postUser(data).then(
        async (res) => {
          var localUser = JSON.parse(localStorage.getItem("user"));
          localUser.first_name = first_name;
          localUser.last_name = last_name;
          localUser.phone = phone;
          localUser.birthday = birthday
            ? birthday.getFullYear() +
              "-" +
              DateFromatter.padTo2Digits(birthday.getMonth() + 1) +
              "-" +
              birthday.getDate()
            : "";
          localUser.gender = gender;
          localUser.buildingName = buildingName;
          localUser.floor = floor;
          localUser.street = street;
          localUser.town = town;
          localUser.iagree = iAgree;

          if (branch) {
            localUser.branch = branch;
            localStorage.setItem("branch", JSON.stringify(selectedBranchObj));
          }

          if (!localUser.picture || picChanged) {
            const data1 = {
              _id: currentUser._id,
              picture: picture,
              picChanged: picChanged,
            };
            await UserService.updateProfile(data1).then(
              (res) => {
                localUser.picture = res.data.data.picFile;
              },
              (error) => {
                const resMessage =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
                if (error.status === 413)
                  alert("Please try again with smaller image");
                else alert(resMessage);
              }
            );
          }
          localUser.picChanged = picChanged;
          if (
            !localUser.cardId &&
            cardId &&
            (user.type === "registered" || user.type === "subscriber")
          ) {
            const data1 = {
              cardId: cardId,
            };
            await UserService.uploadCardId(data1).then(
              (res) => {
                localUser.cardId = res.data.data.cardId;
              },
              (error) => {
                const resMessage =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
                if (error.status === 413)
                  alert("Please try again with smaller image");
                else alert(resMessage);
              }
            );
          }
          if (
            !localUser.cardId2 &&
            cardId2 &&
            (user.type === "registered" || user.type === "subscriber")
          ) {
            const data1 = {
              cardId: cardId2,
              isSecondCard: true,
            };
            await UserService.uploadCardId(data1).then(
              (res) => {
                localUser.cardId2 = res.data.data.cardId;
              },
              (error) => {
                const resMessage =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
                if (error.status === 413)
                  alert("Please try again with smaller image");
                else alert(resMessage);
              }
            );
          }
          localUser.email = email;
          localStorage.setItem("user", JSON.stringify(localUser));
          if (res.data.data.msg) alert(res.data.data.msg);
          if (complete) {
            setIAmTyping(false);
            await PutInWaitingList(branch, selectedBranchObj);
          } else {
            setIAmTyping(false);
            window.location.href =
              window.location.protocol +
              "//" +
              window.location.host +
              "/profile"; // force page to reload
          }
        },
        (error) => {
          //alert("something went wrong")
          //props.history.push('/profile');
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          alert("Please try again with smaller image.");
        }
      );
    } else {
      let firstLine = " is a required field!";
      let message = "";

      if (!first_name) {
        firstNameRef.current.focus();
        message = "First Name";
      } else if (!last_name) {
        lastNameRef.current.focus();
        message = "Last Name";
      } else if (!email) {
        emailRef.current.focus();
        message = "Email";
      } else if (!phone) {
        if (document.getElementById("Phone")) {
          document.getElementById("Phone").scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
        message = "Phone";
      } else if (!town) {
        townRef.current.focus();
        message = "Town";
      } else if (!street) {
        streetRef.current.focus();
        message = "Street";
      } else if (!buildingName) {
        buildingRef.current.focus();
        message = "Building Name";
      } else if (!floor) {
        floorRef.current.focus();
        message = "Floor Number";
      } else if (!branch) {
        branchRef.current.focus();
        message = "Branch";
      } else if (!iAgree || !iAgree2) {
        if (CheckBox !== null || CheckBox2 !== null) {
          message = "Please agree to our terms and conditions!";
          firstLine = "";
          if (CheckBox !== null && !iAgree) listInnerRef.current.focus();
          if (CheckBox2 !== null && !iAgree2) listInnerRef2.current.focus();
        }
      } else if (!cardId && !defaultCardId) {
        if (user.type === "registered" || user.type === "subscriber")
          message = "Identity Card";
      }

      alert(message + firstLine);
    }
  };

  const savePic = (p, path) => {
    setIAmTyping(true);

    try {
      Resizer.imageFileResizer(
        p[0],
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          let newUri =
            uri.substring(0, 16) +
            "name=" +
            p[0].name +
            ";" +
            uri.substring(16, uri.length);
          setPicture(newUri);
          setDefaultImage(newUri);
          setPicChanged(true);
        },
        "base64",
        200,
        200
      );
    } catch (err) {
      console.log(err);
    }
  };

  const removePic = () => {
    setIAmTyping(true);
    setPicture("");
    setPicChanged(true);
  };

  const saveCardId = (p, path, isSecondCard) => {
    setIAmTyping(true);

    try {
      Resizer.imageFileResizer(
        p[0],
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          let newUri =
            uri.substring(0, 16) +
            "name=" +
            p[0].name +
            ";" +
            uri.substring(16, uri.length);
          if (isSecondCard) setCardId2(newUri);
          else setCardId(newUri);
          const data = {
            cardId: newUri,
          };
          if (isSecondCard) data.isSecondCard = true;
        },
        "base64",
        200,
        200
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getBikerLastSubscribtion = async () => {
    if (
      currentUser.type === "registered" ||
      currentUser.type === "subscriber"
    ) {
      let data = await getLastSubscribtion(user._id);
      setLastSubscribe(data);
    }
  };

  const handlePhoneChange = (phone, countryData) => {
    setIAmTyping(true);
    set_phone(phone);
    setPhoneOnly(phone.split(countryData.dialCode)[1]);
    setPhoneCode(countryData.dialCode);
  };

  return (
    <Fragment>
      <Prompt
        when={iAmTyping}
        message={(location) =>
          "Are you sure, you want to leave? All your data will be lost!"
        }
      />
      <Form noValidate validated={validated}>
        <Container
          width="100%"
          className="profile-container mobileProfileContainer"
        >
          <Row
            style={{
              flexDirection: "row",
              textAlign: "center",
              alignItems: "flex-start",
            }}
          >
            <Col sm={12} className="profileBigCol">
              <div className="card-1 card-2">
                <div className="card-body">
                  <h2 className="title">Edit your profile</h2>

                  <div className="row-grid ">
                    <div className="col-grid-3 "></div>
                    <div className="col-grid-3">
                      <ImageUploader
                        withIcon={false}
                        withPreview={true}
                        label=""
                        buttonText="Upload picture"
                        onChange={savePic}
                        accept="image/*"
                        singleImage={true}
                        defaultImages={defaultImage ? [defaultImage] : ""}
                        onDelete={removePic}
                        buttonClassName="profileUpload"
                      />
                    </div>

                    <div className="col-grid-3"></div>
                  </div>
                  <div className="row-grid row-space">
                    <div className="col-grid-3">
                      <div className="input-group">
                        <Form.Control
                          required
                          type="text"
                          placeholder="First Name *"
                          onChange={(e) => {
                            setIAmTyping(true);
                            set_first_name(e.target.value);
                          }}
                          value={first_name}
                          ref={firstNameRef}
                        />
                      </div>
                    </div>
                    <div className="col-grid-3">
                      <div className="input-group">
                        <Form.Control
                          required
                          type="text"
                          placeholder="Last Name *"
                          onChange={(e) => {
                            setIAmTyping(true);
                            set_last_name(e.target.value);
                          }}
                          value={last_name}
                          ref={lastNameRef}
                        />
                      </div>
                    </div>
                    <div className="col-grid-3">
                      <div className="input-group">
                        <Form.Control
                          required
                          type="text"
                          placeholder="Email *"
                          onChange={(e) => {
                            set_email(e.target.value.trim());
                            setIAmTyping(true);
                          }}
                          value={email}
                          ref={emailRef}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row-grid row-space">
                    <div className="col-grid-3">
                      <div id="Phone" className="input-group">
                        <PhoneInput
                          required={complete}
                          type="text"
                          placeholder="Phone *"
                          onChange={(phone, countryData) =>
                            handlePhoneChange(phone, countryData)
                          }
                          value={phone}
                          buttonStyle={{
                            height: 25,
                            margin: "auto",
                            marginLeft: 10,
                            marginRight: 20,
                            width: 50,
                            zIndex: 3,
                          }}
                          ref={phoneRef}
                          inputClass="phoneProfile"
                        />
                      </div>
                    </div>
                    <div className="col-grid-3">
                      <div className="input-group">
                        <DatePicker
                          dateFormat="dd-MM-yyyy"
                          className="form-control birthdayProfile"
                          name="birthday"
                          selected={birthday}
                          placeholderText="Birthday"
                          onChange={(date) => {
                            set_birthday(new Date(date));
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-grid-3">
                      <div className="input-group">
                        <Form.Select
                          className="form-control grid-select genderProfile"
                          value={gender}
                          name="gender"
                          placeholder="Gender"
                          onChange={(e) => {
                            set_gender(e.target.value);
                            setIAmTyping(true);
                          }}
                        >
                          <option value=""></option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                  {complete ||
                  town ||
                  street ||
                  buildingName ||
                  floor ||
                  waitingList ? (
                    <>
                      <div className="row-grid row-space">
                        <div className="col-grid-3">
                          <div className="input-group">
                            <Form.Control
                              required
                              type="text"
                              placeholder="Town *"
                              onChange={(e) => {
                                set_town(e.target.value);
                                setIAmTyping(true);
                              }}
                              value={town}
                              ref={townRef}
                            />
                          </div>
                        </div>
                        <div className="col-grid-3">
                          <div className="input-group">
                            <Form.Control
                              required
                              type="text"
                              placeholder="Street *"
                              onChange={(e) => {
                                set_street(e.target.value);
                                setIAmTyping(true);
                              }}
                              value={street}
                              ref={streetRef}
                            />
                          </div>
                        </div>
                        <div className="col-grid-3">
                          <div className="input-group">
                            <Form.Control
                              required
                              type="text"
                              placeholder="Building Name *"
                              onChange={(e) => {
                                set_buildingName(e.target.value);
                                setIAmTyping(true);
                              }}
                              value={buildingName}
                              ref={buildingRef}
                            />
                          </div>
                        </div>

                        <div className="col-grid-3">
                          <div className="input-group">
                            <Form.Control
                              required
                              type="text"
                              placeholder="Floor *"
                              onChange={(e) => {
                                set_floor(e.target.value);
                                setIAmTyping(true);
                              }}
                              value={floor}
                              ref={floorRef}
                            />
                          </div>
                        </div>
                        {complete && !currentUser.branch ? (
                          <div className="col-grid-3">
                            <div className="input-group">
                              <BranchePullDown
                                branch={branch}
                                labelInside={true}
                                setBranch={setSelectedBranch}
                                branches={branches}
                                extraClass="grid-select genderProfile"
                                isRequired={true}
                                branchRef={branchRef}
                                forceBlank={true}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-grid-3"></div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {(complete || waitingList) &&
                  (user.type === "registered" || user.type === "subscriber") &&
                  !user.iagree ? (
                    <div className="input-group">
                      <h5 className="agreeSentence" style={{ marginTop: 30 }}>
                        Please carefully read the following Terms and Conditions
                      </h5>
                      {IAgreeForm}

                      {iAgree ? IAgreeForm2 : ""}
                    </div>
                  ) : (
                    ""
                  )}
                  {(complete || waitingList) &&
                  (user.type === "registered" || user.type === "subscriber") &&
                  !user.cardId ? (
                    <Row className="cardIdUploader flex-row">
                      <Col md={2}></Col>
                      <Col md={4} id="cardId">
                        <ImageUploader
                          withIcon={false}
                          withPreview={true}
                          label=""
                          buttonText="Upload Identity Card"
                          buttonStyles={{ padding: 0 }}
                          onChange={(p, path) => saveCardId(p, path, false)}
                          accept="image/*"
                          singleImage={true}
                          defaultImages={defaultCardId ? [defaultCardId] : ""}
                          onDelete={() => setCardId("")}
                          buttonClassName="profileUpload"
                        />
                      </Col>
                      <Col md={4} id="cardId2">
                        <ImageUploader
                          withIcon={false}
                          withPreview={true}
                          label=""
                          buttonText="Upload Identity Card 2"
                          buttonStyles={{ padding: 0 }}
                          onChange={(p, path) => saveCardId(p, path, true)}
                          accept="image/*"
                          singleImage={true}
                          defaultImages={defaultCardId2 ? [defaultCardId2] : ""}
                          onDelete={() => setCardId2("")}
                          buttonClassName="profileUpload"
                        />
                      </Col>
                      <Col md={2}></Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <Row className="profileButtons" style={{ flexDirection: "row" }}>
                <Col>
                  <Button
                    style={{ width: "100%" }}
                    id="profile-cancelBtn"
                    className="btn btn-primary profileButton"
                    onClick={() => props.history.push("/profile")}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{ width: "100%" }}
                    id="profile-saveBtn"
                    className="profileButton"
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Save
                  </Button>
                </Col>

                {/*(user.type === "registered" || user.type === "subscriber") && !user.waitingList && (!lastSubscribe || lastSubscribe.length === 0 || lastSubscribe[0].packageReturned) ? <Col><Button className="profileButton" style={{ width: "100%", padding: 0 }} id="profile-saveBtn" type="submit" onClick={(e, waiting) => handleSubmit(e, true)}>Save and add me to waiting list</Button></Col> : ""*/}
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </Fragment>
  );
}
