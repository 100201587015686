import React, { useEffect } from "react";
import JourneysList from "../commonComponents/JourneysList";


const Journeys = () => {

    const currentUser = JSON.parse(localStorage.getItem('user')) || null;
    if (!currentUser) { window.location.href = window.location.protocol + "//" + window.location.host + "/login"; }



    useEffect(() => {
        async function onReady() {
            window.scrollTo(0, 0);
        }

        onReady()

    }, []);





    return (<>


        <div className="container">
            <h3> Journey List </h3>


            <div>

                <JourneysList />




            </div>



        </div>


    </>
    );
};

export default Journeys;
