import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import UserService from "../services/user.service.js";
import CompanyService from "../services/company.service.js";
import packageService from "../services/package.service.js";

export default function InternalNoteModal(props) {
  const isCompany = props.isCompany || false;
  const isPackage = props.isPackage || false;
  const internalNoteModal = props.internalNoteModal;
  const setInternalNoteModal = props.setInternalNoteModal;
  const oldInternalNote = props.oldInternalNote || "";
  const selectedUserByNote = props.selectedUserByNote;
  //const setSelectedUserByNote = props.setSelectedUserByNote;
  const isSubscription = props.isSubscription || false;
  const isFollowup = props.isFollowup || false;
  const list = props.list;
  const setList = props.setList;

  const [internalNote, setInternalNote] = useState("");
  const [internalNoteChanged, setInternalNoteChanged] = useState(false);

  const handleModalClose = () => {
    //setSelectedUserByNote("");
    //setInternalNote("")
    setInternalNoteChanged(false);
    setInternalNoteModal(false);
  };

  const sendInternalNoteHandler = () => {
    /*if (!internalNote && oldInternalNote) {
            alert("No changes have been detected.");
            return;
        }*/

    if (isCompany) {
      var data = {
        companyId: selectedUserByNote,
        internalNote: internalNote,
      };
      CompanyService.updateInternalNote(data).then(async (res) => {
        if (isFollowup) {
          setList(
            list.map((c, i) => {
              c.map((u, index) => {
                if (
                  u.details &&
                  u.details.company &&
                  u.details.company._id === selectedUserByNote
                ) {
                  u.details.company.internalNote = internalNote;
                }
                return u;
              });
              return c;
            })
          );
        } else
          setList(
            list.map((c, i) => {
              if (c._id === selectedUserByNote) {
                c.internalNote = internalNote;
              }
              return c;
            })
          );
      });
    } else if (isPackage) {
      var data = {
        packageId: selectedUserByNote,
        internalNote: internalNote,
      };
      packageService.updateInternalNote(data).then(async (res) => {
        setList(
          list.map((c, i) => {
            if (c._id === selectedUserByNote) {
              c.internalNote = internalNote;
            }
            return c;
          })
        );
      });
    } else {
      var data = {
        userId: selectedUserByNote,
        internalNote: internalNote,
      };
      UserService.updateInternalNote(data).then(async (res) => {
        if (isSubscription) {
          setList(
            list.map((u, i) => {
              if (u.user && u.user._id === selectedUserByNote) {
                u.user.internalNote = internalNote;
              }
              return u;
            })
          );
        } else if (isFollowup) {
          setList(
            list.map((c, i) => {
              c.map((u, index) => {
                if (
                  u.details &&
                  u.details.user &&
                  u.details.user._id === selectedUserByNote
                ) {
                  u.details.user.internalNote = internalNote;
                }
                return u;
              });
              return c;
            })
          );
        } else {
          setList(
            list.map((u, i) => {
              if (u.id === selectedUserByNote) {
                u.internalNote = internalNote;
              }
              return u;
            })
          );
        }
      });
    }

    handleModalClose();
  };

  const handleInternalNoteChange = (value) => {
    setInternalNoteChanged(true);
    setInternalNote(value);
  };

  return (
    <Modal show={internalNoteModal} onHide={handleModalClose}>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Internal Note</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          style={{ width: "100%", borderColor: "#dee2e6" }}
          onChange={(e) => handleInternalNoteChange(e.target.value)}
          rows="10"
          value={internalNoteChanged ? internalNote : oldInternalNote}
        />
      </Modal.Body>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <button style={{ margin: "10px" }} onClick={handleModalClose}>
          Close
        </button>
        <button style={{ margin: "10px" }} onClick={sendInternalNoteHandler}>
          Save
        </button>
      </div>
    </Modal>
  );
}
