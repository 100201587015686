import React, { useState, useRef, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { required } from "../../common/Validation";
import AuthService from "../../services/auth.service";
import { NavLink } from "react-router-dom";
import "../css/login.css";
import Header from "../components/Header";
import { Modal } from "react-bootstrap";
import UserService from "../../services/user.service";
import Accent from "../components/Accent";
import { trackPage } from "../components/GoogleAnalytics";

export default function Login() {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [resendCodeModal, setResendCodeModal] = useState(false);
  const [resendMessage, setResendMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username.trim());
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password.trim());
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(username, password).then(
        (res) => {
          if (res && res.status === 200) {
            window.location.href =
              window.location.protocol +
              "//" +
              window.location.host +
              "/dashboard";
          } else if (
            res.data.message.resend &&
            res.data.message.resend === true
          )
            setResendCodeModal(true);
          setLoading(false);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  const resendCode = async () => {
    setLoading(true);
    await UserService.resendCode(username).then(
      (res) => {
        if (res && res.status === 200) {
          console.log("data is ", res.data.data);
          setResendMessage(res.data.data.msg);
        }
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    trackPage("Login");
  }, []);

  return (
    <div>
      <Modal show={resendCodeModal} onHide={() => setResendCodeModal(false)}>
        <Modal.Header>
          <Modal.Title>Email/Phone not verified!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="landing-login-button-container">
            <button
              id="landing-login-button"
              disabled={loading}
              onClick={resendCode}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Resend Activation Code</span>
            </button>
          </div>
          {resendMessage ? (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {resendMessage}
              </div>
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
      {/* <Header/> */}

      <div className="col-md-12 login-page-container">
        <div className="landing-login-card">
          <h1
            style={{
              color: "#595959",
              textAlign: "center",
              paddingTop: "80px",
            }}
          >
            <b className="pageTitle">
              <Accent style={{ width: "fit-content" }}>
                <span>Login</span>
              </Accent>
            </b>
          </h1>
          <Form onSubmit={handleLogin} ref={form}>
            <div className="landing-login-input-container">
              <svg
                className="landing-login-logo"
                width={30}
                viewBox="0 0 512 512"
              >
                <path d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z" />
              </svg>
              <Input
                autoComplete="off"
                placeholder="E-mail"
                type="text"
                className="landing-login-input"
                name="username"
                value={username}
                onChange={onChangeUsername}
                validations={[required]}
              />
            </div>

            <div className="landing-login-input-container">
              <svg
                className="landing-login-logo"
                width={27}
                viewBox="0 0 448 512"
              >
                <path d="M80 192V144C80 64.47 144.5 0 224 0C303.5 0 368 64.47 368 144V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80zM144 192H304V144C304 99.82 268.2 64 224 64C179.8 64 144 99.82 144 144V192z" />
              </svg>
              <Input
                autoComplete="off"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                className="landing-login-input"
                name="password"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
              />
              <i
                className={"eyeIcon fa fa-eye" + (showPassword ? "-slash" : "")}
                onClick={() => setShowPassword(!showPassword)}
              ></i>
            </div>

            {/* rightLink */}
            <div className="landing-login-footer-container">
              <NavLink
                className="landing-login-remember-container-forget"
                to="/forgetpassword"
              >
                Forgot Password
              </NavLink>
            </div>

            <div id="landing-login-button-container">
              <button id="landing_home_reserve_now_button" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Log
                  In&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </button>
            </div>

            <div className="landing-login-register">
              Don't have an account?&nbsp;
              <span>
                <NavLink
                  to={"/signup"}
                  exact
                  id="landing-login-register-create"
                >
                  Create
                </NavLink>
              </span>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
      </div>
    </div>
  );
}
