import React, { useState, useEffect } from "react";
import authHeader from "../services/auth-header";
import BatService from "../services/bat.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../commonComponents/Loader";
import * as labels from "../labels/en";
import * as options from "../labels/options_en";
import { getBranches, getModels } from "../common/getFunctions";
import { Card, Row, Col } from "react-bootstrap";
import NoteModal from "../commonComponents/NoteModal";
import DateFromatter from "../common/DateFromatter";

const currentUser = JSON.parse(localStorage.getItem("user"));
const Bats = () => {
  const [error, setError] = useState("");
  const [bats, setBats] = useState([]);
  const [branchFilter, setBranchFilter] = useState();
  const [numberFilter, setNumberFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [modelFilter, setModelFilter] = useState("");
  const [models, setModels] = useState([]);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resetMsg, setResetMsg] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const pageSizes = [9, 18, 24, 30, 36, 60];

  const [selectedBatByNote, setSelectedBatByNote] = useState("");
  const [noteModal, setNoteModal] = useState(false);
  const [stolen, setStolen] = useState("");
  const [note, setNote] = useState("");
  const [stolenDate, setStolenDate] = useState("");

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
      setModels(await getModels("", "", "", "", true, "battery"));
      await listBats("", "", page, itemsPerPage, "", "");
    }

    onReady();
  }, []);

  const listBats = async (
    branchFilter,
    numberFilter,
    page,
    itemsPerPage,
    statusFilter,
    modelFilter
  ) => {
    BatService.getBatsList(
      branchFilter,
      numberFilter,
      parseInt(page - 1) * itemsPerPage,
      itemsPerPage,
      false,
      statusFilter,
      modelFilter
    ).then(
      (response) => {
        setLoading(true);
        let data = response.data.data;
        setBats(data.list);
        setTotal(data ? data.total : 0);
        setCount(Math.ceil(data.total / itemsPerPage));
        setResetMsg("");
        setLoading(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setError(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };
  const handlePageChange = async (event, value) => {
    setPage(value);
    await listBats(
      branchFilter,
      numberFilter,
      value,
      itemsPerPage,
      statusFilter,
      modelFilter
    );
  };

  const handlePageSizeChange = async (newItemsPerPage) => {
    setitemsPerPage(newItemsPerPage);
    setPage(1);
    await listBats(
      branchFilter,
      numberFilter,
      1,
      newItemsPerPage,
      statusFilter,
      modelFilter
    );
  };

  const triggerSearch = async () => {
    await setPage(1); // return to first page
    await listBats(
      branchFilter,
      numberFilter,
      1,
      itemsPerPage,
      statusFilter,
      modelFilter
    );
  };
  const handleBranchChange = async (bid) => {
    setBranchFilter(bid);
    resetResults();
  };
  const handleStatusFilter = async (s) => {
    setStatusFilter(s);
    resetResults();
  };
  const handleModelFilter = async (s) => {
    setModelFilter(s);
    resetResults();
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      triggerSearch(e);
    }
  };
  const resetResults = () => {
    setTotal(0);
    setCount(0);
    setBats([]);
    setResetMsg(labels.RESET_MSG);
  };

  const handleNumberChange = async (n) => {
    setNumberFilter(n);
    resetResults();
  };
  const resetSearch = async () => {
    setBranchFilter("");
    setNumberFilter("");
    setStatusFilter("");
    setModelFilter("");
    await listBats("", "", page, itemsPerPage, "", "");
  };

  const deleteBat = async (event, id) => {
    if (window.confirm("Are you sure you want to delete this bat?")) {
      BatService.deleteBat(id).then(
        (response) => {
          alert("Battery deleted!");
          let oldList = bats;
          var data = oldList.filter(function (obj) {
            return obj._id !== id;
          });
          setBats(data);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  const handleMarkAsStolenClick = (id, st, n, d) => {
    setSelectedBatByNote(id);
    setStolen(st);
    setNote(n);
    setStolenDate(d);
    setNoteModal(true);
  };

  return (
    <div className="container">
      <NoteModal
        noteModal={noteModal}
        setNoteModal={setNoteModal}
        selectedByNote={selectedBatByNote}
        setSelectedByNote={setSelectedBatByNote}
        type="bat"
        stolen={stolen}
        list={bats}
        setList={setBats}
        oldNote={note}
        oldStolenDate={stolenDate}
      />
      {currentUser.type == "admin" || currentUser.type == "manager" ? (
        <div className="floatRight ">
          <NavLink to={"/bat/new"} className="greenLink mx-0">
            Add Bat
          </NavLink>
        </div>
      ) : (
        ""
      )}
      <h3> Batteries List </h3>
      <div className="filterContainer">
        {currentUser && currentUser.type == "admin" ? (
          <>
            <label className="filterLabelInput" htmlFor="branch">
              Branch
            </label>

            <select
              className="form-control filterInput"
              value={branchFilter}
              onChange={(e) => handleBranchChange(e.target.value)}
            >
              <option value="" key="0">
                All
              </option>
              {branches.map((branch, index) => (
                <option key={index} value={branch._id}>
                  {branch.label}
                </option>
              ))}
            </select>
          </>
        ) : null}

        <label className="filterLabelInput" htmlFor="numberFilter">
          Number
        </label>

        <input
          className="form-control filterInput"
          type="text"
          value={numberFilter}
          onKeyDown={(e) => handleKeyDown(e)}
          onChange={(e) => handleNumberChange(e.target.value)}
        />

        <label className="filterLabelInput" htmlFor="status">
          Status
        </label>
        <select
          className="form-control filterInput"
          value={statusFilter}
          onChange={(e) => handleStatusFilter(e.target.value)}
        >
          <option value="">All</option>
          {options.bike_status.map((item, index) => (
            <option key={"bike_status_" + index} value={item.key}>
              {item.value}
            </option>
          ))}
        </select>

        <label className="filterLabelInput" htmlFor="model">
          Model
        </label>
        <select
          className="form-control filterInput"
          value={modelFilter}
          onChange={(e) => handleModelFilter(e.target.value)}
        >
          <option value="">All</option>
          {models &&
            models.map((m, index) => (
              <option key={index} value={m._id}>
                {m.label}
              </option>
            ))}
        </select>

        <table border="0">
          <tr>
            <td>
              <button
                onClick={(e) => triggerSearch(e)}
                className="btn btn-primary btn-block search-button "
              >
                Go
              </button>
            </td>
            <td>
              <button
                onClick={(e) => resetSearch(e)}
                className="btn btn-primary btn-block reset-button "
              >
                Reset
              </button>
            </td>
          </tr>
        </table>
      </div>

      {error ? (
        <header className="jumbotron">
          {" "}
          <h3>{error}</h3>{" "}
        </header>
      ) : null}
      {!error && !loading && bats && bats.length != 0 ? (
        <div>
          <Row>
            <Col>
              <span style={{ float: "right", marginLeft: 10 }}>
                {total} results
              </span>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "first baseline",
            }}
          >
            {bats.map((b) => (
              <div style={{ float: "left", marginLeft: 20 }}>
                <Card
                  style={{
                    marginLeft: 0,
                    maxWidth: 250,
                    minHeight: 300,
                    paddingLeft: 10,
                    align: "top",
                  }}
                >
                  <Card.Body>
                    <Card.Title>{b.number}</Card.Title>
                    <NavLink to={"/bat/" + b._id} className="text-dark midText">
                      <i
                        alt="View"
                        title="View"
                        className="fa fa-binoculars"
                      ></i>
                    </NavLink>
                    &nbsp;&nbsp;&nbsp;
                    <NavLink
                      to={"/bat/edit/" + b._id}
                      className="text-dark midText"
                    >
                      <i alt="Edit" title="Edit" className="fa fa-pen"></i>
                    </NavLink>
                    &nbsp;&nbsp;&nbsp;
                    <NavLink
                      to={"/bat/clone/" + b._id}
                      className="text-dark midText"
                    >
                      <i alt="Clone" title="Clone" className="fa fa-clone"></i>
                    </NavLink>
                    {b.status === "Ready" ? (
                      <>
                        &nbsp;&nbsp;&nbsp;
                        <a
                          href="javascript:void(0)"
                          className="text-dark midText"
                          onClick={(e) => deleteBat(e, b._id)}
                        >
                          <i
                            alt="Delete"
                            title="Delete"
                            className="fa fa-trash"
                          ></i>
                        </a>
                      </>
                    ) : null}
                    {b.status === "Stolen" ? (
                      <>
                        &nbsp;&nbsp;&nbsp;
                        <a
                          href="javascript:void(0)"
                          className="red text-dark small-link midText"
                          onClick={() =>
                            handleMarkAsStolenClick(
                              b._id,
                              false,
                              b.notes,
                              b.stolenDate
                            )
                          }
                        >
                          Mark as Found
                        </a>
                      </>
                    ) : (
                      <>
                        &nbsp;&nbsp;&nbsp;
                        <a
                          href="javascript:void(0)"
                          className="red text-dark small-link midText"
                          onClick={() =>
                            handleMarkAsStolenClick(
                              b._id,
                              true,
                              b.notes,
                              b.stolenDate
                            )
                          }
                        >
                          Mark as Stolen
                        </a>
                      </>
                    )}
                    <br />
                    <span className="tinyCardLabel">Model:&nbsp;</span>
                    <span className="midText">
                      {b.model ? b.model.label : ""}
                    </span>
                    <br />
                    <span className="tinyCardLabel">Voltage:&nbsp;</span>
                    <span className="midText">
                      {b.model ? b.model.voltage + " V" : ""}
                    </span>
                    <br />
                    <span className="tinyCardLabel">Ampere:&nbsp;</span>
                    <span className="midText">
                      {b.model ? b.model.ampere + " AH" : ""}
                    </span>
                    <br />
                    <span className="tinyCardLabel">Status:&nbsp;</span>
                    <span className="midText">{b.status}</span>
                    <br />
                    <span className="tinyCardLabel">Date Added:&nbsp;</span>
                    <span className="midText">
                      {DateFromatter.DateFormatterOne(b.createdAt)}
                    </span>{" "}
                    <br />
                    {currentUser.type == "admin" ? (
                      <>
                        <span className="tinyCardLabel">Branch:&nbsp;</span>
                        <span className="midText">{b.branch.label}</span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {b.biker ? (
                      <>
                        <span className="tinyCardLabel">Biker:&nbsp;</span>
                        <span className="midText">{b.biker.full_name}</span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {b.company ? (
                      <>
                        <span className="tinyCardLabel">Company:&nbsp;</span>
                        <span className="midText">{b.company.name}</span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {b.package ? (
                      <>
                        <span className="tinyCardLabel">Package:&nbsp;</span>
                        <span className="midText">{b.package.title}</span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {b.notes ? (
                      <>
                        <span className="tinyCardLabel">Notes:&nbsp;</span>
                        <span className="midText">{b.notes}</span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {b.status === "Stolen" && b.stolenDate ? (
                      <>
                        <span className="tinyCardLabel">
                          Stolen Date:&nbsp;
                        </span>
                        <span className="midText">
                          {DateFromatter.DateFormatterOne(b.stolenDate)}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </Card.Body>
                </Card>
              </div>
            ))}
          </Row>

          <div className="mt-3">
            {"Items per Page: "}
            <select
              onChange={(e) => handlePageSizeChange(e.target.value)}
              value={itemsPerPage}
            >
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>

            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingtotal={1}
              boundarytotal={1}
              variant="outlined"
              color="primary"
              shape="rounded"
              onChange={handlePageChange}
              size="medium"
            />
          </div>
        </div>
      ) : loading ? (
        <Loader />
      ) : resetMsg != "" ? (
        <div className="defaultMsg">{resetMsg}</div>
      ) : (
        <div className="noResultsFound">{labels.NO_RESULTS}</div>
      )}
    </div>
  );
};

export default Bats;
