import React, { useState, useEffect, useRef, Fragment } from "react";
import SurveyService from "../services/survey.service";
import Input from "react-validation/build/input";
import RequiredLabel from '../common/RequiredLabel';
import OptionalLabel from '../common/OptionalLabel';
import { required } from '../common/Validation';
import Form from "react-validation/build/form";
import { NavLink } from "react-router-dom";
import { Col } from "react-bootstrap";
import '../LandingPage/css/about.css';
import AuthService from "../services/auth.service";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import '../assets/css/survey.css';
import sadFace from '../assets/img/faces/sad.png'; // imported from https://www.iconpacks.net/search/?icon=face
import fairFace from '../assets/img/faces/fair.png';
import goodFace from '../assets/img/faces/good.png';
import vgoodFace from '../assets/img/faces/vgood.png';
import excellentFace from '../assets/img/faces/excellent.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Prompt } from "react-router-dom";

const SurveyQuestionsView = (props) => {
    const formId = props.match ? props.match.params.id : props.formId;
    const setLabel = props.setLabel || null;
    const journeyId = props.journeyId || null;
    const questionsLoaded = props.questionsLoaded || false;
    const isCss = props.isCss || false;
    const oldList = props.oldList || [];
    const setOldList = props.setOldList || null;
    const closeFn = props.closeFn || null;
    const [questions, setQuestions] = useState(props.questions || []);
    const [answers, setAnswers] = useState([]);
    const [branch, setBranch] = useState(props.branch || "");
    const [saveButton, setSaveButton] = useState("Save");
    const [surveyDate, setSurveyDate] = useState("");
    const [introductionText, setIntroductionText] = useState("");
    const [userId, setUserId] = useState(props.userId || "");
    const [slider1to10, setSlider1to10] = useState(1);
    const [slider1to5, setSlider1to5] = useState(1);
    const [accessMessage, setAccessMessage] = useState("");
    const [answersDate, setAnswersDate] = useState([])
    const [iAmTyping, setIAmTyping] = useState(false);

    const alertUser = e => {
        e.preventDefault()
        e.returnValue = ''
    }

    useEffect(() => {

        function onReady() {
            if (!questionsLoaded) {
                fetchQuestions();
            } else {
                let list = questions;
                let a = [];
                list.map((q, i) => a.push({ question: q._id, answer: "" }));
                setAnswers(a);
                setQuestions(list);
            }
            window.addEventListener('beforeunload', alertUser)
            return () => {
                window.removeEventListener('beforeunload', alertUser)
            }

        }

        onReady()

    }, [])


    const setSurveyDatefn = (date, index) => {
        updateAnswers(index, date, false, '', true);
        let list = answersDate;
        list[index] = date;
        setAnswersDate(list);
        setSurveyDate(date);
    }
    const fetchQuestions = async () => {
        SurveyService.getQuestionsListByFormId(formId)
            .then(({ data }) => {
                let list = data.data.list;
                let formObj = data.data.formObj;

                //form not found  
                // or 
                //form is not public  and not disabled and userType not admin or helpdesk or manager
                let isInternalUser = (!currentUser || (currentUser.type !== "admin" && currentUser.type !== "helpdesk" && currentUser.type !== "manager")) ? false : true;
                if (!formObj || ((!formObj.public || formObj.disabled) && !isInternalUser)) {
                    //setAccessMessage
                    setAccessMessage("You don't have access to this form.");
                    return;
                }
                setIntroductionText(formObj.introductionText ? formObj.introductionText : "");
                setSaveButton(formObj.saveButton ? formObj.saveButton : "Save");

                if (setLabel) setLabel(formObj.label);
                setBranch(formObj.branch)
                let a = [];
                list.map((q, i) => a.push({ question: q._id, answer: "" }));
                setAnswers(a);
                setQuestions(list);

            },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    alert(_content);


                }
            );
    }
    const currentUser = AuthService.getCurrentUser();
    const updateAnswers = (index, answer, isCheckbox, checked, isDate) => {
        if (isDate) {
            const temp = new Date(answer);
            answer = temp.getDate().toString().padStart(2, '0') + '-' +
                (temp.getMonth() + 1).toString().padStart(2, '0') + '-' + temp.getFullYear(); // dd-MM-yyyy
        }

        let list = answers;
        if (isCheckbox && list[index]) {
            if (checked) list[index].answer = [...list[index].answer, answer];
            else list[index].answer = list[index].answer.filter(s => s !== answer);
        }
        else list[index].answer = answer;
        setAnswers(list);
        /*
        setAnswers(answers.map((a, i) => {
            if (a.question === questionId) {
                if (isCheckbox) {
                    if (checked) a.answer = [...a.answer, answer];
                    else a.answer = a.answer.filter(s => s !== answer);
                }
                else a.answer = answer;
            }
            return a;
        }))*/

    }

    const handleCancel = (e) => {
        if (isCss) {
            e.preventDefault();
            closeFn();
        }
    }
    const handleSaveAnswers = (e) => {
        e.preventDefault();
        setIAmTyping(false);
        let isFilled = true;
        setAnswers(answers.map((a, i) => {
            if (questions[i].isrequired && a.answer === '') {
                isFilled = false;
                return null;
            }
            if (questions[i].answerType === "Checkbox" && a.answer.length > 0) {
                a.answer = Array.isArray(a.answer) ? a.answer.join(", ") : "";
            }
            return a;
        }))
        if (!isFilled) {
            alert("Please fill all the questions.");
            return;
        }

        let data = {
            form: formId,
            journeyId: journeyId,
            user: userId,
            branch: branch,
            answers: answers
        }

        SurveyService.saveAnswers(data).then(
            (response) => {
                alert("Your answers have been saved.");

                if (isCss) {
                    setOldList(oldList.map((j, i) => {
                        if (j._id === journeyId && response.data && response.data.data && response.data.data.survey) {
                            j.survey = {};
                            j.survey._id = response.data.data.survey;

                        }
                        return j;
                    }))
                    closeFn();
                }
                else {
                    window.location.reload();
                }

            }
        )
    }

    return (
        <Fragment>
            <Prompt
                when={iAmTyping}
                message={(location) =>
                    'Are you sure, you want to leave? All your data will be lost!'
                }
            />
            {introductionText ? <section style={{ marginBottom: 15 }} dangerouslySetInnerHTML={{ __html: introductionText }}></section> : ""}
            <Form id="myForm">

                {!accessMessage && questions && questions.length > 0 ? questions.map((q, index) =>
                    <div className="form-group">
                        {q.isrequired ?
                            <RequiredLabel htmlFor="question" text={q.order + ". " + q.label} />
                            :
                            <OptionalLabel htmlFor="question" text={q.order + ". " + q.label} />
                        }
                        {q.answerType === "Radio" || q.answerType === "yes_no" || q.answerType === "yes_maybe_no" ?
                            q.answerOptions && q.answerOptions.length > 0 ?
                                q.answerOptions.map((a, i) =>
                                    <div className="d-flex flex-row align-items-center">
                                        <Input type="radio" id={a} name={"answer_options" + index} value={a} onChange={() => { updateAnswers(index, a); setIAmTyping(true); }} validations={[required]} />&nbsp;&nbsp;
                                        <label for={a}>{a}</label>
                                    </div>
                                ) : ""
                            :
                            q.answerType === "Checkbox" ?
                                q.answerOptions && q.answerOptions.length > 0 ?
                                    q.answerOptions.map((a, i) =>
                                        <div className="d-flex flex-row align-items-center">
                                            <input type="checkbox" id={a} name="answer_options_checkbox" value={a} onChange={(e) => { updateAnswers(index, a, true, e.target.checked); setIAmTyping(true); }} />&nbsp;&nbsp;
                                            <label for={a}>{a}</label>
                                        </div>
                                    )
                                    : ""
                                :
                                q.answerType === "date" ?

                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        className="form-control"
                                        onChange={date => {
                                            setSurveyDatefn(date, index);
                                            setIAmTyping(true);
                                        }}
                                        selected={answersDate[index] ? answersDate[index] : null}
                                        name={"surveyDate" + index}
                                        validations={[required]}
                                    />


                                    :
                                    q.answerType === "input" ?
                                        <Input
                                            type="text"
                                            className="form-control"
                                            onChange={(e) => {
                                                updateAnswers(index, e.target.value);
                                                setIAmTyping(true);
                                            }}
                                            validations={[required]}
                                        />
                                        :
                                        q.answerType === "textarea" ?
                                            <textarea
                                                className="form-control"
                                                rows={5}
                                                onChange={(e) => {
                                                    updateAnswers(index, e.target.value);
                                                    setIAmTyping(true);
                                                }} />
                                            :
                                            q.answerType === "slider1to10" ?
                                                <>
                                                    <Slider
                                                        dots
                                                        min={1}
                                                        max={10}
                                                        marks={{
                                                            '1': '1',
                                                            2: '2',
                                                            3: '3',
                                                            4: '4',
                                                            5: '5',
                                                            6: '6',
                                                            7: '7',
                                                            8: '8',
                                                            9: '9',
                                                            10: '10',
                                                        }}
                                                        step={1}
                                                        onChange={(value) => { setSlider1to10(value); updateAnswers(index, value); setIAmTyping(true); }}
                                                        defaultValue={1}
                                                        value={slider1to10} />

                                                </>
                                                :
                                                q.answerType === "faces1to5" ?


                                                    <Slider
                                                        dots
                                                        min={1}
                                                        max={5}
                                                        marks={{
                                                            '1': <img src={sadFace} alt="sad face" />,
                                                            2: <img src={fairFace} alt="fair face" />,
                                                            3: <img src={goodFace} alt="good face" />,
                                                            4: <img src={vgoodFace} alt="v good face" />,
                                                            5: <img src={excellentFace} alt="excellent face" />,
                                                        }}
                                                        step={1}
                                                        onChange={(value) => { setSlider1to5(value); updateAnswers(index, value); setIAmTyping(true); }}
                                                        defaultValue={1}
                                                        value={slider1to5} />

                                                    : q.answerType === "slider1to5" ?


                                                        <Slider
                                                            dots
                                                            min={1}
                                                            max={5}
                                                            marks={{
                                                                '1': '1',
                                                                2: '2',
                                                                3: '3',
                                                                4: '4',
                                                                5: '5',
                                                            }}
                                                            step={1}
                                                            onChange={(value) => { setSlider1to5(value); updateAnswers(index, value); setIAmTyping(true); }}
                                                            defaultValue={1}
                                                            value={slider1to5} />
                                                        : ""}
                        <br />
                    </div>

                ) : <div className="red" style={{ textAlign: "center" }}>{accessMessage}</div>}

                {!accessMessage ?
                    <div className='d-flex flex-row justify-content-center'>

                        <Col sm={4} ><button id="survey_button" style={{ margin: 10 }} onClick={handleCancel}>Cancel</button></Col>
                        <Col sm={4} ><button id="survey_button"  style={{ margin: 10 }} onClick={handleSaveAnswers}>{saveButton}</button></Col>

                    </div> : ""}


            </Form>
        </Fragment>
    )
}

export default SurveyQuestionsView;