import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";


const getFaqsList = (branch, keyword, onlyNoAnswer, offset, limit, onlyList, country, category) => {
  return axios.get(API_URL + "/admin/faq/list?branch=" + branch + "&keyword=" + keyword + "&onlyNoAnswer=" + onlyNoAnswer + "&offset=" + offset + "&limit=" + limit + "&onlyList=" + onlyList + "&country=" + country + "&category=" + category, { headers: authHeader() });
};

const deleteFaq = (id) => {
  return axios(API_URL + "/admin/faq/delete/" + id, { method: 'get', headers: authHeader() });
};



const postFaq = (id, title, content, status, branch, category) => {
  return axios(API_URL + "/admin/faq/save", { method: 'post', headers: authHeader(), data: { _id: id, title, content, status, branch, category } });
};


const getFaqById = (id) => {
  return axios.get(API_URL + "/admin/faq/" + id, { headers: authHeader() });
};

const getFaqDetailsById = (id) => {
  return axios.get(API_URL + "/admin/faq/details/" + id, { headers: authHeader() });
};

export default {
  postFaq,
  deleteFaq,
  getFaqsList,
  getFaqById,
  getFaqDetailsById
};
