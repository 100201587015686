import React, { useState, useLayoutEffect } from 'react'
import Accordion from 'react-bootstrap/Accordion'

export default function AccordionItem(props) {

    const question = props.question;
    const index = props.index;
    const answer = props.answer;

    const [isActive, setIsAvtive] = useState()

    useLayoutEffect(()=>{
        setIsAvtive(false)
    }, [])

    return (
        <div onClick={() => setIsAvtive(!isActive)} className='Accordion_items_container'>
            <Accordion.Item eventKey={index}>
                <Accordion.Header>
                    <div>{question}</div>
                    {isActive ? <div>-</div> : <div>+</div>}
                </Accordion.Header>
                <div className='Accordion_items_body'>
                    <Accordion.Body>
                        {answer}
                    </Accordion.Body>
                </div>
            </Accordion.Item>
        </div>
    )
}
