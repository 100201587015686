import React from 'react';
import { createContext, useState } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

    const currentUser = JSON.parse(localStorage.getItem('user')) || {
        _id: 0,
        isAuth: false
    };

    const [user, setUser] = useState(currentUser)

    const userHandler = (user) => {
        setUser(user)
        // localStorage.setItem('user', JSON.stringify(user))
    }

    return(
        <UserContext.Provider value={{user, userHandler}}>
            {children}
        </UserContext.Provider>
    )

}