import React from "react";
import ReactDOM from "react-dom";
import ReactGA from 'react-ga';

import { BrowserRouter } from "react-router-dom";
import { UserProvider } from './context/UserContext'

import App from "./App";
import * as serviceWorker from "./serviceWorker";


const TRACKING_ID = process.env.REACT_APP_GOOGLE_TRACK_ID; 
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();