import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const getTerminateReasons = (startDate, endDate, keyword, offset, limit) => {
  return axios.get(
    API_URL +
      "/terminate/reasons?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&keyword=" +
      keyword +
      "&offset=" +
      offset +
      "&limit=" +
      limit,
    { headers: authHeader() }
  );
};

const getRevenues = (startDate, endDate) => {
  return axios.get(
    API_URL +
      "/revenue/analytic?startDate=" +
      startDate +
      "&endDate=" +
      endDate,
    { headers: authHeader() }
  );
};
export default {
  getTerminateReasons,
  getRevenues,
};
