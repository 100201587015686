import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const getTotalUsers = (branch, waitingList, clientsOnly, waitingStatus) => {
  return axios.get(
    API_URL +
      "/dashboard/total/users?branch=" +
      branch +
      "&waitingList=" +
      waitingList +
      "&clientsOnly=" +
      clientsOnly +
      "&waitingStatus=" +
      waitingStatus,
    { headers: authHeader() }
  );
};

const getTotalBikes = (branch, type, bikeStatus) => {
  return axios.get(
    API_URL +
      "/dashboard/total/bikes?branch=" +
      branch +
      "&type=" +
      type +
      "&status=" +
      bikeStatus,
    { headers: authHeader() }
  );
};

const getTotalUnanswered = (branch) => {
  return axios.get(API_URL + "/dashboard/total/questions?branch=" + branch, {
    headers: authHeader(),
  });
};

const getInvoicesStats = (branch) => {
  return axios.get(API_URL + "/dashboard/invoices/stats?branch=" + branch, {
    headers: authHeader(),
  });
};

const getNewlyRegistered = (branch, dateAddedAfter) => {
  return axios.get(
    API_URL +
      "/dashboard/total/users?approved=no&type=registered&dateAddedAfter=" +
      dateAddedAfter +
      "&branch=" +
      branch,
    { headers: authHeader() }
  );
};

const getNewlySubscriptions = (fromDate, toDate, isWeekly) => {
  return axios.get(
    API_URL +
      "/dashboard/subscriptions/new?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate +
      "&isWeekly=" +
      isWeekly,
    { headers: authHeader() }
  );
};

const getAccumulatedSubscriptions = (fromMonth, fromYear, toMonth, toYear) => {
  return axios.get(
    API_URL +
      "/dashboard/subscriptions/accumulated?start_month=" +
      fromMonth +
      "&start_year=" +
      fromYear +
      "&end_month=" +
      toMonth +
      "&end_year=" +
      toYear,
    { headers: authHeader() }
  );
};
const getRegisterSources = (fromDate, toDate, subTypeFilter) => {
  return axios.get(
    API_URL +
      "/user/source/analytics?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate +
      "&subTypeFilter=" +
      subTypeFilter,
    { headers: authHeader() }
  );
};

export default {
  getTotalUsers,
  getTotalBikes,
  getTotalUnanswered,
  getInvoicesStats,
  getNewlyRegistered,
  getNewlySubscriptions,
  getAccumulatedSubscriptions,
  getRegisterSources,
};
