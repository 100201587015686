import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/WLogo-01.png";
import bike from "../assets/bike.svg";
import calender from "../assets/images/calender.svg";
import pen from "../assets/images/pen.svg";
import "../css/home.css";
import Accent from "../components/Accent";
import OurBikeImg from "../assets/images/OurBike.jpg";
import LandingGuyImg from "../assets/LandingGuy.jpg";
import PaymentPlans from "./PaymentPlansSection";
import waveapp from "../assets/WaveApp.jpg";
import Testimonials from "./TestimonialSection";
import WhyWave from "./WhyWaveSection";
import RecentFeatures from "./RecentFeatures";
import { trackPage } from "../components/GoogleAnalytics";

export default function Home() {
  function scrolldiv(id) {
    var elem = document.getElementById(id);
    elem.scrollIntoView();
  }

  const bikeSteps = [
    {
      icon: false,
      image: pen,
      title: "Sign Up",
      content: `Sign up <a href="/signup">here</a> or through the Wave app available on  <a href="https://play.google.com/store/apps/details?id=com.waveB&pcampaignid=web_share" target="_blank" >Play Store</a> and <a href="https://apps.apple.com/lb/app/wave-bike/id1473328880" target="_blank">App Store</a> `,
    },
    {
      icon: false,
      image: calender,
      title: "Book your bike",
      content: "Book a bike and choose your pickup date and branch",
    },
    {
      icon: false,
      image: bike,
      title: "Pick-up your Wave",
      content: `Pick up your bike from the <a href="https://www.google.com/maps/place/Wave/@33.8919643,35.5307855,18.94z/data=!4m5!3m4!1s0x151f17711b93c4ff:0x6a6c9a22d10b2c7e!8m2!3d33.8922325!4d35.5310995?hl=en" target="_blank">Wave hub</a> in Geitawi`,
    },
    {
      icon: false,
      image: logo,
      title: "Ready to go",
      content: "Put on your helmet & enjoy your new trendy ride!",
    },
  ];

  useEffect(() => {
    if (window.location.href.indexOf("landing_home_fees") > -1) {
      trackPage("Fees");
      scrolldiv("landing_home_fees");
    } else {
      trackPage("Home");
    }
  }, []);

  return (
    <div className="outer_container">
      <div className="inner_container">
        <div className="home-header">
          <div>
            <div className="landing_titles">
              <h1>
                <b>Wave through Lebanon</b>
              </h1>
              <p style={{ fontSize: "14px" }}>
                Get around the city more efficiently while saving money, time
                and staying healthy, with a simple monthly subscription to the
                safest and trendiest electric bike.
                <br />
                <span style={{ color: "#3CB4E5" }}>
                  Subscription plans starting at $49/month only.
                </span>{" "}
                <br />
                <br />
                Join our community today!
              </p>
              <button
                id="landing_home_reserve_now_button"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/signup";
                }}
              >
                Subscribe Now
              </button>{" "}
            </div>
          </div>
          <div>
            <div>
              <img src={OurBikeImg} alt="Wave electric bike" width={"100%"} />
            </div>
          </div>
        </div>
      </div>
      <div className="inner_container">
        <div className="landing_video">
          <iframe src="https://www.youtube.com/embed/Ix8ZOursyj8?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Fwww.wave.bike&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1"></iframe>
        </div>
      </div>
      <section className="inner_container">
        <div id="how_it_works">
          <div className="landing_titles">
            <h1>
              <b className="pageTitle">
                <Accent>
                  <span>How it works</span>
                </Accent>
              </b>
            </h1>
          </div>

          <div className="get_bike_steps">
            {bikeSteps.map((step, index) => {
              return (
                <div key={`step-${index}`}>
                  {step.icon && (
                    <div>
                      <FontAwesomeIcon icon={step.icon} color="#1FB59A" />
                    </div>
                  )}
                  {step.image && (
                    <div>
                      <img
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                          alignSelf: "stretch",

                          height: "30px",
                          textAlign: "center",
                          margin: "0 auto",
                        }}
                        src={step.image}
                        alt="Wave, electric bike for monthly rent in Beirut, Lebanon"
                      />
                    </div>
                  )}
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: 700,
                      textAlign: "center",
                      margin: "0 auto",
                      color: "#595959",
                    }}
                  >
                    <b>{step.title}</b>
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      textAlign: "center",
                      margin: "0 auto",
                      color: "#595959",
                      padding: "0 40px",
                    }}
                    dangerouslySetInnerHTML={{ __html: step.content }}
                  />
                </div>
              );
            })}
          </div>
          <div className="button_container">
            <button
              id="landing_home_how_it_Works_button"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/signup";
              }}
            >
              Subscribe Now
            </button>
          </div>
        </div>
      </section>
      <div className="inner_container">
        <div className="designing_the_home_page_img">
          <img src={waveapp} />
        </div>
      </div>
      <PaymentPlans />
      <div className="inner_container">
        <div className="designing_the_home_page_img">
          <img src={LandingGuyImg} />
        </div>
      </div>
      <WhyWave />
      <Testimonials />
      <RecentFeatures />
      <section className="inner_container padding_container">
        <p id="centering_the_text">
          Your safety is our top priority. We’ve got you covered with the safest
          bicycle and service for your peace of mind on every ride.
        </p>
      </section>
    </div>
  );
}
